import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const SvgNorvegia = (props) => (_jsxs("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 200 200", focusable: "false", "aria-hidden": "true" }, props, { children: [_jsx("path", { d: "M169.836 2081.51c52.852-127.51 230.789-486.27 591.355-582.63 361.909-96.66 693.919 124.78 803.079 208.87-52.8 127.57-230.62 486.58-591.172 583.23-360.422 96.57-693.711-125.32-803.262-209.47m777.859-752.13c-72.257 0-147.265 8.74-223.972 29.21-516.235 137.97-701.817 700.03-709.465 723.89L0 2126.95l34.55 31.41c18.536 16.86 459.981 411.19 976.13 272.87 516.1-138.34 701.5-700.54 709.13-724.41l14.25-44.5-34.6-31.39c-15.77-14.32-337.85-301.55-751.765-301.55", style: {
                fill: "#c4193b",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }, transform: "matrix(.0581 0 0 -.0581 2.545 197.455)" }), _jsx("path", { d: "M2081.66 3185.24c-127.42-52.79-486.4-230.74-582.8-591.4-96.21-360.5 125.04-693.5 209.03-803.04 127.67 52.85 486.49 230.66 583.09 591.11 96.99 361.87-125.07 694.16-209.32 803.33m-419.33-1564.28-31.39 34.6c-16.81 18.52-410.12 459.57-272.37 975.74 137.99 516.22 700.07 701.83 723.94 709.48l44.47 14.25 31.41-34.56c16.85-18.54 411.17-460.02 272.84-976.15-138.32-516.08-700.54-701.48-724.4-709.11z", style: {
                fill: "#c4193b",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }, transform: "matrix(.0581 0 0 -.0581 2.545 197.455)" }), _jsx("path", { d: "M1790.81 1647.13c52.85-127.67 230.67-486.48 591.11-583.09 361.79-97.009 694.15 125.07 803.32 209.33-52.78 127.41-230.72 486.38-591.39 582.78-360.48 96.23-693.5-125.02-803.04-209.02m777.75-752.712c-72.34 0-147.43 8.781-224.23 29.371-516.08 138.341-701.48 700.531-709.12 724.381l-14.24 44.5 34.6 31.4c18.52 16.81 459.59 410.16 975.75 272.36 516.23-137.98 701.81-700.05 709.45-723.91l14.26-44.46-34.55-31.42c-15.78-14.34-338.06-302.222-751.92-302.222", style: {
                fill: "#c4193b",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }, transform: "matrix(.0581 0 0 -.0581 2.545 197.455)" }), _jsx("path", { d: "M1647.15 1564.19c-127.67-52.84-486.5-230.64-583.11-591.081-96.599-360.437 125.31-693.718 209.47-803.281 127.51 52.852 486.28 230.793 582.64 591.352 96.23 360.49-125.02 693.48-209 803.01M1228.06 0l-31.41 34.55c-16.86 18.552-411.181 460.02-272.861 976.14 138.341 516.08 700.551 701.46 724.411 709.1l44.5 14.24 31.39-34.59c16.81-18.53 410.13-459.56 272.35-975.729-137.97-516.231-700.05-701.813-723.91-709.45z", style: {
                fill: "#c4193b",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }, transform: "matrix(.0581 0 0 -.0581 2.545 197.455)" }), _jsx("path", { d: "M169.719 1273.61c109.422-83.93 442.996-305.84 803.551-208.97 361.75 97.3 538.56 455.04 591.04 582.44-109.5 84.08-442.98 306.07-803.58 209.52-361.875-96.95-538.601-455.43-591.011-582.99m616.48-378.692c-413.64 0-735.808 287.342-751.601 301.662L.02 1227.97l14.218 44.48c7.625 23.87 192.782 586.12 708.926 724.4 516.136 138.25 957.766-255.95 976.316-272.8l34.59-31.41-14.27-44.49c-7.63-23.81-192.92-584.96-708.84-723.74-76.944-20.66-152.269-29.492-224.761-29.492", style: {
                fill: "#c4193b",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }, transform: "matrix(.0581 0 0 -.0581 2.545 197.455)" }), _jsx("path", { d: "M1273.64 3185.32c-83.95-109.42-305.859-443.02-209.01-803.57 96.92-360.29 455.04-538.05 582.56-590.93 84.13 109.6 305.96 443 209.4 803.45-96.94 361.89-455.4 538.64-582.95 591.05m419.02-1564.36-44.49 14.27c-23.82 7.63-584.98 192.94-723.768 708.83-138.601 516.05 255.378 957.83 272.208 976.39l31.39 34.58 44.48-14.23c23.87-7.62 586.09-192.8 724.37-708.96 138.25-516.09-255.93-957.75-272.78-976.29z", style: {
                fill: "#c4193b",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }, transform: "matrix(.0581 0 0 -.0581 2.545 197.455)" }), _jsx("path", { d: "M1790.82 1707.85c109.6-84.14 442.99-306 803.46-209.43 361.87 96.95 538.61 455.41 591.03 582.97-109.42 83.95-443.05 305.85-803.56 208.99-360.31-96.9-538.06-455.02-590.93-582.53m616.87-379.02c-413.96 0-736.35 287.78-752.14 302.13l-34.59 31.41 14.26 44.49c7.63 23.82 192.92 584.99 708.83 723.74 516.1 138.6 957.83-255.35 976.38-272.18l34.58-31.38-14.22-44.49c-7.63-23.86-192.8-586.1-708.94-724.39-76.74-20.54-151.87-29.33-224.16-29.33", style: {
                fill: "#c4193b",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }, transform: "matrix(.0581 0 0 -.0581 2.545 197.455)" }), _jsx("path", { d: "M1707.86 1564.21c-84.14-109.6-305.98-443.02-209.43-803.491 96.57-360.418 455.39-538.098 583.04-590.899 84.02 109.532 305.76 443.032 208.92 803.442-96.91 360.318-455.02 538.078-582.53 590.948M2127.04.02l-44.48 14.218c-23.87 7.633-586.1 192.813-724.39 708.91-138.24 516.122 255.96 957.792 272.81 976.342l31.41 34.58 44.49-14.26c23.81-7.64 584.97-192.93 723.73-708.85 138.64-516.019-255.35-957.812-272.18-976.358z", style: {
                fill: "#c4193b",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }, transform: "matrix(.0581 0 0 -.0581 2.545 197.455)" }), _jsx("path", { d: "M395.309 1371.73v612.09c0 45.07 35.539 81.61 79.367 81.61H2880.39c43.82 0 79.39-36.54 79.39-81.61v-612.09c0-45.04-35.57-81.62-79.39-81.62H474.676c-43.828 0-79.367 36.58-79.367 81.62", style: {
                fill: "#223162",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }, transform: "matrix(.0581 0 0 -.0581 2.545 197.455)" }), _jsx("path", { d: "M640.809 1811.57h43.562l143.352-185.02v185.02h46.25v-267.58h-39.375l-147.551 190.36v-190.36h-46.238zM1160.62 1677v.77c0 51.99-37.83 95.17-90.97 95.17-53.12 0-90.201-42.41-90.201-94.41v-.76c0-51.98 37.831-94.79 90.981-94.79 53.12 0 90.19 42.05 90.19 94.02m-230.499 0v.77c0 75.31 58.109 138.38 140.309 138.38 82.17 0 139.52-62.31 139.52-137.62v-.76c0-75.3-58.11-138.37-140.3-138.37-82.181 0-139.529 62.31-139.529 137.6M1382.67 1678.93c33.65 0 55.06 17.57 55.06 44.71v.76c0 28.68-20.65 44.36-55.43 44.36h-68.42v-89.83zm-115.81 132.64h119.27c33.64 0 60.02-9.95 77.22-26.76 14.13-14.55 22.16-34.41 22.16-57.73v-.77c0-43.96-26.37-70.33-63.45-81.03l72.23-101.29h-55.81l-65.73 93.26h-58.87v-93.26h-47.02zM1514.53 1811.57h51.99l80.66-205.66 80.27 205.66h50.84l-110.86-269.49h-41.65zM1816.5 1811.57h198.38v-42.06h-151.37v-69.56h134.17v-42.05h-134.17v-71.85h153.28v-42.06H1816.5zM2062.64 1677v.77c0 74.92 57.34 138.38 137.99 138.38 46.63 0 75.3-13 102.83-36.32l-29.82-35.55c-20.64 17.6-40.89 28.66-74.92 28.66-49.31 0-86.76-43.18-86.76-94.41v-.76c0-55.03 36.3-95.55 90.97-95.55 25.22 0 48.16 8.02 64.61 20.25v50.07h-68.44v40.91h113.92v-112.37c-26.37-22.57-64.22-41.68-111.62-41.68-83.71 0-138.76 59.63-138.76 137.6M2376.81 1543.99h47.031v267.582h-47.031ZM2643.99 1650.24l-46.24 107.06-45.88-107.06zm-67.27 163.23h43.57l117.74-269.48h-49.69l-27.14 64.59h-126.53l-27.52-64.59h-48.17zM2738.85 1758.1c-16.13 0-28.77 12.71-28.77 29.17 0 16.08 12.91 29.17 28.77 29.17 16.16 0 28.79-12.74 28.79-29.17 0-16.09-12.92-29.17-28.79-29.17m0 64.04c-19.11 0-34.67-15.65-34.67-35.06 0-19.14 15.56-34.69 34.67-34.69 19.14 0 34.69 15.65 34.69 35.06 0 19.13-15.55 34.69-34.69 34.69", style: {
                fill: "#fff",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }, transform: "matrix(.0581 0 0 -.0581 2.545 197.455)" }), _jsx("path", { d: "M2740.6 1788.05c4.46 0 6.96 2.32 6.96 5.61 0 3.67-2.5 5.61-6.96 5.61h-8.31v-11.22zm-14.89 17.01h15.48c7.54 0 13.15-3.66 13.15-10.82 0-5.63-3.29-9.1-7.94-10.46l9.09-12.97h-7.93l-8.13 11.82h-7.14v-11.82h-6.58z", style: {
                fill: "#fff",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }, transform: "matrix(.0581 0 0 -.0581 2.545 197.455)" })] })));
export default SvgNorvegia;
