import { cn } from "@tine/designsystem-utils";
import type { HTMLAttributes, PropsWithChildren } from "react";

type BoxWithFlowerProps = {
   theme: "main" | "christmas";
} & HTMLAttributes<HTMLDivElement>;

const tineFlowersImage = new URL("../../../img/tine-flowers.png", import.meta.url).toString();
const whiteFlowersImage = new URL("../../../img/white-flowers.png", import.meta.url).toString();

const BoxWithFlower = ({ theme, className, children }: PropsWithChildren<BoxWithFlowerProps>) => {
   const commonClasses = "tw-flex tw-flex-col tw-rounded-lg tw-p-5 tw-gap-4 tw-relative";

   const themeClasses = {
      main: "tw-text-ink-brand-default tw-mt-6 tw-bg-infoBoxBackground",
      christmas: "tw-text-ink-brand-inverted [&_label]:tw-text-base-0 tw-bg-christmas"
   }[theme];

   return (
      <div className={cn(className, commonClasses, themeClasses)}>
         {theme === "main" && (
            <div className="tw-absolute tw-left-[calc(50%-45px)] tw-top-[-22px]">
               <img src={tineFlowersImage} alt="blomster" className="tw-w-[90px]" />
            </div>
         )}
         {theme === "christmas" && (
            <div className="tw-mt-2 tw-flex tw-items-center tw-justify-center">
               <img src={whiteFlowersImage} alt="blomster" className="tw-w-[90px]" />
            </div>
         )}
         <div className={theme === "main" ? "tw-pt-6" : ""}>{children}</div>
      </div>
   );
};

export default BoxWithFlower;
