import { view } from "@risingstack/react-easy-state";
import { Grid } from "@tine/designsystem-ui-react";
import range from "lodash-es/range";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";

import type { ContentfulArticle, ContentfulRecipe, ContentfulSection } from "../../common/types/cmsTypes";
import type { RecipeType } from "../../common/types/recipeTypes";
import { initializeWithDefaultData, isDataAvailable, isDefaultData, isWaitingForData } from "../../common/utils/asyncDataUtils";

import contentStore from "../../stores/cms/contentStore";

import theme from "../../themes/theme";
import CallToAction from "./CallToAction";
import ContentBreadcrumb from "./ContentBreadcrumb";
import SectionNavigator from "./SectionNavigator";
import RecipeTeaser from "./recipe/RecipeTeaser";

type SectionProps = {
   id: string;
   section: ContentfulSection;
};
const Section = ({ id, section }: SectionProps) => {
   if (!contentStore.sectionContent[id]) {
      contentStore.sectionContent[id] = initializeWithDefaultData([]);
   }
   const sectionContent = contentStore.sectionContent[id];

   if (isDefaultData(sectionContent)) {
      void contentStore.fetchSectionContentForId(id);
   }

   return (
      <>
         <ContentBreadcrumb />
         {!!section.fields.title && (
            <Helmet>
               <title>{`${section.fields.title} - ${theme.siteName}`}</title>
            </Helmet>
         )}

         <SectionNavigator />

         {isDataAvailable(sectionContent) && (
            <Grid
               cols={{
                  base: 1,
                  sm: 2,
                  md: 3,
                  lg: 4
               }}
               gap={4}
            >
               {sectionContent.data.map((object) => {
                  if (object.sys.contentType.sys.id === "recipe") {
                     const recipeContent = object as ContentfulRecipe;

                     const id = recipeContent.sys.id;
                     const recipe = recipeContent.fields.recipeData as RecipeType;
                     const title = recipeContent.fields.title;
                     if (recipe) {
                        return <RecipeTeaser key={`recipe-teaser-${object.sys.id}`} id={id} recipe={recipe} title={title} />;
                     }
                  } else {
                     const article = object as ContentfulArticle;
                     const id = article.sys.id;
                     const callToAction = article.fields.callToAction || article.fields.title || "";
                     const imageId = article.fields.headerImage?.sys.id || "";
                     const subTitle = article.fields.callToActionSubTitle;
                     return (
                        <CallToAction
                           key={`cta-${id}`}
                           id={id}
                           callToAction={callToAction}
                           imageId={imageId}
                           subTitle={subTitle}
                        />
                     );
                  }
               })}
            </Grid>
         )}

         {(isDefaultData(sectionContent) || isWaitingForData(sectionContent)) && (
            <Grid cols={4} gap={4}>
               {range(6).map((x) => (
                  <div key={`skeleton${x}`}>
                     <Skeleton width="100%" style={{ paddingBottom: "56.24%" }} />
                  </div>
               ))}
            </Grid>
         )}
      </>
   );
};

export default view(Section);
