import { cn } from "@tine/designsystem-utils";
import { Link } from "wouter";

type TagLinkProps = {
   url: string;
   label: string;
   isActive?: boolean;
};

const styles = (isActive: boolean) =>
   cn(
      "tw-text-nowrap tw-rounded-lg tw-border tw-border-solid tw-border-ink-border-subtle",
      "tw-px-4 tw-py-2 tw-text-sm hover:tw-bg-surface-default-hover",
      {
         "tw-bg-surface-default-hover": isActive
      }
   );

const TagLink = ({ url, label, isActive = false }: TagLinkProps) => (
   <Link to={url}>
      <div className={styles(isActive)}>{label}</div>
   </Link>
);

export default TagLink;
