import { useEffect } from "react";

import authStore from "../../stores/auth/authStore";

// Ensures that effect runs when isLoggedIn changes, but only when it changes to true.
const useWhenLoggedIn = (callback: () => void, additionalDeps: unknown[] = []) => {
   const isLoggedIn = authStore.isLoggedIn();
   useEffect(() => {
      if (authStore.isLoggedIn()) {
         callback();
      }
   }, [...additionalDeps, isLoggedIn]);
};

export default useWhenLoggedIn;
