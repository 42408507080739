import { view } from "@risingstack/react-easy-state";
import { Alert, GridSimple } from "@tine/designsystem-ui-react";
import type { Entry } from "contentful";
import { uniqBy } from "lodash-es";
import take from "lodash-es/take";

import type {
   ContentfulArticle,
   ContentfulImageLink,
   ContentfulImageLinkToUrl,
   ContentfulRecipe
} from "../../common/types/cmsTypes";
import type { TypeCallToActionListSkeleton } from "../../common/types/contentful";
import type { RecipeData, RecipeType } from "../../common/types/recipeTypes";
import useMediaQuery from "../../common/useMediaQuery";
import { mediaQueries } from "../../common/utils";
import { getModuleVisibility } from "../../common/utils/segmentUtils";

import segmentStore from "../../stores/cms/segmentStore";

import EntryImageLink from "./EntryImageLink";
import TeaserListLayout from "./TeaserListLayout";
import UrlImageLink from "./UrlImageLink";
import RecipeTeaser from "./recipe/RecipeTeaser";

type ListType = "standard" | "recipe";

const determineListType = (entries: TeaserListProps["content"]["fields"]["contentList"]): ListType => {
   if (entries?.every((entry) => entry?.sys.contentType.sys.id === "recipe")) {
      return "recipe";
   }

   return "standard";
};

type TeaserListProps = {
   content: Entry<TypeCallToActionListSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>;
};

const TeaserList = ({ content }: TeaserListProps) => {
   const isLargerThanSm = useMediaQuery(mediaQueries.sm);
   const isLargerThanMd = useMediaQuery(mediaQueries.md);

   const isVisible = getModuleVisibility(content, segmentStore.currentCustomerProperties);
   if (!isVisible) {
      return null;
   }

   if (content.fields.contentList) {
      const contentList = content.fields.contentList;
      const filteredContentList = contentList.filter((content) => {
         const contentType = content?.sys?.contentType?.sys?.id;
         if (content && contentType === "v2_article") {
            return segmentStore.isArticleVisible(content.sys.id);
         }

         if (content && contentType === "imageLink") {
            const targetEntry = (content as ContentfulImageLink).fields.entry;
            if (targetEntry) {
               return segmentStore.isArticleVisible(targetEntry.sys.id);
            }
         }

         if (content && contentType === "imageLinkToUrl") {
            const targetEntry = content as ContentfulImageLinkToUrl;
            if (targetEntry) {
               return getModuleVisibility(targetEntry, segmentStore.currentCustomerProperties);
            }
         }

         // Avoids drafts being included in the teaser list
         return !!contentType;
      });

      const uniqueContentList = uniqBy(filteredContentList, (entry) => entry?.sys.id);
      const visibleContent = take(uniqueContentList, content.fields.maxVisible ?? uniqueContentList.length);

      const title = content.fields.title as string | undefined;
      const trackingListName = content.fields.internalName as string;

      const shouldShowSubtitle =
         visibleContent.length === 1 || (visibleContent.length === 2 && isLargerThanMd) || !isLargerThanSm;

      if (visibleContent.length === 0) {
         return null;
      }

      // TODO: Remove this temporary solution when better recipe list is implemented
      // havesp, 29.11.2024 - More info: DSP-1247
      const listType = determineListType(visibleContent);
      if (listType === "recipe" && visibleContent.length === 2) {
         return (
            <div className="temp-recipe-list">
               {title && (
                  <div className="tw-mb-4">
                     <h2>{title}</h2>
                  </div>
               )}
               <GridSimple cols={{ base: 1, sm: 2, md: 3, lg: 4 }} gap={6}>
                  {visibleContent.map((callToAction, i) => {
                     if (!callToAction) {
                        return null;
                     }

                     const content = callToAction as ContentfulRecipe;
                     if (content?.fields?.recipeData) {
                        return (
                           <RecipeTeaser
                              key={`recipe-teaser-${callToAction.sys.id}`}
                              id={content.sys.id}
                              recipe={content.fields.recipeData as RecipeType}
                              trackingListName={trackingListName}
                           />
                        );
                     }
                  })}
               </GridSimple>
            </div>
         );
      }

      return (
         <div>
            {title && (
               <div className="tw-mb-4">
                  <h2>{title}</h2>
               </div>
            )}
            <TeaserListLayout id={content.sys.id}>
               {visibleContent.map((callToAction, i) => {
                  if (callToAction && callToAction.sys.contentType.sys.id === "v2_article") {
                     const entry = callToAction as ContentfulArticle;
                     if (entry.fields.headerImage) {
                        const ctaTitle = entry.fields.callToAction ?? content.fields.title;

                        return (
                           <EntryImageLink
                              key={`cta-${callToAction.sys.id}`}
                              id={entry.sys.id}
                              type="article"
                              imageWithFocusId={entry.fields.headerImage.sys.id}
                              title={ctaTitle}
                              subTitle={entry.fields.callToActionSubTitle}
                              trackingListName={trackingListName}
                           />
                        );
                     }
                     return null;
                  }
                  if (callToAction && callToAction.sys.contentType.sys.id === "recipe") {
                     const content = callToAction as ContentfulRecipe;
                     const recipeData: RecipeData = {
                        id: content.sys.id,
                        recipe: content.fields.recipeData as RecipeType,
                        title: content.fields.title
                     };
                     const { recipe } = recipeData;
                     if (recipe) {
                        return (
                           <RecipeTeaser
                              key={`recipe-teaser-${callToAction.sys.id}`}
                              id={recipeData.id}
                              recipe={recipe}
                              title={recipeData.title}
                              trackingListName={trackingListName}
                           />
                        );
                     }
                  } else if (callToAction && callToAction.sys.contentType.sys.id === "imageLink") {
                     const entryImageLink = callToAction as ContentfulImageLink;
                     const entry = entryImageLink.fields.entry as ContentfulArticle | ContentfulRecipe;
                     const image = entryImageLink.fields.image;
                     const type = entry.sys.contentType.sys.id === "v2_article" ? "article" : "recipe";

                     if (entry && image) {
                        const subTitle = shouldShowSubtitle ? entryImageLink.fields.caption : "";

                        return (
                           <EntryImageLink
                              key={`image-link-${callToAction.sys.id}`}
                              id={entry.sys.id}
                              type={type}
                              imageWithFocusId={image.sys.id}
                              title={entryImageLink.fields.text}
                              subTitle={subTitle}
                              trackingListName={trackingListName}
                           />
                        );
                     }
                  } else if (callToAction && callToAction.sys.contentType.sys.id === "imageLinkToUrl") {
                     const entryImageLinkToUrl = callToAction as ContentfulImageLinkToUrl;
                     const image = entryImageLinkToUrl.fields.image;
                     const url = entryImageLinkToUrl.fields.url;
                     const title = entryImageLinkToUrl.fields.text;
                     const subTitle = shouldShowSubtitle ? entryImageLinkToUrl.fields.caption : "";

                     if (image) {
                        return (
                           <UrlImageLink
                              key={`image-link-to-url-${callToAction.sys.id}`}
                              imageWithFocusId={image.sys.id}
                              title={title}
                              subTitle={subTitle}
                              url={url}
                              trackingListName={trackingListName}
                           />
                        );
                     }
                  } else {
                     return null;
                  }
               })}
            </TeaserListLayout>
         </div>
      );
   }
   return (
      <Alert variant="information" showCloseButton={false}>
         Innhold dukker opp når alle feltene er fylt ut
      </Alert>
   );
};

export default view(TeaserList);
