import Bugsnag from "@bugsnag/js";
import { batch, store } from "@risingstack/react-easy-state";
import { format } from "date-fns";
import isEmpty from "lodash-es/isEmpty";
import isNil from "lodash-es/isNil";
import pull from "lodash-es/pull";
import remove from "lodash-es/remove";
import sortBy from "lodash-es/sortBy";
import uniq from "lodash-es/uniq";

import { apiClient } from "../common/apiClient";
import { sendPurchase } from "../common/tracking";
import type {
   M3AugmentedSubscription,
   M3OrderError,
   M3OrderListed,
   M3Subscription,
   M3SubscriptionDeleteResult,
   M3SubscriptionInstanceError,
   M3SubscriptionInstanceResult,
   M3SubscriptionListed,
   M3SubscriptionPlannedDelivery,
   M3SubscriptionRaw,
   M3SubscriptionRawListed,
   OrderFilterQueryParams
} from "../common/types/m3Types";
import {
   type AsyncData,
   initializeWithDefaultData,
   setAsDataAvailable,
   setAsErrorOccured,
   setAsWaitingForData
} from "../common/utils/asyncDataUtils";
import { formatISODate, formatM3Date, generateSubscriptionName } from "../common/utils/dateUtils";

import { API_HOST } from "../common/environment";
import type { BaseStoreType } from "../common/types/BaseStoreType";
import theme from "../themes/theme";
import authStore from "./auth/authStore";
import cartStore from "./cart/cartStore";
import deliveryDatesStore from "./deliveryDates/deliveryDatesStore";

/**
 * This generic function constructs the resulting type by defining that it will always be
 * whatever type is sent in unioned with M3AugementedSuscription
 * This allows us to use the function in two cases separate cases with correct type inferance:
 *
 * M3SubscriptionRawListed -> M3SubscriptionListed
 * M3SubscriptionRaw -> M3Subscription
 */
const augmentSubscription = <T extends M3SubscriptionRawListed | M3SubscriptionRaw>(sub: T): T & M3AugmentedSubscription => {
   return {
      ...sub,
      id: sub.orderNumber,
      active: sub.orderStatus === "20",
      name: generateSubscriptionName(sub.deliveryWeekdayNumber, sub.deliveryIntervalDays)
   };
};

type SubscriptionStore = BaseStoreType & {
   selectedInterval: number;
   subscriptionsList: AsyncData<M3SubscriptionListed[]>;
   subscriptionDetails: Record<string, AsyncData<M3Subscription | null>>;
   fetchingAllPlannedDeliveries: boolean;
   plannedDeliveries: Record<string, M3SubscriptionPlannedDelivery[]>;
   daysLoading: string[];

   ensureSubscriptionDetailsKeyAvailable(orderNumber: string): void;
   getSubscriptionDetails(orderNumber: string): AsyncData<M3Subscription | null>;
   fetchSubscriptionList(): Promise<void>;
   fetchSubscriptionDetails(orderNumber: string): Promise<M3SubscriptionRaw | null>;
   fetchAllPlannedDeliveries(): void;
   fetchPlannedDeliveries(m3Date?: string | null, orderNumber?: string | null): Promise<void>;
   getDetailsEntryKey(orderNumber: string, date?: string): string;
   fetchInstanceOrder(orderNumber: string, date: Date | string): Promise<M3SubscriptionRaw>;
   togglePauseDay(m3Date: string | null, toggleTo: boolean): Promise<void>;
   submitInstanceChanges(): Promise<string[]>;
   submitNewInstanceOrder(): Promise<string[]>;
   submitSubscriptionChanges(): Promise<string[]>;
   submitNewSubscription(): Promise<string[]>;
   changeSubscriptionStatus(orderNumber: string, newStatus: boolean): Promise<boolean>;
   deleteInstanceOrder(orderNumber: string, deliveryDate: string): Promise<boolean>;
   deleteSubscription(orderNumber: string): Promise<boolean>;
};

const subscriptionStore: SubscriptionStore = store({
   selectedInterval: 7,
   subscriptionsList: initializeWithDefaultData([]),
   subscriptionDetails: {},
   fetchingAllPlannedDeliveries: false,
   plannedDeliveries: {},
   daysLoading: [],

   ensureSubscriptionDetailsKeyAvailable: (orderNumber) => {
      if (!(orderNumber in subscriptionStore.subscriptionDetails) || isNil(subscriptionStore.subscriptionDetails[orderNumber])) {
         subscriptionStore.subscriptionDetails[orderNumber] = initializeWithDefaultData(null);
      }
   },

   getSubscriptionDetails: (orderNumber) => {
      subscriptionStore.ensureSubscriptionDetailsKeyAvailable(orderNumber);
      return subscriptionStore.subscriptionDetails[orderNumber];
   },

   fetchSubscriptionList: () => {
      if (!authStore.currentCompany) {
         console.warn("Not able to fetch subscription list before user is logged in");
         return Promise.reject();
      }

      setAsWaitingForData(subscriptionStore.subscriptionsList);

      return apiClient(`${API_HOST}/api/${theme.tipApiPrefix}tip/API/subscriptionOrderList`, authStore.getSessionToken())
         .query({
            companyNumber: theme.m3CompanyNumber,
            customerNumber: authStore.currentCompany
         })
         .get()
         .json((res: M3SubscriptionRawListed[]) => {
            const sortedResult = sortBy(res, "deliveryWeekdayNumber");
            setAsDataAvailable(subscriptionStore.subscriptionsList, sortedResult.map(augmentSubscription));
         })
         .catch((err) => {
            console.warn("Unable to fetch subscription list", err);
            setAsErrorOccured(subscriptionStore.subscriptionsList, `${err}`);
         });
   },

   fetchSubscriptionDetails: async (orderNumber) => {
      if (!authStore.isLoggedIn() || isNil(orderNumber)) {
         console.warn("Subscription Details before logged in: ", orderNumber);
         return Promise.reject();
      }
      const key = subscriptionStore.getDetailsEntryKey(orderNumber);
      const order = subscriptionStore.getSubscriptionDetails(key);
      setAsWaitingForData(order);

      try {
         const orderDetails: M3SubscriptionRaw = await apiClient(
            `${API_HOST}/api/${theme.tipApiPrefix}tip/API/subscriptionOrder`,
            authStore.getSessionToken()
         )
            .query({
               companyNumber: theme.m3CompanyNumber,
               customerNumber: authStore.currentCompany,
               orderNumber
            })
            .get()
            .json();

         if ("Error_Code" in orderDetails && typeof orderDetails.Error_Code === "string") {
            setAsErrorOccured(order, orderDetails.Error_Code);
            return null;
         }

         setAsDataAvailable(order, augmentSubscription(orderDetails));
         return orderDetails;
      } catch (err) {
         console.warn("Unable to fetch subscription details", err);
         setAsErrorOccured(order, `${err}`);
         return null;
      }
   },

   fetchAllPlannedDeliveries: () => {
      subscriptionStore.fetchingAllPlannedDeliveries = true;
      return subscriptionStore.fetchPlannedDeliveries().finally(() => {
         subscriptionStore.fetchingAllPlannedDeliveries = false;
      });
   },

   getDetailsEntryKey: (orderNumber, date) => orderNumber + (!isNil(date) ? `-${date}` : ""),

   fetchInstanceOrder: async (orderNumber, date) => {
      if (!authStore.isLoggedIn()) {
         console.warn("Subscription Next Deliveries before logged in: ", orderNumber);
         return Promise.reject();
      }

      if (isEmpty(orderNumber)) {
         console.warn("No order number specified for subscription instance fetch");
         return Promise.reject();
      }

      // Convert date to M3 format
      const requestedDeliveryDate = date instanceof Date ? formatM3Date(date) : date;
      const key = subscriptionStore.getDetailsEntryKey(orderNumber, requestedDeliveryDate);

      const instanceEntry = subscriptionStore.getSubscriptionDetails(key);

      const params = {
         optimizeOrderLines: true,
         companyNumber: theme.m3CompanyNumber,
         customerNumber: authStore.currentCompany,
         requestedDeliveryDate,
         orderNumber
      };

      setAsWaitingForData(instanceEntry);
      try {
         const instanceDetails: M3SubscriptionRaw | M3OrderError = await apiClient(
            `${API_HOST}/api/${theme.tipApiPrefix}tip/API/changedSubscriptionOrderDelivery`,
            authStore.getSessionToken()
         )
            .query(params)
            .get()
            .json();

         // Check if API completed successfully
         if ("orderNumber" in instanceDetails) {
            setAsDataAvailable(instanceEntry, {
               ...augmentSubscription(instanceDetails),
               success: true,
               id: key
            });
            return instanceDetails;
         }
         if (!instanceDetails || "Error_Code" in instanceDetails) {
            console.warn("An error occured while fetching", instanceDetails);
            Bugsnag.notify(new Error(`Unable to fetch subscription confirmation ${key}: ${instanceDetails.Error_Code}`));
            setAsErrorOccured(instanceEntry, "Unable to fetch subscription instance details");
            return Promise.reject("An error occured");
         }
         return instanceDetails;
      } catch (err) {
         setAsErrorOccured(instanceEntry, `Unable to fetch subscription instance details: ${err}`);
         return Promise.reject(`An error occured while fetching instance details: ${err}`);
      }
   },

   fetchPlannedDeliveries: async (m3Date = null, orderNumber = null) => {
      if (!authStore.isLoggedIn() || isNil(authStore.currentCompany)) {
         console.warn("Subscription Next Deliveries before logged in: ", orderNumber);
         return;
      }

      const payload: OrderFilterQueryParams = {
         companyNumber: theme.m3CompanyNumber,
         customerNumber: authStore.currentCompany,
         typeOfOrder: "F"
      };

      if (!isNil(orderNumber)) {
         payload.orderNumber = orderNumber;
      }

      if (!isNil(m3Date)) {
         payload.fromDate = m3Date;
         payload.toDate = m3Date;
      }

      const deliveries: M3OrderListed[] = await apiClient(
         `${API_HOST}/api/${theme.tipApiPrefix}tip/API/customerOrderList`,
         authStore.getSessionToken()
      )
         .query(payload)
         .get()
         .json();

      console.log("Got result for planned deliveries", deliveries);
      const orderNumbers = uniq(deliveries.map((d) => d.orderNumber));

      batch(() => {
         for (const orderNo of orderNumbers) {
            if (isNil(m3Date) || isNil(subscriptionStore.plannedDeliveries[orderNo])) {
               subscriptionStore.plannedDeliveries[orderNo] = [];
            } else {
               remove(subscriptionStore.plannedDeliveries[orderNo], (d) => d.deliveryDate === m3Date);
            }
         }

         if (deliveries.length === 0 && !isNil(orderNumber)) {
            subscriptionStore.plannedDeliveries[orderNumber] = [];
         }

         for (const d of deliveries) {
            subscriptionStore.plannedDeliveries[d.orderNumber].push({
               deliveryDate: d.deliveryDate,
               paused: d.typeOfOrder === "S",
               changed: d.typeOfOrder === "I"
            });
         }
      });

      if (!isNil(orderNumber)) {
         console.log(`Loaded ${deliveries.length} planned deliveries for subscription ${orderNumber} from server`);
      } else {
         console.log(`Loaded ${deliveries.length} planned deliveries from ${orderNumbers.length} orderNumbers from server`);
      }
   },

   togglePauseDay: async (m3Date, toggleTo) => {
      if (isNil(m3Date)) {
         console.warn("Unable to toggle pause date without date");
         return Promise.reject("Det oppsto en feil");
      }
      console.log("Adding loading day");
      subscriptionStore.daysLoading = [...subscriptionStore.daysLoading, m3Date];

      const pauseDayUrl = `${API_HOST}/api/${theme.tipApiPrefix}tip/API/cancellationDate`;
      const req = apiClient(pauseDayUrl, authStore.getSessionToken()).query({
         companyNumber: theme.m3CompanyNumber,
         customerNumber: authStore.currentCompany,
         date: m3Date
      });

      const returnObj = toggleTo ? req.post() : req.delete();

      await returnObj
         .json()
         .then(async () => {
            console.log("Fetching planned deliveries");
            await subscriptionStore.fetchPlannedDeliveries(m3Date);
         })
         .finally(() => {
            console.log("Removing loading day");
            pull(subscriptionStore.daysLoading, m3Date);
         });
   },

   submitInstanceChanges: async () => {
      const currentDelivery = deliveryDatesStore.getCurrentDelivery();
      if (currentDelivery === null) {
         return Promise.reject("Unable to determine current delivery");
      }
      if (cartStore.editing === null || isNil(cartStore.editing.orderNumber)) {
         return Promise.reject("Not currently editing an order, unable to submit changes.");
      }

      cartStore.sendingOrder = true;

      const payload = cartStore.generateChangeOrderPayload();
      if (payload === null) {
         cartStore.sendingOrder = false;
         void cartStore.stopEditOrderMode();
         return [cartStore.editing.orderNumber];
      }
      payload.deliveryDate = formatISODate(currentDelivery.date);

      let response: M3SubscriptionInstanceResult | M3SubscriptionInstanceError;
      try {
         response = await apiClient(
            `${API_HOST}/api/${theme.tipApiPrefix}tip/API/changedSubscriptionOrderDelivery`,
            authStore.getSessionToken()
         )
            .query({ optimizeOrderLines: true })
            .content("application/json")
            .put(payload)
            .json();

         // Remove the cached order details so we are able to see the changes load in on the confirmation page
         delete subscriptionStore.subscriptionDetails[cartStore.editing.orderNumber];
      } catch (err) {
         console.warn("An error occured during order update", err);
         throw err;
      } finally {
         cartStore.sendingOrder = false;
      }

      if ("@type" in response) {
         throw new Error(`${response["@type"]}: ${response.Message}`);
      }

      void cartStore.stopEditOrderMode();
      return [response.orderNumber];
   },

   // Instance orders are planned deliveries for a subscription on a specific date that differs from the rest of the subscription
   submitNewInstanceOrder: async () => {
      const currentDelivery = deliveryDatesStore.getCurrentDelivery();
      if (currentDelivery === null) {
         return Promise.reject("Unable to determine current delivery");
      }
      if (cartStore.editing === null || isNil(cartStore.editing.orderNumber)) {
         return Promise.reject("Not currently editing an order, unable to submit changes.");
      }

      cartStore.sendingOrder = true;

      const instanceOrder = {
         companyNumber: theme.m3CompanyNumber,
         customerNumber: authStore.currentCompany,
         orderNumber: cartStore.editing.orderNumber,
         validFromDate: formatISODate(currentDelivery.date),
         estimatedOrderAmount: 2500,
         orderLines: cartStore.items.map((i) => ({
            sku: i.sku,
            quantity: i.qty,
            orderLineUnit: i.unit
         }))
      };

      let response: M3SubscriptionInstanceResult | M3OrderError;
      try {
         response = await apiClient(
            `${API_HOST}/api/${theme.tipApiPrefix}tip/API/changedSubscriptionOrderDelivery`,
            authStore.getSessionToken()
         )
            .query({
               optimizeOrderLines: true
            })
            .content("application/json")
            .post(instanceOrder)
            .json();

         // Remove the cached order details so we are able to see the changes load in on the confirmation page
         delete subscriptionStore.subscriptionDetails[cartStore.editing.orderNumber];
      } catch (err) {
         console.warn("An error occured during order update", err);
         throw err;
      } finally {
         cartStore.sendingOrder = false;
      }

      if ("Error_Code" in response) {
         throw new Error(`${response.Response}: ${response.Error_Code}`);
      }

      void cartStore.stopEditOrderMode();
      return [response.orderNumber];
   },

   submitSubscriptionChanges: async () => {
      const currentDelivery = deliveryDatesStore.getCurrentDelivery();
      if (currentDelivery === null) {
         return Promise.reject("Unable to determine current delivery");
      }
      if (cartStore.editing === null || isNil(cartStore.editing.orderNumber)) {
         return Promise.reject("Not currently editing an order, unable to submit changes.");
      }

      cartStore.sendingOrder = true;

      const payload = cartStore.generateChangeOrderPayload();

      if (payload === null) {
         cartStore.sendingOrder = false;
         void cartStore.stopEditOrderMode();
         return [cartStore.editing.orderNumber];
      }

      let response: M3SubscriptionInstanceResult | M3OrderError;
      try {
         response = await apiClient(`${API_HOST}/api/${theme.tipApiPrefix}tip/API/subscriptionOrder`, authStore.getSessionToken())
            .query({
               optimizeOrderLines: true,
               keepInstances: true
            })
            .content("application/json")
            .put(payload)
            .json();

         if ("orderNumber" in response) {
            sendPurchase(response.orderNumber, [...cartStore.items], true);
         }

         // Remove the cached order details so we are able to see the changes load in on the confirmation page
         delete subscriptionStore.subscriptionDetails[cartStore.editing.orderNumber];
      } catch (err) {
         console.warn("An error occured during order update", err);
         throw err;
      } finally {
         cartStore.sendingOrder = false;
      }

      if ("Error_Code" in response) {
         throw new Error(`${response.Response}: ${response.Error_Code}`);
      }

      void cartStore.stopEditOrderMode();
      return [response.orderNumber];
   },

   submitNewSubscription: async () => {
      cartStore.sendingOrder = true;

      const currentDelivery = deliveryDatesStore.getCurrentDelivery();
      if (isNil(currentDelivery)) {
         return Promise.reject("Current delivery is not available, unable to create new subscription");
      }

      const firstDeliveryDate = formatISODate(currentDelivery.date);
      const deliveryWeekdayNumber = format(currentDelivery.date, "i");
      const deliveryIntervalDays = currentDelivery.interval;

      const subscription = {
         companyNumber: theme.m3CompanyNumber,
         customerNumber: authStore.currentCompany,
         firstDeliveryDate,
         validFromDate: firstDeliveryDate,
         validToDate: "",
         deliveryWeekdayNumber,
         deliveryIntervalDays,
         estimatedOrderAmount: 2500,
         ...cartStore.orderRefs,
         orderLines: cartStore.items.map((i) => ({
            sku: i.sku,
            quantity: i.qty,
            orderLineUnit: i.unit
         }))
      };

      const createOrderUrl = `${API_HOST}/api/${theme.tipApiPrefix}tip/API/subscriptionOrder?optimizeOrderLines=true`;
      let response: M3SubscriptionInstanceResult | M3OrderError;
      try {
         console.log("Starting subscription creation...", subscription);
         response = await apiClient(createOrderUrl, authStore.getSessionToken())
            .content("application/json")
            .post(subscription)
            .json();

         if ("orderNumber" in response) {
            sendPurchase(response.orderNumber, [...cartStore.items], false);
         }
      } catch (err) {
         console.warn("Unable to create subscription", err);
         throw err;
      } finally {
         cartStore.sendingOrder = false;
      }

      if ("Error_Code" in response) {
         throw new Error(`${response.Response}: ${response.Error_Code}`);
      }

      cartStore.emptyCart();
      cartStore.resetOrderRefs();
      cartStore.changeOrderType(theme.defaultOrderType);

      return [response.orderNumber];
   },

   changeSubscriptionStatus: async (orderNumber, newStatus) => {
      const statusUrl = `${API_HOST}/api/${theme.tipApiPrefix}tip/API/subscriptionOrderStatus`;
      const resp: M3SubscriptionInstanceResult | M3SubscriptionInstanceError = await apiClient(
         statusUrl,
         authStore.getSessionToken()
      )
         .query({
            companyNumber: theme.m3CompanyNumber,
            customerNumber: authStore.currentCompany,
            orderNumber,
            activate: newStatus
         })
         .put()
         .json();

      if ("Message" in resp) {
         console.warn("Error when changing subscription status: ", resp);
         throw new Error(`Unable to change status for subscription: ${resp.Message}`);
      }

      return resp.success;
   },

   deleteInstanceOrder: async (orderNumber, deliveryDate) => {
      const deleteInstanceUrl = `${API_HOST}/api/${theme.tipApiPrefix}tip/API/changedSubscriptionOrderDelivery`;
      const response: M3SubscriptionDeleteResult | M3OrderError = await apiClient(deleteInstanceUrl, authStore.getSessionToken())
         .query({
            companyNumber: theme.m3CompanyNumber,
            customerNumber: authStore.currentCompany,
            orderNumber,
            deliveryDate
         })
         .delete()
         .json();

      if ("Error_Code" in response) {
         throw new Error(`Unable to delete subscription ${response.Error_Code}`);
      }

      return response.status;
   },

   deleteSubscription: async (orderNumber) => {
      const deleteUrl = `${API_HOST}/api/${theme.tipApiPrefix}tip/API/subscriptionOrder`;
      const response: M3SubscriptionDeleteResult | M3OrderError = await apiClient(deleteUrl, authStore.getSessionToken())
         .query({
            companyNumber: theme.m3CompanyNumber,
            customerNumber: authStore.currentCompany,
            orderNumber
         })
         .delete()
         .json();

      if ("Error_Code" in response) {
         throw new Error(`Unable to delete subscription ${response.Error_Code}`);
      }

      subscriptionStore.subscriptionsList.data = subscriptionStore.subscriptionsList.data.filter(
         (subscription) => subscription.orderNumber !== orderNumber
      );

      return response.status;
   },
   clearCompanySpecificData: () => {
      // add methods to clear company specific data
      subscriptionStore.selectedInterval = 7;
      subscriptionStore.subscriptionsList = initializeWithDefaultData([]);
      subscriptionStore.subscriptionDetails = {};
      subscriptionStore.fetchingAllPlannedDeliveries = false;
      subscriptionStore.plannedDeliveries = {};
      subscriptionStore.daysLoading = [];
   }
} satisfies SubscriptionStore);

export default subscriptionStore;
