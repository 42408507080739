import { Grid, VideoPlayer } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";

import type { RecipeType } from "../../../common/types/recipeTypes";
import { getYoutubeVideoIdFromURL, hasContent } from "../../../common/utils";

import RecipeHeader from "./RecipeHeader";
import RecipeImage from "./RecipeImage";

type RecipeHeaderAndImageProps = {
   recipe: RecipeType;
};

const urlForWidthAndHeight = (urlTemplate: string, width: number, height: number) => {
   return urlTemplate.replace("%height%", height.toFixed(0)).replace("%width%", width.toFixed(0));
};

const RecipeHeaderAndImage = ({ recipe }: RecipeHeaderAndImageProps) => {
   const { video } = recipe;

   const youtubeId = getYoutubeVideoIdFromURL(video?.url || "");
   const hasVideo = !!youtubeId;

   const imageUrl = recipe?.image?.url ? urlForWidthAndHeight(recipe.image.url, 1250, Math.round((1250 * 9) / 16)) : "";

   return (
      <Grid cols={{ base: 1, lg: 12 }} gap={12} className="tw-mb-16">
         <Grid.Col order={{ base: 1, lg: 0 }} span={{ base: 1, lg: 4 }}>
            <RecipeHeader recipe={recipe} />
         </Grid.Col>
         <Grid.Col
            order={{ base: 0, lg: 1 }}
            span={{ base: 1, lg: 8 }}
            className={cn({
               "tw-flex tw-items-center tw-justify-center": !hasVideo,
               "tw-cursor-pointer": hasVideo
            })}
         >
            {!hasVideo && hasContent(recipe.image?.url) && (
               <RecipeImage
                  width={1250}
                  className="tw-rounded-lg print:tw-hidden"
                  placement="aboveFold"
                  ratio="16x9"
                  title={recipe.image?.title}
                  url={recipe.image.url}
                  sourceWidth={recipe.image.sourceWidth || undefined}
                  sourceHeight={recipe.image.sourceHeight || undefined}
               />
            )}
            {hasVideo && <VideoPlayer videoId={youtubeId} poster={imageUrl} />}
         </Grid.Col>
      </Grid>
   );
};

export default RecipeHeaderAndImage;
