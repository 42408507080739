import { type SetStateAction, useContext, useEffect, useRef } from "react";

import { FormContext } from "../FormContext";

// Create a placeholder FormContext (replace with your actual form context)

/**
 * Custom hook to manage error visibility based on form validity from context.
 * @param {Function} setShowError - Function to update error visibility.
 */
const useFormError = <T,>(
   setShowError: (value: SetStateAction<boolean>) => void,
   validator: (value: T, required: boolean) => string,
   name: string,
   required: boolean
) => {
   const { isFormValid, formValues } = useContext(FormContext); // Access formValid from context
   const isInitialRender = useRef(true); // Ref to track initial render

   useEffect(() => {
      if (isInitialRender.current) {
         isInitialRender.current = false; // Mark initial render as complete
         return; // Skip the effect logic on the initial render
      }

      // Update `setShowError` when `formValid` changes
      if (!isFormValid) {
         setShowError((c) => {
            return !!validator(formValues[name].value, required);
         });
      }
   }, [isFormValid]);
};

export default useFormError;
