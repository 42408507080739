import { IconArrowForward } from "@tine/designsystem-icons/sharp";
import {
   LogoBiola,
   LogoDovreYsteri,
   LogoGryr,
   LogoIste,
   LogoJarlsberg,
   LogoLitago,
   LogoNoisy,
   LogoNorvegia,
   LogoSelbuBlaa,
   LogoSnofrisk,
   LogoSunniva,
   LogoYt
} from "@tine/web-logos/brands";
import type { EntryFieldTypes } from "contentful";
import { useInView } from "react-intersection-observer";
import { Link } from "wouter";

import { sendSelectTeaser, sendViewTeaser } from "../common/tracking";
import type { ContentfulLogos } from "../common/types/cmsTypes";
import type { TypeLogosFields } from "../common/types/contentful";

import FullWidthBackground from "./FullWidthBackground";

type LogoName = TypeLogosFields["logos"] extends EntryFieldTypes.Array<EntryFieldTypes.Symbol<infer T>> ? T : never;

type LogoListProps = {
   content: ContentfulLogos;
};

const LogoList = ({ content }: LogoListProps) => {
   const title = content.fields.title;
   const logos = content.fields.logos;
   const linkText = content.fields.linkText;
   const linkUrl = content.fields.linkUrl;
   const backgroundColor = content.fields.background;
   const logoClasses = "tw-w-16 lg:tw-w-24";
   const contentType = content.sys.contentType.sys.id;

   const logoComponents: Record<LogoName, JSX.Element> = {
      Norvegia: <LogoNorvegia className={logoClasses} />,
      Jarlsberg: <LogoJarlsberg className={logoClasses} />,
      Sunniva: <LogoSunniva className={logoClasses} />,
      Iste: <LogoIste className={logoClasses} />,
      Yt: <LogoYt className={logoClasses} />,
      Selbu: <LogoSelbuBlaa className={logoClasses} />,
      Biola: <LogoBiola className={logoClasses} />,
      Snofrisk: <LogoSnofrisk className={logoClasses} />,
      Dovre: <LogoDovreYsteri className={logoClasses} />,
      Litago: <LogoLitago className={logoClasses} />,
      Noisy: <LogoNoisy className={logoClasses} />,
      Gryr: <LogoGryr className={logoClasses} />
   };

   const trackImpression = (isVisible: boolean) => {
      if (isVisible) {
         sendViewTeaser(title, contentType);
      }
   };

   const { ref: viewRef } = useInView({
      triggerOnce: true,
      onChange: trackImpression
   });

   const handleClickLogoLink = () => {
      sendSelectTeaser(title, contentType, undefined, `/${linkUrl}`);
   };

   return (
      <div ref={viewRef} className="tw-relative tw-flex tw-w-full tw-flex-col">
         {backgroundColor && <FullWidthBackground color="tw-bg-infoBoxBackground" />}
         {title && (
            <h2 className="tw-px-10 tw-pt-8 tw-text-center tw-text-xl tw-font-semibold lg:tw-pt-12 lg:tw-text-2xl">{title}</h2>
         )}
         <div className="tw-flex tw-max-w-[800px] tw-flex-wrap tw-items-center tw-justify-center tw-gap-10 tw-self-center tw-px-10 tw-py-10 lg:tw-px-0 lg:tw-py-12">
            {Object.keys(logoComponents).map((logoName) =>
               logos.includes(logoName as LogoName) ? <div key={logoName}>{logoComponents[logoName as LogoName]}</div> : null
            )}
         </div>

         {linkUrl && linkText && (
            <Link
               to={linkUrl}
               onClick={handleClickLogoLink}
               className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-pb-8 lg:tw-pb-12"
            >
               <span className="tw-text-base">{linkText}</span>
               <IconArrowForward />
            </Link>
         )}
      </div>
   );
};

export default LogoList;
