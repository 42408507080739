import { view } from "@risingstack/react-easy-state";
import { Group } from "@tine/designsystem-ui-react";
import intersection from "lodash-es/intersection";
import isNil from "lodash-es/isNil";
import { useEffect } from "react";

import type { ProductTileCallbacks } from "../common/types/productTypes";
import { LIST_TYPE } from "../common/types/trackingTypes";
import { isDefaultData } from "../common/utils/asyncDataUtils";
import ProductList from "./common/product/ProductList";

import { Interweave } from "interweave";
import authStore from "../stores/auth/authStore";
import featuresStore from "../stores/features/featuresStore";
import productStore from "../stores/product/productStore";
import recommendationStore from "../stores/recommendationStore";

type RecommendationsProps = {
   heading: string | null;
   count?: number;
   skus?: string | string[] | null;
   className?: string;
};

const Recommendations = ({ heading = null, count = 6, className = "" }: RecommendationsProps) => {
   const isLoggedIn = authStore.isLoggedIn();

   useEffect(() => {
      if (isLoggedIn) {
         if (isDefaultData(recommendationStore.recommendations)) {
            void recommendationStore.getRecommendations();
         }
      }
   }, [isLoggedIn]);

   if (!authStore.isLoggedIn() || recommendationStore.recommendations.data === null) {
      // This components should only be displayed when customer is logged in
      return null;
   }

   const recommendationToUse = featuresStore.recommendationType;
   if (recommendationToUse === "OFF") {
      return null;
   }

   const recommended = recommendationStore.recommendations.data[recommendationToUse];
   if (isNil(recommended)) {
      // If we don't have any recommendations available, we can just hide the component
      return null;
   }

   const skusInRecommendation = recommended.skus.map((suggestion) => suggestion.sku);

   const inAssortment = intersection(skusInRecommendation, productStore.assortment);
   if (inAssortment.length === 0) {
      console.log("None of the recommendation are in the assortment. Hiding module...");
      return null;
   }

   const callbacks: ProductTileCallbacks = {
      addToCart: (product) => {
         void recommendationStore.sendRecommendationFeedback(recommended.producedBy, product.sku, "ADD_TO_CART");
      },
      click: (product) => {
         void recommendationStore.sendRecommendationFeedback(recommended.producedBy, product.sku, "VIEW_DETAILS");
      },
      impression: (product) => {
         void recommendationStore.sendRecommendationFeedback(recommended.producedBy, product.sku, "IMPRESSION");
      }
   };

   return (
      <div className={className} id={`recommendation_${recommended.producedBy}`.toLowerCase()}>
         {heading !== null && (
            <Group justify="start">
               <h3 className="tw-pb-2">{heading}</h3>
            </Group>
         )}

         <Group justify="start">
            <p>
               <Interweave content={recommended.reason} />
            </p>
         </Group>

         <Group justify="start">
            <ProductList
               skus={inAssortment}
               count={count}
               callbacks={callbacks}
               listName={`Produktanbefalinger (${recommendationToUse})`}
               listType={LIST_TYPE.RECOMMENDATIONS}
               context="recommendations"
            />
         </Group>
      </div>
   );
};

export default view(Recommendations);
