import { store } from "@risingstack/react-easy-state";

import { apiClient } from "../../common/apiClient";

import { API_HOST } from "../../common/environment";
import type { BaseStoreType } from "../../common/types/BaseStoreType";
import type { ContactFormInformation } from "../../pages/ContactFormPage";
import theme from "../../themes/theme";
import toastStore from "../toastStore";

type SubmitContactFormReturnType = {
   success: boolean;
   feedback: string;
   payload: unknown;
};

type ContactFormStore = BaseStoreType & {
   submitContactForm(contactForm: ContactFormInformation): Promise<SubmitContactFormReturnType | undefined>;
};

const contactFormStore: ContactFormStore = store({
   submitContactForm: async (contactForm) => {
      let result: SubmitContactFormReturnType | undefined;

      const formatedForm = {
         ...contactForm
      };
      try {
         result = await apiClient(`${API_HOST}${theme.contactForm.apiPath}`).post(formatedForm).json();
      } catch (err) {
         console.error("Error while saving contact form:", err);
         toastStore.addError("Feil under sending av skjema", "Det oppsto en uforutsett feil under sending av skjema.", {
            context: "contact_form",
            text: "contact_form_error"
         });
      }
      return result;
   },
   clearCompanySpecificData: () => {
      // add methods to clear company specific data
   }
} satisfies ContactFormStore);

export default contactFormStore;
