import { cn } from "@tine/designsystem-utils";
import type { Entry } from "contentful";

import type { ContentfulUsp } from "../../../common/types/cmsTypes";
import type { TypeCallToActionListSkeleton } from "../../../common/types/contentful";
import Usp from "./Usp";

type UspListProps = {
   content: Entry<TypeCallToActionListSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>;
};

const UspList = ({ content }: UspListProps) => {
   // A USP list should always have either 3 or 4 USPs
   const threeUsps = content.fields.contentList?.length === 3;
   const fourUsps = content.fields.contentList?.length === 4;

   const uspListClasses = cn(
      "tw-relative tw-flex tw-flex-col tw-items-center tw-justify-between tw-gap-4 lg:tw-flex-row lg:tw-gap-8 lg:tw-py-8",
      {
         "xl:tw-px-12": threeUsps,
         "xl:tw-px-10": fourUsps
      }
   );

   const trackingListName = content.fields.internalName;
   const contentType = content.sys.contentType.sys.id;

   if (!content.fields.contentList) {
      return null;
   }

   const contentList = content.fields.contentList;

   if (contentList.length > 4 || contentList.length < 3) {
      return null;
   }

   return (
      <div className={uspListClasses}>
         {contentList.map((entry) => {
            const usp = entry as ContentfulUsp;
            return (
               <Usp
                  key={usp.sys.id}
                  usp={usp}
                  threeUsps={threeUsps}
                  fourUsps={fourUsps}
                  trackingListName={trackingListName}
                  contentType={contentType}
               />
            );
         })}
      </div>
   );
};

export default UspList;
