import { Input } from "@tine/designsystem-ui-react";
import { type ChangeEvent, useContext, useState } from "react";

import { FormContext } from "./FormContext";
import type { ValidatedInputProps } from "./FormTypes";
import { validateNotEmpty } from "./FormUtils/FieldValidators";
import useFormError from "./FormUtils/useFormErrors";
import useRegisterfield from "./FormUtils/useRegisterField";

const ValidatedInput = ({
   initialValue,
   validator = validateNotEmpty,
   label,
   name,
   required = false,
   ...inputProps
}: ValidatedInputProps) => {
   const [showError, setShowError] = useState(false);
   const { updateFormValues, formValues } = useContext(FormContext);

   const hasMoreThanOneField = Object.keys(formValues).length > 1;

   const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setShowError(false);
      updateFormValues({
         ...formValues[name],
         value,
         error: validator(value, required)
      });
   };

   const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const error = validator(value, required);
      setShowError(!!error);
   };

   useRegisterfield(name, initialValue, validator, required);
   useFormError(setShowError, validator, name, required);

   return (
      <Input
         {...inputProps}
         value={formValues[name]?.value || ""}
         name={name}
         label={required && hasMoreThanOneField ? `${label} *` : label}
         onFocus={() => setShowError(false)}
         onChange={handleChange}
         onBlur={handleBlur}
         errorMessage={formValues[name]?.error}
         showErrorMessage={showError}
      />
   );
};

export default ValidatedInput;
