import { store, view } from "@risingstack/react-easy-state";
import { InputStepper } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import { isNumber } from "lodash-es";
import isFunction from "lodash-es/isFunction";
import { useRef } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "wouter";

import useContainerWidth, { type WidthRanges } from "../../../common/hooks/useContainerWidth";
import { productImageUrl } from "../../../common/imageUtils";
import { queueImpression, sendProductClick } from "../../../common/tracking";
import { PageType, type Product, type ProductTileCallbacks } from "../../../common/types/productTypes";
import type { GAListInformation } from "../../../common/types/trackingTypes";
import { formatPrice, formatUnit } from "../../../common/utils";

import authStore from "../../../stores/auth/authStore";
import productStore from "../../../stores/product/productStore";
import promotionStore from "../../../stores/promotionStore";
import uiStore from "../../../stores/uiStore";

import theme from "../../../themes/theme";
import AddToCartButton from "../../AddToCartButton";
import Badges from "../../Badges";
import Button from "../../Button";
import DisplayPrice from "../../DisplayPrice";
import Favorite from "../../Favorite";
import Labels from "../../Labels";
import PricePerItem from "../../PricePerItem";
import PriceBomb from "../PriceBomb";

type ProductTileProps = {
   product: Product;
   listInformation: GAListInformation;
   position: number;
   className?: string;
   callbacks?: ProductTileCallbacks;
};

type InternalStateStore = {
   qty: number;
   hovering: boolean;
   hasReportedVisibility: boolean;
};

type BreakpointSize = "thin" | "normal";
const ranges: WidthRanges<BreakpointSize> = {
   thin: [0, 190],
   normal: [190]
};
const ProductTile = ({ product, listInformation, position, className = "", callbacks = {} }: ProductTileProps) => {
   const imageRef = useRef<HTMLImageElement>(null);
   const tileRef = useRef<HTMLDivElement>(null);
   const { ref: containerRef, size: currentContainerSize } = useContainerWidth<BreakpointSize, HTMLDivElement>(ranges);
   const tileState: InternalStateStore = store({
      qty: 1,
      hovering: false,
      hasReportedVisibility: false
   } satisfies InternalStateStore);

   const trackImpression = (isVisible: boolean) => {
      if (isVisible && !tileState.hasReportedVisibility) {
         queueImpression(product, listInformation.name, position);
         // Allow other consumers to insert their own callback
         if (isFunction(callbacks.impression)) {
            callbacks.impression(product, listInformation.name, position);
         }
         tileState.hasReportedVisibility = true;
      }
   };

   const { ref } = useInView({
      triggerOnce: true,
      onChange: trackImpression
   });

   const checkIfWarn = () => {
      tileState.hovering = !authStore.isLoggedIn();
   };

   const removeWarning = () => {
      tileState.hovering = false;
   };

   const trackClick = () => {
      sendProductClick(product, listInformation, position);
      // Allow other consumers to insert their own callback
      if (isFunction(callbacks.click)) {
         callbacks.click(product, listInformation.name, position);
      }
   };

   const handleQuantityChange = (qty: number) => {
      tileState.qty = qty;
      return qty;
   };

   const replacementProduct: Product | undefined = (product.newSku && productStore.resolveSku(product.newSku)) || undefined;

   const stepperAndButtonContainer = cn("tw-flex tw-w-full tw-items-center tw-justify-stretch tw-gap-1", {
      "tw-flex-col tw-gap-2": currentContainerSize === "thin"
   });

   const inputStepper = cn(
      "tw-flex-grow tw-self-center [&>button]:tw-h-[43px] [&>button]:tw-w-[43px] disabled:[&>button]:tw-bg-transparent [&>div]:tw-h-[43px] [&>div]:tw-min-w-[30px] [&>div]:tw-max-w-[120px] [&>div]:tw-flex-grow [&>div]:tw-overflow-hidden",
      { "tw-w-full [&>button]:tw-flex-grow-0 [&>div]:tw-max-w-[120px] [&>div]:tw-flex-grow": currentContainerSize === "thin" }
   );

   const outerClassName = cn("tw-h-full tw-rounded-lg tw-shadow-sm hover:tw-shadow-md", className);

   return (
      <div ref={ref} className={outerClassName}>
         <div ref={containerRef} className="tw-h-full">
            <div
               ref={tileRef}
               className={cn(
                  "tw-group tw-relative tw-flex tw-min-h-full tw-flex-col tw-justify-between tw-rounded-lg tw-bg-base-0 tw-p-3",
                  {
                     "tw-z-10 tw-shadow-xl": tileState.hovering
                  }
               )}
               onMouseEnter={checkIfWarn}
               onMouseLeave={removeWarning}
            >
               <div className="tw-flex tw-flex-col">
                  <div className="favs-badges-picture tw-relative">
                     <Favorite sku={product.sku} listInformation={listInformation} position={position} />
                     <Badges badges={product.badges} page={PageType.ProductListPage} />
                     <Labels labels={product.labels} page={PageType.ProductListPage} sku={product.sku} />
                     <Link to={productStore.getUrl(product)} onClick={trackClick} data-testid="product-link">
                        <img
                           alt={`Bilde av ${product.name}`}
                           ref={imageRef}
                           loading="lazy"
                           className="tw-mx-auto tw-my-4 tw-block tw-aspect-square tw-w-7/12 tw-p-1 group-hover:tw-p-0 group-hover:tw-transition-[padding] group-hover:tw-duration-150"
                           src={productImageUrl(product.sku, 130)}
                        />
                        {promotionStore.getPromotionsBySku(product.sku).map((promotion) => (
                           <PriceBomb size="small" key={promotion.banner}>
                              {promotion.banner}
                           </PriceBomb>
                        ))}
                     </Link>
                  </div>
                  <div className="product-name-div tw-mb-1 tw-text-sm/[16px] tw-font-medium">
                     <Link to={productStore.getUrl(product)} onClick={trackClick}>
                        {product.name}
                     </Link>
                  </div>
                  <div className="tw-mb-3 tw-text-xxs tw-text-base-600">Art.nr. {product.sku}</div>
               </div>
               <div className="tw-flex tw-flex-col tw-justify-end">
                  {authStore.isLoggedIn() && !product.newSku && (
                     <>
                        <DisplayPrice
                           className="tw-mb-1"
                           price={product.priceBeforeDiscount ?? product.price ?? 0}
                           newPrice={product.price ?? 0}
                           postPriceDetail={formatUnit(product.unit)}
                        />
                        <div className="tw-mb-2 tw-flex tw-flex-col tw-gap-1">
                           <PricePerItem className="tw-text-xxs tw-text-base-600" product={product} />
                           {isNumber(product.comparisonPrice) && theme.showComparisonPrice && !!product.comparisonUnit && (
                              <div className="tw-mb-1 tw-text-xxs tw-text-base-600">
                                 {formatPrice(product.comparisonPrice)} per {product.comparisonUnit?.toLowerCase()}
                              </div>
                           )}
                        </div>
                     </>
                  )}
                  {!product.newSku && authStore.isLoggedIn() && (
                     <div className={stepperAndButtonContainer}>
                        <InputStepper className={inputStepper} value={tileState.qty} onChange={handleQuantityChange} min={1} />

                        <AddToCartButton
                           product={product}
                           qty={tileState.qty}
                           imageRefFn={() => imageRef}
                           callbacks={callbacks}
                           listInformation={listInformation}
                           position={position}
                           size={currentContainerSize === "thin" ? "regular" : "iconOnly"}
                           className={currentContainerSize === "thin" ? "tw-w-full" : ""}
                        />
                     </div>
                  )}
                  {!product.newSku && !authStore.isLoggedIn() && (
                     <Button className="tw-mt-4" variant="tertiary" size="compact" onClick={uiStore.showLoginModal}>
                        Logg inn for kjøp
                     </Button>
                  )}
                  {!!replacementProduct && (
                     <p className="tw-text-xs">
                        Dette produktet erstattes med{" "}
                        <Link to={productStore.getUrl(replacementProduct)}>{replacementProduct.name}</Link>
                     </p>
                  )}
               </div>
            </div>
         </div>
      </div>
   );
};

export default view(ProductTile);
