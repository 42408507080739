import { disposables as c } from "./designsystem-ui-react136.js";
import { match as u } from "./designsystem-ui-react113.js";
import { once as b } from "./designsystem-ui-react188.js";
function m(t, ...e) {
  t && e.length > 0 && t.classList.add(...e);
}
function v(t, ...e) {
  t && e.length > 0 && t.classList.remove(...e);
}
function g(t, e) {
  let a = c();
  if (!t)
    return a.dispose;
  let { transitionDuration: l, transitionDelay: s } = getComputedStyle(t), [d, p] = [l, s].map((i) => {
    let [r = 0] = i.split(",").filter(Boolean).map((n) => n.includes("ms") ? parseFloat(n) : parseFloat(n) * 1e3).sort((n, f) => f - n);
    return r;
  }), o = d + p;
  if (o !== 0) {
    a.group((r) => {
      r.setTimeout(() => {
        e(), r.dispose();
      }, o), r.addEventListener(t, "transitionrun", (n) => {
        n.target === n.currentTarget && r.dispose();
      });
    });
    let i = a.addEventListener(t, "transitionend", (r) => {
      r.target === r.currentTarget && (e(), i());
    });
  } else
    e();
  return a.add(() => e()), a.dispose;
}
function y(t, e, a, l) {
  let s = a ? "enter" : "leave", d = c(), p = l !== void 0 ? b(l) : () => {
  };
  s === "enter" && (t.removeAttribute("hidden"), t.style.display = "");
  let o = u(s, { enter: () => e.enter, leave: () => e.leave }), i = u(s, { enter: () => e.enterTo, leave: () => e.leaveTo }), r = u(s, { enter: () => e.enterFrom, leave: () => e.leaveFrom });
  return v(t, ...e.base, ...e.enter, ...e.enterTo, ...e.enterFrom, ...e.leave, ...e.leaveFrom, ...e.leaveTo, ...e.entered), m(t, ...e.base, ...o, ...r), d.nextFrame(() => {
    v(t, ...e.base, ...o, ...r), m(t, ...e.base, ...o, ...i), g(t, () => (v(t, ...e.base, ...o), m(t, ...e.base, ...e.entered), p()));
  }), d.dispose;
}
export {
  y as transition
};
