import { view } from "@risingstack/react-easy-state";
import { IconExpandMore, IconLogout, IconUser } from "@tine/designsystem-icons/sharp";
import { Button } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import { type MouseEventHandler, useRef, useState } from "react";
import { Link, useLocation } from "wouter";

import useMediaQuery from "../../common/useMediaQuery";
import { mediaQueries } from "../../common/utils";
import Popover from "../../components/common/popover/Popover";

import authStore from "../../stores/auth/authStore";
import loginState from "../../stores/auth/loginState";
import featuresStore from "../../stores/features/featuresStore";
import notificationsStore from "../../stores/notifications/notificationsStore";

import NotificationsBadge from "../../account/notifications/NotificationsBadge";
import { sendInternalLinkClick } from "../../common/tracking";
import { useMenuItems } from "../../common/utils/menuItems";

const UserMenu = () => {
   const [_, redirectTo] = useLocation();
   const [show, setShow] = useState(false);
   const menuItems = useMenuItems();
   const buttonRef = useRef<HTMLDivElement>(null);

   const notificationsCount = notificationsStore.notifications.data.length;

   const activateSwitchCompany = (e: React.MouseEvent) => {
      loginState.transitionTo("COMPANY_SELECTION");
      e.preventDefault();
   };

   const showSwitchCompany = authStore.companies && authStore.companies.length > 1 && !authStore.isPunchoutSession();

   const logoutAndRedirect: MouseEventHandler = () => {
      if (authStore.isPunchoutSession()) {
         loginState.transitionTo("NOT_LOGGED_IN");
         window.location.replace("/");
      }
      redirectTo("/");
      loginState.transitionTo("NOT_LOGGED_IN");
   };

   const handleClick = () => {
      setShow(!show);
   };

   const handleButtonKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
      if (show && e.key === "Tab" && !e.shiftKey) {
         const firstLink = document?.querySelector("#user-menu ul") as HTMLAnchorElement;
         firstLink?.focus();
      }
   };
   const listItemClasses = "tw-px-5 tw-py-3 hover:tw-bg-surface-default-hover tw-w-full tw-text-start tw-flex tw-items-center";

   return (
      <>
         <div className="tw-flex">
            {featuresStore.notificationsEnabled && notificationsStore.notifications.data.length > 0 && (
               <div className="tw-z-10 -tw-mr-4">
                  <NotificationsBadge count={notificationsCount} />
               </div>
            )}

            <div ref={buttonRef}>
               <Button
                  onKeyDown={handleButtonKeyDown}
                  onClick={handleClick}
                  aria-expanded={show}
                  aria-label="Min konto"
                  aria-controls="user-menu"
                  size="full"
                  iconPosition="left"
                  icon={<IconUser />}
                  variant="tertiary"
                  data-testid="user-menu-button"
               >
                  {useMediaQuery(mediaQueries.xl) && (
                     <div className="tw-flex tw-flex-row tw-items-center">
                        <span className="tw-text-nowrap">Min konto</span>
                        <span className="-tw-mb-[2px] tw-pl-1 tw-text-xs">({authStore.currentCompany})</span>
                        <IconExpandMore className="-tw-my-2 -tw-me-2 tw-h-6 tw-w-6" />
                     </div>
                  )}
               </Button>
            </div>
         </div>

         <Popover
            referenceElement={buttonRef.current}
            id="user-menu"
            show={show}
            onHide={() => setShow(false)}
            placement="bottom"
         >
            <ul
               className="tw-mb-0 tw-px-0 tw-text-sm"
               onClick={() => {
                  buttonRef.current?.focus();
                  setShow(false);
               }}
               onKeyDown={(e) => {
                  if (e.key === "Escape") {
                     buttonRef.current?.focus();
                     setShow(false);
                  }
               }}
            >
               {showSwitchCompany && (
                  <li>
                     <button className="tw-flex tw-w-full" type="button" onClick={activateSwitchCompany}>
                        <div className={listItemClasses}>Bytt firma</div>
                     </button>
                  </li>
               )}

               <div className="tw-border-b tw-border-t tw-border-ink-border-subtle">
                  {menuItems.map((menuItem) => (
                     <li key={menuItem.name}>
                        <Link
                           to={menuItem.link}
                           className="hover:tw-no-underline"
                           onClick={() => sendInternalLinkClick(menuItem.link, "User menu", menuItem.name)}
                        >
                           <div className={cn(listItemClasses, { "tw-bg-surface-default-hover": menuItem.isActive })}>
                              <span>{menuItem.name}</span>
                              <span className="tw-ml-1">
                                 {menuItem.name === "Varslinger" && <NotificationsBadge count={notificationsCount} />}
                              </span>
                           </div>
                        </Link>
                     </li>
                  ))}
               </div>

               <li>
                  <button className="tw-flex tw-w-full" type="button" onClick={logoutAndRedirect}>
                     <div className={listItemClasses}>
                        <IconLogout className="tw-ml-[-2.4px] tw-h-[24px] tw-w-[24px]" />
                        Logg ut
                     </div>
                  </button>
               </li>
            </ul>
         </Popover>
      </>
   );
};

export default view(UserMenu);
