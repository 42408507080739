import { view } from "@risingstack/react-easy-state";
import isNil from "lodash-es/isNil";
import { useState } from "react";
import { useLocation } from "wouter";

import Select from "../common/Select";

import cartStore from "../../stores/cart/cartStore";
import subscriptionStore from "../../stores/subscriptionStore";

import Button from "../Button";
import Loading from "../Loading";
import ShortcutBox from "./ShortcutBox";

const ShortcutChangeSubscription = () => {
   const [_, redirectTo] = useLocation();
   const [showModal, setShowModal] = useState(false);

   const startEdit = (orderNumber: string | null) => {
      if (isNil(orderNumber)) {
         console.warn("Missing order number when starting edit of subscription");
         return;
      }
      setShowModal(true);
      subscriptionStore.fetchSubscriptionDetails(orderNumber).then((subscription) => {
         if (subscription !== null) {
            setShowModal(false);
            void cartStore.startEditSubscriptionMode(subscription);
            redirectTo("/checkout/cart");
         }
      });
   };

   const options = subscriptionStore.subscriptionsList.data.map((sub) => ({ label: sub.name, value: sub.orderNumber }));
   return (
      <>
         {showModal && <Loading modal label="Henter abonnementsdetaljer..." />}
         <ShortcutBox>
            <h4>Mine abonnement</h4>
            <p>Se oversikt over dine abonnement, eller velg et abonnement for å endre alle fremtidige leveringer.</p>

            <Button to="/customer/subscriptions" variant="secondary" className="tw-my-2">
               Alle abonnement
            </Button>

            <Select
               label=""
               options={options}
               value={{ label: "Endre abonnement", value: "", isDisabled: true }}
               onChange={(option) => startEdit(option.value || null)}
               zOnOpen={30}
            />
         </ShortcutBox>
      </>
   );
};

export default view(ShortcutChangeSubscription);
