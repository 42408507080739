import { autoEffect, store, view } from "@risingstack/react-easy-state";
import { IconLightning } from "@tine/designsystem-icons/sharp";
import { Input } from "@tine/designsystem-ui-react";
import isNil from "lodash-es/isNil";
import type { KeyboardEventHandler } from "react";

import {
   BackendIssueError,
   ServerNotFoundError,
   WrongUsernameOrPasswordError,
   backendIssueMessagesByLocale,
   genericLoginErrorByLocale,
   getCredentialsErrorMessage,
   networkErrorMessageByLocale
} from "../../common/errors";
import { getUserNameFromStorage, setUserNameInStorage } from "../../common/utils/storageUtils";
import useSupportedLocale from "../../common/utils/useSupportedLocale";

import authStore from "../../stores/auth/authStore";
import loginState from "../../stores/auth/loginState";
import uiStore, { LOGIN_MODE } from "../../stores/uiStore";

import Button from "../Button";
import ErrorAlertWithOptionalTracking from "../ErrorAlertWithOptionalTracking";

type LocalState = {
   username: string;
   password: string;
   error: WrongUsernameOrPasswordError | BackendIssueError | ServerNotFoundError | null;
};

const LoginUsername = () => {
   const formState: LocalState = store({
      username: getUserNameFromStorage(),
      password: "",
      error: null
   });
   const locale = useSupportedLocale();

   autoEffect(() => setUserNameInStorage(formState.username));

   const attemptLogin = () => {
      formState.error = null;
      authStore.login(formState.username, formState.password).catch((error) => {
         console.warn("Login failed", error);
         formState.error = error;
      });
   };

   const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (event) => {
      if (event.key === "Enter") {
         attemptLogin();
      }
   };

   const error = formState.error;
   let errorMessage = genericLoginErrorByLocale[locale];
   let gaErrorTracking = undefined;

   if (error instanceof BackendIssueError) {
      const backendMessages = backendIssueMessagesByLocale[locale];
      const errorMap: { [message: string]: string } = {
         "Unable to load assortment, error from backend": "fetch_assortment_error",
         "Unable to load delivery dates / pickup": "fetch_delivery_dates_error",
         "Unable to load favourites, error from backend": "fetch_favourites_error",
         "Missing delivery dates": "missing_delivery_dates_error"
      };

      const trackingText = errorMap[error.message];

      if (trackingText) {
         gaErrorTracking = { context: "login", text: trackingText };
         errorMessage = backendMessages[error.message];
      }
   }
   if (error instanceof WrongUsernameOrPasswordError) {
      errorMessage = getCredentialsErrorMessage(formState.username, locale);
   }
   if (error instanceof ServerNotFoundError) {
      gaErrorTracking = { context: "login", text: "server_error" };
      errorMessage = networkErrorMessageByLocale[locale];
   }
   return (
      <div className="tw-flex tw-flex-col">
         {uiStore.accessLevel === 2 ? (
            <div className="tw-py-6 tw-font-bold tw-text-ink-warning">
               <IconLightning /> Du har nå KS-tilgang. Last siden på nytt (F5) for å komme tilbake til normal tilgang.
            </div>
         ) : (
            <>
               <p className="tw-pb-4 tw-pt-2">Skriv inn ditt kundenummer eller din e-postadresse for å logge inn.</p>
               <div className="tw-flex tw-flex-col tw-gap-2">
                  <Input
                     data-testid="login-number-or-email-input"
                     label="E-postadresse/ kundenummer"
                     name="username"
                     value={formState.username}
                     onChange={(e) => {
                        formState.username = e.target.value;
                     }}
                     onKeyDown={handleKeyPress}
                     autoFocus
                     showErrorMessage={!isNil(formState.error)}
                  />

                  <Input
                     data-testid="login-password-input"
                     label="Passord"
                     name="password"
                     type="password"
                     value={formState.password}
                     onChange={(e) => {
                        formState.password = e.target.value;
                     }}
                     onKeyDown={handleKeyPress}
                     showErrorMessage={!isNil(formState.error)}
                     errorMessage=""
                  />
                  <div className="tw-flex tw-justify-end">
                     <Button variant="tertiary" onClick={() => uiStore.switchLoginMode(LOGIN_MODE.RESET_PASSWORD_EMAIL)}>
                        Glemt passord?
                     </Button>
                  </div>

                  {error ? (
                     <ErrorAlertWithOptionalTracking showCloseButton={false} gaErrorTracking={gaErrorTracking}>
                        {errorMessage}
                     </ErrorAlertWithOptionalTracking>
                  ) : null}
               </div>

               <div className="tw-flex tw-flex-col tw-gap-2 tw-pt-2">
                  <Button
                     data-testid="login-submit-button"
                     variant="primary"
                     size="regular"
                     onClick={attemptLogin}
                     loading={loginState.is("CHECKING_LOGIN")}
                  >
                     {loginState.is("LOGIN_MODAL") && "Logg inn"}
                  </Button>

                  <div className="tw--flex tw-items-center tw-gap-2">
                     <hr className="login-divider" />
                     <p className="tw-m-0 tw-text-center">Eller</p>
                     <hr className="login-divider" />
                  </div>

                  <Button size="regular" variant="secondary" onClick={() => uiStore.switchLoginMode(LOGIN_MODE.SMS)}>
                     Logg inn med mobilnummer
                  </Button>
               </div>
            </>
         )}
      </div>
   );
};

export default view(LoginUsername);
