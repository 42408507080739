import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { view } from "@risingstack/react-easy-state";
import { Alert } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";

import type { WarningFields } from "../common/types/cmsTypes";

type WarningProps = {
   warning: WarningFields;
   className?: string;
};

const AlertMessage = ({ warning, className }: WarningProps) => {
   const Message = () => documentToReactComponents(warning.message);

   let variant: "warning" | "information" | "error" | "success" | undefined;

   switch (warning.theme) {
      case "Warning":
         variant = "error";
         break;
      case "Information":
         variant = "information";
         break;
      case "Important":
         variant = "warning";
         break;
      default:
         variant = "information";
   }

   const classNames = cn([
      "tw-justify-center tw-px-0 [&>div>div>div>div>p]:tw-mb-0 [&>div]:tw-max-w-[--main-column-max-width]",
      "[&>div]:tw-px-3 sm:[&>div]:tw-px-4",
      className
   ]);

   return (
      <Alert fullWidth={true} variant={variant} showCloseButton={false} className={classNames}>
         <Message />
      </Alert>
   );
};
export default view(AlertMessage);
