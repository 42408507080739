import { view } from "@risingstack/react-easy-state";
import { IconArrowBack } from "@tine/designsystem-icons/sharp";
import { Grid, Group } from "@tine/designsystem-ui-react";
import { getDate, parseISO } from "date-fns";

import type { ContentfulAdventCalendarSlot } from "../../../common/types/cmsTypes";
import { isDateInTheFuture } from "../../../common/utils/dateUtils";
import BoxWithFlower from "../../common/BoxWithFlower";

import { InternalLink } from "../../Links";
import ContestForm from "../ContestForm";
import RichTextRenderer from "../RichTextRenderer";
import { AdventCalendarHeader } from "./AdventCalendarHeader";

type AdventCalendarArticleProps = {
   adventCalendarArticleContent: ContentfulAdventCalendarSlot;
   isPreview?: boolean;
};

const calendarFooterImage = new URL("../../../../img/julekalender_footer.png", import.meta.url).toString();

const AdventCalendarArticle = ({ adventCalendarArticleContent, isPreview = false }: AdventCalendarArticleProps) => {
   const prizeImage = adventCalendarArticleContent.fields?.prizeImage;
   const prizeText = adventCalendarArticleContent.fields?.prizeText;
   const title = adventCalendarArticleContent.fields?.title;
   const date = adventCalendarArticleContent.fields.date ? parseISO(adventCalendarArticleContent.fields.date) : undefined;

   return (
      <>
         <Group>
            <AdventCalendarHeader />
         </Group>

         <div className="tw-relative tw-flex tw-justify-center tw-pb-4 sm:tw-pb-8">
            <div className="tw-absolute tw-left-0 tw-top-0">
               <InternalLink href="/julekalender" icon={<IconArrowBack />}>
                  Tilbake til julekalenderen
               </InternalLink>
            </div>
            <h1 className="tw-pt-16 tw-font-suomi tw-text-6xl tw-font-medium sm:tw-pt-0">
               {date ? `${getDate(date)}. desember` : title}
            </h1>
         </div>

         <Group>
            {date && isDateInTheFuture(date) && !isPreview && (
               <p>Denne luken er ikke åpen ennå. Gå tilbake til julekalenderen og prøv en annen luke.</p>
            )}
         </Group>

         {((date && !isDateInTheFuture(date)) || isPreview || !date) && (
            <>
               {adventCalendarArticleContent.fields.question && (
                  <Group className="tw-pb-16">
                     <BoxWithFlower theme="christmas" className="tw-w-[400px]">
                        <h2 className="tw-text-center tw-text-2xl">Delta i dagens luke og vinn fine premier</h2>
                        <Grid cols={{ base: 12 }} gap={4} className="tw-py-7">
                           <Grid.Col span={{ base: 3 }}>
                              <div className="tw-aspect-square">
                                 {prizeImage ? (
                                    <img
                                       className="tw-h-full tw-w-full tw-rounded-lg tw-object-cover"
                                       src={prizeImage?.fields.file?.url}
                                       alt={prizeText || "Premie"}
                                    />
                                 ) : (
                                    <div className="tw-h-full tw-w-full tw-rounded-lg tw-bg-base-200" />
                                 )}
                              </div>
                           </Grid.Col>
                           <Grid.Col span={{ base: 9 }}>
                              <p className="tw-text-sm">{prizeText}</p>
                           </Grid.Col>
                        </Grid>
                        <ContestForm
                           id={adventCalendarArticleContent.fields.question?.sys.id}
                           entry={adventCalendarArticleContent.fields.question}
                           slug={adventCalendarArticleContent.fields.slug}
                           trackingFormName="Lukepåmelding julekalender"
                        />
                     </BoxWithFlower>
                  </Group>
               )}
               <>
                  {adventCalendarArticleContent.fields.body && (
                     <div className="tw-pb-12">
                        <RichTextRenderer
                           textColumnWidth="threeQuarters"
                           context="standard-article"
                           document={adventCalendarArticleContent.fields.body}
                           key={JSON.stringify(adventCalendarArticleContent.fields.body)}
                           trackingLocationName="Advent calendar article"
                        />
                     </div>
                  )}
                  <Group>
                     <img className="tw-h-full tw-w-full" src={calendarFooterImage} alt="Julekalender-footer" />
                  </Group>
               </>
            </>
         )}
      </>
   );
};

export default view(AdventCalendarArticle);
