import { uniq } from "lodash-es";
import isEmpty from "lodash-es/isEmpty";
// import isEmail from "validator/lib/isEmail";
// import isMobilePhone from "validator/lib/isMobilePhone";

import type { Recipient, RecipientsResponse } from "../../common/types/recipientTypes";

export const toApiRecipient = (recipient: Recipient): RecipientsResponse["ProfileList"][0] => {
   return {
      Id: recipient.profileId,
      FirstName: recipient.firstName,
      LastName: recipient.lastName,
      Email: recipient.email,
      Mobile: recipient.mobile,
      EmailNotifications: recipient.emailNotifications,
      SmsNotifications: recipient.smsNotifications
   };
};

export const getDeleteRequestData = (profileId: string) => ({ ProfileId: [profileId] });

export const sanitizePhoneNumber = (phone: string) => {
   let sanitized = phone.replaceAll(/[^0-9\\+]+/g, "");
   sanitized = sanitized.replaceAll(/[\\ \\-_]+/g, "");
   if (sanitized.startsWith("00") && sanitized.length > 8) {
      sanitized = `+${sanitized.substring(2)}`;
   }
   if (sanitized.startsWith("47") && sanitized.length === 10) {
      sanitized = `+${sanitized}`;
   }
   if (!sanitized.startsWith("+") && sanitized.length === 8) {
      sanitized = `+47${sanitized}`;
   }
   return sanitized;
};

export type LegacyRecipient = {
   id: string | null;
   name: string;
   email: string;
   mobile: string;
   emailNotifications: string[];
   smsNotifications: string[];
};

export const mapFromLegacyRecipient = (recipient: LegacyRecipient) => {
   const name = recipient.name || "";

   const nameSplit = name.lastIndexOf(" ");
   const firstName = name.substring(0, nameSplit);
   const lastName = name.substring(nameSplit + 1);

   return {
      profileId: recipient.id ?? "",
      firstName,
      lastName,
      email: recipient.email,
      mobile: recipient.mobile,
      emailNotifications: recipient.emailNotifications,
      smsNotifications: recipient.smsNotifications,
      allNotifications: uniq([...recipient.emailNotifications, ...recipient.smsNotifications])
   } satisfies Recipient;
};

export const mapToLegacyRecipient = (recipient: Recipient): LegacyRecipient => ({
   id: !isEmpty(recipient.profileId) ? recipient.profileId : null,
   name: `${recipient.firstName} ${recipient.lastName}`,
   email: recipient.email,
   mobile: recipient.mobile,
   emailNotifications: recipient.emailNotifications,
   smsNotifications: recipient.smsNotifications
});
