import { view } from "@risingstack/react-easy-state";

import useSupportedLocale from "../../../common/utils/useSupportedLocale";

type AmountDisplayProps = {
   amount: number;
   multiplier: number;
};

const replacements = [
   { from: ",25", to: "¼" },
   { from: ",75", to: "¾" },
   { from: ",33", to: "⅓" },
   { from: ",67", to: "⅔" },
   { from: ",5", to: "½" }
];

/**
 * Find the closest third or quarter to the given amount
 */
const closestRound = (amount: number) => {
   const quarter = Math.ceil(amount * 4) / 4;
   const third = Math.ceil(amount * 3) / 3;
   if (Math.abs(amount - quarter) < Math.abs(amount - third)) {
      return quarter;
   }
   return third;
};

const AmountDisplay = ({ amount, multiplier }: AmountDisplayProps) => {
   const locale = useSupportedLocale();
   const totalAmount = amount * multiplier;
   const formatter = new Intl.NumberFormat(locale, { maximumFractionDigits: totalAmount > 50 ? 0 : 2 });
   const roundedAmount = closestRound(totalAmount);
   let prettyAmount = formatter.format(roundedAmount);

   for (const replacement of replacements) {
      prettyAmount = prettyAmount.replace(new RegExp(`0${replacement.from}$`), replacement.to);
      prettyAmount = prettyAmount.replace(new RegExp(`${replacement.from}$`), replacement.to);
   }

   return <>{prettyAmount}</>;
};

export default view(AmountDisplay);
