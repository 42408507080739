import { jsx as t, jsxs as e } from "react/jsx-runtime";
import { cn as f } from "./designsystem-ui-react46.js";
import { Fragment as w } from "react";
import { Button as h } from "./designsystem-ui-react6.js";
import v from "./designsystem-ui-react53.js";
import { Transition as i } from "./designsystem-ui-react48.js";
import { Dialog as a } from "./designsystem-ui-react66.js";
const N = {
  sm: "tw-max-w-sm md:tw-max-w-md md:tw-min-w-sm",
  md: "tw-max-w-md md:tw-max-w-lg md:tw-min-w-md",
  lg: "tw-max-w-lg md:tw-max-w-xl md:tw-min-w-lg",
  xl: "tw-max-w-2xl md:tw-max-w-3xl md:tw-min-w-xl",
  "2xl": "tw-max-w-3xl md:tw-max-w-4xl md:tw-min-w-2xl"
};
function g({
  className: c,
  title: m,
  TitleIcon: s,
  description: n,
  children: p,
  footer: r,
  isOpen: o,
  onClose: d = () => {
  },
  size: x = "lg",
  zIndex: l = 50,
  showCloseButton: u = !0,
  showBackdrop: y = !0
}) {
  return /* @__PURE__ */ t(i, { appear: !0, as: w, show: o, children: /* @__PURE__ */ e(a, { className: "tw-relative", onClose: d, open: o, children: [
    y ? /* @__PURE__ */ t(
      i.Child,
      {
        as: w,
        enter: "tw-ease-out tw-duration-300",
        enterFrom: "tw-opacity-0",
        enterTo: "tw-opacity-100",
        leave: "tw-ease-in tw-duration-200",
        leaveFrom: "tw-opacity-100",
        leaveTo: "tw-opacity-0",
        children: /* @__PURE__ */ t(
          "div",
          {
            "aria-hidden": "true",
            className: "tw-fixed tw-inset-0 tw-bg-base-900 tw-opacity-50",
            style: { zIndex: l - 1 }
          }
        )
      }
    ) : null,
    /* @__PURE__ */ t("div", { className: "tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center tw-p-4", style: { zIndex: l }, children: /* @__PURE__ */ t(
      i.Child,
      {
        as: w,
        enter: "tw-ease-out tw-duration-300",
        enterFrom: "tw-opacity-0 tw-scale-95",
        enterTo: "tw-opacity-100 tw-scale-100",
        leave: "tw-ease-in tw-duration-300",
        leaveFrom: "tw-opacity-100 tw-scale-100",
        leaveTo: "tw-opacity-0 tw-scale-95",
        children: /* @__PURE__ */ e(
          a.Panel,
          {
            className: f(
              "tw-transition-all tw-rounded-md tw-w-full tw-bg-base-0",
              "tw-flex tw-flex-col tw-max-h-[90vh]",
              N[x],
              c
            ),
            style: { zIndex: l },
            children: [
              /* @__PURE__ */ e("div", { className: "tw-p-6 tw-pb-4", children: [
                /* @__PURE__ */ e("div", { className: "tw-flex tw-items-center tw-justify-between", children: [
                  m ? /* @__PURE__ */ e(a.Title, { className: "tw-flex tw-gap-2 tw-mb-0", children: [
                    s ? /* @__PURE__ */ t("span", { children: /* @__PURE__ */ t(s, { className: "tw-size-6" }) }) : null,
                    /* @__PURE__ */ t("span", { className: "tw-font-bold", children: m })
                  ] }) : null,
                  u ? /* @__PURE__ */ t(
                    h,
                    {
                      "aria-label": "Close",
                      icon: /* @__PURE__ */ t(v, {}),
                      onClick: d,
                      size: "iconOnly",
                      type: "button",
                      variant: "tertiary"
                    }
                  ) : null
                ] }),
                n ? /* @__PURE__ */ t(a.Description, { className: "tw-mt-4", children: n }) : null
              ] }),
              /* @__PURE__ */ t("div", { className: "tw-overflow-y-auto tw-flex-1", children: /* @__PURE__ */ t("div", { className: "tw-p-6 tw-py-2", children: p }) }),
              r ? /* @__PURE__ */ t("div", { className: "tw-p-6 tw-pt-4", children: r }) : null
            ]
          }
        )
      }
    ) })
  ] }) });
}
g.displayName = "Modal";
export {
  g as Modal
};
