import { view } from "@risingstack/react-easy-state";

import { LIST_TYPE } from "../common/types/trackingTypes";
import ProductList from "./common/product/ProductList";

import authStore from "../stores/auth/authStore";
import favoriteStore from "../stores/favoriteStore";

import ShortcutBoxes from "./shortcuts/ShortcutBoxes";

type FavoritesAndSubscriptionsWidgetProps = {
   heading?: string | null;
};

const FavoritesAndSubscriptionsWidget = ({ heading }: FavoritesAndSubscriptionsWidgetProps) => {
   if (!authStore.isLoggedIn()) {
      return null;
   }

   return (
      <>
         <ShortcutBoxes />
         <ProductList
            heading={heading}
            count={4}
            skus={favoriteStore.allSkus.slice(0, 4)}
            buttonText={"Favorittlister"}
            buttonUrl="/customer/favorites"
            listName="Mine Favoritter (CMS Block)"
            listType={LIST_TYPE.CUSTOM}
            context="favorites-and-subscriptions"
         />
      </>
   );
};

export default view(FavoritesAndSubscriptionsWidget);
