import { view } from "@risingstack/react-easy-state";
import { SectionCard } from "@tine/designsystem-ui-react";
import { useInView } from "react-intersection-observer";
import { Link } from "wouter";

import { sendSelectTeaser, sendViewTeaser } from "../../common/tracking";
import type { ContentfulLargeTeaser } from "../../common/types/cmsTypes";
import { getModuleVisibility } from "../../common/utils/segmentUtils";

import segmentStore from "../../stores/cms/segmentStore";

import { isExternalLink } from "../../common/utils";
import FullWidthBackground from "../FullWidthBackground";

type LargeTeaserProps = {
   content: ContentfulLargeTeaser;
};

const LargeTeaser = ({ content }: LargeTeaserProps) => {
   const trackImpression = (isVisible: boolean) => {
      if (isVisible) {
         sendViewTeaser(title, contentType);
      }
   };

   const { ref: viewRef } = useInView({
      triggerOnce: true,
      onChange: trackImpression
   });

   const isVisible = getModuleVisibility(content, segmentStore.currentCustomerProperties);

   if (!isVisible) {
      return null;
   }
   const title = content.fields.title;
   const description = content.fields.description;
   const image = content.fields.image;
   const buttonText = content.fields.buttonText;
   const buttonLink = content.fields.buttonLink ?? "";
   const themeBackground = content.fields.background;
   const imagePosition = content.fields.imagePosition;
   const contentType = content.sys.contentType.sys.id;

   const LinkComponent = isExternalLink(buttonLink) ? "a" : Link;

   return (
      <div ref={viewRef} className="tw-relative">
         {themeBackground && <FullWidthBackground color="tw-bg-infoBoxBackground" />}

         <div className="tw-relative tw-py-8 lg:tw-py-10">
            <SectionCard
               title={title}
               description={description}
               image={image?.fields.file?.url}
               imageAlt={image?.fields.title}
               ctaLabel={buttonText}
               href={buttonLink}
               linkComponent={LinkComponent}
               backgroundColor="transparent"
               buttonVariant="primary"
               isImageFlipped={imagePosition === "Right"}
               className="[&_strong]:tw-font-semibold"
               onCtaClick={() => sendSelectTeaser(title, contentType, undefined, `/${buttonLink}`)}
            />
         </div>
      </div>
   );
};

export default view(LargeTeaser);
