import { view } from "@risingstack/react-easy-state";

import { LIST_TYPE } from "../common/types/trackingTypes";
import { isDefaultData, isLoading } from "../common/utils/asyncDataUtils";
import ProductList from "./common/product/ProductList";

import authStore from "../stores/auth/authStore";
import recommendationStore from "../stores/recommendationStore";

import Loading from "./Loading";

type ContractualProductListProps = {
   heading?: string;
   count: number;
   type: "shouldBuy" | "fullList";
};

const ContractualProductList = ({ heading, count, type }: ContractualProductListProps) => {
   if (!authStore.isLoggedIn()) {
      return null;
   }

   if (isDefaultData(recommendationStore.contractualRecommendations)) {
      recommendationStore.getContractualRecommendations();
   }

   if (isLoading(recommendationStore.contractualRecommendations)) {
      return <Loading />;
   }

   const data = recommendationStore.contractualRecommendations.data;

   const skus =
      type === "shouldBuy"
         ? data?.shouldBuy || []
         : [...(data?.voluntaryProducts || []), ...(data?.contractualObligatedProducts.map((p) => p.sku) || [])];

   return (
      <ProductList
         skus={skus}
         heading={heading}
         count={count}
         listName="Avtalt Sortiment"
         listType={LIST_TYPE.CONTRACTUAL_PRODUCTS}
         context="contractual-products"
      />
   );
};

export default view(ContractualProductList);
