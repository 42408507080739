import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const SvgYt = (props) => (_jsxs("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 200 200", focusable: "false", "aria-hidden": "true" }, props, { children: [_jsx("defs", { children: _jsx("clipPath", { id: "yt_svg__a", clipPathUnits: "userSpaceOnUse", children: _jsx("path", { d: "M0 0h2849v2873.91H0Z" }) }) }), _jsxs("g", { clipPath: "url(#yt_svg__a)", transform: "matrix(.06815 0 0 -.06815 2.922 197.926)", children: [_jsx("path", { d: "M2849.06 0H.313L0 2873.91h2849.06z", style: {
                        fill: "#332d85",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    } }), _jsx("path", { d: "M439.723 1058.73V804.988h433.875l185.772 351.152zM1877.55 1284.74l117.06-479.752h430.37v565.802z", style: {
                        fill: "#ed1c24",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    } }), _jsx("path", { d: "m1059.37 1156.14 159.15-351.152h431.14l227.89 479.752z", style: {
                        fill: "#1f59ab",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    } }), _jsx("path", { d: "M2374.03 2027.66H1334.24l-288.17-416.01c-134.836 194.64-331.179 478.04-331.179 478.04l-289.68-174.67 448.387-647.28V804.988h344.922v462.752l303.52 438.12h127.62V804.988h344.95v900.872h379.42zM2325.63 927.922H2315v-18.953h10.63c6.08 0 10.28 4.179 10.28 9.461 0 5.308-4.2 9.492-10.28 9.492m10.28-53.043-12.86 24.922H2315v-24.922h-11.56v62.832h23.62c11.91 0 20.27-8.832 20.27-19.281 0-8.821-5.48-14.61-12.38-17.032l13.96-26.519zm-12.22 78.742c-26.03 0-46.61-20.883-46.61-47.383 0-26.519 20.58-47.41 46.61-47.41 26.06 0 46.46 20.891 46.46 47.41 0 26.5-20.4 47.383-46.46 47.383m0-105.543c-32.15 0-58.21 26-58.21 58.16 0 32.141 26.06 58.141 58.21 58.141s58.2-26 58.2-58.141c0-32.16-26.05-58.16-58.2-58.16", style: {
                        fill: "#fff",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    } })] })] })));
export default SvgYt;
