import { view } from "@risingstack/react-easy-state";
import isNil from "lodash-es/isNil";
import isNumber from "lodash-es/isNumber";

import type { SimplifiedProduct } from "../common/types/productTypes";
import { formatPrice, translateBaseUnit } from "../common/utils";

type PricePerItemProps = {
   product: SimplifiedProduct;
   className?: string;
};

const PricePerItem = ({ product, className = "" }: PricePerItemProps) => {
   const amount =
      product.unit === "STK" || isNil(product?.conversionFactor) || !isNumber(product.conversionFactor)
         ? 1
         : product.conversionFactor;
   const baseUnit = translateBaseUnit(product.baseUnit, amount);

   return (
      <div className={className}>
         {amount.toFixed(0)} {baseUnit} á {formatPrice(!isNil(product.price) ? product.price / amount : null)}
      </div>
   );
};

export default view(PricePerItem);
