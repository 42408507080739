/* import { TagIcon } from "@heroicons/react/24/outline"; */
import { isFuture, isPast, parse, parseISO, subWeeks } from "date-fns";
import { groupBy, isNil, isUndefined, keys, mapValues, toLower } from "lodash-es";
import every from "lodash-es/every";
import includes from "lodash-es/includes";
import isArray from "lodash-es/isArray";
import some from "lodash-es/some";

import { ORDER_UNIT } from "../../common/types/productOrderTypes";
import {
   type Badge,
   type BadgeKey,
   type FilterSettings,
   type Filters,
   type Label,
   type LegalFilterKey,
   PageType,
   type Product,
   hasKey,
   isFilterAttribute
} from "../../common/types/productTypes";

/**
 * Takes a list of existing badges and adds another bedge if the product has the given attributes.
 */
const mapBadge = (
   existingBadges: Badge[],
   product: Product,
   certificationValue: string,
   badgeName: string,
   badgeKey: BadgeKey
) => {
   if (isNil(product.certifications)) {
      return;
   }

   const normalizedCerts = product.certifications.map((cert) => cert?.toLowerCase() || "");

   if (includes(normalizedCerts, certificationValue.toLowerCase())) {
      existingBadges.push({ name: badgeName, key: badgeKey });
   }
};

/**
 * Inspects the attributes of the products and creates Badges for matching attributes.
 * @returns An array of badges.
 */
export const mapBadges = (product: Product) => {
   const badges: Badge[] = [];
   if (product.recyclingChargeBaseUnit === 2) {
      badges.push({ name: "Pant, kr 2", key: "pant_2kr" });
   }
   if (product.recyclingChargeBaseUnit === 3) {
      badges.push({ name: "Pant, kr 3", key: "pant_3kr" });
   }
   mapBadge(badges, product, "Nyt_Norge", "Nyt Norge", "nyt_norge");
   mapBadge(badges, product, "Nokkelhullet", "Nøkkelhull", "nokkelhullet");
   return badges;
};

export const shouldShowExpiryNotice = (product: Product) =>
   !isNil(product.expiringFromDate) && product.expiringFromDate !== "" && isPast(subWeeks(parseISO(product.expiringFromDate), 4));

/**
 * Inspects the attributes of the products and creates Labels for matching attributes.
 * @returns An array of labels.
 */
export const mapLabels = (product: Product) => {
   const labels: Label[] = [];

   // Label: Veievare - Prio: 1
   if (product.variableWeight) {
      labels.push({
         text: "Veievare",
         color: "gray",
         position: "bottom-right",
         page: [PageType.ProductDetailsPage, PageType.ProductListPage]
      });
   }

   // Label: Nyhet - Prio: 2 (#c40076)
   if (!isNil(product.newUntilDate) && product.newUntilDate.length > 0) {
      const newUntil = parse(product.newUntilDate, "dd.MM.yyyy", new Date());
      if (isFuture(newUntil)) {
         labels.push({
            text: "Nyhet",
            color: "blue",
            position: "top-left",
            page: [PageType.ProductDetailsPage, PageType.ProductListPage]
         });
      }
   }

   // Label: Siste sjanse - Prio: 3 - Show 4 weeks before expiry date (#c40076)
   if (!isNil(product.expiringFromDate) && product.expiringFromDate.length > 0) {
      if (shouldShowExpiryNotice(product)) {
         labels.push({
            text: "Siste sjanse",
            color: "red",
            position: "top-left",
            page: [PageType.ProductDetailsPage, PageType.ProductListPage]
         });
      }
   }

   // Label: Avtalesortiment - Prio: 3 (#554494 + tag icon)
   if (product.inAgreementAssortment) {
      labels.push({
         text: "Avtalesortiment",
         color: "gray",
         position: "bottom-left",
         page: [PageType.ProductListPage]
      });
   }

   // Label: Økologisk - Prio: 3 (#c40076)
   const lowercaseCertifications = product.certifications?.map(toLower) ?? [];
   if (includes(lowercaseCertifications, "eu_okologi") || includes(lowercaseCertifications, "debio")) {
      labels.push({
         text: "Økologisk",
         color: "green",
         position: "top-left",
         page: [PageType.ProductListPage, PageType.ProductDetailsPage]
      });
   }

   // Label: Egne frister (for minitanker) - Prio: 5 (#4d70bc)
   if (product.miniTank) {
      labels.push({
         text: "Egne frister",
         color: "gray",
         position: "bottom-left",
         page: [PageType.ProductListPage, PageType.ProductDetailsPage]
      });
   }

   const seasonalProducts = [
      "5778",
      "7110",
      "7106",
      "7109",
      "345",
      "102",
      "410",
      "805",
      "6896",
      "638",
      "8687",
      "8768",
      "6746",
      "6855",
      "6862",
      "6865",
      "6706",
      "6746",
      "2907",
      "2978",
      "8723",
      "8771",
      "8773",
      "8815",
      "8661",
      "5254",
      "8720"
   ];

   if (includes(seasonalProducts, product.sku)) {
      labels.push({
         text: "Sesong",
         color: "moss",
         position: "top-left",
         page: [PageType.ProductListPage, PageType.ProductDetailsPage]
      });
   }

   return labels;
};

/**
 * Returns a boolean indicating whether the product matches the active filters.
 */
export const matchesFilter = (product: Product, activeFilters: Filters): boolean => {
   return every(activeFilters, (selectedValues, field) => {
      // Type-guards to make sure field name sent to filter function are "filterable"
      if (!hasKey(product, field) || !isFilterAttribute(field)) {
         return false;
      }
      const productValue = product[field];
      return some(selectedValues, (selectedValue) =>
         isArray(productValue) ? includes(productValue, selectedValue) : productValue === selectedValue
      );
   });
};

/**
 * Creates a list of unique values for a specific product attribute given the name of the field and a list of products. Used to build one filter
 * for each filterable attribute.
 *
 * @returns Object with field key, field name and a object with one key for each avaialble value and a list of SKUs that have this value
 */
export const buildFilter = (products: Product[] | undefined, field: LegalFilterKey, name: string): FilterSettings | null => {
   if (isUndefined(products)) {
      return null;
   }

   // Remove products that dont have this field set or has an empty array for this field
   const validProductsForFilters = products.filter((p) => !isNil(p[field]) && (p[field]?.length ?? 0) > 0);

   // If a field given is a field that can have multiple values, we split the product into multiple products, one for each value
   // in array (for instance, one products with two certifications turns into two separate products in this chain)
   const skuAndFieldValues = validProductsForFilters.flatMap((p) =>
      field === "certifications" ? (p[field] ?? []).map((c) => ({ sku: p.sku, value: c })) : [{ sku: p.sku, value: p[field] }]
   );

   // Group all products based on their value in field
   const groupedByFieldValue: { [fieldValue: string]: string[] } = mapValues(groupBy(skuAndFieldValues, "value"), "sku");

   if (keys(groupedByFieldValue).length === 0) {
      return null;
   }

   const optional = field === "customerType" ? { radioDefault: "Isbar" } : {};

   return {
      field,
      name,
      options: groupedByFieldValue,
      ...optional
   };
};

export const getRelevantFilterSettings = (
   fullSettings: FilterSettings[],
   effectiveFilters: Filters,
   products: Product[]
): FilterSettings[] => {
   return fullSettings
      .map((settings) => {
         if (settings.radioDefault) {
            return settings;
         }
         let testFilters = { ...effectiveFilters };
         const filterForSettings = effectiveFilters[settings.field];
         if (filterForSettings) {
            const { [settings.field]: _, ...otherFilters } = effectiveFilters;
            testFilters = otherFilters;
         }
         const productsFilteredOnOtherFilters = products.filter((product) => matchesFilter(product, testFilters));
         return buildFilter(productsFilteredOnOtherFilters, settings.field, settings.name);
      })
      .filter((f) => f) as FilterSettings[];
};

export const hasDiscount = (product: Product): boolean => product.priceBeforeDiscount !== product.price;

export const isDistributionPack = (unit: ORDER_UNIT): boolean => includes([ORDER_UNIT.D, ORDER_UNIT.DFP], unit);

const nbsp = String.fromCodePoint(0x00a0);
/**
 * Trims the input name and replaces any whitespace before units (kg, g, l, ml)
 * at the end of the string with a non-breaking space to prevent line breaks.
 * For example, "Milk 1 l" becomes "Milk 1<nbsp>l".
 */
export const fixDanglingUnits = (name: string) => {
   return name ? name.trim().replace(/\s+(kg|g|l|ml)$/i, `${nbsp}$1`) : name;
};

export function prioritizeAgreementAssortment(products: Product[] | undefined, shouldPrioritize: boolean): Product[] {
   const safeProducts = products ?? [];
   return shouldPrioritize
      ? safeProducts.toSorted((a, b) => Number(b.inAgreementAssortment) - Number(a.inAgreementAssortment))
      : safeProducts;
}
