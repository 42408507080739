import { view } from "@risingstack/react-easy-state";

import loginState from "../../stores/auth/loginState";
import contentStore from "../../stores/cms/contentStore";
import uiStore from "../../stores/uiStore";

import Button from "../Button";
import TranslationText from "../TranslationText";

const LoginButton = () => {
   return (
      <>
         {!contentStore.isPreviewMode && !loginState.is("START_UP") && (
            <Button
               data-testid="login-button"
               size="regular"
               className="tw-mr-2"
               onClick={uiStore.showLoginModal}
               trackingName="Logg inn navbar"
            >
               <TranslationText textKey="Login.LoginButton" defaultText="Logg inn" />
            </Button>
         )}
      </>
   );
};

export default view(LoginButton);
