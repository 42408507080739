import { debounce } from "lodash-es";
import { useCallback, useLayoutEffect, useRef, useState } from "react";

export type WidthRange = [start: number, end: number] | [start: number];

export type WidthRanges<Size extends string> = { [size in Size]: WidthRange };

const widthFromBoxSize = (size: ResizeObserverSize | readonly ResizeObserverSize[]): number =>
   Array.isArray(size) ? widthFromBoxSize(size[0] || { blockSize: 0, inlineSize: 0 }) : (size as ResizeObserverSize).inlineSize;

const externalWidthFromEntry = (entry: ResizeObserverEntry) => {
   return entry.borderBoxSize ? widthFromBoxSize(entry.borderBoxSize) : entry.contentRect.width;
};

const sizeFromWidthFactory =
   <Size extends string>(widthRanges: WidthRanges<Size>) =>
   (width: number): Size | undefined => {
      const roundedWidth = Math.floor(width + 0.5);
      for (const [key, value] of Object.entries(widthRanges)) {
         const range = value as WidthRange;
         const [start, end] = range;
         if (typeof end === "number") {
            if (roundedWidth <= end && roundedWidth >= start) {
               return key as Size;
            }
         } else {
            if (roundedWidth >= start) {
               return key as Size;
            }
         }
      }
      return undefined;
   };

/*
 *
 * useContainerWidths provides a value that is derived from the border-box width of an HTMLElement.  Internally, the hook instantiates a ResizeObserver that
 * will update the value whenever the element's width crosses the defined threshold values.
 *
 */
const useContainerWidth = <Size extends string, T extends HTMLElement>(ranges: WidthRanges<Size>) => {
   const [size, setSize] = useState<Size>();
   const ref = useRef<T | null>(null);
   const observerRef = useRef<ResizeObserver>();

   const handleResize = useCallback(
      debounce(([entry]: readonly ResizeObserverEntry[]) => {
         const sizeFromWidth = sizeFromWidthFactory(ranges);
         const width = externalWidthFromEntry(entry);
         const nextSize = sizeFromWidth(width);
         if (nextSize && nextSize !== size) {
            setSize(nextSize);
         }
      }, 10),
      [ranges]
   );

   useLayoutEffect(() => {
      if (!observerRef.current) {
         observerRef.current = new ResizeObserver(handleResize);
      }
      if (ref.current) {
         observerRef.current.observe(ref.current, { box: "border-box" });
      }

      return () => {
         observerRef.current?.disconnect();
         observerRef.current = undefined;
      };
   }, [ranges, observerRef, ref]);

   return { size, ref };
};

export default useContainerWidth;
