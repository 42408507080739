import { view } from "@risingstack/react-easy-state";
import type { PropsWithChildren } from "react";

type ShortcutBoxProps = {
   className?: string;
};

const ShortcutBox = ({ className = "", children }: PropsWithChildren<ShortcutBoxProps>) => {
   return <div className={`tw-m-1 tw-flex-shrink tw-bg-surface-default tw-p-3 ${className}`}>{children}</div>;
};

export default view(ShortcutBox);
