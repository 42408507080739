import { cn } from "@tine/designsystem-utils";
import { type Day as DFDay, getISOWeek, isSameDay } from "date-fns";
import { useMemo } from "react";

import type { DayFlags, MonthAndYear } from "../../../common/types/calendarTypes";
import { datesForWeek } from "../../../common/utils/dateUtils";

import Day from "./Day";

type WeekProps = {
   weekIndex: number;
   showWeekNumber?: boolean;
   size: "compact" | "regular";
   weekStartsOn?: DFDay; // 0 = Sunday, 1 = Monday (default)
   currentMonthOnly: boolean;
   isArrowFocus?: boolean;
   focusedDate?: Date;
   getDayFlags: (date: Date) => DayFlags;
   onDayClicked: (date: Date, element: HTMLElement) => void;
} & MonthAndYear;

const Week = ({
   weekIndex,
   month,
   year,
   weekStartsOn = 1,
   showWeekNumber = false,
   currentMonthOnly,
   isArrowFocus,
   focusedDate,
   getDayFlags,
   size,
   onDayClicked
}: WeekProps) => {
   const dates = useMemo(() => {
      return datesForWeek(weekIndex, { month, year }, weekStartsOn);
   }, [weekIndex, month, year]);

   const thursday = useMemo(() => dates.find((date) => date.getDay() === 4), [dates]);

   const weekNumber = showWeekNumber && thursday ? getISOWeek(thursday) : undefined;

   const weekNumberCN = cn("tw-flex tw-items-center tw-justify-center", "tw-text-ink-brand-subtle", {
      "tw-text-sm": size === "compact"
   });
   const dayFlags = (date: Date) => {
      const notInMonth = date.getMonth() !== month;
      const flags: DayFlags = getDayFlags(date);
      if (!notInMonth && focusedDate && isSameDay(focusedDate, date)) {
         flags.focused = true;
      }
      return {
         ...flags,
         notInMonth,
         focused: flags.focused && !notInMonth
      };
   };
   return (
      <>
         {typeof weekNumber === "number" && <div className={weekNumberCN}>{weekNumber}</div>}
         {dates.map((date) => {
            if (currentMonthOnly && date.getMonth() !== month) {
               return <div key={`${date.toISOString()}-${month}`} />;
            }
            return (
               <Day
                  key={`${date.toISOString()}-${month}`}
                  date={date}
                  {...dayFlags(date)}
                  size={size}
                  onDayClicked={onDayClicked}
                  isArrowFocus={isArrowFocus}
               />
            );
         })}
      </>
   );
};

export default Week;
