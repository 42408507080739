import { view } from "@risingstack/react-easy-state";
import { cn } from "@tine/designsystem-utils";

type LineSeparatorProps = {
   className?: string;
   margin?: "sm" | "md" | "lg" | "none";
};

const Divider = ({ className = "", margin = "md" }: LineSeparatorProps) => {
   const lineSeparatorStyle = "tw-border-solid tw-border-0 tw-border-t tw-border-ink-border-subtle";
   const lineMargin = {
      sm: "tw-my-4",
      md: "tw-my-6",
      lg: "tw-my-8",
      none: ""
   }[margin];

   return <div className={cn(className, lineSeparatorStyle, lineMargin)} />;
};

export default view(Divider);
