import { isNil } from "lodash-es";

import { generateRandomOrderId } from "../../stores/orders/orderUtilsAndApi";

import type { Session } from "../types/companyTypes";
import type { EditMode, ORDERTYPE } from "../types/productOrderTypes";

export enum STORAGEKEY {
   EDIT_MODE = "editMode",
   ASSORTMENT = "assortment",
   SESSION = "session",
   CAMPAIGN_SIGNUP = "campaignSignup",
   USER_NAME = "storeUsername",
   BARCODE_FOCUS_DIST = "barcodeFocusDist",
   BARCODE_DEVICE_ID = "barcodeDeviceId",
   PHONE_NUMBER = "rememberPhoneNumber",
   ORDER_TYPE = "orderType",
   DATA_VERSION = "dataVersion",
   HAS_REFRESHED = "hasRefreshed",
   LANGUAGE = "language",
   COMPANY = "company",
   LAST_COMPANY = "lastCompany",
   CART_ID = "cartId"
}

/** Generic function which returns either the item from the storage or the defaultvalue */
const getStorage = <T>(key: STORAGEKEY, defaultValue: T, storageType?: "keepBetweenSessions" | "keepThisSession"): T => {
   const storage = storageType === "keepBetweenSessions" ? window.localStorage : window.sessionStorage;
   const item = storage.getItem(key);
   return isNil(item) ? defaultValue : JSON.parse(item);
};

/** Generic function which sets the value in storage */
const setStorage = <T>(key: STORAGEKEY, newValue: T, storageType?: "keepBetweenSessions" | "keepThisSession") => {
   const storage = storageType === "keepBetweenSessions" ? window.localStorage : window.sessionStorage;
   isNil(newValue) ? storage.removeItem(key) : storage.setItem(key, JSON.stringify(newValue));
};

// BARCODE DEVICE ID
export const getBarcodeDeviceId = (): string | null => getStorage(STORAGEKEY.BARCODE_DEVICE_ID, null, "keepBetweenSessions");
export const setBarcodeDeviceIdInStorage = (newValue: string) =>
   setStorage(STORAGEKEY.BARCODE_DEVICE_ID, newValue, "keepBetweenSessions");

// BARCODE FOCUS DIST
export const getBarcodeFocusDistFromStorage = (defaultValue: number): number =>
   getStorage(STORAGEKEY.BARCODE_FOCUS_DIST, defaultValue, "keepBetweenSessions");
export const setBarcodeFocusDistInStorage = (newValue: string | null) =>
   setStorage(STORAGEKEY.BARCODE_FOCUS_DIST, newValue, "keepBetweenSessions");

// PHONE NUMBER
export const getPhoneNumberFromStorage = (): string => getStorage(STORAGEKEY.PHONE_NUMBER, "", "keepBetweenSessions");
export const setPhoneNumberInStorage = (newValue: string | null) =>
   setStorage(STORAGEKEY.PHONE_NUMBER, newValue, "keepBetweenSessions");

// USER NAME
export const getUserNameFromStorage = (): string => getStorage(STORAGEKEY.USER_NAME, "", "keepBetweenSessions");
export const setUserNameInStorage = (newValue: string | null) =>
   setStorage(STORAGEKEY.USER_NAME, newValue, "keepBetweenSessions");

// SESSION
export const getSessionFromStorage = (): Session | null => getStorage(STORAGEKEY.SESSION, null, "keepThisSession");
export const setSessionInStorage = (newValue: Session | null) => setStorage(STORAGEKEY.SESSION, newValue, "keepThisSession");

type CampaignSignupsType = {
   [key: string]: string[];
};

// HAS REFRESHED
export const getHasRefreshedFromStorage = (): boolean => getStorage(STORAGEKEY.HAS_REFRESHED, false, "keepBetweenSessions");
export const setHasRefreshedInStorage = (newValue: boolean) =>
   setStorage(STORAGEKEY.HAS_REFRESHED, newValue, "keepBetweenSessions");

// DATA VERSION
export const getDataVersionFromStorage = (): null | string => getStorage(STORAGEKEY.DATA_VERSION, null, "keepThisSession");
export const setDataVersionInStorage = (newValue: string | null) =>
   setStorage(STORAGEKEY.DATA_VERSION, newValue, "keepThisSession");

// ORDER TYPE
export const getOrdertypeFromStorage = (defaultOrderType: ORDERTYPE): ORDERTYPE =>
   getStorage(STORAGEKEY.ORDER_TYPE, defaultOrderType, "keepThisSession");
export const setOrderTypeInStorage = (newValue: ORDERTYPE | undefined) =>
   setStorage(STORAGEKEY.ORDER_TYPE, newValue, "keepThisSession");

// CART_ID
export const getCartIdFromStorage = (): string | null =>
   getStorage(STORAGEKEY.CART_ID, generateRandomOrderId(), "keepThisSession");
export const setCartIdInStorage = (newValue: string | null) => setStorage(STORAGEKEY.CART_ID, newValue, "keepThisSession");

// ASSORTMENT
export const getAssortmentFromStorage = (): string[] | [] => getStorage(STORAGEKEY.ASSORTMENT, [], "keepThisSession");
export const setAssortmentInStorage = (newValue: string[]) => setStorage(STORAGEKEY.ASSORTMENT, newValue, "keepThisSession");

// EDIT MODE
export const getEditModeFromStorage = (): EditMode | null => getStorage(STORAGEKEY.EDIT_MODE, null, "keepThisSession");
export const setEditmodeInStorage = (newValue: EditMode | null) => setStorage(STORAGEKEY.EDIT_MODE, newValue, "keepThisSession");

//LANGUAGE
export const getLanguageFromStorage = (): "nb" | "en-US" | null => getStorage(STORAGEKEY.LANGUAGE, null, "keepBetweenSessions");
export const setLanguageInStorage = (newValue: string | "nb" | "en-US" | null) =>
   setStorage(STORAGEKEY.LANGUAGE, newValue, "keepBetweenSessions");

//COMPANY
export const getCompanyFromStorage = (): string | null => getStorage(STORAGEKEY.COMPANY, null, "keepThisSession");
export const setCompanyInStorage = (newValue: string | null) => setStorage(STORAGEKEY.COMPANY, newValue, "keepThisSession");

//COMPANY - across sessions
export const getLastCompanyFromStorage = (): string | null => getStorage(STORAGEKEY.LAST_COMPANY, null, "keepBetweenSessions");
export const setLastCompanyInStorage = (newValue: string | null) =>
   setStorage(STORAGEKEY.LAST_COMPANY, newValue, "keepBetweenSessions");
