import { IconLightbulb } from "@tine/designsystem-icons/sharp";
import { Alert } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import { trim } from "lodash-es";

type StepTipProps = {
   tip?: string | null;
   variant: "tip" | "information";
   title?: string;
   lightbulbIcon?: boolean;
   className?: string;
};

const StepTip = ({ tip, variant, title, lightbulbIcon, className }: StepTipProps) => {
   if (tip) {
      if (tip.includes("data-unresolved-url")) {
         return null;
      }

      const styles = cn("tw-mt-2", className);

      const cleanedTip = tip.replace(/(<([^>]+)>)/gi, "").replace("&nbsp;", " ");
      if (trim(cleanedTip).length === 0) {
         // If there is no content left after removing html tags, don't who alert container
         return null;
      }

      return (
         <Alert
            variant={variant}
            title={title}
            fullWidth
            showCloseButton={false}
            className={styles}
            customIcon={lightbulbIcon ? IconLightbulb : undefined}
         >
            {cleanedTip}
         </Alert>
      );
   }
   return null;
};

export default StepTip;
