import { view } from "@risingstack/react-easy-state";
import { Grid } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import isNil from "lodash-es/isNil";

import type { IngredientLineType } from "../../../common/types/recipeTypes";

import productStore from "../../../stores/product/productStore";

import { InternalLink } from "../../Links";
import AmountDisplay from "./AmountDisplay";

type IngredientLineProps = {
   ingredientLine: IngredientLineType;
   multiplier: number;
};

const styles = {
   row: cn("tw-w-full tw-text-sm"),
   cell: cn("tw-p-2")
};

// This is added because the unit "stykk" deviates from all other units in the recipe database. It's actually
// the only unit that makes sense. The other units have "dl" in both plural and singular form, and "desiliter"
// in the abbrev field. This is a quick fix. Should probably have been fixed in the database, but we now
// have many frontends that rely on this behavior so its hard to change.
const shortenStykkUnit = (unit: string) => {
   return unit === "stykk" ? "stk" : unit;
};

const IngredientLine = ({ ingredientLine, multiplier }: IngredientLineProps) => {
   const { amount, ingredient, unit, preText, postText } = ingredientLine;

   const singleItem = amount === 1;
   const unitName = shortenStykkUnit(singleItem ? unit.singular : unit.plural);
   const ingredientName = singleItem ? ingredient.singular : ingredient.plural || ingredient.singular;

   const product = productStore.resolveSku(ingredient.articleNo);

   return (
      <Grid cols={12} gap={0} className={styles.row}>
         <Grid.Col span={3} className={cn(styles.cell, "tw-text-nowrap tw-text-right")}>
            <AmountDisplay amount={amount} multiplier={multiplier} /> {unitName}
         </Grid.Col>
         <Grid.Col span={9} className={cn(styles.cell)}>
            {preText}{" "}
            {!isNil(product) ? <InternalLink href={productStore.getUrl(product)}>{ingredientName}</InternalLink> : ingredientName}{" "}
            {postText}
         </Grid.Col>
      </Grid>
   );
};

export default view(IngredientLine);
