import { store } from "@risingstack/react-easy-state";
import { User } from "configcat-js";

import { ConfigCatKeys, getConfigCatValue } from "../../common/configCatClient";
import type { Company } from "../../common/types/companyTypes";
import type { CustomerFeature, FEATURE_NAME } from "../../common/types/featureTypes";
import { keepIdOnly } from "../../common/utils/segmentUtils";

import type { BaseStoreType } from "../../common/types/BaseStoreType";
import theme from "../../themes/theme";
import authStore from "../auth/authStore";

type FeatureStore = BaseStoreType & {
   customerFeatures: FEATURE_NAME[];
   barcodeScannerEnabled: boolean;
   claimsEnabled: boolean;
   pickupOrderAvailable: boolean;
   notificationsEnabled: boolean;
   keyFiguresAvailable: boolean;
   recommendationType: "AI1" | "AI2" | "AI3" | "OFF";
   contractualRecommendations: boolean;
   voucherifyAvailable: boolean;
   useDynamicsForNotificationProfiles: boolean;
   prioritizeAgreementAssortment: boolean;

   fetchEnabledConfigCatFeatures: (company?: Company) => Promise<void>;
   fetchEnabledCustomerFeatures: (customerNumber: string) => Promise<void>;
   hasCustomerFeature: (feature: FEATURE_NAME) => boolean;
};

export const featuresStore: FeatureStore = store({
   customerFeatures: [],
   barcodeScannerEnabled: false,
   claimsEnabled: false,
   pickupOrderAvailable: false,
   notificationsEnabled: false,
   keyFiguresAvailable: false,
   recommendationType: "AI1",
   contractualRecommendations: false,
   voucherifyAvailable: false,
   useDynamicsForNotificationProfiles: false,
   prioritizeAgreementAssortment: false,

   fetchEnabledConfigCatFeatures: async (company) => {
      const userData = new User(company?.customerNumber || "Anonymous");

      userData.custom = {
         chainLevel1: keepIdOnly(company?.businessLevels.level1 || "N/A"),
         chainLevel2: keepIdOnly(company?.businessLevels.level2 || "N/A"),
         chainLevel3: keepIdOnly(company?.businessLevels.level3 || "N/A"),
         chainLevel4: keepIdOnly(company?.businessLevels.level4 || "N/A"),
         customerGroup: keepIdOnly(company?.customerGroup || "N/A")
      };

      featuresStore.barcodeScannerEnabled = await getConfigCatValue(ConfigCatKeys.BARCODESCANNER_AVAILABLE, userData);
      featuresStore.claimsEnabled = await getConfigCatValue(ConfigCatKeys.CLAIMS_AVAILABLE, userData);
      featuresStore.pickupOrderAvailable = await getConfigCatValue(ConfigCatKeys.PICKUP_ORDER_AVAILABLE, userData);
      featuresStore.notificationsEnabled = await getConfigCatValue(ConfigCatKeys.NOTIFICATIONS_AVAILABLE, userData);
      featuresStore.recommendationType = await getConfigCatValue(ConfigCatKeys.RECOMMENDATION_TYPE, userData);
      featuresStore.keyFiguresAvailable = await getConfigCatValue(ConfigCatKeys.KEYFIGURES_AVAILABLE, userData);
      featuresStore.contractualRecommendations = await getConfigCatValue(ConfigCatKeys.CONTRACTUAL_RECOMMENDATIONS, userData);
      featuresStore.voucherifyAvailable = await getConfigCatValue(ConfigCatKeys.VOUCHERIFY_AVAILABLE, userData);
      featuresStore.useDynamicsForNotificationProfiles = await getConfigCatValue(
         ConfigCatKeys.USE_DYNAMICS_FOR_NOTIFICATION_PROFILES,
         userData
      );
      featuresStore.prioritizeAgreementAssortment = await getConfigCatValue(
         ConfigCatKeys.PRIORITIZE_AGREEMENT_ASSORTMENT,
         userData
      );
   },

   fetchEnabledCustomerFeatures: async (customerNumber) => {
      const resp: CustomerFeature[] = await theme.fetchCustomerFeatures(customerNumber, authStore.getSessionToken() || "");
      featuresStore.customerFeatures = resp.map((feature) => feature.name);
      console.log("Features found: ", featuresStore.customerFeatures);
   },

   hasCustomerFeature: (featureName) => {
      return featuresStore.customerFeatures.includes(featureName);
   },
   clearCompanySpecificData: () => {
      featuresStore.customerFeatures = [];
      featuresStore.fetchEnabledConfigCatFeatures();
   }
} satisfies FeatureStore);

export default featuresStore;
