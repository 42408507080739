import { view } from "@risingstack/react-easy-state";
import cn from "classnames";
import { isToday, parseISO } from "date-fns";
import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useLocation } from "wouter";

import { sendSelectTeaser, sendViewTeaser } from "../../../common/tracking";
import type { ContentfulAdventCalendarSlot } from "../../../common/types/cmsTypes";
import { isDateInTheFuture, isDateInThePast } from "../../../common/utils/dateUtils";

import contentStore from "../../../stores/cms/contentStore";
import promotionStore from "../../../stores/promotionStore";

type AdventCalendarSlotProps = {
   content: ContentfulAdventCalendarSlot;
   trackingListName?: string;
};

const SLOT_COMMON_STYLES = {
   base: "tw-w-full tw-rounded-lg",
   behindSlotImage: "tw-absolute tw-left-0 tw-top-0 -tw-z-10 tw-h-full tw-object-cover tw-visible"
};

const AdventCalendarSlot = ({ content, trackingListName }: AdventCalendarSlotProps) => {
   const [_, redirectTo] = useLocation();
   const [shouldShakeSlot, setShouldShakeSlot] = useState(false);
   const [isOpen, setIsOpen] = useState(false);

   const date = parseISO(content.fields.date || "");
   const slotId = content.sys.id;
   const url = `/${contentStore.urlData.data.find((urlData) => urlData.id === content.sys.id)?.fullUrl}`;

   const slotImage = content.fields.slotImage;
   const behindSlotImage = content.fields.behindSlotImage;
   const contentType = "adventCalendarSlot";

   const signUpCode = `julekalender-2024-${content.fields.slug}`;
   const isSignedUp = promotionStore.isSignedUpTo(signUpCode);
   const isSlotOpenedOrOutdated = isSignedUp || isDateInThePast(date);

   function goToCalendarSlot() {
      if (isSlotOpenedOrOutdated) {
         redirectTo(url);
      } else {
         // Wait for the animation to finish before redirecting
         setTimeout(() => {
            redirectTo(url);
         }, 700);
      }
   }

   function shakeCalendarSlot() {
      setShouldShakeSlot(true);
      setTimeout(() => {
         setShouldShakeSlot(false);
      }, 500);
   }

   function handleClickImageLink() {
      if (!isDateInTheFuture(date)) {
         contentStore.fetchContentIfNotAlreadyInStore(slotId, "adventCalendarSlot");
         sendSelectTeaser(signUpCode, contentType, trackingListName, url);
         setIsOpen(true);
         goToCalendarSlot();
      } else {
         shakeCalendarSlot();
      }
   }

   const trackImpression = (isVisible: boolean) => {
      if (isVisible) {
         sendViewTeaser(signUpCode, contentType, trackingListName);
      }
   };

   const { ref: viewRef } = useInView({
      triggerOnce: true,
      onChange: trackImpression
   });

   return (
      <>
         {slotImage && (
            <div
               ref={viewRef}
               onClick={() => handleClickImageLink()}
               className={cn(SLOT_COMMON_STYLES.base, "tw-relative tw-h-auto hover:tw-cursor-pointer", {
                  "[&>img:first-child]:hover:tw-hover-calendar-slot": isSlotOpenedOrOutdated
               })}
               aria-expanded={isOpen}
               aria-label={`Kalenderluke ${content.fields.date?.slice(-2)} ${isSlotOpenedOrOutdated ? "åpen" : "lukket"} `}
            >
               <img
                  alt=""
                  className={cn(
                     SLOT_COMMON_STYLES.base,
                     "tw-z-0 tw-block tw-aspect-square tw-h-auto tw-origin-left tw-overflow-hidden tw-object-cover tw-transition-transform tw-duration-500 tw-ease-[cubic-bezier(0.42,0,0.58,1)]",
                     {
                        "shake-calendar-slot": shouldShakeSlot,
                        "tw-opened-calendar-slot": isSlotOpenedOrOutdated,
                        "tw-open-calendar-slot": isOpen && isToday(date) && !isSignedUp
                     }
                  )}
                  src={slotImage.fields.file?.url}
               />
               {behindSlotImage?.fields.file ? (
                  <img
                     alt=""
                     className={cn(SLOT_COMMON_STYLES.base, SLOT_COMMON_STYLES.behindSlotImage, "tw-brightness-50", {
                        "tw-hidden tw-opacity-0": !isOpen && !isSlotOpenedOrOutdated && !isToday(date)
                     })}
                     src={behindSlotImage.fields.file?.url}
                  />
               ) : (
                  <div
                     className={cn(SLOT_COMMON_STYLES, SLOT_COMMON_STYLES.behindSlotImage, "tw-bg-ink-brand-subtle", {
                        "tw-hidden tw-opacity-0": !isOpen && !isSlotOpenedOrOutdated && !isToday(date)
                     })}
                  />
               )}
            </div>
         )}
      </>
   );
};

export default view(AdventCalendarSlot);
