import { Group } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";

import type { RecipeStepType } from "../../../common/types/recipeTypes";
import { hasContent } from "../../../common/utils";

import StepTip from "./StepTip";
import StepVideo from "./StepVideo";

type StepProps = {
   step: RecipeStepType;
   stepNumber: number;
};

const Step = ({ step, stepNumber }: StepProps) => {
   const { instruction, video, tip } = step;
   const styles = cn({
      "tw-mb-6": hasContent(tip),
      "tw-mb-12": !hasContent(tip)
   });
   return (
      <>
         <Group align="start" justify="start" gap={0} className={styles}>
            <div className="tw-mr-7 tw-flex tw-min-h-9 tw-min-w-9 tw-items-center tw-justify-center tw-rounded-[50%] tw-bg-surface-default-inverted tw-font-semibold tw-text-ink-brand-inverted">
               {stepNumber}
            </div>
            <div className="tw-mt-[6px]">{instruction}</div>
         </Group>
         {hasContent(tip) && <StepTip className="tw-mb-12 tw-ml-16" tip={tip} variant="information" lightbulbIcon={true} />}
         {hasContent(video) && (
            <div className="tw-py-3">
               <StepVideo video={video} />
            </div>
         )}
      </>
   );
};

export default Step;
