import type { Document } from "@contentful/rich-text-types";
import { view } from "@risingstack/react-easy-state";
import { RadioGroup } from "@tine/designsystem-ui-react";
import { isFuture, parseISO } from "date-fns";
import isString from "lodash-es/isString";
import { type ChangeEvent, useState } from "react";

import type { ContentfulContestForm } from "../../common/types/cmsTypes";
import { formatDate } from "../../common/utils/dateUtils";
import { getCaptureConfig, getCaptureMappings } from "../../common/utils/dynamicsUtils";
import SignupForm from "../common/signup/SignupForm";

import authStore from "../../stores/auth/authStore";
import promotionStore from "../../stores/promotionStore";
import uiStore from "../../stores/uiStore";

import Button from "../Button";
import RichTextRenderer from "./RichTextRenderer";

type CorrectAnswerProps = {
   signUpCode: string;
   answer: string;
   companyName: string;
   slug?: string;
   trackingFormName?: string;
};

const CorrectAnswer = view(({ signUpCode, answer, companyName, slug, trackingFormName }: CorrectAnswerProps) => {
   const isSignedUp = promotionStore.isSignedUpTo(signUpCode);
   if (!authStore.isLoggedIn()) {
      return (
         <div>
            <h2>{answer} er riktig! </h2>
            <p>Du må logge inn for å delta.</p>
            <Button variant="secondary" onClick={() => uiStore.showLoginModal()}>
               Logg inn
            </Button>
         </div>
      );
   }

   if (isSignedUp) {
      return (
         <div>
            <h2>{answer} er riktig!</h2>
            <p>{companyName} er nå med i trekningen for denne konkurransen.</p>
            <p>Vi vil ta kontakt med vinneren så fort konkurransen er over.</p>
         </div>
      );
   }

   const handleSignup = (payload: Record<string, string>) => {
      void promotionStore.signUpToPromotion(signUpCode, "", "", payload);
   };

   const { currentCompany } = authStore;
   const descriptionValue: Record<string, string> = {
      description: slug || ""
   };
   if (currentCompany) {
      return (
         <>
            <h2>{answer} er riktig!</h2>
            <p>Fyll ut navn og e-postadresse for å delta i trekningen</p>
            <SignupForm
               values={{ company: currentCompany, ...descriptionValue }}
               mappings={getCaptureMappings("slot")}
               config={getCaptureConfig("slot")}
               buttonText="Delta i trekningen"
               onSignup={handleSignup}
               buttonVariant="secondary"
               trackingFormName={trackingFormName}
            />
         </>
      );
   }
});

const WrongAnswer = view(() => {
   return <h2>Feil svar. Prøv igjen!</h2>;
});

type NotAvailableNowProps = { date: string };

const NotAvailableYet = view(({ date }: NotAvailableNowProps) => {
   return (
      <div>
         <h3>Dette spørsmålet er ikke tilgjengelig enda.</h3>
         <p>Du kan delta på denne f.o.m. {formatDate(date, true, true, false)}.</p>
      </div>
   );
});

type NotAvailableAnymoreProps = { text: Document };

const NotAvailableAnymore = view(({ text }: NotAvailableAnymoreProps) => {
   return (
      <div>
         <RichTextRenderer document={text} context="contest-unavailable" />
      </div>
   );
});

type ContestFormProps = {
   id: string;
   entry: ContentfulContestForm;
   slug?: string;
   trackingFormName?: string;
};

const ContestForm = ({ entry, slug, trackingFormName }: ContestFormProps) => {
   const options = entry.fields.options.map((option) => ({
      label: option,
      value: option
   }));
   const company = authStore.getCurrentCompany();
   const companyName = company?.name || "Ditt selskap";

   const [answer, setAnswer] = useState<string | undefined>(options[0].value);
   const [hasAnswered, setHasAnswered] = useState<boolean>(false);

   const storeAnswer = (e: ChangeEvent<HTMLInputElement>) => {
      setAnswer(e.target.value);
      setHasAnswered(false);
   };

   const checkAnswer = () => {
      setHasAnswered(true);
   };

   const isSignedUp = promotionStore.isSignedUpTo(entry.fields.signUpCode);

   if (isString(entry.fields.validFrom) && isFuture(parseISO(entry.fields.validFrom))) {
      return <NotAvailableYet date={entry.fields.validFrom} />;
   }

   return (
      <div className="tw-flex tw-flex-col tw-gap-4">
         <h2 className="tw-text-xl">{entry.fields.question}</h2>

         {isSignedUp || (hasAnswered && answer === entry.fields.correctOption) ? (
            <CorrectAnswer
               answer={entry.fields.correctOption}
               signUpCode={entry.fields.signUpCode}
               slug={slug}
               companyName={companyName}
               trackingFormName={trackingFormName}
            />
         ) : (
            <>
               <RadioGroup label="Velg et alternativ:" options={options} onChange={storeAnswer} className="tw-pb-2" />
               {hasAnswered && answer !== entry.fields.correctOption && <WrongAnswer />}
               <div className="tw-flex tw-flex-row tw-justify-center">
                  <Button variant="secondary" onClick={checkAnswer} disabled={hasAnswered || isSignedUp}>
                     Sjekk svar
                  </Button>
               </div>
            </>
         )}
      </div>
   );
};

export default view(ContestForm);
