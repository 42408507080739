import { useContentfulInspectorMode } from "@contentful/live-preview/react";
import { view } from "@risingstack/react-easy-state";
import { cn } from "@tine/designsystem-utils";
import type { Entry } from "contentful";

import type { ContentfulTextAndImage } from "../../common/types/cmsTypes";
import type { TypeImageWithFocusSkeleton } from "../../common/types/contentful";
import { getModuleVisibility } from "../../common/utils/segmentUtils";

import segmentStore from "../../stores/cms/segmentStore";

import ImageWithFocus from "./ImageWithFocus";
import RichTextRenderer from "./RichTextRenderer";

type TextAndImageProps = {
   content: ContentfulTextAndImage;
};

const TextAndImage = ({ content }: TextAndImageProps) => {
   const inspectorProps = useContentfulInspectorMode();

   const isVisible = getModuleVisibility(content, segmentStore.currentCustomerProperties);

   if (!isVisible) {
      return null;
   }

   if (!content.fields) {
      return null;
   }

   const entryId = content.sys.id;
   const image = content.fields.image as Entry<TypeImageWithFocusSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>;
   const imagePosition = content.fields.imagePosition;
   const ratio = content.fields.imageSize ?? "Standard";

   const className = cn("tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6", "lg:tw-flex-row", {
      "lg:tw-flex-row": imagePosition === "Left",
      "lg:tw-flex-row-reverse": imagePosition === "Right"
   });

   const childClassName = "lg:tw-w-1/2";

   const imageClassName = cn(childClassName, "lg:tw-pt-1", {
      "lg:tw-w-1/3": ratio === "Liten",
      "lg:tw-w-1/2": ratio === "Standard",
      "lg:tw-w-2/3": ratio === "Stor"
   });

   return (
      <div className={className}>
         <div
            className={imageClassName}
            {...inspectorProps({
               entryId: entryId,
               fieldId: "image"
            })}
         >
            {image && (
               <ImageWithFocus width={800} ratio="16x9" entryId={image.sys.id} imageFromParent={image} placement="belowFold" />
            )}
         </div>
         <div
            className={childClassName}
            {...inspectorProps({
               entryId: entryId,
               fieldId: "text"
            })}
         >
            <RichTextRenderer document={content.fields.text} context="text-and-image" trackingLocationName="Text and image" />
         </div>
      </div>
   );
};

export default view(TextAndImage);
