import { store } from "@risingstack/react-easy-state";
import { isNil } from "lodash-es";
import debounce from "lodash-es/debounce";
import defaultTo from "lodash-es/defaultTo";
import find from "lodash-es/find";

import { apiClient } from "../common/apiClient";
import type { DeliveryFeeResponse, DeliveryFees } from "../common/types/deliveryTypes";
import {
   type AsyncData,
   initializeWithDefaultData,
   setAsDataAvailable,
   setAsErrorOccured,
   setAsWaitingForData
} from "../common/utils/asyncDataUtils";
import { formatISODate } from "../common/utils/dateUtils";

import { API_HOST } from "../common/environment";
import type { BaseStoreType } from "../common/types/BaseStoreType";
import theme from "../themes/theme";
import authStore from "./auth/authStore";
import cartStore from "./cart/cartStore";
import deliveryDatesStore from "./deliveryDates/deliveryDatesStore";
import productStore from "./product/productStore";

type DeliveryFeeStore = BaseStoreType & {
   deliveryFee: AsyncData<DeliveryFees>;
   isDeliveryFeeEnabled(): boolean;
   currentDeliveryCharge(): DeliveryFees;
   recalculateDeliveryCharge(): void;
   fetchDeliveryFee(): void;
   fetchDeliveryFeeWithoutDelay(): Promise<void>;
};
const INITIAL_DELIVERY_FEE_DATA = initializeWithDefaultData({
   currentFee: null,
   nextFee: null
});
const deliveryFeeStore: DeliveryFeeStore = store({
   deliveryFee: INITIAL_DELIVERY_FEE_DATA,

   isDeliveryFeeEnabled: () => {
      return theme.deliveryFee.enabled && cartStore.orderType === theme.defaultOrderType;
   },

   currentDeliveryCharge: () => {
      return deliveryFeeStore.deliveryFee.data;
   },

   recalculateDeliveryCharge: () => {
      deliveryFeeStore.deliveryFee.data = { currentFee: null, nextFee: null };
      setAsWaitingForData(deliveryFeeStore.deliveryFee);
      void deliveryFeeStore.fetchDeliveryFee();
   },

   fetchDeliveryFee: debounce(() => deliveryFeeStore.fetchDeliveryFeeWithoutDelay(), 500),

   fetchDeliveryFeeWithoutDelay: async () => {
      const currentDelivery = deliveryDatesStore.getCurrentDelivery();

      if (!deliveryFeeStore.isDeliveryFeeEnabled() || isNil(currentDelivery)) {
         setAsDataAvailable(deliveryFeeStore.deliveryFee, {
            currentFee: null,
            nextFee: null
         });
         return Promise.resolve();
      }

      const orderLines = cartStore.items.map((ci) => ({
         sku: ci.sku,
         qty: ci.qty,
         unit: ci.unit,
         orderLineTotal: defaultTo(productStore.resolveSku(ci.sku)?.price, 0) * ci.qty
      }));

      if (cartStore.lostSales) {
         for (const lostSale of cartStore.lostSales) {
            const product = productStore.resolveSku(lostSale.sku);
            if (product) {
               const orderLine = {
                  orderLineTotal: Number(((product.price || 0) * lostSale.quantity).toFixed(2)),
                  sku: lostSale.sku,
                  qty: lostSale.quantity,
                  unit: product.unit
               };
               orderLines.push(orderLine);
            }
         }
      }
      const totalPrice = orderLines.reduce((total, line) => line.orderLineTotal + total, 0);

      const payload = {
         companyNumber: theme.m3CompanyNumber,
         customerNumber: authStore.currentCompany,
         deliveryDate: formatISODate(currentDelivery.date),
         totalPrice,
         orderType: cartStore.orderType,
         orderLines
      };

      try {
         const controller = deliveryFeeStore.deliveryFee.abortController;

         const response: DeliveryFeeResponse = await apiClient(
            `${API_HOST}/api/${theme.tipApiPrefix}tip/API/deliveryFeeInfo`,
            authStore.getSessionToken(),
            controller
         )
            .content("application/json")
            .post(payload)
            .json();

         const currentFee = find(
            response.feeTypes,
            (ft) => !isNil(ft.limitMin) && !isNil(ft.limitMax) && ft.limitMin < totalPrice && totalPrice <= ft.limitMax
         );
         const nextFee = find(response.feeTypes, (ft) => !isNil(ft.limitMin) && ft.limitMin > totalPrice);

         setAsDataAvailable(deliveryFeeStore.deliveryFee, {
            currentFee: defaultTo(currentFee, { fee: 0 }),
            nextFee: nextFee ?? null
         });
         console.log("Recalculated delivery fees: ", deliveryFeeStore.deliveryFee.data);
      } catch (err) {
         console.warn("Was not able to recalculate delivery fees.", err);
         setAsErrorOccured(deliveryFeeStore.deliveryFee, `${err}`);
      }
   },
   clearCompanySpecificData: () => {
      deliveryFeeStore.deliveryFee = INITIAL_DELIVERY_FEE_DATA;
   }
} satisfies DeliveryFeeStore);

export default deliveryFeeStore;
