import { view } from "@risingstack/react-easy-state";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "wouter";

import { sendPageNotFound } from "../common/tracking";
import { isDataAvailable, isDefaultData, isWaitingForData } from "../common/utils/asyncDataUtils";

import contentStore from "../stores/cms/contentStore";

import ErrorAlertWithOptionalTracking from "./ErrorAlertWithOptionalTracking";
import CmsContentFetchWrapper from "./cms/CmsContentFetchWrapper";

export const PAGE_NOT_FOUND_SLUG = "404";

const NotFound = () => (
   <>
      <Helmet>
         <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <NotFoundContent />
   </>
);

const NotFoundContent = () => {
   const [location, _] = useLocation();

   useEffect(() => {
      sendPageNotFound(location);
   }, [location]);

   if (isWaitingForData(contentStore.urlData) || isDefaultData(contentStore.urlData)) {
      return <Skeleton height={380} />;
   }
   if (isDataAvailable(contentStore.urlData)) {
      const urlData = contentStore.urlData.data.find((data) => data.slug === PAGE_NOT_FOUND_SLUG);
      if (!urlData) {
         return (
            <ErrorAlertWithOptionalTracking
               showCloseButton={false}
               gaErrorTracking={{ context: "page_not_found", text: "slug_not_found_error" }}
            >
               Denne siden eksisterer ikke. Prøv en annen side.
            </ErrorAlertWithOptionalTracking>
         );
      }

      return <CmsContentFetchWrapper id={urlData.id} />;
   }
   return (
      <ErrorAlertWithOptionalTracking
         showCloseButton={false}
         gaErrorTracking={{ context: "page_not_found", text: "page_load_error" }}
      >
         Det oppsto en uventet feil under lasting av siden.
      </ErrorAlertWithOptionalTracking>
   );
};

export default view(NotFound);
