import { __module as u } from "./designsystem-ui-react186.js";
import { P as d } from "./designsystem-ui-react187.js";
(function(r, a) {
  Object.defineProperty(a, "__esModule", {
    value: !0
  });
  var l = d, e = s(l);
  function s(t) {
    return t && t.__esModule ? t : { default: t };
  }
  a.default = {
    pauseVideo: {
      acceptableStates: [e.default.ENDED, e.default.PAUSED],
      stateChangeRequired: !1
    },
    playVideo: {
      acceptableStates: [e.default.ENDED, e.default.PLAYING],
      stateChangeRequired: !1
    },
    seekTo: {
      acceptableStates: [e.default.ENDED, e.default.PLAYING, e.default.PAUSED],
      stateChangeRequired: !0,
      // TRICKY: `seekTo` may not cause a state change if no buffering is
      // required.
      timeout: 3e3
    }
  }, r.exports = a.default;
})(u, u.exports);
var i = u.exports;
export {
  i as F
};
