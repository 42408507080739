import { cn } from "@tine/designsystem-utils";
import { type ReactNode, useEffect, useRef, useState } from "react";

import Calendar, { type CalendarProps } from "../calendar/Calendar";
import Popover from "../popover/Popover";

type DatePickerProps = Omit<CalendarProps, "onDatesChange"> & {
   render: (dates: Date[], isPickerOpen: boolean, openPicker: (show: boolean) => void) => ReactNode;
   hide?: boolean;
   onHide?: () => void;
   onDatesChange?: (dates: Date[]) => void;
   closeOnChange?: boolean;
   children?: ReactNode;
};

const DatePicker = ({
   render,
   mode,
   dates,
   size = "compact",
   closeOnChange,
   hide,
   onHide = () => {},
   children,
   ...props
}: DatePickerProps) => {
   const onDatesChange: (dates: Date[]) => void = props.onDatesChange || (() => {});

   const [selectedDates, setSelectedDates] = useState<Date[]>(dates);
   const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
   const [show, setShow] = useState<boolean>(false);
   const ref = useRef<HTMLDivElement>(null);

   useEffect(() => {
      if (hide && show) {
         setSelectedDates(dates);
         const referenceElement: HTMLElement | null = ref.current?.querySelector("input,button") || null;
         setShow(false);
         referenceElement?.focus();
         onHide();
      }
   }, [hide]);

   useEffect(() => {
      if (show) {
         setSelectedDates(dates);
      }
   }, [show]);

   const handleChange = (dates: Date[]) => {
      setSelectedDates(dates);
      onDatesChange(dates);
      if (closeOnChange) {
         if (mode !== "range" || dates.length === 2) {
            const referenceElement: HTMLElement | null = ref.current?.querySelector("input,button") || null;
            setShow(false);
            referenceElement?.focus();
         }
      }
   };

   useEffect(() => {
      if (ref.current) {
         setReferenceElement(ref.current?.querySelector("input,button") as HTMLElement);
      }
   }, [ref]);

   const calendarProps: CalendarProps = {
      ...props,
      dates,
      mode,
      onDatesChange: handleChange,
      isArrowFocus: true
   };

   const handleHide = () => {
      setShow(false);
      onHide();
   };

   const calendarWrapperCN = cn({
      "tw-m-3": size === "compact",
      "tw-m-4": size === "regular"
   });

   const keysWrapperCN = cn(calendarWrapperCN, "tw-flex tw-flex-col tw-justify-between tw-gap-1 tw-px-3 sm:tw-flex-row");

   useEffect(() => {
      const referenceElement: HTMLElement | null = ref.current?.querySelector("input,button") || null;
      const handleBlur = (e: FocusEvent) => {
         if (e.target !== referenceElement && ref.current && !ref.current.contains(e.relatedTarget as Node)) {
            e.preventDefault();
            e.stopPropagation();
            referenceElement?.focus();
         }
      };
      if (ref.current) {
         ref.current.addEventListener("focusout", handleBlur);
      }
      return () => ref.current?.removeEventListener("focusout", handleBlur);
   }, []);
   return (
      <div ref={ref}>
         {render(selectedDates, show, setShow)}
         <Popover onHide={handleHide} show={show} referenceElement={referenceElement}>
            <div className={calendarWrapperCN}>
               <Calendar {...calendarProps} />
            </div>
            <div className={keysWrapperCN}>{children}</div>
         </Popover>
      </div>
   );
};

export default DatePicker;
