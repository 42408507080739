import { view } from "@risingstack/react-easy-state";
import { cn } from "@tine/designsystem-utils";
import { useMemo } from "react";

import type { DeliveryDate } from "../../common/types/deliveryTypes";
import { formatDateTime } from "../../common/utils/dateUtils";

import { SIXTY_MINUTES, TEN_MINUTES, formatTimePeriod } from "../../stores/deliveryDates/deliveryDateUtils";
import deliveryDatesStore from "../../stores/deliveryDates/deliveryDatesStore";

type DeadlineMode = "GT60" | "LTE60" | "LTE10" | "expired" | "none";

const getMode = (deliveryDate: DeliveryDate | null, expiration: number | null): DeadlineMode => {
   if (deliveryDate && expiration !== null) {
      if (expiration === 0) {
         return "expired";
      }
      if (expiration > SIXTY_MINUTES) {
         return "GT60";
      }
      if (expiration > TEN_MINUTES) {
         return "LTE60";
      }
      return "LTE10";
   }
   return "none";
};

const deadlineClassName = cn(["tw-w-[210px]"]);

const conspicuousClassName = cn([deadlineClassName, "tw-text-error-600"]);

const Deadline = () => {
   const currentDelivery = deliveryDatesStore.getCurrentDelivery();
   const expiration = deliveryDatesStore.getCurrentDeliveryExpiration();

   const mode = useMemo(() => getMode(currentDelivery, expiration), [currentDelivery, expiration]);

   const formattedDate = currentDelivery ? formatDateTime(currentDelivery.deadline, true, true) : "";
   const formattedTimePeriod = typeof expiration === "number" ? formatTimePeriod(expiration) : "";
   const className = mode === "LTE10" || mode === "expired" ? conspicuousClassName : deadlineClassName;

   return (
      <>
         {mode === "GT60" && <div className={className}>{formattedDate}</div>}
         {(mode === "LTE60" || mode === "LTE10") && <div className={className}>{formattedTimePeriod}</div>}
         {mode === "expired" && <div className={className}>Utløpt</div>}
      </>
   );
};

export default view(Deadline);
