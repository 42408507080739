import { store } from "@risingstack/react-easy-state";
import { chunk } from "lodash-es";
import isUndefined from "lodash-es/isUndefined";

import { apiClient } from "../common/apiClient";
import { UserCancelError } from "../common/errors";
import type { Progress } from "../common/types/commonTypes";
import type { CartItem, Product, ProductAndAvailability, ProductAvailability } from "../common/types/productTypes";
import { formatM3Date } from "../common/utils/dateUtils";

import { API_HOST } from "../common/environment";
import type { BaseStoreType } from "../common/types/BaseStoreType";
import { accumulate } from "../common/utils";
import theme from "../themes/theme";
import authStore from "./auth/authStore";
import cartStore from "./cart/cartStore";
import deliveryDatesStore from "./deliveryDates/deliveryDatesStore";
import productStore from "./product/productStore";

type CartItemsBySku = { [sku: string]: CartItem };

type QuantitiesBySku = { [sku: string]: number };

type AvailabilityStore = BaseStoreType & {
   newCartItems: CartItemsBySku;
   availability: ProductAvailability[] | null;
   alternatives: ProductAndAvailability[];
   availabilityFetchProgress?: Progress;
   lookingForAlternatives: boolean;

   hasUnavailable: () => boolean;
   abortController?: AbortController;
   checkAvailability: () => Promise<boolean>;
   fetchAvailabilitiesBySkus: (skus: string[]) => Promise<ProductAvailability[]>;
   fetchAlternatives: () => Promise<void>;

   clearAvailability: () => void;
};

const DEFAULT_AVAILABILITY_CHECK_QTY = 1000;
const AVAILABILITY_CHUNK_SIZE = 10;

const chunkQuantitiesBySku = (quantities: QuantitiesBySku, chunkSize: number): QuantitiesBySku[] => {
   const quantitiesArray = Object.keys(quantities).map((sku) => ({ sku, qty: quantities[sku] }));
   if (quantitiesArray.length > chunkSize) {
      return chunk(quantitiesArray, chunkSize).map((chunk) =>
         chunk.map((item) => ({ [item.sku]: item.qty })).reduce(accumulate, {})
      );
   }
   return [quantities];
};

const serialFetchAvailability = async (chunks: QuantitiesBySku[]): Promise<ProductAvailability[]> => {
   if (chunks.length) {
      const firstChunkAvailability = await fetchAvailability(chunks[0]);
      const subsequentChunksAvailability = await serialFetchAvailability(chunks.slice(1));
      return [...firstChunkAvailability, ...subsequentChunksAvailability];
   }
   return [];
};

const fetchAvailability = async (quantities: QuantitiesBySku, isSerial?: boolean): Promise<ProductAvailability[]> => {
   const skus = Object.keys(quantities);
   if (!skus.length) {
      return [];
   }
   if (skus.length > AVAILABILITY_CHUNK_SIZE) {
      const chunks: QuantitiesBySku[] = chunkQuantitiesBySku(quantities, AVAILABILITY_CHUNK_SIZE);
      availabilityStore.availabilityFetchProgress = {
         count: 0,
         target: chunks.length
      };
      try {
         let fetches: ProductAvailability[][];
         if (isSerial) {
            fetches = [await serialFetchAvailability(chunks)];
         } else {
            fetches = await Promise.all(chunks.map((chunk) => fetchAvailability(chunk)));
         }
         availabilityStore.abortController = undefined;

         return fetches.flat();
      } catch (err) {
         if ((err as { name?: string })?.name === "AbortError") {
            throw new UserCancelError();
         }
         throw err;
      }
   }
   if (!availabilityStore.abortController) {
      availabilityStore.abortController = new AbortController();
   }
   const abortController = availabilityStore.abortController;

   const params = {
      customerNumber: authStore.currentCompany,
      m3OrderType: cartStore.orderType,
      requestedDeliveryDate: formatM3Date(deliveryDatesStore.getCurrentDelivery()?.date),
      products: skus.map((sku) => `${sku}*${quantities[sku]}`).join("|")
   };
   try {
      const availability: ProductAvailability[] = await apiClient(
         `${API_HOST}/api/${theme.tipApiPrefix}tip/API/productAvailability`,
         authStore.getSessionToken()
      )
         .signal(abortController)
         .query(params)
         .get()
         .json();
      if (availabilityStore.availabilityFetchProgress) {
         availabilityStore.availabilityFetchProgress.count++;
      }
      return availability as ProductAvailability[];
   } catch (err) {
      availabilityStore.abortController = undefined;
      throw err;
   }
};

const availabilityStore: AvailabilityStore = store({
   newCartItems: {},
   availability: null,
   lookingForAlternatives: false,
   alternatives: [],

   hasUnavailable: () => {
      return availabilityStore.availability !== null && !!availabilityStore.availability.length;
   },

   clearAvailability: () => {
      availabilityStore.newCartItems = {};
      availabilityStore.availability = null;
      availabilityStore.alternatives = [];
   },

   fetchAvailabilitiesBySkus: async (skus: string[]) => {
      if (!skus.length) {
         return [];
      }
      const quantities = skus.map((sku) => ({ [sku]: DEFAULT_AVAILABILITY_CHECK_QTY })).reduce(accumulate, {});

      return fetchAvailability(quantities);
   },

   checkAvailability: async (): Promise<boolean> => {
      if (!theme.productAvailability.enabled) {
         return true;
      }
      const currentDelivery = deliveryDatesStore.getCurrentDelivery();
      if (!currentDelivery) {
         availabilityStore.clearAvailability();
         return true;
      }

      const quantities: QuantitiesBySku = cartStore.editing
         ? cartStore
              .getEditingCartChanges()
              .filter((delta) => delta.qtyDelta > 0)
              .map((delta) => ({ [delta.sku]: delta.qtyDelta }))
              .reduce(accumulate, {})
         : cartStore.items.map((item) => ({ [item.sku]: item.qty })).reduce(accumulate, {});

      const availabilities = await fetchAvailability(quantities, true);

      const unavailable = availabilities
         .filter((availability) => !availability.available)
         .flatMap((availability) => {
            const cartItem = cartStore.getCartItem(availability.productNumber);
            if (availability.delayedDeliveryDate) {
               availability.availableQuantity = 0;
            }

            return cartItem
               ? { ...availability, selectedQuantity: availability.availableQuantity, requestedQuantity: cartItem.qty }
               : ([] as ProductAvailability[]);
         });

      if (!unavailable.length) {
         availabilityStore.clearAvailability();
         return true;
      }

      cartStore.addLostSales(unavailable);

      availabilityStore.availability = unavailable;
      void availabilityStore.fetchAlternatives();
      return false;
   },

   fetchAlternatives: async () => {
      if (!availabilityStore.availability) {
         return;
      }
      availabilityStore.lookingForAlternatives = true;

      const unavailableProducts = availabilityStore.availability
         .map((availability) => productStore.resolveSku(availability.productNumber))
         .filter((product): product is Product => !isUndefined(product));

      const alternativeSkus = unavailableProducts
         .flatMap((product) => product.similarProducts || [])
         .filter((sku, i, skus) => skus.indexOf(sku) === i)
         .filter((sku) => !unavailableProducts.find((product) => product.sku === sku));

      try {
         const alternativeAvailabilities = (await availabilityStore.fetchAvailabilitiesBySkus(alternativeSkus)).map(
            (availability) => {
               if (availability.delayedDeliveryDate) {
                  return { ...availability, availableQuantity: 0 };
               }
               return availability;
            }
         );

         const alternativeProductAvailabilities = alternativeSkus
            .map((sku) => ({
               product: productStore.resolveSku(sku),
               availability: alternativeAvailabilities.find((availability) => availability.productNumber === sku)
            }))
            .filter(
               (productAndAvailability): productAndAvailability is ProductAndAvailability =>
                  !isUndefined(productAndAvailability.product) && !isUndefined(productAndAvailability.availability)
            );

         const productsWithoutAvailableAlternatives = unavailableProducts.filter((product) => {
            return product.similarProducts
               .filter((sku) => !unavailableProducts.find((unavailableProduct) => unavailableProduct.sku === sku))
               .every((similarSku) => {
                  const productAvailability = alternativeAvailabilities.find(
                     (availability) => availability.productNumber === similarSku
                  );
                  return !productAvailability?.availableQuantity;
               });
         });

         const alternativeProductAndAvailabilities = alternativeProductAvailabilities.filter(
            (productAndAvailability) => productAndAvailability.availability.availableQuantity
         );

         for (const product of productsWithoutAvailableAlternatives) {
            const alternativeProducts = product.similarProducts
               .map((sku) => productStore.resolveSku(sku))
               .flatMap((product) => (product ? product : []));
            for (const alternativeProduct of alternativeProducts) {
               if (
                  !alternativeProductAndAvailabilities.find(
                     (productAndAvailability) => productAndAvailability.product.sku === alternativeProduct.sku
                  )
               ) {
                  const availability = alternativeAvailabilities.find(
                     (availability) => availability.productNumber === alternativeProduct.sku
                  );
                  if (availability) {
                     alternativeProductAndAvailabilities.push({ product: alternativeProduct, availability });
                  }
               }
            }
         }

         availabilityStore.alternatives = alternativeProductAndAvailabilities.filter(
            (productAndAvailability) =>
               !availabilityStore.availability?.find(
                  (availability) => availability.productNumber === productAndAvailability.product.sku
               )
         );
      } catch (err) {
         availabilityStore.clearAvailability();
      } finally {
         availabilityStore.lookingForAlternatives = false;
      }
   },
   clearCompanySpecificData: () => {
      availabilityStore.clearAvailability();
   }
});

export default availabilityStore;
