import { type CustomerFeature, FEATURE_NAME } from "../common/types/featureTypes";
import type { SupportedLocaleType } from "../common/types/languageTypes";
import { ORDERTYPE } from "../common/types/productOrderTypes";
import type { Product } from "../common/types/productTypes";
import type { SiteConfig } from "../common/types/themeTypes";

const CONTENTFUL_ENV = process.env.CONTENTFUL_ENV ?? "";
const ALGOLIA_APPID = "D4VU44A4OA";
const ALGOLIA_SEARCHKEY = "4d6a5d2430cde6d18ba421ceb9bf6d27";
const algoliaEnvironment = process.env.ENV_NAME === "prod" ? "prod" : "preprod";
const logoUrl = new URL("/src/img/themes/leverant/logo_med_tekst.svg", import.meta.url);
/**
 * Parses the response bodies into suitable data structure for use in stores. Diplomis does not up pickup alternatives
 */

const leverantSiteConfig: SiteConfig = {
   storeId: "leverant",
   storeCompanyName: "Leverant",
   siteName: "Leverant Handel",
   orderSupportEmail: "kontakt@leverant.no",
   m3CompanyNumber: "100",
   m3DivisionNumber: "102",
   defaultOrderType: ORDERTYPE.LEV,
   storeLogo: {
      searchbar: {
         url: `${logoUrl}`,
         width: 72
      },
      searchbarMobile: null,
      footer: null
   },
   productDropdownEnabled: false,
   showComparisonPrice: false,
   faqSlug: "",
   filterAttributes: () => ({ brand: "Type" }),
   filterRefreshOnChange: true,
   tipApiPrefix: "lev-",
   papirflyApsCustomerId: "12590",
   papirflyApsImagePrefix: "",
   baseUrl: "",
   enhanceProduct: (product: Product): Product => {
      return product;
   },
   fetchCustomerFeatures: () => {
      return Promise.resolve([
         { name: FEATURE_NAME.invoicePaymentAvailable, description: "Makes invoice overview available" },
         { name: FEATURE_NAME.createOrderAvailable, description: "Allows customer to place orders" },
         { name: FEATURE_NAME.subscriptionOrderAvailable, description: "Allows customer to place subscription orders" }
      ] satisfies CustomerFeature[]);
   },
   invoiceTooltipText: () => "",

   deliveryFee: {
      enabled: false
   },
   productAvailability: {
      enabled: false
   },
   newCustomerForm: "mailto:kontakt@leverant.no",
   deliveryNotes: {
      visible: false
   },
   contentImages: {
      cloudinaryMapping: "leverant_images"
   },
   searchConfig: {
      algoliaConfig: {
         appId: ALGOLIA_APPID,
         searchApiKey: ALGOLIA_SEARCHKEY
      },
      searchDomains: [
         {
            type: "product",
            name: "produkter",
            displayName: (locale: SupportedLocaleType) => (locale === "en-US" ? "Products" : "Produkter"),
            homePath: (_locale) => "/produkter.html",
            indexName: `products_${algoliaEnvironment}`,
            linkText: (_locale) => "Se alle produkter"
         },
         {
            type: "v2_article",
            name: "artikler",
            displayName: (locale: SupportedLocaleType) => (locale === "en-US" ? "Articles" : "Artikler"),
            homePath: (_locale) => "/artikler",
            indexName: `contentful_${CONTENTFUL_ENV}`,
            linkText: (_locale) => "Se alle artikler"
         }
      ]
   },
   contactForm: {
      apiPath: "/api/lev-contactform",
      reasons: [
         { value: "claims", label: "Reklamasjon", label_en: "Claims" },
         { value: "invoice", label: "Fakturaspørsmål / endring av fakturaadresse", label_en: "Invoice address changes" },
         { value: "delivery", label: "Ønske om levering før oppsatt leveringsdag", label_en: "Questions about ordering" },
         { value: "product_question", label: "Spørsmål om våre produkter eller sortiment", label_en: "Question about products" },
         { value: "new_customer", label: "Ny kunde?", label_en: "New customer?" },
         { value: "other", label: "Annet", label_en: "Other" }
      ]
   },
   whileYouWaitMessage: "Ta frem kaffekoppen, len deg tilbake og nyt soloppgangen!",
   notifications: {
      categories: [
         { id: "IMPORTANT_INFO_ABOUT_DELIVERY", name: "Viktige meldinger om levering" },
         { id: "ORDER_CONFIRMATION", name: "Ordrebekreftelse" }
      ],
      enableDeliveryMessages: false
   },
   orderConfirmation: {
      headerSubTitle: ""
   },
   cart: {
      customerReferenceLabel: "Melding til sjåfør"
   },
   promotionsPage: {
      enabled: false
   }
};

export default leverantSiteConfig;
