import { IconBoil, IconChef, IconFood, IconLeaf, IconShoppingCart, IconSupport, IconTruck } from "@tine/designsystem-icons/sharp";
import { cn } from "@tine/designsystem-utils";
import type { EntryFieldTypes } from "contentful";
import { useInView } from "react-intersection-observer";
import { Link } from "wouter";

import { sendSelectTeaser, sendViewTeaser } from "../../../common/tracking";
import type { ContentfulUsp } from "../../../common/types/cmsTypes";
import type { TypeUspFields } from "../../../common/types/contentful";

type IconName = TypeUspFields["icon"] extends EntryFieldTypes.Symbol<infer T> ? T : never;

type UspProps = {
   usp: ContentfulUsp;
   threeUsps: boolean;
   fourUsps: boolean;
   trackingListName: string;
   contentType: string;
};

const Usp = ({ usp, threeUsps, fourUsps, trackingListName, contentType }: UspProps) => {
   const uspTitle = usp.fields.title;
   const uspUrl = usp.fields.url;
   const uspIcon = usp.fields.icon;
   const uspDescription = usp.fields.description;

   const uspIconClasses = cn("tw-flex tw-size-12 tw-items-center tw-justify-center tw-rounded-[50%] tw-bg-infoBoxBackground", {
      "lg:tw-size-16": threeUsps,
      "lg:tw-size-14": fourUsps
   });

   const uspTitleCLasses = cn("tw-m-0 tw-text-lg tw-font-semibold", {
      "lg:tw-text-xl": threeUsps
   });

   const uspDescriptionCLasses = cn("tw-text-base", {
      "lg:tw-text-lg": threeUsps
   });

   const iconClass = cn("tw-size-8", {
      "lg:tw-size-12": threeUsps,
      "lg:tw-size-10": fourUsps
   });

   const uspLinkClasses =
      "tw-flex tw-w-80 tw-flex-col tw-items-center tw-justify-center tw-gap-2 lg:tw-gap-4 tw-rounded-lg tw-p-2 tw-text-center hover:tw-bg-infoBoxBackground hover:tw-no-underline lg:tw-p-4";

   const icons: Record<IconName, JSX.Element> = {
      IconSupport: <IconSupport className={iconClass} />,
      IconFood: <IconFood className={iconClass} />,
      IconLeaf: <IconLeaf className={iconClass} />,
      IconShoppingCart: <IconShoppingCart className={iconClass} />,
      IconTruck: <IconTruck className={iconClass} />,
      IconChef: <IconChef className={iconClass} />,
      IconBoil: <IconBoil className={iconClass} />
   };

   const handleClickUsp = () => {
      sendSelectTeaser(uspTitle, contentType, trackingListName, `/${uspUrl}`);
   };

   const trackImpression = (isVisible: boolean) => {
      if (isVisible) {
         sendViewTeaser(uspTitle, contentType, trackingListName);
      }
   };

   const { ref: viewRef } = useInView({
      triggerOnce: true,
      onChange: trackImpression
   });

   return (
      <Link onClick={() => handleClickUsp()} ref={viewRef} to={uspUrl} className={uspLinkClasses} key={usp.sys.id}>
         <div className={uspIconClasses}>{icons[uspIcon as IconName]}</div>
         <h3 className={uspTitleCLasses}>{uspTitle}</h3>
         <p className={uspDescriptionCLasses}>{uspDescription}</p>
      </Link>
   );
};

export default Usp;
