import { useContentfulInspectorMode } from "@contentful/live-preview/react";
import { view } from "@risingstack/react-easy-state";
import type { Entry } from "contentful";

import type { EmbeddedEntryContextType } from "../../common/types/cmsTypes";
import type { TypeProductListSkeleton } from "../../common/types/contentful";
import { LIST_TYPE } from "../../common/types/trackingTypes";
import { getModuleVisibility } from "../../common/utils/segmentUtils";
import ProductList from "../common/product/ProductList";

import segmentStore from "../../stores/cms/segmentStore";
import productStore from "../../stores/product/productStore";

type CmsManualProductListProps = {
   content: Entry<TypeProductListSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>;
   context: EmbeddedEntryContextType;
};

const CmsManualProductList = ({ content, context }: CmsManualProductListProps) => {
   const inspectorProps = useContentfulInspectorMode();
   if (!content.fields) {
      return null;
   }

   const isVisible = getModuleVisibility(content, segmentStore.currentCustomerProperties);
   if (!isVisible) {
      return null;
   }

   const allSkus = content.fields.skus ? [...content.fields.skus] : [];
   const title = content.fields.title;
   const subtitle = content.fields.subtitle;
   const entryId = content.sys.id;
   const productCount = content.fields.productCount;
   const products = productStore.resolveSkus(allSkus, true);
   const skus = products.map((product) => product.sku);

   let numberOfProducts: number =
      productCount === "Ubegrenset" ? skus.length : productCount ? Number.parseInt(productCount) : skus.length;

   numberOfProducts = Math.min(numberOfProducts, skus.length);

   return (
      <div
         {...inspectorProps({
            entryId: entryId,
            fieldId: "entry"
         })}
      >
         <ProductList
            heading={title}
            subtitle={subtitle}
            listName={content.fields.title ?? `ProductListWithoutName-${content.sys.id}`}
            listType={LIST_TYPE.CUSTOM}
            count={numberOfProducts}
            context={context}
            skus={skus}
         />
      </div>
   );
};

export default view(CmsManualProductList);
