import pull from "lodash-es/pull";
import { useEffect } from "react";

import authStore, { type LoginRequirement } from "../../stores/auth/authStore";

const useLoginRequired = (feature: LoginRequirement) => {
   useEffect(() => {
      if (feature === "NOT_REQUIRED") {
         return;
      }

      console.log(`LOGIN REQUIRED: Added ${feature}`);
      authStore.currentlyRequiresLogin.push(feature);

      return () => {
         console.log(`LOGIN REQUIRED: Removed ${feature}`);
         pull(authStore.currentlyRequiresLogin, feature);
      };
   }, [feature]);
};

export default useLoginRequired;
