import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const SvgIste = (props) => (_jsxs("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 200 200", focusable: "false", "aria-hidden": "true" }, props, { children: [_jsx("defs", { children: _jsx("clipPath", { id: "iste_svg__a", clipPathUnits: "userSpaceOnUse", children: _jsx("path", { d: "M0 2324.69V0h2375.58v2324.69z" }) }) }), _jsxs("g", { clipPath: "url(#iste_svg__a)", transform: "matrix(.08173 0 0 -.08173 2.927 194.994)", children: [_jsx("path", { d: "m2213.84 1385.54-797.95 780.92c-125.9 123.16-330.05 123.16-455.921 0L162.02 1385.54c-125.946-123.19-125.946-322.95 0-446.149l797.949-780.879c125.871-123.192 330.021-123.192 455.921 0l797.95 780.879c125.94 123.199 125.94 322.959 0 446.149", style: {
                        fill: "#231f20",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    } }), _jsx("path", { d: "M1725.51 1539.95c.57 10.55 1.13 22.26 1.34 31.03h.2c1.96-8.32 4.78-17.38 8.15-27.31l11.36-33.39h6.88l12.4 34.01c3.54 9.61 6.48 18.46 8.87 26.69h.33c-.11-8.77.53-20.48 1.02-30.3l1.8-30.84h8.87l-4.39 70.2h-11.55l-12.57-33.97c-3.13-8.77-5.59-16.53-7.39-23.66h-.34c-1.72 7.33-4.12 15.09-7.07 23.66l-11.98 33.97h-11.56l-4.88-70.2h8.61zm-47.2-30.11h9.2v62.51h21.47v7.69h-52v-7.69h21.33zM970.922 1172.5c-62.738 23.16-75.57 33.13-75.57 62.91 0 34.32 26.746 54.16 73.226 54.16 30.25 0 70.912-9.9 113.962-27.63 1.6-.48 7.53-3.03 13.97-5.09l42.07 88.97c-5.04 3.43-10.46 6.29-13.03 7.8-39.51 22.09-110.48 38.69-163.909 38.69-118.637 0-202.313-72.92-202.313-174.48 0-37.55 12.77-71.8 36.027-96.13 22.129-25.36 46.504-37.55 127.868-67.43 73.316-26.45 87.207-37.48 87.207-68.379 0-33.2-26.758-55.289-66.239-55.289-43.046 0-93.039 14.429-153.468 45.339-2.325 1.399-10.375 6.231-16.352 9.418l-37.777-99.48c5.191-3.899 11.605-7.629 15.761-10.481 59.286-30.918 136.02-50.769 203.473-50.769 115.102 0 193.002 70.66 193.002 173.402 0 37.519-12.82 71.729-34.9 98.309-25.57 28.71-48.84 41.97-143.008 76.16m685.288-1.09c10.43 88.38 36.04 122.59 95.33 122.59 56.96 0 79.09-33.14 81.38-122.59zm326.74-71.8c0 194.39-74.47 292.7-224.44 292.7-148.84 0-246.5-114.9-246.5-288.28 0-181.132 88.35-279.401 252.29-279.401 49.99 0 94.22 7.742 140.71 24.32 26.46 9.379 52.03 20.539 69.18 30.942l-45.52 99.711c-8.05-3.883-16.99-8.481-20.19-10.293-44.17-22.149-87.18-33.149-130.16-33.149-82.6 0-122.11 44.16-124.47 135.86h328.68c.24 6.48.42 15.56.42 27.59m-373.29 480.43h-518.61v-128.11h166.3V721.609h156.94v730.321h161.39zM402.371 1274.1h104.66V843.391h143.008v530.129H402.371Zm173.274 343.54c-51.184 0-91.86-38.7-91.86-86.2 0-48.55 40.676-87.21 90.68-87.21 51.152 0 91.828 38.66 91.828 87.21 0 47.5-40.676 86.2-90.648 86.2", style: {
                        fill: "#fff",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    } }), _jsx("path", { d: "m2156.42 951.84-753.3-737.16c-118.83-116.34-311.55-116.34-430.448 0L219.391 951.84c-118.891 116.35-118.891 304.93 0 421.22l753.281 737.19c118.898 116.25 311.618 116.25 430.448 0l753.3-737.19c118.9-116.29 118.9-304.87 0-421.22m83.38 531.82-723.57 708.19c-181.03 177.12-475.6 177.12-656.664 0l-723.597-708.19C48.309 1397.88 0 1283.81 0 1162.47-.04 1041.12 48.309 927 135.969 841.129L859.566 133.07C947.27 47.2 1063.91 0 1187.91 0c123.98 0 240.62 47.2 328.32 133.07l723.57 708.059c181.03 177.201 181.03 465.461 0 642.531", style: {
                        fill: "#fff",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    } })] })] })));
export default SvgIste;
