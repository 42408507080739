import { type RefObject, useEffect } from "react";

function useOutsideClick(refs: RefObject<Element | null>[], callback: () => void) {
   useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
         if (event.target === null) {
            return;
         }
         const clickOutside = refs.reduce(
            (p: boolean, ref: RefObject<Element | null> | null) =>
               p && !!ref?.current && event.target instanceof Element && !ref.current.contains(event.target),
            true
         );
         if (clickOutside) {
            callback();
         }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         // Unbind the event listener on clean up
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, [refs]);
}

export default useOutsideClick;
