import { view } from "@risingstack/react-easy-state";

import segmentStore from "../../stores/cms/segmentStore";

import EntryImageLink from "./EntryImageLink";

type CallToActionProps = {
   id: string;
   imageId: string;
   callToAction: string;
   subTitle?: string;
};

const CallToAction = ({ id, imageId, callToAction, subTitle }: CallToActionProps) => {
   if (!segmentStore.isArticleVisible(id)) {
      return null;
   }

   if (!imageId) {
      return null;
   }
   return <EntryImageLink id={id} type="article" imageWithFocusId={imageId} title={callToAction} subTitle={subTitle} />;
};

export default view(CallToAction);
