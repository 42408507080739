import { view } from "@risingstack/react-easy-state";
import isNil from "lodash-es/isNil";
import sortBy from "lodash-es/sortBy";
import values from "lodash-es/values";
import { useLocation } from "wouter";

import Select from "../common/Select";

import favoriteStore, { type FavoriteList } from "../../stores/favoriteStore";
import productStore from "../../stores/product/productStore";
import toastStore from "../../stores/toastStore";

import ShortcutBox from "./ShortcutBox";

const ShortcutFavoritesToCart = () => {
   const [_, redirectTo] = useLocation();

   const addListToCart = (listId: string | null) => {
      if (isNil(listId)) {
         console.warn("Missing listId for change event");
         return;
      }
      const list: FavoriteList | undefined = favoriteStore.getFavoriteListById(listId);
      if (!list) {
         console.warn("Unable to find favorite list when adding to cart", {
            list,
            listId,
            favorites: favoriteStore.allFavoriteLists
         });
         toastStore.addError("En feil oppsto", "Finner ikke favorittlisten", {
            context: "favorite_list",
            text: "list_not_found_error"
         });
         return;
      }
      console.log("Adding favorite list to cart: ", list);

      const products = productStore.resolveSkus(list.sequence);
      if (products.length !== list.sequence.length) {
         toastStore.addError(
            "Sjekk listen for utilgjengelig produkter",
            "Favorittlisten inneholder produkter som ikke lengre er tilgjengelig. Fjern disse for å bruke funksjonen på forsiden.",
            { context: "favorite_list", text: "unavailable_items_error" }
         );
         redirectTo(`/customer/favorites/${listId}`);
         return;
      }

      const itemsNotAddedToCart = list.sequence.filter((sku) => !products.some((product) => sku === product.sku));

      if (products.length === 0) {
         toastStore.addError(
            "Ingen varer lagt i handlekurven",
            `Ingen varer fra favorittlisten '${list.listName}' kunne legges i handlekurven.`,
            { context: "favorite_list", text: "no_items_added_to_cart_error" }
         );
      } else if (products.length !== list.sequence.length) {
         toastStore.addError(
            "Noen varer kunne ikke legges i handlekurven",
            `Varen${itemsNotAddedToCart.length > 1 ? "e" : ""} ${itemsNotAddedToCart.join(", ")} fra favorittlisten '${
               list.listName
            }' kunne ikke legges i handlekurven. Resten har blitt lagt til.`,
            { context: "favorite_list", text: "some_items_not_added_to_cart_error" }
         );
         redirectTo("/checkout/cart");
      } else {
         toastStore.addSuccess(
            "Varer lagt i handlekurven",
            `Varene fra favorittlisten '${list.listName}' er lagt i handlekurven.`
         );
         redirectTo("/checkout/cart");
      }
   };

   const sortedFavoriteLists = sortBy(values(favoriteStore.allFavoriteLists), "listName");

   const options = sortedFavoriteLists.map((list) => ({ label: list.listName, value: list.id }));
   return (
      <ShortcutBox>
         <h4>Legg favorittliste i handlekurven</h4>
         <p>Velg en favorittliste og den vil bli lagt direkte i handlekurven.</p>

         <Select
            label=""
            options={options}
            value={{ value: "", label: "Velg favorittliste" }}
            onChange={(option) => option.value && addListToCart(option.value)}
            zOnOpen={30}
         />
      </ShortcutBox>
   );
};

export default view(ShortcutFavoritesToCart);
