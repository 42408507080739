import {
   type NodeRenderer,
   type Options,
   type RenderNode,
   documentToReactComponents
} from "@contentful/rich-text-react-renderer";
import { BLOCKS, type Document, INLINES } from "@contentful/rich-text-types";
import { cn } from "@tine/designsystem-utils";

import type { ContentEntryType, EmbeddedEntryContextType } from "../../common/types/cmsTypes";

import contentStore from "../../stores/cms/contentStore";

import { DownloadLink, ExternalLink, InternalLink } from "../Links";
import CmsEmbeddedEntry from "./CmsEmbeddedEntry";
import { type ColumnWidthType, RichTextColumn, RichTextListItem, RichTextTable, type TextTags } from "./RichTextRendererHelpers";

type RichTextRendererProps = {
   document: Document;
   variant?: "simple" | "standard";
   context: EmbeddedEntryContextType;
   textColumnWidth?: ColumnWidthType;
   trackingLocationName?: string;
};

const contentBlock: { [variant in "simple" | "standard"]: string } = {
   simple: "tw-my-8",
   standard: "tw-my-16"
};

const RichTextRenderer = ({
   document,
   variant = "standard",
   context,
   textColumnWidth,
   trackingLocationName
}: RichTextRendererProps) => {
   const textNodeRenderer =
      (tag: TextTags): NodeRenderer =>
      (node, children) => {
         const isListTag = tag === "ul" || tag === "ol";
         const isTableTag = tag === "table";
         const isListItem = tag === "li";

         const Tag = tag;
         const listElementStyle = cn("tw-pl-6", { "[&>li]:tw-list-decimal": tag === "ol", "[&>li]:tw-list-disc": tag === "ul" });

         if (isListItem) {
            return <RichTextListItem node={node} customRenderNodes={assetAndEntryRenderNodes} />;
         }

         return (
            <RichTextColumn columnWidth={textColumnWidth}>
               {isListTag && <Tag className={listElementStyle}>{children}</Tag>}
               {isTableTag && <RichTextTable table={node} customRenderNodes={assetAndEntryRenderNodes} />}
               {!isListTag && !isTableTag && <Tag>{children}</Tag>}
            </RichTextColumn>
         );
      };

   const textRenderNodes: RenderNode = {
      [BLOCKS.PARAGRAPH]: textNodeRenderer("p"),
      [BLOCKS.HEADING_2]: textNodeRenderer("h2"),
      [BLOCKS.HEADING_3]: textNodeRenderer("h3"),
      [BLOCKS.HEADING_4]: textNodeRenderer("h4"),
      [BLOCKS.HEADING_5]: textNodeRenderer("h5"),
      [BLOCKS.HEADING_6]: textNodeRenderer("h6"),
      [BLOCKS.UL_LIST]: textNodeRenderer("ul"),
      [BLOCKS.OL_LIST]: textNodeRenderer("ol"),
      [BLOCKS.TABLE]: textNodeRenderer("table")
   };

   const assetAndEntryRenderNodes: RenderNode = {
      [BLOCKS.LIST_ITEM]: textNodeRenderer("li"),
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
         if (!node.data?.target?.fields) {
            return null;
         }
         const nodeId = node.data.target.sys.id;
         const nodeType = node.data.target.sys.contentType.sys.id as ContentEntryType;

         return (
            <div className={cn(contentBlock[variant], "first:tw-mt-0 last:tw-mb-0")}>
               <CmsEmbeddedEntry entryId={nodeId} content={node.data.target} type={nodeType} context={context} />
            </div>
         );
      },
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
         const url = node.data.target.fields.file.url;
         return <DownloadLink href={url}>{children}</DownloadLink>;
      },
      [INLINES.HYPERLINK]: (node, children) => {
         const url = node.data.uri;
         if (url.startsWith("/")) {
            return <InternalLink href={url}>{children}</InternalLink>;
         }
         return <ExternalLink href={url}>{children}</ExternalLink>;
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
         const nodeId = node.data.target.sys.id;
         const existingContent = contentStore.urlData.data.find((data) => data.id === nodeId);
         if (existingContent) {
            return (
               <InternalLink href={`/${existingContent.fullUrl}`} trackingLocationName={trackingLocationName}>
                  {children}
               </InternalLink>
            );
         }
         return <>{children}</>;
      }
   };

   const customRenderOptions: Options = {
      renderNode: {
         ...textRenderNodes,
         ...assetAndEntryRenderNodes
      }
   };
   return <>{documentToReactComponents(document, customRenderOptions)}</>;
};

export default RichTextRenderer;
