import { view } from "@risingstack/react-easy-state";
import { IconArrowForward } from "@tine/designsystem-icons/sharp";
import { cn } from "@tine/designsystem-utils";
import { useInView } from "react-intersection-observer";
import { Link } from "wouter";

import useContainerWidth, { type WidthRanges } from "../../common/hooks/useContainerWidth";
import { sendSelectTeaser, sendViewTeaser } from "../../common/tracking";
import type { AspectRatioType } from "../../common/types/mediaTypes";

import ImageWithFocus from "./ImageWithFocus";

type UrlImageLinkProps = {
   url: string;
   imageWithFocusId?: string;
   title?: string;
   subTitle?: string;
   trackingListName?: string;
   type?: string;
};

type BreakpointSize = "small" | "large";

const ranges: WidthRanges<BreakpointSize> = {
   small: [0, 700],
   large: [700]
};

const UrlImageLink = ({ url, imageWithFocusId, title, subTitle, trackingListName, type = "url" }: UrlImageLinkProps) => {
   const trackImpression = (isVisible: boolean) => {
      if (isVisible) {
         sendViewTeaser(title, type, trackingListName);
      }
   };

   const { ref: viewRef } = useInView({
      triggerOnce: true,
      onChange: trackImpression
   });

   const { ref: containerRef, size: currentContainerSize } = useContainerWidth<BreakpointSize, HTMLDivElement>(ranges);

   const largeContainer = currentContainerSize === "large";
   const width = largeContainer ? 1200 : 800;
   const ratio: AspectRatioType = largeContainer ? "32x9" : "16x9";
   const hasSubTitle = !!subTitle;
   const hasTitle = !!title;
   const hasText = hasTitle || hasSubTitle;
   const hasOnlySubTitle = hasSubTitle && !hasTitle;

   const subTitleStyle =
      "tw-p-0 tw-m-0 tw-text-[0.67em] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-hidden @[500px]:tw-inline tw-rounded";

   const gradientStyle = cn(
      "tw-bg-gradient-to-b tw-from-[#0000] tw-from-55% tw-to-[#232323ab] tw-to-100% tw-shadow-[#00000080] tw-text-shadow hover:tw-from-[#00000024] hover:tw-to-[#232323ab]",
      {
         "tw-from-65%": largeContainer
      }
   );

   const titlesTextStyle = "tw-text-base tw-text-balance @[360px]:tw-text-xl @[500px]:tw-text-2xl @[700px]:tw-text-3xl";
   const gradientOverlayStyle = cn("tw-absolute tw-inset-0 tw-hidden tw-rounded", titlesTextStyle, gradientStyle, {
      "tw-block": hasTitle,
      "@[500px]:tw-block": hasOnlySubTitle
   });

   const titlesContainerStyle =
      "tw-absolute tw-bottom-0 tw-rounded tw-p-4 tw-text-base-0 @[360px]:tw-pb-5 @[500px]:tw-pb-6 @[700px]:tw-w-full @[700px]:tw-px-16 @[700px]:tw-pb-10 @[700px]:tw-text-center";
   const titleStyle = cn(titlesTextStyle, "tw-m-0 tw-p-0");

   const ariaLabel = `Gå til ${title ?? url}`;

   if (!imageWithFocusId) {
      console.warn(`No image specified for UrlImageLink to ${url}`);
      return null;
   }

   function handleClickImageLink() {
      sendSelectTeaser(title, type, trackingListName, url);
   }

   return (
      <div ref={viewRef}>
         <div ref={containerRef} aria-label={ariaLabel} className={"tw-relative tw-@container"}>
            <Link to={url} onClick={() => handleClickImageLink()}>
               <ImageWithFocus
                  width={width}
                  entryId={imageWithFocusId}
                  placement={"belowFold"}
                  ratio={ratio}
                  className="rounded-1"
               />

               {hasText && (
                  <div className={gradientOverlayStyle}>
                     <div className={titlesContainerStyle}>
                        <h2 className={titleStyle}>{title}</h2>
                        {!!subTitle && (
                           <p className={subTitleStyle}>
                              {subTitle}
                              <IconArrowForward className="tw-inline" />
                           </p>
                        )}
                     </div>
                  </div>
               )}
            </Link>
         </div>
      </div>
   );
};

export default view(UrlImageLink);
