import { view } from "@risingstack/react-easy-state";
import type { Entry } from "contentful";

import type { EmbeddedEntryContextType } from "../../common/types/cmsTypes";
import type { TypeAutomaticGeneratedProductListSkeleton } from "../../common/types/contentful";
import { LIST_TYPE } from "../../common/types/trackingTypes";
import ProductList from "../common/product/ProductList";

import authStore from "../../stores/auth/authStore";
import favoriteStore from "../../stores/favoriteStore";
import productStore from "../../stores/product/productStore";

import ContractualProductList from "../ContractualProductList";
import ErrorAlertWithOptionalTracking from "../ErrorAlertWithOptionalTracking";
import FavoritesAndSubscriptionsWidget from "../FavoritesAndSubscriptionsWidget";
import Recommendations from "../Recommendations";

type CmsAutomaticProductListProps = {
   content: Entry<TypeAutomaticGeneratedProductListSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "nb_NO">;
   context: EmbeddedEntryContextType;
};

const CmsAutomaticProductList = ({ content, context }: CmsAutomaticProductListProps) => {
   const title = content.fields.title;
   const type = content.fields.type;
   const productCount = content.fields.productCount;
   const numberOfProducts: number =
      productCount === "Ubegrenset"
         ? Number.MAX_SAFE_INTEGER
         : productCount
           ? Number.parseInt(productCount)
           : content.fields.numberOfProducts || Number.MAX_SAFE_INTEGER;

   if (!authStore.isLoggedIn()) {
      return null;
   }

   switch (type) {
      case "User Favorites": {
         const skus = productStore.resolveSkus(favoriteStore.allSkus, true).map((product) => product.sku);
         return (
            <ProductList
               skus={skus.slice(0, numberOfProducts)}
               buttonUrl="/customer/favorites"
               listName="Mine Favoritter (CMS Block)"
               listType={LIST_TYPE.RECOMMENDATIONS}
               heading={title}
               context={context}
            />
         );
      }

      case "Contractual Recommendations - Should Buy":
         return <ContractualProductList heading={title} count={numberOfProducts} type="shouldBuy" />;

      case "Contractual Recommendations - Full List":
         return <ContractualProductList heading={title} count={numberOfProducts} type="fullList" />;

      case "User Favorites And Subscriptions":
         return <FavoritesAndSubscriptionsWidget heading={title} />;

      case "Recommendations":
         return <Recommendations heading={null} count={numberOfProducts} />;

      default:
         return (
            <ErrorAlertWithOptionalTracking
               showCloseButton={false}
               gaErrorTracking={{ context: "cms_content", text: "products_error" }}
            >
               Det opppsto en uventet feil under visning av innhold {content.sys.id}
            </ErrorAlertWithOptionalTracking>
         );
   }
};

export default view(CmsAutomaticProductList);
