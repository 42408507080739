import { view } from "@risingstack/react-easy-state";
import { IconClose, IconExpandAll } from "@tine/designsystem-icons/sharp";
import { Alert } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { isDataAvailable } from "../common/utils/asyncDataUtils";
import powerBiStore, { type AvailablePowerBIReports } from "../stores/powerBIStore";

import { isPast } from "date-fns";
import { LayoutType } from "powerbi-models";
import useWhenLoggedIn from "../common/hooks/useWhenLoggedIn";
import type { PowerBIEmbedData } from "../common/types/powerBITypes";
import useMediaQuery from "../common/useMediaQuery";
import { mediaQueries } from "../common/utils";
import Button from "../components/Button";

type ReportAspectRatio = 0.25 | 0.5 | 0.75 | 1 | 1.25 | 1.5 | 1.75 | 2 | 3 | 4;

type PowerBIReportProps = {
   title: string;
   reportName: AvailablePowerBIReports;
   /**
    * Sets height of report on mobile. Report will take up 100% of width and mutliplying the width with this factor to find height.
    * Setting to 1 will give a square report. Numbers above 1 will make the report taller than wide. Desktop uses 16/10 aspect ratio.
    */
   mobileHeightRatio: false | ReportAspectRatio;
};

const isEmbedTokenExpired = (embedData: PowerBIEmbedData | null): boolean => {
   if (!embedData) {
      return false;
   }

   const expirationData = embedData.expirationDate;
   return expirationData && isPast(expirationData);
};

const PowerBIReport = ({ title, reportName, mobileHeightRatio }: PowerBIReportProps) => {
   const [expanded, setExpanded] = useState<boolean>(false);

   const embedData = powerBiStore.getEmbedToken(reportName);
   const isExpired = isEmbedTokenExpired(embedData.data);

   useWhenLoggedIn(() => {
      void powerBiStore.generateEmbedToken(reportName);
   });

   useEffect(() => {
      if (isDataAvailable(embedData) && isExpired) {
         console.log(`Embed token for ${reportName} has expired. Fetching new token...`);
         void powerBiStore.generateEmbedToken(reportName);
      }
   }, [isExpired]);

   useEffect(() => {
      const { classList } = document.getElementsByTagName("body")[0];
      if (expanded) {
         classList.add("tw-overflow-hidden");
      } else {
         classList.remove("tw-overflow-hidden");
      }

      return () => {
         classList.remove("tw-overflow-hidden");
      };
   }, [expanded]);

   const toggleExpanded = () => setExpanded(!expanded);
   const isMobile = !useMediaQuery(mediaQueries.md) && mobileHeightRatio !== false;

   if (!isDataAvailable(embedData) || !embedData.data) {
      return (
         <>
            <Skeleton height={38} className="tw-mb-2" />
            <Skeleton height={380} />
         </>
      );
   }

   const data = embedData.data;

   const embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${data.reportId}&groupId=${data.workspaceId}&config=${data.token}`;

   const ExpandCollapseIcon = expanded ? <IconClose /> : <IconExpandAll />;
   const h1Class = expanded ? "tw-m-4" : "tw-mb-6";
   const buttonClass = expanded ? "tw-mr-4" : "";

   const containerClasses = cn("keyfigures", {
      "tw-hidden sm:tw-block": !mobileHeightRatio,
      "tw-fixed tw-inset-0 tw-z-[200] tw-bg-surface-default": expanded
   });

   const mobileWarningClasses = cn({
      "tw-block sm:tw-hidden": !mobileHeightRatio,
      "tw-hidden": mobileHeightRatio
   });

   const reportSizeClasses = cn("tw-w-full md:tw-aspect-[16/10] md:tw-w-full", {
      "tw-aspect-[4/1]": mobileHeightRatio === 0.25,
      "tw-aspect-[4/2]": mobileHeightRatio === 0.5,
      "tw-aspect-[4/3]": mobileHeightRatio === 0.75,
      "tw-aspect-square": mobileHeightRatio === 1,
      "tw-aspect-[4/5]": mobileHeightRatio === 1.25,
      "tw-aspect-[2/3]": mobileHeightRatio === 1.5,
      "tw-aspect-[4/7]": mobileHeightRatio === 1.75,
      "tw-aspect-[1/2]": mobileHeightRatio === 2,
      "tw-aspect-[1/3]": mobileHeightRatio === 3,
      "tw-aspect-[1/4]": mobileHeightRatio === 4
   });
   const internalSizeClasses = cn("tw-h-full");

   const layoutType = isMobile ? { layoutType: LayoutType.MobilePortrait } : {};

   return (
      <>
         <div className={containerClasses}>
            <div className="keyfigures-container">
               <div className="tw-flex tw-items-center tw-justify-between">
                  <h1 className={h1Class}>{title}</h1>
                  <Button
                     className={cn("print:tw-hidden", buttonClass)}
                     icon={ExpandCollapseIcon}
                     size="compact"
                     variant="tertiary"
                     onClick={toggleExpanded}
                  >
                     {expanded ? "Lukk fullskjerm" : "Vis fullskjerm"}
                  </Button>
               </div>

               <div className={reportSizeClasses}>
                  <PowerBIEmbed
                     key="keyfigures-embed"
                     cssClassName={internalSizeClasses}
                     embedConfig={{
                        type: "report",
                        id: data.reportId,
                        embedUrl,
                        permissions: models.Permissions.Read,
                        accessToken: data.token,
                        tokenType: models.TokenType.Embed,
                        settings: {
                           panes: {
                              filters: { expanded: false, visible: false },
                              pageNavigation: { visible: false }
                           },
                           background: models.BackgroundType.Default,
                           ...layoutType
                        }
                     }}
                  />
               </div>
            </div>
         </div>
         <div className={mobileWarningClasses}>
            <h1 className="tw-mb-6">{title}</h1>
            <Alert showCloseButton={false} fullWidth variant="information">
               <p className="tw-mb-0">{title} er dessverre kun tilgjengelig på større skjerm.</p>
            </Alert>
         </div>
      </>
   );
};

export default view(PowerBIReport);
