import { __module as r } from "./designsystem-ui-react182.js";
import { d as p } from "./designsystem-ui-react183.js";
(function(u, o) {
  o = u.exports = p, o.log = f, o.formatArgs = l, o.save = g, o.load = t, o.useColors = d, o.storage = typeof chrome < "u" && typeof chrome.storage < "u" ? chrome.storage.local : m(), o.colors = [
    "lightseagreen",
    "forestgreen",
    "goldenrod",
    "dodgerblue",
    "darkorchid",
    "crimson"
  ];
  function d() {
    return typeof window < "u" && window.process && window.process.type === "renderer" ? !0 : typeof document < "u" && document.documentElement && document.documentElement.style && document.documentElement.style.WebkitAppearance || // is firebug? http://stackoverflow.com/a/398120/376773
    typeof window < "u" && window.console && (window.console.firebug || window.console.exception && window.console.table) || // is firefox >= v31?
    // https://developer.mozilla.org/en-US/docs/Tools/Web_Console#Styling_messages
    typeof navigator < "u" && navigator.userAgent && navigator.userAgent.toLowerCase().match(/firefox\/(\d+)/) && parseInt(RegExp.$1, 10) >= 31 || // double check webkit in userAgent just in case we are in a worker
    typeof navigator < "u" && navigator.userAgent && navigator.userAgent.toLowerCase().match(/applewebkit\/(\d+)/);
  }
  o.formatters.j = function(e) {
    try {
      return JSON.stringify(e);
    } catch (n) {
      return "[UnexpectedJSONParseError]: " + n.message;
    }
  };
  function l(e) {
    var n = this.useColors;
    if (e[0] = (n ? "%c" : "") + this.namespace + (n ? " %c" : " ") + e[0] + (n ? "%c " : " ") + "+" + o.humanize(this.diff), !!n) {
      var c = "color: " + this.color;
      e.splice(1, 0, c, "color: inherit");
      var i = 0, a = 0;
      e[0].replace(/%[a-zA-Z%]/g, function(s) {
        s !== "%%" && (i++, s === "%c" && (a = i));
      }), e.splice(a, 0, c);
    }
  }
  function f() {
    return typeof console == "object" && console.log && Function.prototype.apply.call(console.log, console, arguments);
  }
  function g(e) {
    try {
      e == null ? o.storage.removeItem("debug") : o.storage.debug = e;
    } catch {
    }
  }
  function t() {
    var e;
    try {
      e = o.storage.debug;
    } catch {
    }
    return !e && typeof process < "u" && "env" in process && (e = process.env.DEBUG), e;
  }
  o.enable(t());
  function m() {
    try {
      return window.localStorage;
    } catch {
    }
  }
})(r, r.exports);
var v = r.exports;
export {
  v as b
};
