import { view } from "@risingstack/react-easy-state";
import { Group } from "@tine/designsystem-ui-react";
import { useMemo, useState } from "react";

import type { RecipeType } from "../../../common/types/recipeTypes";

import WhiteSheet from "../../WhiteSheet";
import IngredientGroup from "./IngredientGroup";
import IngredientsPortionsControl from "./IngredientsPortionsControl";

type IngredientsProps = {
   recipe: RecipeType;
};

const Ingredients = ({ recipe }: IngredientsProps) => {
   const { multipliable, ingredientGroups, portions } = recipe;
   const [multiplier, setMultiplier] = useState<number>(1);

   const showPortionsControl = !!portions && !!multipliable;
   const currentPortions = useMemo(() => (showPortionsControl ? Math.floor(portions * multiplier) : portions || 4), [multiplier]);

   const handlePortionsControlChange = (newPortions: number) => {
      const multiplier = newPortions / (portions || 4);
      setMultiplier(multiplier);
   };

   const portionsText = portions ? (portions === 1 ? "1 porsjon" : `${portions} porsjoner`) : "";

   return (
      <>
         <h2 className="tw-mb-4">Ingredienser</h2>
         <WhiteSheet>
            <div className="tw-mb-4">
               {showPortionsControl && (
                  <Group justify="between">
                     <p className="tw-text-sm tw-font-semibold">Antall porsjoner</p>
                     <IngredientsPortionsControl portions={currentPortions} onChange={handlePortionsControlChange} />
                  </Group>
               )}
               {portions && !showPortionsControl && <p className="tw-text-sm tw-font-semibold">{portionsText}</p>}
            </div>
            <div className="tw-flex tw-flex-col tw-gap-8">
               {ingredientGroups.map((ingredientGroup, i) => (
                  <IngredientGroup key={`${recipe.recipeId}-${i}`} ingredientGroup={ingredientGroup} multiplier={multiplier} />
               ))}
            </div>
         </WhiteSheet>
      </>
   );
};

export default view(Ingredients);
