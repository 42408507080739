import { view } from "@risingstack/react-easy-state";
import { Grid, Group } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import isNil from "lodash-es/isNil";
import { Link, useParams } from "wouter";

import type { ContentfulSection } from "../../common/types/cmsTypes";
import { isDataAvailable } from "../../common/utils/asyncDataUtils";

import contentStore from "../../stores/cms/contentStore";
import segmentStore from "../../stores/cms/segmentStore";

import TagLink from "../TagLink";

type SectionProps = {
   section?: ContentfulSection;
   label?: string;
   active: boolean;
};

const sectionHasContent = (sectionId: string) => {
   return contentStore.urlData.data.some(
      (urlData) =>
         urlData.visibleInSections?.includes(sectionId) &&
         (urlData.type !== "v2_article" || segmentStore.isArticleVisible(urlData.id))
   );
};

const mainSectionStyles = (active: boolean) =>
   cn("tw-h-full tw-rounded tw-bg-surface-default tw-p-4 tw-shadow-sm hover:tw-bg-surface-default-hover", {
      "tw-bg-surface-default-hover": active
   });

const MainSection = view(({ section, active, label }: SectionProps) => {
   const sectionId = section?.sys.id || "";
   const urlData = contentStore.urlData.data.find((urlData) => urlData.id === sectionId);

   if (!sectionHasContent(sectionId)) {
      return null;
   }

   return (
      <div>
         <Link to={`/${urlData?.fullUrl}`}>
            <div className={mainSectionStyles(active)}>
               <div className="tw-font-bold">{section?.fields.title ?? label}</div>
               <div className="tw-text-sm">{section?.fields.description}</div>
            </div>
         </Link>
      </div>
   );
});

const TagSection = view(({ section, label, active }: SectionProps) => {
   const sectionId = section?.sys.id || "";
   const urlData = contentStore.urlData.data.find((urlData) => urlData.id === sectionId);

   if (!sectionHasContent(sectionId)) {
      return null;
   }

   return <TagLink url={`/${urlData?.fullUrl}`} label={label ?? section?.fields.title ?? "Mangler tittel"} isActive={active} />;
});

const SectionNavigator = () => {
   const { fullUrl } = useParams();
   const rootNodeSlug = fullUrl?.split("/")[0];
   const mainSectionSlug = fullUrl?.split("/")[1];
   const tagSectionSlug = fullUrl?.split("/")[2];

   if (isNil(rootNodeSlug)) {
      return <>Unable to determine current root node</>;
   }

   if (!isDataAvailable(contentStore.urlData) || !isDataAvailable(contentStore.sections)) {
      return null;
   }

   const rootNode = contentStore.urlData.data.find((urlData) => urlData.fullUrl === rootNodeSlug);
   const mainSections = contentStore.sections.data.filter((section) => section.fields.parentId === rootNode?.id);

   const selectedMainSection = mainSections.find((section) => section.fields.slug === mainSectionSlug);

   const tags = selectedMainSection
      ? contentStore.sections.data.filter((section) => section.fields.parentId === selectedMainSection.sys.id)
      : [];
   const selectedTagSection = tags.find((section) => section.fields.slug === tagSectionSlug);

   return (
      <>
         <Grid cols={{ base: 1, sm: 2, md: 3, lg: 4 }} gap={4} className="tw-mb-3">
            {mainSections.map((section) => (
               <MainSection key={section.sys.id} active={section.fields.slug === mainSectionSlug} section={section} />
            ))}
         </Grid>

         {tags.length > 0 && (
            <Group className="tw-mb-5" justify="start" gap={2}>
               <TagSection label="Alle" section={selectedMainSection} active={isNil(selectedTagSection)} />
               {tags.map((tag) => (
                  <TagSection key={tag.sys.id} active={tag.fields.slug === tagSectionSlug} section={tag} />
               ))}
            </Group>
         )}
      </>
   );
};

export default view(SectionNavigator);
