import { autoEffect, view } from "@risingstack/react-easy-state";
import isNil from "lodash-es/isNil";
import { Helmet } from "react-helmet";

import authStore from "../stores/auth/authStore";

const VURBIS_API_URL = "https://vurbismarketplace.com";

const VurbisPunchoutScript = () => {
   if (!authStore.isLoggedIn()) {
      return null;
   }

   if (!authStore.isPunchoutSession()) {
      return null;
   }

   if (isNil(authStore.user?.additionalInfo.VURBIS_SESSION_ID)) {
      console.warn("VURBIS_SESSION_ID is missing in user additional info even if this is a punchout session");
   }

   // Helmet is necessary for React to execute the script references in the script tag inserted
   return (
      <Helmet>
         <script src={`${VURBIS_API_URL}/api/punchout/files/${authStore.user?.additionalInfo.VURBIS_SESSION_ID}/enterprise.js`} />
      </Helmet>
   );
};

autoEffect(() => {
   const currentCompany = authStore.currentCompany;
   if (isNil(currentCompany)) {
      window.cartId = undefined;
      return;
   }

   window.cartId = currentCompany;
});

export default view(VurbisPunchoutScript);
