import { store } from "@risingstack/react-easy-state";
import { isNil } from "lodash-es";

import { apiClient } from "../common/apiClient";
import type { PowerBIEmbedData, PowerBIPayload } from "../common/types/powerBITypes";
import {
   type AsyncData,
   initializeWithDefaultData,
   setAsDataAvailable,
   setAsErrorOccured,
   setAsWaitingForData
} from "../common/utils/asyncDataUtils";

import { API_HOST } from "../common/environment";
import { ensureError } from "../common/errors";
import type { BaseStoreType } from "../common/types/BaseStoreType";
import theme from "../themes/theme";
import authStore from "./auth/authStore";

export type AvailablePowerBIReports = "keyfigures" | "aretsmatglede";

type PowerBIStoreType = BaseStoreType & {
   embedData: Partial<Record<AvailablePowerBIReports, AsyncData<PowerBIEmbedData | null>>>;

   getEmbedToken: (reportName: AvailablePowerBIReports) => AsyncData<PowerBIEmbedData | null>;
   generateEmbedToken: (reportName: AvailablePowerBIReports) => Promise<void>;
};

const powerBiStore: PowerBIStoreType = store({
   embedData: {},

   getEmbedToken: (reportName: AvailablePowerBIReports) => {
      if (!powerBiStore.embedData[reportName]) {
         powerBiStore.embedData[reportName] = initializeWithDefaultData(null);
      }

      return powerBiStore.embedData[reportName];
   },

   generateEmbedToken: async (reportName: AvailablePowerBIReports) => {
      if (!authStore.isLoggedIn() || isNil(authStore.currentCompany)) {
         console.warn("Not logged in or no company selected, skipping PowerBI token generation.");
         return;
      }

      const entry = powerBiStore.embedData[reportName];
      if (!entry) {
         console.warn("Do not call generateEmbedToken directly, use getEmbedToken to allow store to be initialized properly.");
         return;
      }

      const abortController = setAsWaitingForData(entry);

      try {
         const url = `${API_HOST}/api/powerbi/${theme.storeId}/${reportName}/${authStore.currentCompany}`;
         const embedData: PowerBIPayload = await apiClient(url, authStore.getSessionToken()).signal(abortController).get().json();

         if (embedData.expiration) {
            const fullEmbedData = {
               ...embedData,
               expirationDate: new Date(embedData.expiration)
            };
            setAsDataAvailable(entry, fullEmbedData);
         } else {
            setAsErrorOccured(entry, "Unknown object returned from server");
         }
      } catch (err) {
         setAsErrorOccured(entry, ensureError(err).message);
      }
   },

   clearCompanySpecificData: () => {
      powerBiStore.embedData = {};
   }
});

export default powerBiStore;
