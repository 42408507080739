import { view } from "@risingstack/react-easy-state";
import { IconHeart, IconHeartFilled } from "@tine/designsystem-icons/sharp";
import { Checkbox } from "@tine/designsystem-ui-react";
import size from "lodash-es/size";
import sortBy from "lodash-es/sortBy";
import values from "lodash-es/values";
import { forwardRef, useRef, useState } from "react";

import { sendAddItemsToFavoriteList } from "../common/tracking";
import type { GAListInformation } from "../common/types/trackingTypes";
import Popover from "./common/popover/Popover";

import authStore from "../stores/auth/authStore";
import favoriteStore from "../stores/favoriteStore";
import productStore from "../stores/product/productStore";

import Button from "./Button";

type FavoriteProps = {
   sku: string;
   listInformation: GAListInformation;
   position: number;
};

type FavoriteHeartIconProps = {
   isFavorite: boolean;
   onClick?: () => void;
   disabled?: boolean;
};

const FavoriteHeartIcon = forwardRef<HTMLDivElement, FavoriteHeartIconProps>(({ isFavorite, onClick, disabled = false }, ref) => (
   <>
      {!disabled && (
         <button type="button" onClick={onClick} className="tw-absolute tw-right-0" data-testid="favorite-icon-button">
            <div ref={ref}>
               {isFavorite ? <IconHeartFilled className="tw-fill-favorite" /> : <IconHeart className="hover:tw-fill-favorite" />}
            </div>
         </button>
      )}
      {disabled && (
         <div className="tw-absolute tw-right-0 tw-cursor-not-allowed">
            <IconHeart className="tw-fill-base-300" />
         </div>
      )}
   </>
));

const Favorite = ({ sku, listInformation, position }: FavoriteProps) => {
   const [show, setShow] = useState<boolean>(false);
   const isFavorite = favoriteStore.isFavorite(sku);
   const isLoggedIn = authStore.isLoggedIn();
   const hasMultipleLists = size(favoriteStore.allFavoriteLists) > 1;
   const parentRef = useRef<HTMLDivElement>(null);

   const handleClick = () => {
      if (isLoggedIn) {
         isFavorite ? favoriteStore.removeFavorite(sku) : addFavorite(sku);
      }
   };

   if (!isLoggedIn) {
      return <FavoriteHeartIcon disabled isFavorite={isFavorite} />;
   }

   const addFavorite = (sku: string, listId?: string) => {
      const product = productStore.resolveSku(sku);
      if (product) {
         favoriteStore.addFavorite(sku, listId);
         sendAddItemsToFavoriteList([product], listInformation, position);
      }
   };

   if (hasMultipleLists) {
      const lists = sortBy(values(favoriteStore.allFavoriteLists), "name");
      const favoriteListPicker = (
         <div>
            <h3 className="tw-bg-base-100 tw-px-4 tw-py-2">Velg liste</h3>
            <div className="tw-p-4">
               <ul className="tw-list-none tw-ps-0">
                  {lists.map((list) => {
                     const checked = typeof list.items[sku] === "number" && list.items[sku] > 0;
                     return (
                        <li key={list.id}>
                           <Checkbox
                              className="tw-mb-2 tw-min-h-6"
                              label={list.listName}
                              isChecked={checked}
                              onChange={() => (checked ? favoriteStore.removeFavorite(sku, list.id) : addFavorite(sku, list.id))}
                           />
                        </li>
                     );
                  })}
               </ul>
               <div className="tw-mt-2 tw-flex tw-justify-center">
                  <Button data-testid="add-to-favorite-list-popover-button" onClick={() => setShow(false)}>
                     OK
                  </Button>
               </div>
            </div>
         </div>
      );

      return (
         <div>
            <div>
               <FavoriteHeartIcon onClick={() => setShow(!show)} isFavorite={isFavorite} ref={parentRef} />
            </div>
            <Popover
               arrowStyles={{
                  bottom: {
                     /*
                     // @ts-ignore*/
                     "--popover-bg": "var(--color-base-100)"
                  }
               }}
               show={show}
               onHide={() => setShow(false)}
               placement="auto"
               referenceElement={parentRef.current}
            >
               {favoriteListPicker}
            </Popover>
         </div>
      );
   }

   return <FavoriteHeartIcon isFavorite={isFavorite} onClick={handleClick} />;
};

export default view(Favorite);
