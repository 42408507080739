import isNil from "lodash-es/isNil";
import wretch, { type WretchError } from "wretch";
import { abortAddon, formDataAddon, queryStringAddon } from "wretch/addons";

export const apiClient = (url: string, token?: string | null, controller?: AbortController) => {
   let client = wretch(url).addon(queryStringAddon).addon(formDataAddon).addon(abortAddon()).headers({
      Accept: "application/json"
   });

   if (controller) {
      client = client.signal(controller);
   }
   if (!isNil(token)) {
      client = client.auth(`Bearer ${token}`);
   }

   return client;
};

export const ensureWretchError = (error: unknown): WretchError | null => {
   if ((error as WretchError).response) {
      return error as WretchError;
   }
   return null;
};
