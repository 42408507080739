import type { Entry } from "contentful";
import { concat, intersection, uniq } from "lodash-es";
import isString from "lodash-es/isString";

import type { ContentVisibility, CustomerAttribute, CustomerSegment } from "../../stores/cms/segmentStore";

import type { TypeSegmentSkeleton } from "../types/contentful";

type SegmentEntry = Entry<TypeSegmentSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string> | undefined;

// If you get an entry with multiple comma separated values, this fuctuon splits it into an array
// E.g. ["102030, 100300", "109770"] => ["102030", "100300", "109770"]
const flattenListField = (field: string[] | undefined): string[] | undefined => {
   if (field?.length) {
      return field.flatMap((value) => value.replace(/\s/g, "").split(","));
   }
   return field;
};
export const mergeArrays = <T>(array1: T[] | undefined, array2: T[] | undefined) =>
   uniq(concat(array1, array2)).filter((element): element is T => !!element);

const getCustomerAttributesFromEntryFields = (entry: SegmentEntry): CustomerAttribute[] => {
   const customerAttributes: CustomerAttribute[] = [];
   if (entry?.fields.loggedIn) {
      customerAttributes.push("loggedIn");
   }
   if (entry?.fields.punchoutCustomer) {
      customerAttributes.push("punchoutCustomer");
   }
   return customerAttributes;
};

export const convertEntriesToSegment = (entries: SegmentEntry[]): CustomerSegment =>
   entries.reduce(
      (result: CustomerSegment, entry: SegmentEntry) => {
         if (entry && !entry.fields) {
            console.warn("Encountered segment entry without fields, skipping...", entry);
            return result;
         }

         return entry
            ? {
                 customerNumber: mergeArrays(result.customerNumber, flattenListField(entry.fields.customerNumbers)),
                 chains: mergeArrays(result.chains, flattenListField(entry.fields.chains)),
                 customerGroups: mergeArrays(result.customerGroups, flattenListField(entry.fields.customerGroups)),
                 customerWarehouses: mergeArrays(result.customerWarehouses, flattenListField(entry.fields.customerWarehouses)),
                 customerAttributes: mergeArrays(result.customerAttributes, getCustomerAttributesFromEntryFields(entry))
              }
            : result;
      },
      {
         customerNumber: [],
         chains: [],
         customerGroups: [],
         customerWarehouses: [],
         customerAttributes: []
      }
   );

export const isOverlappingArrays = <T>(array1: T[], array2: T[]) =>
   intersection(array1, array2).filter((element): element is T => !!element).length > 0;

export const isSegmentOverlapping = (
   { customerNumber, chains, customerGroups, customerWarehouses, customerAttributes }: CustomerSegment,
   currentCustomerProperties: CustomerSegment
) => {
   return (
      isOverlappingArrays(customerNumber, currentCustomerProperties.customerNumber) ||
      isOverlappingArrays(chains, currentCustomerProperties.chains) ||
      isOverlappingArrays(customerGroups, currentCustomerProperties.customerGroups) ||
      isOverlappingArrays(customerWarehouses, currentCustomerProperties.customerWarehouses) ||
      isOverlappingArrays(customerAttributes, currentCustomerProperties.customerAttributes)
   );
};
// Takes a value formatted as "121|Barnehager" and keeps only the id. If
// the value is not formatted as "id|name" it returns the value as is.
export const keepIdOnly = (segment: string) => {
   if (!isString(segment) || !segment.includes("|")) {
      return segment;
   }
   return segment.split("|")[0];
};

type ModuleVisibilityProperties = {
   fields: {
      includeSegments?: SegmentEntry[];
      excludeSegments?: SegmentEntry[];
   };
};

// Checks if a segmentable entry in a teaser list or article is visible for current user
export const getModuleVisibility = (content: ModuleVisibilityProperties, currentCustomerProperties: CustomerSegment) => {
   const include = content.fields.includeSegments && convertEntriesToSegment(content.fields.includeSegments);
   const exclude = content.fields.excludeSegments && convertEntriesToSegment(content.fields.excludeSegments);

   const visibility = getEntryVisibility(currentCustomerProperties, { include, exclude });
   return visibility === "visible";
};

export const getEntryVisibility = (
   currentCustomerProperties: CustomerSegment,
   entrySegment: {
      include?: CustomerSegment;
      exclude?: CustomerSegment;
   }
): ContentVisibility => {
   const { exclude, include } = entrySegment;
   const isLoggedIn = currentCustomerProperties.customerAttributes.includes("loggedIn");

   if (exclude) {
      const isExcludeOverlap = isSegmentOverlapping(exclude, currentCustomerProperties);
      if (isExcludeOverlap) {
         return "hidden";
      }
   }

   if (include) {
      if (!isLoggedIn) {
         if (include.customerAttributes.includes("punchoutCustomer")) {
            return "hidden";
         }
         return "requiresLogin";
      }

      const isIncludeOverlap = isSegmentOverlapping(include, currentCustomerProperties);
      return isIncludeOverlap ? "visible" : "hidden";
   }

   return "visible";
};
