import { Group, Stack } from "@tine/designsystem-ui-react";

import type { RecipeType } from "../../../common/types/recipeTypes";

import RecipeDifficulty from "./RecipeDifficulty";
import RecipePreparationTime from "./RecipePreparationTime";

type RecipeHeaderProps = {
   recipe: RecipeType;
};

const RecipeHeader = ({ recipe }: RecipeHeaderProps) => {
   const { displayName, description, difficulty, workTime, workTimeRange } = recipe;

   const displayDescription = description.replace(/&nbsp;/i, " ");

   return (
      <Stack align="start" justify="center" className="tw-h-full" gap={1}>
         <h1>{displayName}</h1>
         <Group justify="start" className="tw-mb-3">
            <RecipePreparationTime workTime={workTime} workTimeRange={workTimeRange} />
            <RecipeDifficulty difficulty={difficulty} />
         </Group>
         <p className="tw-text-xl">{displayDescription}</p>
      </Stack>
   );
};

export default RecipeHeader;
