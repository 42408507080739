import { view } from "@risingstack/react-easy-state";
import { IconClock, IconTruck } from "@tine/designsystem-icons/sharp";
import { cn } from "@tine/designsystem-utils";
import isNil from "lodash-es/isNil";
import type { ReactNode } from "react";

import { translateDayLabel } from "../../common/utils";
import { isLoading } from "../../common/utils/asyncDataUtils";
import { formatDeliveryDate } from "../../common/utils/dateUtils";
import MainColumn from "../../components/common/MainColumn";

import authStore from "../../stores/auth/authStore";
import cartStore from "../../stores/cart/cartStore";
import deliveryDatesStore from "../../stores/deliveryDates/deliveryDatesStore";

import Button from "../../components/Button";
import Loading from "../../components/Loading";
import DeliveryDateButton from "../../components/deliverydates/DeliveryDateButton";
import ChangeOrderOrSubscription from "../ChangeOrderOrSubscription";
import Deadline from "./Deadline";

const className = cn(["tw-flex tw-flex-row tw-justify-start tw-gap-8 tw-py-2"]);

const itemClassName = cn(["tw-flex tw-flex-row tw-items-center tw-gap-2"]);

const iconAndLabelClassName = cn(["tw-flex tw-items-center", "tw-font-bold"]);

const DeliveryInfo = () => {
   if (!authStore.isLoggedIn() || authStore.error) {
      return null;
   }

   if (isLoading(deliveryDatesStore.deliveryDates)) {
      return <Loading label="Laster leveringsdager..." />;
   }

   if (cartStore.isEditing() && !isNil(cartStore.editing)) {
      return <ChangeOrderOrSubscription />;
   }

   const dayLabel = translateDayLabel(cartStore.orderType);
   const currentDelivery = deliveryDatesStore.getCurrentDelivery();
   if (currentDelivery === null) {
      console.log("No current delivery found");
      return null;
   }

   const renderDeliveryDateButton: (dates: Date[], isPickerOpen: boolean, setPickerOpen: (show: boolean) => void) => ReactNode = (
      _dates,
      isPickerOpen,
      setPickerOpen
   ) => {
      return (
         <Button variant="tertiary" onClick={() => setPickerOpen(!isPickerOpen)}>
            Endre
         </Button>
      );
   };

   return (
      <MainColumn className="tw-shadow-md tw-bg-surface-default">
         <div className={className}>
            <div className={itemClassName}>
               <div className={iconAndLabelClassName}>
                  <IconClock className="tw-mr-1" /> <p className="tw-m-0 tw-p-0">Frist:</p>
               </div>
               {!!currentDelivery.deadline && <Deadline />}
            </div>
            <div className={itemClassName}>
               <div className={iconAndLabelClassName}>
                  <IconTruck className="tw-mr-1" />
                  <p className="tw-m-0 tw-p-0">{dayLabel}:</p>
               </div>
               <div className="tw-flex tw-items-center">
                  <div>{formatDeliveryDate(currentDelivery.date, cartStore.orderType, currentDelivery.interval)} |</div>
                  <DeliveryDateButton render={renderDeliveryDateButton} />
               </div>
            </div>
         </div>
      </MainColumn>
   );
};

export default view(DeliveryInfo);
