import { view } from "@risingstack/react-easy-state";
import { cn } from "@tine/designsystem-utils";

type NotificationBadgeProps = {
   count: number;
};
const notificationBadgeStyle = cn(
   "tw-bg-notification-bg tw-text-notification-text",
   "tw-size-5 tw-rounded-full tw-text-center tw-align-middle tw-text-xs tw-leading-5"
);

const NotificationsBadge = ({ count }: NotificationBadgeProps) => {
   if (count > 0) {
      return (
         <div className={notificationBadgeStyle} aria-label={`${count} uleste varslinger`}>
            {count}
         </div>
      );
   }
};

export default view(NotificationsBadge);
