import type { Entry } from "contentful";
import { isNumber, isString } from "lodash-es";

import { CONTENTFUL_SPACE } from "../environment";
import type { FocalPointData, ImageWithFocusData } from "../types/cmsTypes";
import type { TypeImageWithFocusSkeleton } from "../types/contentful";
import type { SourceSetItem } from "../types/mediaTypes";

export type ImageSpec = {
   minWidth?: number;
   maxWidth?: number;
   width: number;
   height: number;
};

/**
 * Generate a source set that gives several smaller sizes with the same aspect ratio
 * as the size used for xxl screens, including a fallback for browsers that is not
 * able to parse source set.
 * @param xxlWidth
 * @param xxlHeight
 */
export const generateSizesFromMaxSize = (xxlWidth: number, xxlHeight: number): ImageSpec[] => {
   const result: ImageSpec[] = [];
   const aspectRatio = xxlHeight / xxlWidth;
   const fullWidth = 1250;
   const breakpointMobile = 995;
   const breakpointLowest = 580;

   // Size for xxl desktop users
   result.push({ minWidth: fullWidth, width: xxlWidth, height: xxlHeight });

   // This is usually images transition into full width regardless of layout
   result.push({
      maxWidth: fullWidth,
      minWidth: breakpointLowest,
      width: Math.ceil(xxlWidth * (breakpointMobile / fullWidth)),
      height: Math.ceil(xxlHeight * (breakpointMobile / fullWidth))
   });

   // Fallback for small browsers under 500 px width
   result.push({ width: breakpointLowest, height: Math.ceil(breakpointLowest * aspectRatio) });

   return result;
};
export const createMediaQuery = (imageSpec: ImageSpec): string => {
   const queryParts = [];
   if (isNumber(imageSpec.minWidth)) {
      queryParts.push(`(min-width: ${imageSpec.minWidth}px)`);
   }
   if (isNumber(imageSpec.maxWidth)) {
      queryParts.push(`(max-width: ${imageSpec.maxWidth}px)`);
   }
   return queryParts.join(" and ");
};

export const createSourceSet = (
   imageSpecs: ImageSpec[],
   urlFactory: (width: number, height: number) => string
): SourceSetItem[] =>
   imageSpecs
      .map((imageSpec) => ({
         width: imageSpec.width,
         height: imageSpec.height,
         media: createMediaQuery(imageSpec),
         url: urlFactory(imageSpec.width, imageSpec.height)
      }))
      .filter((item) => isString(item.url));

export const fromContentToImageWithFocus = (
   image: Entry<TypeImageWithFocusSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>
): ImageWithFocusData | undefined => {
   if (!image.fields.image || !image.fields.altText || !image.fields.focalPoint) {
      return undefined;
   }
   const focalPoint = image.fields.focalPoint as FocalPointData;
   return {
      entryId: image.sys.id,
      altText: image.fields.altText,
      focalPoint: focalPoint.focalPoint,
      originalAssetUrl: image.fields.image.fields.file?.url
   };
};

export const createCloudinaryUrl = (
   image: ImageWithFocusData,
   width: number,
   height: number,
   cloudinaryMapping: string
): string | undefined => {
   const validWidth = Math.round(width);
   const validHeight = Math.round(height);
   if (image.focalPoint === null) {
      console.warn(`Focal point is null, using default focal point for image ${image.entryId}`);
   }
   const validFocalPointX = image.focalPoint !== null ? Math.round(image.focalPoint.x) : validWidth / 2;
   const validFocalPointY = image.focalPoint !== null ? Math.round(image.focalPoint.y) : validHeight / 2;
   const cloudiaryBaseUrl = "https://res.cloudinary.com/tine-products/image/upload";
   const transformations = `c_fill,g_xy_center,w_${validWidth},h_${validHeight},x_${validFocalPointX},y_${validFocalPointY}/f_auto`;
   const filepath = image.originalAssetUrl?.replace(`//images.ctfassets.net/${CONTENTFUL_SPACE}/`, "");

   return `${cloudiaryBaseUrl}/${transformations}/${cloudinaryMapping}/${filepath}`;
};
