import { Grid, Group } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import { type Day as DFDay, getWeeksInMonth } from "date-fns";
import { type CSSProperties, useMemo } from "react";

import type { ArrowDirection, DayFlags, MonthAndYear } from "../../../common/types/calendarTypes";
import { weekDayNames } from "../../../common/utils";

import Week from "./Week";

export type MonthProps = {
   showWeekNumber?: boolean;
   currentMonthOnly?: boolean;
   weekStartsOn?: DFDay;
   size?: "compact" | "regular";
   getDayFlags: (date: Date) => DayFlags;
   onDayClicked?: (date: Date, element: HTMLElement) => void;
   onArrowKeyDown?: (direction: ArrowDirection) => void;
   focusedDate?: Date;
} & MonthAndYear;

const Month = ({
   month,
   year,
   showWeekNumber,
   weekStartsOn = 1,
   currentMonthOnly = false,
   getDayFlags,
   size = "compact",
   onDayClicked = () => {},
   onArrowKeyDown,
   focusedDate
}: MonthProps) => {
   const weekCount = useMemo(() => getWeeksInMonth(new Date(year, month, 1), { weekStartsOn }), [weekStartsOn, month, year]);

   const weekIndices = useMemo(() => Array.from(Array(weekCount).keys()), [weekCount]);

   const days = useMemo(() => weekDayNames.map((_n, i, arr) => arr[(i + weekStartsOn) % 7]), [weekIndices, weekStartsOn]);

   const dayOfWeekCN = cn("tw-text-center", {
      "tw-text-sm": size === "compact"
   });

   const style: CSSProperties & Record<string, string> = {
      "--button-border-radius": "0.25rem"
   };
   return (
      <Group className="tw-items-start" style={style}>
         <Grid cols={showWeekNumber ? 8 : 7} gap={0}>
            {showWeekNumber && <div />}
            {days.map((day) => (
               <div key={`day-${day}-month-${month}-year-${year}`} className={dayOfWeekCN}>
                  {day}
               </div>
            ))}
            {weekIndices.map((weekIndex) => (
               <Week
                  key={`week-${weekIndex}-${month}-${year}`}
                  weekIndex={weekIndex}
                  month={month}
                  year={year}
                  size={size}
                  getDayFlags={getDayFlags}
                  showWeekNumber={showWeekNumber}
                  currentMonthOnly={currentMonthOnly}
                  weekStartsOn={weekStartsOn}
                  onDayClicked={onDayClicked}
                  isArrowFocus={!!onArrowKeyDown}
                  focusedDate={focusedDate}
               />
            ))}
         </Grid>
      </Group>
   );
};

export default Month;
