import { view } from "@risingstack/react-easy-state";
import { IconShoppingCart } from "@tine/designsystem-icons/sharp";
import { Button } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import isFunction from "lodash-es/isFunction";
import isNil from "lodash-es/isNil";

import { sendAddToCart } from "../common/tracking";
import type { Product, ProductTileCallbacks } from "../common/types/productTypes";
import type { GAListInformation } from "../common/types/trackingTypes";

import type { RefObject } from "react";
import authStore from "../stores/auth/authStore";
import cartStore from "../stores/cart/cartStore";
import uiStore from "../stores/uiStore";

type AddToCartButtonProps = {
   product: Product;
   qty: number;
   callbacks: ProductTileCallbacks;
   listInformation: GAListInformation;
   position: number;
   imageRefFn: () => RefObject<HTMLImageElement> | null;
   className?: string;
   size?: "iconOnly" | "regular";
};

const AddToCartButton = ({
   product,
   qty,
   callbacks,
   listInformation,
   position,
   imageRefFn,
   className,
   size = "iconOnly"
}: AddToCartButtonProps) => {
   const addToCart = () => {
      if (authStore.isLoggedIn()) {
         const imageRef = imageRefFn();
         if (cartStore.addToCart(product.sku, qty)) {
            // If the add to cart action is successful
            sendAddToCart([{ sku: product.sku, qty, position }], listInformation);
            if (!isNil(imageRef)) {
               uiStore.addFlyingProduct(product.sku, imageRef);
            }

            // Allow other consumers to insert their own callback
            if (!isNil(callbacks) && isFunction(callbacks.addToCart)) {
               callbacks.addToCart(product, listInformation.name, position);
            }
         } else {
            console.warn("An unexpected error occured");
         }
      }
   };

   return (
      <Button
         size={size}
         className={cn("print:tw-hidden", className)}
         aria-label="Add to cart"
         onClick={addToCart}
         data-testid="add-to-cart-button"
         icon={<IconShoppingCart />}
      />
   );
};

export default view(AddToCartButton);
