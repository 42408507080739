import { view } from "@risingstack/react-easy-state";
import { Input } from "@tine/designsystem-ui-react";
import isNil from "lodash-es/isNil";
import { type FormEvent, useEffect, useState } from "react";
import isEmail from "validator/lib/isEmail";

import { isErrorOccured, isWaitingForData } from "../../../common/utils/asyncDataUtils";

import authStore from "../../../stores/auth/authStore";
import uiStore, { LOGIN_MODE } from "../../../stores/uiStore";

import Button from "../../Button";

const InputEmail = () => {
   const [email, setEmail] = useState("");
   const [invalidEmail, setInvalidEmail] = useState(false);
   const codeSent = !isNil(authStore.resetPasswordId.data);

   useEffect(() => {
      if (codeSent) {
         uiStore.switchLoginMode(LOGIN_MODE.RESET_PASSWORD_OTP);
      }
   }, [codeSent]);

   const onSubmit = async (event: FormEvent) => {
      event.preventDefault();
      setInvalidEmail(false);
      if (email.length === 0 || !isEmail(email)) {
         setInvalidEmail(true);
      } else {
         await authStore.requestResetPasswordOtp(email);
      }
   };

   return (
      <>
         <p>Fyll inn e-postadresse for å motta engangskode over e-post.</p>
         <form noValidate onSubmit={onSubmit} className="tw-flex tw-flex-col tw-gap-6">
            <Input
               label="E-postadresse"
               type="email"
               value={email}
               onChange={(e) => setEmail(e.target.value)}
               autoFocus
               disabled={isWaitingForData(authStore.resetPasswordId)}
               showErrorMessage={invalidEmail || isErrorOccured(authStore.resetPasswordId)}
               errorMessage={
                  isErrorOccured(authStore.resetPasswordId)
                     ? "Vi fant ingen bruker med denne e-postadressen"
                     : "E-postadressen er ikke gyldig."
               }
            />

            <Button variant="primary" size="regular" type="submit" loading={isWaitingForData(authStore.resetPasswordId)}>
               Send engangskode
            </Button>
         </form>
      </>
   );
};

export default view(InputEmail);
