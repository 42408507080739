import { jsx as p, jsxs as u } from "react/jsx-runtime";
import { cn as a } from "./designsystem-ui-react46.js";
function d({
  children: i,
  icon: t = null,
  iconPosition: e = "right",
  className: l,
  as: s = "button",
  checked: r = !1,
  onClick: w,
  disabled: o,
  ...b
}) {
  const n = !!t;
  return /* @__PURE__ */ p(
    s,
    {
      ...b,
      "aria-pressed": r,
      className: a(
        // common
        "tw-bg-chip-default tw-rounded-full tw-p-2 tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-min-w-20",
        // Border
        "tw-border-button-primary-bg",
        // Hover
        "hover:tw-bg-chip-default-hover",
        // for hasIcon
        n && "tw-no-underline [&>svg]:tw-ring",
        // for checked
        r && "tw-bg-chip-active tw-text-base-0 hover:tw-bg-chip-active-hover",
        // for disabled
        o && "tw-bg-button-disabled-bg tw-text-ink-disabled hover:tw-bg-button-disabled-bg",
        // for override
        l
      ),
      onClick: w,
      children: /* @__PURE__ */ u("span", { className: a(n && "tw-flex tw-items-center tw-gap-x-1"), children: [
        e === "left" ? t : null,
        i,
        e === "right" ? t : null
      ] })
    }
  );
}
d.displayName = "Chip";
export {
  d as Chip
};
