import { useContentfulInspectorMode } from "@contentful/live-preview/react";
import { cn } from "@tine/designsystem-utils";
import { Helmet } from "react-helmet";

import type { ContentfulArticle, ContentfulImageWithFocus, EmbeddedEntryContextType } from "../../common/types/cmsTypes";

import theme from "../../themes/theme";
import ContentBreadcrumb from "./ContentBreadcrumb";
import ImageWithFocus from "./ImageWithFocus";
import RichTextRenderer from "./RichTextRenderer";
import { cmsTextColumnClassNames } from "./RichTextRendererHelpers";

type ArticleProps = {
   id: string;
   article: ContentfulArticle;
};

const headerContainer = "tw-flex tw-flex-col tw-relative tw-items-center";
const lgThreeQuartersCentered = "lg:tw-w-3/4 lg:tw-mx-auto";

const titleColumn = "tw-px-3 tw-bg-[--color-body-bg] lg:tw-rounded-t-lg";
const prefaceFont = "tw-text-xl tw-leading-relaxed tw-tracking-normal";

const StandardArticleHeader = ({ article, id }: ArticleProps) => {
   const inspectorProps = useContentfulInspectorMode();
   const headerImage = article.fields.headerImage as ContentfulImageWithFocus;
   const titleContainer = cn(
      "tw-flex tw-flex-wrap tw-relative lg:tw-w-max-[1360px] lg:tw-absolute lg:tw-left-0 lg:tw-right-0 lg:tw-bottom-0",
      { "lg:tw-relative": !headerImage }
   );
   return (
      <>
         <ContentBreadcrumb />
         <div className={headerContainer}>
            <div>
               {headerImage && (
                  <ImageWithFocus
                     width={1250}
                     ratio={"21x9"}
                     key={JSON.stringify(headerImage)}
                     entryId={headerImage.sys.id}
                     placement="aboveFold"
                     className="tw-rounded-md"
                     {...inspectorProps({
                        entryId: id,
                        fieldId: "headerImage"
                     })}
                     imageFromParent={headerImage}
                  />
               )}
            </div>
            <div className={titleContainer}>
               <div className={cn("tw-flex tw-flex-col", titleColumn, lgThreeQuartersCentered)}>
                  <h1
                     className="tw-px-2 tw-pt-12 tw-text-center tw-text-4xl"
                     {...inspectorProps({
                        entryId: id,
                        fieldId: "title"
                     })}
                  >
                     {article.fields.title}
                  </h1>
               </div>
            </div>
         </div>

         <div className={cn("tw-flex tw-flex-col", cmsTextColumnClassNames.sevenTwelfths)}>
            <p
               className={cn("tw-mb-10 tw-px-2 tw-py-6 tw-text-center", prefaceFont)}
               {...inspectorProps({
                  entryId: id,
                  fieldId: "preface"
               })}
            >
               {article.fields.preface}
            </p>
         </div>
      </>
   );
};

const SimplifiedArticleHeader = ({ article, id }: ArticleProps) => {
   const inspectorProps = useContentfulInspectorMode();

   return (
      <>
         <h1
            {...inspectorProps({
               entryId: id,
               fieldId: "title"
            })}
         >
            {article.fields.title}
         </h1>

         {article.fields.preface && (
            <p
               className={cn("tw-mb-6 tw-py-6", prefaceFont)}
               {...inspectorProps({
                  entryId: id,
                  fieldId: "preface"
               })}
            >
               {article.fields.preface}
            </p>
         )}
      </>
   );
};

const Article = ({ article, id }: ArticleProps) => {
   // Defaults to false, unless a parent is wraps this component with <BodyOnlyContext.Provider value={true} />
   const articleType = article.fields.articleType;

   const articleVariant =
      articleType === "Uten bilde, header og ingress" || articleType === "Enkel tittel og ingress" ? "simple" : "standard";

   const entryContext: EmbeddedEntryContextType = articleVariant === "simple" ? "simple-article" : "standard-article";
   const seoMetaDescription = article.fields.metaDescription || article.fields.preface;
   const title = article.fields.metaTitle || `${article.fields.title} - ${theme.siteName}`;

   return (
      <>
         <Helmet>
            <title>{title}</title>
            {!!seoMetaDescription && <meta name="description" content={seoMetaDescription} />}
         </Helmet>
         <div className={cn({ "lg:tw-px-[--article-px]": articleVariant === "standard" })}>
            {articleType === "Enkel tittel og ingress" && <SimplifiedArticleHeader article={article} id={id} />}
            {articleVariant === "standard" && <StandardArticleHeader article={article} id={id} />}

            <div>
               <RichTextRenderer
                  textColumnWidth="threeQuarters"
                  variant={articleVariant}
                  document={article.fields.body}
                  context={entryContext}
                  trackingLocationName="Article"
               />
            </div>
         </div>
      </>
   );
};

export default Article;
