import Bugsnag from "@bugsnag/browser";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import includes from "lodash-es/includes";
import isNil from "lodash-es/isNil";
import React from "react";

import authStore from "./js/stores/auth/authStore";
import uiStore from "./js/stores/uiStore";

import "./js/common/i18n";

import { IconCheckCircle } from "@tine/designsystem-icons/sharp";
import { createRoot } from "react-dom/client";

import App from "./js/App";
import { APP_VERSION, BUGSNAG_ID, BUGSNAG_STAGE } from "./js/common/environment";
import Button from "./js/components/Button";
import ErrorAlertWithOptionalTracking from "./js/components/ErrorAlertWithOptionalTracking";
import theme from "./js/themes/theme";

/**
 * Add global error reporting for Bugsnag, adds additional context to the report before sending
 */
Bugsnag.start({
   apiKey: BUGSNAG_ID ?? "",
   plugins: [new BugsnagPluginReact()],
   releaseStage: BUGSNAG_STAGE,
   appVersion: APP_VERSION,
   enabledBreadcrumbTypes: ["error", "log", "manual", "navigation", "process", "request", "state"],
   onError: (event) => {
      console.log("Decorating error report for Bugsnag...", event.originalError);
      if (authStore.isLoggedIn() && !isNil(authStore.user) && !isNil(authStore.currentCompany)) {
         event.setUser(authStore.currentCompany, authStore.user.email, authStore.getCurrentCompany()?.name);
      }

      // Add data to identify context for data used
      event.addMetadata("session", {
         loggedIn: authStore.isLoggedIn(),
         dataUsed: uiStore.dataVersion
      });

      // Add request data to error report if error contains such data
      if (!isNil(event.originalError?.response)) {
         const resp = event.originalError.response;
         console.log(`Error was related to a request, adding request context for ${resp.status} response`);
         event.addMetadata("fetch", {
            url: resp.url,
            status: resp.status,
            statusText: resp.statusText,
            success: resp.ok,
            body: event.originalError.text
         });
      }
   }
});

const bugSnagReactPlugin = Bugsnag.getPlugin("react");
if (isNil(bugSnagReactPlugin)) {
   throw new Error("Unabe to ");
}
const BugsnagErrorBoundary = bugSnagReactPlugin.createErrorBoundary(React);

const UpgradeHapppened = () => (
   <>
      <h2>
         <IconCheckCircle className="tw-inline tw-fill-success-600" /> En ny versjon av {theme.siteName} er tilgjengelig!
      </h2>
      <p>Trykk på knappen under for å laste inn den nye versjonen og fortsette handleturen.</p>
      <Button onClick={() => window.location.reload()}>Fortsett</Button>
   </>
);

const UnexpectedError = () => (
   <ErrorAlertWithOptionalTracking
      showCloseButton={false}
      fullWidth
      title="Det har oppstått en uforutsett feil!"
      gaErrorTracking={{ context: "site", text: "generic_site_error" }}
   >
      <p>Du kan prøve å trykke på knappen under for å starte om websiden.</p>
      <p>
         Feilen har blitt automatisk rapportert inn til {theme.storeCompanyName}, så vi kommer til å undersøke problemet nærmere
         for å se om vi kan unngå at dette skjer i fremtiden.
      </p>
      <Button variant="primary" onClick={() => window.location.reload()}>
         Start om siden
      </Button>
   </ErrorAlertWithOptionalTracking>
);

type SuggestedActionProps = {
   error: Error | null | undefined;
};

const SuggestedAction = ({ error }: SuggestedActionProps) => (
   <div className="tw-m-12 tw-flex tw-items-center tw-justify-center">
      <div className="tw-w-full tw-bg-surface-default tw-p-12 tw-shadow">
         {includes(error?.message, "Failed to fetch dynamically imported module") ? <UpgradeHapppened /> : <UnexpectedError />}
      </div>
   </div>
);

const el = document.getElementById("root");
if (el) {
   const root = createRoot(el);
   root.render(
      <BugsnagErrorBoundary FallbackComponent={SuggestedAction}>
         <App />
      </BugsnagErrorBoundary>
   );
}
