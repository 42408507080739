import i18next from "i18next";
import HttpBackend, { type HttpBackendOptions } from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import LocalazyMeta from "../../localazy-meta";
import { NODE_ENV } from "./environment";
import { getLanguageFromStorage } from "./utils/storageUtils";

/** Help function needed to make i18n work with Localazy */
export const getKeyPlural = (key: string, count: number) => {
   const currentLanguage = LocalazyMeta.languages.find((l) => l.language === i18next.language);
   const pluralType = currentLanguage?.pluralType(+count);
   return `${key}.${pluralType}`;
};

export const getLangCodeFromLanguage = (language: { region: string; language: string }) => {
   if (language.region === "") {
      return language.language;
   }
   return `${language.language}-${language.region}`;
};

export const getSupportedLangCodes = () =>
   LocalazyMeta.languages.map((l) => {
      return getLangCodeFromLanguage(l);
   });

export const getBaseLanguage = () => LocalazyMeta.baseLocale;

export const getCurrentLanguage = () => {
   return getLanguageFromStorage() || "nb";
};

export const getLanguages = () => {
   return LocalazyMeta.languages;
};

i18next
   .use(initReactI18next)
   .use(HttpBackend)
   .init<HttpBackendOptions>({
      lng: getBaseLanguage(),
      fallbackLng: {
         nb: ["nb-NO"],
         en: ["en-US"]
      },
      debug: NODE_ENV !== "production",
      supportedLngs: getSupportedLangCodes(),
      resources: {},
      partialBundledLanguages: true,
      keySeparator: ".",
      interpolation: {
         escapeValue: false
      },
      load: "currentOnly",
      react: {
         useSuspense: false
      },
      backend: {
         loadPath:
            "https://delivery.localazy.com/_a765905005502057668266b33190/_e0/ed13368406891cf5fa833d4b09428a6c673a523a/{{lng}}/translations.json"
      }
   });

export default i18next;
