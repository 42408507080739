import { view } from "@risingstack/react-easy-state";

import useLoginRequired from "../../common/hooks/useLoginRequired";
import { isDataAvailable, isDefaultData, isErrorOccured, isWaitingForData } from "../../common/utils/asyncDataUtils";

import contentStore from "../../stores/cms/contentStore";
import segmentStore, { type ContentVisibility } from "../../stores/cms/segmentStore";

import ErrorAlertWithOptionalTracking from "../ErrorAlertWithOptionalTracking";
import NotFound from "../NotFound";
import NotLoggedIn from "../NotLoggedIn";
import CmsEmbeddedEntry from "./CmsEmbeddedEntry";

type CmsContentFetchWrapperProps = {
   id: string;
};

/**
 * When we want to show a Contentful content, this wrapper makes sure that
 * we load the desired content from Contentful into the store and shows
 * a skeleton until it is properly loaded.
 */
const CmsContentFetchWrapper = ({ id }: CmsContentFetchWrapperProps) => {
   const content = contentStore.fetchedContent[id];
   const articleVisibility: ContentVisibility = segmentStore.getArticleVisibility(id);

   console.log(`Fetch wrapper for ${id}`, content);

   useLoginRequired(
      content.type === "v2_article" && articleVisibility === "requiresLogin" ? "SEGMENTED_CONTENT" : "NOT_REQUIRED"
   );

   if (content.type === "v2_article") {
      if (articleVisibility === "hidden") {
         return <NotFound />;
      }
      if (articleVisibility === "requiresLogin") {
         return <NotLoggedIn />;
      }
   }

   contentStore.fetchContentIfNotAlreadyInStore(id, content.type);

   if (isDefaultData(content.entry) || isWaitingForData(content.entry)) {
      return;
   }

   if (isDataAvailable(content.entry)) {
      return <CmsEmbeddedEntry entryId={id} content={content.entry.data} type={content.type} context="content" />;
   }

   if (isErrorOccured(content.entry)) {
      return (
         <ErrorAlertWithOptionalTracking
            showCloseButton={false}
            gaErrorTracking={{ context: "cms_content", text: "fetch_content_error" }}
         >
            Det oppsto en feil under henting av innholdet
         </ErrorAlertWithOptionalTracking>
      );
   }

   return <NotFound />;
};

export default view(CmsContentFetchWrapper);
