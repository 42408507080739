import { cn } from "@tine/designsystem-utils";
import type { PropsWithChildren } from "react";

type WhiteSheetProps = {
   className?: string;
   padding?: "sm" | "md" | "lg" | "none";
};

const WhiteSheet = ({ className = "", padding = "md", children }: PropsWithChildren<WhiteSheetProps>) => {
   const whiteSheet = "tw-bg-surface-default tw-basis-0 tw-rounded-md";

   const sheetPadding = {
      sm: "tw-p-4",
      md: "tw-p-6",
      lg: "tw-p-8",
      none: ""
   }[padding];

   return <div className={cn(className, whiteSheet, sheetPadding)}>{children}</div>;
};

export default WhiteSheet;
