import { view } from "@risingstack/react-easy-state";
import { IconShoppingCart } from "@tine/designsystem-icons/sharp";
import { Button } from "@tine/designsystem-ui-react";

import { translateOrderType } from "../../common/utils";
import SlideFromSide from "../../components/common/slidefromside/SlideFromSide";

import authStore from "../../stores/auth/authStore";
import cartStore from "../../stores/cart/cartStore";

import theme from "../../themes/theme";
import MiniCartContent from "./MiniCartContent";
import MiniCartDates from "./MiniCartDates";
import MiniCartGoToCart from "./MiniCartGoToCart";

const MiniCart = () => {
   const itemCount = cartStore.items.reduce((s, i) => s + i.qty, 0);
   const isLoggedIn = authStore.isLoggedIn();

   return (
      <>
         <Button
            data-testid="minicart-button"
            variant={isLoggedIn && itemCount === 0 ? "secondary" : "primary"}
            size="full"
            id="mini-cart"
            onClick={cartStore.showMiniCart}
            disabled={!isLoggedIn}
            icon={<IconShoppingCart />}
         >
            <div className="tw-ml-1 tw-font-bold">{isLoggedIn ? itemCount : 0}</div>
         </Button>

         <SlideFromSide
            show={cartStore.miniCartVisible}
            side="right"
            onHide={cartStore.hideMiniCart}
            renderHeader={() => (
               <>
                  <h2 className="tw-m-0 tw-px-1 tw-py-0 @[575px]:tw-px-2">
                     {translateOrderType(cartStore.orderType, theme.storeCompanyName)}
                  </h2>
               </>
            )}
            renderFooter={() => <MiniCartGoToCart />}
         >
            <div className="tw-flex tw-min-h-full tw-w-[min(90vw,576px)] tw-flex-col tw-@container">
               {authStore.isLoggedIn() && <MiniCartDates />}
               <MiniCartContent />
            </div>
         </SlideFromSide>
      </>
   );
};

export default view(MiniCart);
