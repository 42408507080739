import { view } from "@risingstack/react-easy-state";
import { Grid } from "@tine/designsystem-ui-react";
import { useState } from "react";
import type { JsonValue } from "type-fest";
import { Link as WouterLink } from "wouter";
import MainColumn from "../components/common/MainColumn";
import PhoneLink from "../components/common/PhoneLink";

import contentStore from "../stores/cms/contentStore";
import uiStore from "../stores/uiStore";

import { APP_VERSION } from "../common/environment";
import StoreLogoComponent from "../components/StoreLogoComponent";
import theme from "../themes/theme";
import ContentfulFooterLink, { type FooterLink } from "./ContentfulFooterLink";
import FooterDecorationImage from "./FooterDecorationImage";

const openingHourKey = (openingHour: JsonValue) =>
   openingHour &&
   typeof openingHour === "object" &&
   "key" in openingHour &&
   typeof openingHour.key === "string" && <div>{openingHour.key}</div>;

const openingHourValue = (openingHour: JsonValue) =>
   openingHour &&
   typeof openingHour === "object" &&
   "value" in openingHour &&
   typeof openingHour.value === "string" && <div>{openingHour.value}</div>;

const sectionClasses = "tw-flex tw-flex-col tw-gap-4";

const Footer = () => {
   const { siteName } = theme;
   const { dataVersion } = uiStore;

   const footerContent = contentStore.footerContent.data;
   const currentYear = new Date().getFullYear();

   const columnCustomerServiceLabel = footerContent?.fields.columnCustomerServiceLabel as string;
   const columnTwoLabel = footerContent?.fields.column2label as string;
   const columnTwoLinks = footerContent?.fields.column2links as FooterLink[];
   const columnThreeLabel = footerContent?.fields.column3label as string;
   const columnThreeLinks = footerContent?.fields.column3links as FooterLink[];
   const columnFourLabel = footerContent?.fields.column4label as string;
   const columnFourLinks = footerContent?.fields.column4links as FooterLink[];

   const termsAndConditionLinks = footerContent?.fields.termsAndConditions as FooterLink[];

   const [showVersionInfo, setShowVersionInfo] = useState<boolean>(false);

   return (
      <MainColumn>
         <div className="tw-flex tw-w-full tw-flex-col tw-px-2 lg:tw-px-0 print:tw-hidden">
            <Grid cols={12} gap={{ base: 4, lg: 12 }} className="tw-my-14">
               <Grid.Col span={{ base: 12, lg: 3, sm: 6 }} className={sectionClasses}>
                  <h3 className="tw-text-xl">{columnCustomerServiceLabel}</h3>
                  <ul className="tw-list-none tw-px-0">
                     {footerContent?.fields.openingHours &&
                        Array.isArray(footerContent.fields.openingHours) &&
                        footerContent.fields.openingHours?.map((openingHour, index) => (
                           <li key={`OpeningHourLine ${index + 1}`} className="tw-flex tw-gap-8 tw-pb-4">
                              {openingHourKey(openingHour)}
                              {openingHourValue(openingHour)}
                           </li>
                        ))}
                     <li>
                        <PhoneLink
                           className="tw-text-headerFooterText tw-underline hover:tw-underline"
                           telephoneNumber={footerContent?.fields.contactNumber || ""}
                        />
                     </li>
                  </ul>
               </Grid.Col>

               {columnTwoLinks && (
                  <Grid.Col span={{ base: 12, lg: 3, sm: 6 }} className={sectionClasses}>
                     <h3 className="tw-text-xl">{columnTwoLabel}</h3>
                     <ul className="tw-list-none tw-px-0">
                        {columnTwoLinks?.map((link) => (
                           <li key={link.sys.id} className="tw-pb-4">
                              <ContentfulFooterLink content={link} />
                           </li>
                        ))}
                     </ul>
                  </Grid.Col>
               )}

               {columnThreeLinks && (
                  <Grid.Col span={{ base: 12, lg: 3, sm: 6 }} className={sectionClasses}>
                     <h3 className="tw-text-xl">{columnThreeLabel}</h3>
                     <ul className="tw-list-none tw-px-0">
                        {columnThreeLinks?.map((link) => (
                           <li key={link.sys.id} className="tw-pb-4">
                              <ContentfulFooterLink content={link} />
                           </li>
                        ))}
                     </ul>
                  </Grid.Col>
               )}

               {columnFourLinks && (
                  <Grid.Col span={{ base: 12, lg: 3, sm: 6 }} className={sectionClasses}>
                     <h3 className="tw-text-xl">{columnFourLabel}</h3>
                     <ul className="tw-list-none tw-px-0">
                        {columnFourLinks?.map((link) => (
                           <li key={link.sys.id} className="tw-pb-4">
                              <ContentfulFooterLink content={link} />
                           </li>
                        ))}
                     </ul>
                  </Grid.Col>
               )}
            </Grid>
            <div className="tw-flex tw-w-full tw-flex-col">
               <FooterDecorationImage />
               <div className="-tw-mt-[1px] tw-flex tw-w-full tw-flex-col tw-justify-between tw-gap-6 tw-border-t tw-border-solid tw-pt-6 lg:tw-flex-row lg:tw-text-right">
                  <div className="tw-flex tw-flex-col tw-gap-6 lg:tw-flex-row">
                     {termsAndConditionLinks?.map((termsAndConditionLink) => (
                        <ContentfulFooterLink key={termsAndConditionLink.sys.id} content={termsAndConditionLink} />
                     ))}
                  </div>
                  <div className="tw-relative">
                     <button type="button" className="tw-cursor-default" onClick={() => setShowVersionInfo(!showVersionInfo)}>
                        &copy; {currentYear} {siteName}
                     </button>
                     {showVersionInfo && (
                        <span
                           className="tw-absolute tw-left-0 tw-top-8 tw-cursor-pointer tw-text-nowrap tw-text-sm lg:tw-left-auto lg:tw-right-0"
                           onClick={() => uiStore.checkDataVersion(true)}
                        >
                           d.{dataVersion} | b.{APP_VERSION}
                        </span>
                     )}
                  </div>
               </div>

               <div className="tw-mb-14 tw-mt-12 tw-flex tw-justify-center">
                  <WouterLink to="/">
                     <StoreLogoComponent placement="footer" />
                  </WouterLink>
               </div>
            </div>
         </div>
      </MainColumn>
   );
};

export default view(Footer);
