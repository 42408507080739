import { IconDownload, IconOpenInNew } from "@tine/designsystem-icons/sharp";
import { Link, type LinkProps } from "@tine/designsystem-ui-react";
import type { PropsWithChildren } from "react";
import { Link as WouterLink } from "wouter";

import { sendInternalLinkClick } from "../common/tracking";

interface InternalLinkProps extends PropsWithChildren<LinkProps> {
   trackingLocationName?: string;
   onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const InternalLink = ({
   href = "",
   variant = "default",
   children,
   icon = null,
   iconPosition = "left",
   className = "",
   trackingLocationName,
   onClick,
   ...props
}: InternalLinkProps) => {
   const linkName = children?.toString();
   const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      sendInternalLinkClick(href, trackingLocationName, linkName);
      if (typeof onClick === "function") {
         onClick(e);
      }
   };

   return (
      <WouterLink href={href} {...props} asChild onClick={handleClick}>
         <Link
            as="a"
            className={className}
            variant={variant}
            icon={icon}
            iconPosition={iconPosition}
            aria-label={`Gå til ${href}`}
         >
            {children}
         </Link>
      </WouterLink>
   );
};

export const ExternalLink = ({
   href = "",
   variant = "default",
   children,
   className = "",
   ...props
}: PropsWithChildren<LinkProps>) => {
   return (
      <Link
         href={href}
         as="a"
         target="_blank"
         rel="noopener noreferrer"
         icon={<IconOpenInNew />}
         className={className}
         variant={variant}
         iconPosition="right"
         aria-label={`Gå til ${href}`}
         {...props}
      >
         {children}
      </Link>
   );
};

export const DownloadLink = ({
   href = "",
   variant = "default",
   children,
   className = "",
   ...props
}: PropsWithChildren<LinkProps>) => {
   return (
      <Link
         href={href}
         target="_blank"
         rel="noopener noreferrer"
         className={className}
         variant={variant}
         icon={<IconDownload />}
         iconPosition="right"
         aria-label={`Last ned ${href}`}
         {...props}
      >
         {children}
      </Link>
   );
};
