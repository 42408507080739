import { isNil, isUndefined, keyBy, sumBy } from "lodash-es";

import type { CartItem, CartTotals } from "../../common/types/productTypes";

import productStore from "../product/productStore";
import { isDistributionPack } from "../product/productUtils";

export const getCartTotal = (cartItems: CartItem[], beforeDiscount: boolean) => {
   const skusInCart = cartItems.map((i) => i.sku);
   const productsIncart = productStore.resolveSkus(skusInCart);
   const productsBySku = keyBy(productsIncart, "sku");
   const productPrice = (sku: string): number => {
      const product = productsBySku[sku];
      if (beforeDiscount) {
         return !isNil(product) && !isNil(product.priceBeforeDiscount) ? product.priceBeforeDiscount : 0;
      }
      return !isNil(product) && !isNil(product.price) ? product.price : 0;
   };

   return {
      total: sumBy(cartItems, (i) => i.qty * productPrice(i.sku)),
      recyclingCharge: sumBy(cartItems, (i) => {
         if (isUndefined(productsBySku[i.sku])) {
            return 0;
         }
         const item = productsBySku[i.sku];
         return (isDistributionPack(i.unit) ? item.conversionFactor * i.qty : i.qty) * item.recyclingChargeBaseUnit;
      })
   } satisfies CartTotals;
};
