import { batch } from "@risingstack/react-easy-state";
import accessStore from "../accessStore";
import availabilityStore from "../availabilityStore";
import barcodeStore from "../barcodeStore";
import categoryStore from "../categories/categoryStore";
import claimsStore from "../claims/claimsStore";
import contactFormStore from "../claims/contactFormStore";
import contentStore from "../cms/contentStore";
import segmentStore from "../cms/segmentStore";
import deliveryDatesStore from "../deliveryDates/deliveryDatesStore";
import deliveryWindowStore from "../deliveryDates/deliveryWindowStore";
import deliveryFeeStore from "../deliveryFeeStore";
import favoriteStore from "../favoriteStore";
import featuresStore from "../features/featuresStore";
import invoiceStore from "../invoiceStore";
import notificationsStore from "../notifications/notificationsStore";
import recipientsStore from "../notifications/recipientsStore";
import orderStore from "../orders/orderStore";
import powerBiStore from "../powerBIStore";
import productStore from "../product/productStore";
import promotionStore from "../promotionStore";
import recommendationStore from "../recommendationStore";
import searchStore from "../searchStore";
import subscriptionStore from "../subscriptionStore";
import uiStore from "../uiStore";
import authStore from "./authStore";

export const clearAllCompanySpecificStoreData = () => {
   batch(() => {
      authStore.clearCompanySpecificData();
      invoiceStore.clearCompanySpecificData();
      promotionStore.clearCompanySpecificData();
      recommendationStore.clearCompanySpecificData();
      accessStore.clearCompanySpecificData();
      productStore.clearCompanySpecificData();
      deliveryDatesStore.clearCompanySpecificData();
      recipientsStore.clearCompanySpecificData();
      notificationsStore.clearCompanySpecificData();
      claimsStore.clearCompanySpecificData();
      favoriteStore.clearCompanySpecificData();
      segmentStore.clearCompanySpecificData();
      featuresStore.clearCompanySpecificData();

      deliveryWindowStore.clearCompanySpecificData();
      searchStore.clearCompanySpecificData();
      contactFormStore.clearCompanySpecificData();
      availabilityStore.clearCompanySpecificData();
      orderStore.clearCompanySpecificData();
      categoryStore.clearCompanySpecificData();
      deliveryFeeStore.clearCompanySpecificData();
      uiStore.clearCompanySpecificData();
      contentStore.clearCompanySpecificData();
      subscriptionStore.clearCompanySpecificData();
      powerBiStore.clearCompanySpecificData();
      barcodeStore.clearCompanySpecificData();
   });
};
