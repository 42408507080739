import { useContext, useEffect } from "react";

import { FormContext } from "../FormContext";

const useRegisterfield = <T,>(
   name: string,
   initialValue: T,
   validator: (value: T, required: boolean) => string,
   required: boolean
) => {
   const { registerField, unregisterField } = useContext(FormContext);
   useEffect(() => {
      registerField({
         name: name,
         value: initialValue,
         error: validator(initialValue, required)
      });

      return () => {
         unregisterField(name);
      };
   }, []);
};
export default useRegisterfield;
