import { Modal as KremModal, type ModalProps as KremModalProps } from "@tine/designsystem-ui-react";
import { useEffect } from "react";

import { sendModal } from "../common/tracking";

const Modal = ({
   name,
   title,
   TitleIcon,
   isOpen,
   onClose,
   size,
   zIndex,
   children,
   ...other
}: KremModalProps & { name: string }) => {
   useEffect(() => {
      if (!isOpen || !name) return;
      sendModal(name);
   }, [isOpen, name]);

   return (
      <KremModal TitleIcon={TitleIcon} isOpen={isOpen} title={title} onClose={onClose} size={size} zIndex={zIndex} {...other}>
         {children}
      </KremModal>
   );
};

export default Modal;
