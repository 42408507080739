import { view } from "@risingstack/react-easy-state";
import { Link, useLocation } from "wouter";

import { DEFAULT_LOCALE, type SupportedLocaleType } from "../../common/types/languageTypes";
import { type AlgoliaCmsContentHit, SEARCH_TYPE, type SearchDomain } from "../../common/types/searchTypes";
import { getLocalizedString } from "../../common/utils/searchUtils";

import contentStore from "../../stores/cms/contentStore";
import searchStore from "../../stores/searchStore";

import type { ReactNode } from "react";
import ImageWithFocus from "../cms/ImageWithFocus";
import RecipeImage from "../cms/recipe/RecipeImage";

const hide = () => searchStore.clear(SEARCH_TYPE.QuickSearch, true);

type CmsContentHitProps = {
   hit: AlgoliaCmsContentHit;
   position: number;
   locale: SupportedLocaleType;
   searchDomain: SearchDomain;
   queryID: string;
};

const CmsContentHit = ({ hit, position, locale, searchDomain, queryID }: CmsContentHitProps) => {
   const [location] = useLocation();
   const articleUrlData = contentStore.urlData.data.find((data) => data.id === hit.objectID);
   if (!articleUrlData) {
      return null;
   }
   let image: ReactNode;
   let displayTitle = getLocalizedString(hit.fields?.title || {}, locale);
   const articleUrl = `/${articleUrlData.fullUrl}`;
   if (hit.fields && "headerImage" in hit.fields) {
      const imageId = hit.fields?.headerImage?.[DEFAULT_LOCALE]?.sys.id;
      image = <ImageWithFocus className="rounded-2" placement="aboveFold" width={100} ratio="16x9" entryId={imageId} />;
   }
   if (
      hit.fields &&
      "recipeData" in hit.fields &&
      hit.fields.recipeData &&
      hit.fields.recipeData[DEFAULT_LOCALE] &&
      hit.fields.recipeData[DEFAULT_LOCALE].image
   ) {
      displayTitle = hit.fields.recipeData[DEFAULT_LOCALE].displayName || displayTitle;
      const recipeImage = hit.fields.recipeData[DEFAULT_LOCALE].image;
      if (recipeImage.url && recipeImage.title) {
         image = (
            <RecipeImage
               className="tw-rounded-md"
               placement="aboveFold"
               width={100}
               ratio="16x9"
               url={recipeImage.url}
               title={recipeImage.title}
            />
         );
      }
   }

   const handleClick = (id: string, position: number, url: string) => {
      searchStore.reportAlgoliaClick(id, searchDomain.indexName, queryID, position, true, SEARCH_TYPE.QuickSearch);
      searchStore.reportArticleViewed(id, searchDomain.indexName, queryID);
      if (url === location) {
         hide();
      }
   };
   return (
      <div className="tw-flex tw-items-center tw-justify-between tw-px-1 tw-py-2 hover:tw-bg-[--search-quick-results-item-bg-hover] lg:tw-pr-6">
         <Link
            to={articleUrl}
            onClick={() => handleClick(hit.objectID, position, articleUrl)}
            className="tw-flex tw-items-center"
         >
            {!!image && <div className="tw-min-w-20 tw-max-w-20">{image}</div>}
            <div className="tw-mx-2 tw-grow tw-overflow-ellipsis">{displayTitle}</div>
         </Link>
      </div>
   );
};

export default view(CmsContentHit);
