import { view } from "@risingstack/react-easy-state";

import type { WidthRanges } from "../../common/hooks/useContainerWidth";

import contentStore from "../../stores/cms/contentStore";
import segmentStore from "../../stores/cms/segmentStore";

import UrlImageLink from "./UrlImageLink";

type EntryImageLinkProps = {
   id: string;
   type: "article" | "recipe";
   imageWithFocusId?: string;
   title?: string;
   subTitle?: string;
   trackingListName?: string;
};

type BreakpointSize = "xs" | "small" | "medium" | "large";

const ranges: WidthRanges<BreakpointSize> = {
   xs: [0, 360],
   small: [361, 500],
   medium: [501, 700],
   large: [701]
};

const EntryImageLink = ({ id, type, imageWithFocusId, title, subTitle, trackingListName }: EntryImageLinkProps) => {
   if (!segmentStore.isArticleVisible(id)) {
      return null;
   }

   if (!imageWithFocusId) {
      return null;
   }

   const urlData = contentStore.urlData.data.find((urlData) => urlData.id === id);
   const url = `/${urlData?.fullUrl}`;

   return (
      <UrlImageLink
         url={url}
         imageWithFocusId={imageWithFocusId}
         title={title}
         subTitle={subTitle}
         trackingListName={trackingListName}
      />
   );
};

export default view(EntryImageLink);
