import { store } from "@risingstack/react-easy-state";
import { isEmptyArray } from "@tine/designsystem-utils";
import debounce from "lodash-es/debounce";
import isEmpty from "lodash/isEmpty";

import { apiClient } from "../common/apiClient";
import { ensureError } from "../common/errors";
import { ORDERTYPE } from "../common/types/productOrderTypes";
import type { ApplicablePromotion, AvailablePromotion, PromotionDiscount, SignUpType } from "../common/types/promotionTypes";
import {
   type AsyncData,
   initializeWithDefaultData,
   setAsDataAvailable,
   setAsErrorOccured,
   setAsWaitingForData
} from "../common/utils/asyncDataUtils";

import { API_HOST, DYNAMICS_INGESTION_KEY } from "../common/environment";
import type { BaseStoreType } from "../common/types/BaseStoreType";
import type { FormValuesToSubmit } from "../components/formvalidation/FormTypes";
import theme from "../themes/theme";
import authStore from "./auth/authStore";
import cartStore from "./cart/cartStore";
import featuresStore from "./features/featuresStore";
import productStore from "./product/productStore";
import toastStore from "./toastStore";

type PromotionStore = BaseStoreType & {
   availablePromotions: AsyncData<AvailablePromotion[]>;
   promotionDiscounts: AsyncData<PromotionDiscount[]>;

   signUps: AsyncData<SignUpType[]>;

   isOrderTypeWithPromotions(): boolean;
   fetchAvailablePromotions(): Promise<void>;
   fetchPromotionDiscounts(): void;
   fetchPromotionDiscountsWithoutDelay(): Promise<void>;
   getPromotionsBySku(sku: string): ApplicablePromotion[];
   getPromotionBannerById(promotionId: string): string | undefined;
   cartToVoucherifyItems(): { sku: string; qty: number; price: number }[];
   hasPromotionDiscount(sku: string): boolean;
   getPromotionDiscount(sku: string): number;
   getTotalPromotionDiscount(promotionId: string): number;
   getTotalPromotionDiscountForCart(): number;
   fetchSignUps(): Promise<void>;
   isSignedUpTo(promotionId: string): boolean;
   signUpToPromotion(
      promotionId: string,
      voucherifyEventName: string,
      dynamicsEventId: string,
      additionalFields: FormValuesToSubmit
   ): Promise<void>;
   clearDiscounts(): void;
};

const promotionStore: PromotionStore = store({
   availablePromotions: initializeWithDefaultData([]),
   promotionDiscounts: initializeWithDefaultData([]),
   signUps: initializeWithDefaultData([]),

   isOrderTypeWithPromotions: () => {
      return cartStore.orderType === theme.defaultOrderType || cartStore.orderType === ORDERTYPE.HPN;
   },

   fetchAvailablePromotions: async () => {
      if (!featuresStore.voucherifyAvailable || !promotionStore.isOrderTypeWithPromotions()) {
         return;
      }

      const customerNumber = authStore.currentCompany;
      if (!customerNumber) {
         console.warn("PROMOTIONS - Unable to fetch promotions when you are not logged in");
         return;
      }

      console.log(`PROMOTIONS - Fetching available promotions for ${authStore.currentCompany}`);
      const abortController = setAsWaitingForData(promotionStore.availablePromotions);

      try {
         const promotionsUrl = `${API_HOST}/api/discount/${theme.storeId}/${customerNumber}/availablePromotions`;
         const promotions = await apiClient(promotionsUrl, authStore.getSessionToken()).signal(abortController).get().json();

         console.log("PROMOTIONS - Promotions available: ", promotions);
         setAsDataAvailable(promotionStore.availablePromotions, promotions);
      } catch (returnObj: unknown) {
         const error = ensureError(returnObj);
         console.warn("PROMOTIONS - An error occured while fetching available promotions", error.message);
         setAsErrorOccured(promotionStore.availablePromotions, error.message);
      }
   },

   getPromotionsBySku: (sku: string) => {
      const promotionsApplicableToSku = promotionStore.availablePromotions.data.filter((promotion) =>
         promotion.products.includes(sku)
      );

      if (isEmptyArray(promotionsApplicableToSku)) {
         return [];
      }

      const promotionDiscounts = promotionStore.promotionDiscounts.data;

      return promotionDiscounts.flatMap((promotionDiscount) => {
         const availablePromotion = promotionsApplicableToSku.find((promotion) => promotion.id === promotionDiscount.id);
         return availablePromotion
            ? [
                 {
                    id: availablePromotion.id,
                    banner: availablePromotion.banner,
                    applicable: promotionDiscount.applicable,
                    inapplicableReason: promotionDiscount.inapplicableReason
                 }
              ]
            : [];
      });
   },

   getPromotionBannerById: (promotionId: string) => {
      return promotionStore.availablePromotions.data.find((promotion) => promotion.id === promotionId)?.banner;
   },

   cartToVoucherifyItems: () => {
      const cart = cartStore.items;
      return cart.map((item) => {
         const product = productStore.resolveSku(item.sku);
         return {
            sku: item.sku,
            qty: item.qty,
            price: product?.price ?? 0
         };
      });
   },

   fetchPromotionDiscountsWithoutDelay: async () => {
      if (
         !featuresStore.voucherifyAvailable ||
         !promotionStore.isOrderTypeWithPromotions() ||
         promotionStore.availablePromotions.data.length === 0
      ) {
         return;
      }

      const abortController = setAsWaitingForData(promotionStore.promotionDiscounts);

      if (promotionStore.availablePromotions.data.length === 0) {
         console.log("PROMOTIONS - No need to check discounts when there are no available promotions, aborting...");
         setAsDataAvailable(promotionStore.promotionDiscounts, []);
         return;
      }

      const promotionDiscountsUrl = `${API_HOST}/api/discount/${theme.storeId}/${authStore.currentCompany}/promotionDiscounts`;
      try {
         const promotionIds = promotionStore.availablePromotions.data.map((promotion) => promotion.id);

         const promotionDiscounts = await apiClient(promotionDiscountsUrl, authStore.getSessionToken())
            .signal(abortController)
            .post({ items: promotionStore.cartToVoucherifyItems(), promotionIds })
            .json();

         console.log("PROMOTIONS - Discounts: ", promotionDiscounts);
         setAsDataAvailable(promotionStore.promotionDiscounts, promotionDiscounts);
      } catch (errorObj: unknown) {
         const error = ensureError(errorObj);
         console.warn("PROMOTIONS - An error occured while fetching promotion discounts", error.message);
         setAsErrorOccured(promotionStore.promotionDiscounts, error.message);
      }
   },

   fetchPromotionDiscounts: debounce(() => {
      void promotionStore.fetchPromotionDiscountsWithoutDelay();
   }, 500),

   hasPromotionDiscount: (sku: string) => {
      for (const promotion of promotionStore.promotionDiscounts.data) {
         if (!promotion.applicable) {
            continue;
         }

         if (promotion.items.some((item) => item.sku === sku)) {
            return true;
         }
      }
      return false;
   },

   getPromotionDiscount: (sku: string) => {
      let discountAmount = 0;
      for (const promotion of promotionStore.promotionDiscounts.data) {
         if (!promotion.applicable) {
            continue;
         }

         const discountInCurrentPromotion = promotion.items.find((item) => item.sku === sku);
         if (discountInCurrentPromotion) {
            discountAmount += discountInCurrentPromotion.discount;
         }
      }
      return discountAmount;
   },

   getTotalPromotionDiscount: (promotionId: string) => {
      const promotion = promotionStore.promotionDiscounts.data.find((promotion) => promotion.id === promotionId);
      const discountForItems = promotion?.items.reduce((acc, item) => acc + item.discount, 0) ?? 0;
      return discountForItems + (promotion?.orderLevelDiscount ?? 0);
   },

   getTotalPromotionDiscountForCart: () => {
      let totalDiscount = 0;
      for (const promotion of promotionStore.promotionDiscounts.data) {
         totalDiscount += promotionStore.getTotalPromotionDiscount(promotion.id);
      }
      return totalDiscount;
   },

   fetchSignUps: async () => {
      if (authStore.currentCompany === null) {
         console.warn("Unable to fetch signups when you are not logged in");
         return;
      }

      const abortController = setAsWaitingForData(promotionStore.signUps);
      const url = `${API_HOST}/api/discount/${theme.storeId}/${authStore.currentCompany}/signup`;

      return apiClient(url, authStore.getSessionToken())
         .signal(abortController)
         .get()
         .json((signUps: SignUpType[]) => {
            console.log("PROMOTIONS - Signed up to: ", signUps);
            setAsDataAvailable(promotionStore.signUps, signUps);
         })
         .catch((errObj) => {
            const error = ensureError(errObj);
            if (error instanceof DOMException && error.name === "AbortError") {
               console.log("Aborted fetch of signups");
            } else {
               console.warn("An error occured while fetching signups", error);
               setAsErrorOccured(promotionStore.signUps, error.message);
            }
         });
   },

   isSignedUpTo: (promotionId: string) => {
      if (!authStore.isLoggedIn() || authStore.currentCompany === null) {
         return false;
      }

      return promotionStore.signUps.data.some((signUp) => promotionId === signUp.signUpCode);
   },

   signUpToPromotion: async (
      promotionId: string,
      voucherifyEventName: string,
      dynamicsEventId: string,
      additionalData: FormValuesToSubmit
   ) => {
      if (authStore.currentCompany === null) {
         console.warn("Unable to sign up for promotions when you are not logged in");
         return;
      }

      const signUpApiUrl = `${API_HOST}/api/discount/${theme.storeId}/${authStore.currentCompany}/signup/${promotionId}`;
      try {
         await apiClient(signUpApiUrl, authStore.getSessionToken()).post(additionalData).text();
      } catch (errObj: unknown) {
         const error = ensureError(errObj);
         console.warn("An error occured while signing up for promotion", error.message);
         toastStore.addError("En uventet feil oppsto", "Vi greide ikke å melde deg på kampanjen.", {
            context: "promotions",
            text: "promotion_signup_error"
         });
         return;
      }
      // Update local version of signups
      void promotionStore.fetchSignUps();

      if (!isEmpty(voucherifyEventName)) {
         await apiClient(
            `${API_HOST}/api/discount/${theme.storeId}/${authStore.currentCompany}/event`,
            authStore.getSessionToken()
         )
            .query({ eventName: voucherifyEventName })
            .post()
            .json();
      }

      if (!isEmpty(dynamicsEventId) && window.msdynmkt && DYNAMICS_INGESTION_KEY) {
         window.msdynmkt.setUser({ authId: authStore.currentCompany });
         window.msdynmkt.trackEvent({
            name: dynamicsEventId,
            ingestionKey: DYNAMICS_INGESTION_KEY,
            version: "1.0.0"
         });
      }
   },
   clearDiscounts: () => {
      promotionStore.promotionDiscounts = initializeWithDefaultData([]);
   },

   clearCompanySpecificData() {
      promotionStore.signUps = initializeWithDefaultData([]);
      promotionStore.availablePromotions = initializeWithDefaultData([]);
      promotionStore.clearDiscounts();
   }
});

export default promotionStore;
