import { useMemo } from "react";

import i18n from "../i18n";
import type { SupportedLocaleType } from "../types/languageTypes";

const useSupportedLocale = (): SupportedLocaleType => {
   return useMemo(() => (i18n.language?.startsWith("en") ? "en-US" : "nb-NO"), [i18n.language]);
};

export default useSupportedLocale;
