import type { ContentfulSegment } from "../../common/types/cmsTypes";

import type { CustomerAttribute } from "./segmentStore";

export type PreviewSegment = {
   label: string;
   value: string;
   customerNumber?: string;
   chain?: string;
   customerGroup?: string;
   customerWarehouse?: string;
   punchoutCustomer?: boolean;
   notLoggedIn?: boolean;
};

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const extractSegmentsFromContent = (content: any): ContentfulSegment[] => {
   const segments: ContentfulSegment[] = [];
   const idsSeen = new Set<string>();
   const visited = new WeakSet<object>();

   // biome-ignore lint/suspicious/noExplicitAny: <explanation>
   function traverse(content: any): void {
      if (!content || typeof content !== "object") return;

      if (visited.has(content)) return;
      visited.add(content);

      // Look for segments
      if (content.sys?.contentType?.sys?.id === "segment" && !idsSeen.has(content.sys.id)) {
         idsSeen.add(content.sys.id);
         segments.push({
            sys: content.sys,
            fields: content.fields
         } as ContentfulSegment);
      }

      // Traverse arrays or objects
      if (Array.isArray(content)) {
         content.forEach(traverse);
      } else {
         for (const key in content) {
            if (Object.prototype.hasOwnProperty.call(content, key)) {
               traverse(content[key]);
            }
         }
      }
   }
   traverse(content);
   return segments;
};

export const getPreviewSegmentAttributes = (
   notLoggedIn: boolean | undefined,
   punchoutCustomer: boolean | undefined
): CustomerAttribute[] => {
   const attributes: CustomerAttribute[] = [];

   if (!notLoggedIn) {
      attributes.push("loggedIn");
   }
   if (punchoutCustomer) {
      attributes.push("punchoutCustomer");
   }
   return attributes;
};
