import { VideoPlayer } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";

import type { ContentfulYoutubeVideo } from "../../common/types/cmsTypes";
import { createCloudinaryUrl, fromContentToImageWithFocus } from "../../common/utils/imageUtils";

import theme from "../../themes/theme";
import RichTextRenderer from "./RichTextRenderer";

type CmsYoutubeProps = {
   content: ContentfulYoutubeVideo;
};

const TextAndYoutube = ({ content }: CmsYoutubeProps) => {
   const { fields } = content;
   const { text, videoPosition, ...fieldsWithoutTextAndPosition } = fields;
   const { youtubeId } = fieldsWithoutTextAndPosition;

   const videoContent: ContentfulYoutubeVideo = {
      fields: fieldsWithoutTextAndPosition,
      metadata: content.metadata,
      sys: content.sys
   };

   const className = cn("tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6", "lg:tw-flex-row lg:tw-items-start", {
      "lg:tw-flex-row": videoPosition === "Left",
      "lg:tw-flex-row-reverse": videoPosition === "Right"
   });

   const childClassName = "lg:tw-w-1/2";

   const videoClassName = cn(childClassName, "lg:tw-pt-1");
   return (
      <div className={className}>
         <div className={videoClassName}>{!!youtubeId && <CmsYoutubeContent content={videoContent} />}</div>
         <div className={childClassName}>{!!text && <RichTextRenderer document={text} context="youtube" />}</div>
      </div>
   );
};

const CmsYoutubeContent = ({ content }: CmsYoutubeProps) => {
   const { image, youtubeId } = content.fields;
   let poster: string | undefined;
   if (image) {
      const imageData = fromContentToImageWithFocus(image);
      poster = imageData
         ? createCloudinaryUrl(imageData, 1250, Math.round((1250 * 9) / 16), theme.contentImages.cloudinaryMapping)
         : "";
   }
   return <VideoPlayer videoId={youtubeId} poster={poster} />;
};

const CmsYoutube = ({ content }: CmsYoutubeProps) => {
   const { text, videoPosition } = content.fields;
   if (text && videoPosition) {
      return <TextAndYoutube content={content} />;
   }

   return (
      <div className="lg:tw-mx-auto lg:tw-w-2/3">
         <CmsYoutubeContent content={content} />
      </div>
   );
};

export default CmsYoutube;
