import { view } from "@risingstack/react-easy-state";
import { Input } from "@tine/designsystem-ui-react";
import { type FormEvent, useEffect, useState } from "react";

import { isErrorOccured, isWaitingForData } from "../../../common/utils/asyncDataUtils";

import authStore from "../../../stores/auth/authStore";
import uiStore, { LOGIN_MODE } from "../../../stores/uiStore";

import Button from "../../Button";
import ErrorAlertWithOptionalTracking from "../../ErrorAlertWithOptionalTracking";
import PasswordField from "../../PasswordField";

const InputOtp = () => {
   const [otp, setOtp] = useState("");
   const [invalidOtp, setInvalidOtp] = useState(false);

   const [newPassword, setNewPassword] = useState("");
   const [invalidPassword, setInvalidPassword] = useState(false);
   const resetSuccess = authStore.resetPasswordProgress.data;

   const onSubmit = async (event: FormEvent) => {
      event.preventDefault();
      setInvalidPassword(false);
      if (newPassword.length < 8) {
         setInvalidPassword(true);
      } else {
         await authStore.attemptResetPassword(otp, newPassword);
      }
   };

   function handleOtpChange(event: React.ChangeEvent<HTMLInputElement>) {
      setOtp(event.target.value);
      if (event.target.value.length === 6 && !Number.isNaN(Number(event.target.value))) {
         setInvalidOtp(false);
      }
   }

   useEffect(() => {
      if (resetSuccess) {
         uiStore.switchLoginMode(LOGIN_MODE.RESET_PASSWORD_SUCCESS);
      }
   }, [resetSuccess]);

   return (
      <>
         <p>Du har fått tilsendt engangskode over e-post. Fyll inn koden og oppgi nytt passord.</p>
         <form noValidate onSubmit={onSubmit} className="tw-flex tw-flex-col tw-gap-2">
            <Input
               label="Engangskode (6 tegn)"
               type="otp"
               value={otp}
               onChange={handleOtpChange}
               onBlur={() => setInvalidOtp(otp.length !== 6 || Number.isNaN(Number(otp)))}
               autoFocus
               showErrorMessage={invalidOtp}
               errorMessage="Engangskoden må være 6 siffer."
            />
            <PasswordField
               label="Nytt passord"
               type="password"
               value={newPassword}
               onChange={(event) => {
                  setNewPassword(event.target.value);
               }}
               strengthBar
               isInvalid={invalidPassword}
               disabled={invalidOtp || isWaitingForData(authStore.resetPasswordProgress)}
            />

            {isErrorOccured(authStore.resetPasswordProgress) && !invalidPassword && (
               <ErrorAlertWithOptionalTracking
                  showCloseButton={false}
                  gaErrorTracking={{ context: "login", text: "reset_password_error" }}
               >
                  Noe gikk galt ved endring av passord. Prøv igjen senere.
               </ErrorAlertWithOptionalTracking>
            )}

            <Button
               variant="primary"
               size="regular"
               type="submit"
               disabled={invalidOtp || isWaitingForData(authStore.resetPasswordProgress)}
               loading={isWaitingForData(authStore.resetPasswordProgress)}
            >
               Bekreft
            </Button>
         </form>
      </>
   );
};

export default view(InputOtp);
