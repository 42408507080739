import { batch, store } from "@risingstack/react-easy-state";
import find from "lodash-es/find";
import isNil from "lodash-es/isNil";

import { apiClient } from "../../common/apiClient";
import { ensureError } from "../../common/errors";
import { RECIPIENT_MODAL, type Recipient } from "../../common/types/recipientTypes";
import {
   initializeWithDefaultData,
   setAsDataAvailable,
   setAsErrorOccured,
   setAsWaitingForData
} from "../../common/utils/asyncDataUtils";

import { API_HOST } from "../../common/environment";
import theme from "../../themes/theme";
import authStore from "../auth/authStore";
import type { RecipientsStore } from "./dynamicsRecipientsStore";
import recipientsStore, { NEW_RECIPIENT } from "./recipientsStore";
import { mapFromLegacyRecipient, mapToLegacyRecipient } from "./recipientsUtils";

type LegacyRecipientsStore = RecipientsStore & {
   saveInternalState(customerNumber: string, recipients: Recipient[]): Promise<void>;
};

const categories = theme.notifications.categories;

const legacyRecipientsStore: LegacyRecipientsStore = store({
   recipients: initializeWithDefaultData([]),
   categories: initializeWithDefaultData([]),
   currentlyEditingId: null,
   currentlyDeletingId: null,
   requestProgress: initializeWithDefaultData(false),

   fetchRecipients: async () => {
      // Directly set this as data available, since we don't have dynamic categories in the legacy system
      setAsWaitingForData(legacyRecipientsStore.categories);
      setAsDataAvailable(legacyRecipientsStore.categories, categories);

      setAsWaitingForData(legacyRecipientsStore.recipients);

      return apiClient(`${API_HOST}/api/notification_contacts/${authStore.currentCompany}`, authStore.getSessionToken())
         .query({
            companyNumber: theme.m3CompanyNumber,
            division: theme.m3DivisionNumber
         })
         .get()
         .json((res) => {
            const recipients = res.payload.map(mapFromLegacyRecipient);
            setAsDataAvailable(legacyRecipientsStore.recipients, recipients);
         })
         .catch((errorObject) => {
            console.warn("Unable to fetch recipients", errorObject);
            setAsErrorOccured(legacyRecipientsStore.recipients, ensureError(errorObject).message);
         });
   },

   saveNewRecipient: async (recipient: Recipient) => {
      if (isNil(authStore.currentCompany)) {
         return Promise.reject();
      }

      const recipients = [...legacyRecipientsStore.recipients.data, recipient];
      const customerNumber = authStore.currentCompany;
      return legacyRecipientsStore.saveInternalState(customerNumber, recipients);
   },

   updateRecipient: async (currentRecipient: Recipient) => {
      if (isNil(authStore.currentCompany)) {
         return Promise.reject();
      }
      const customerNumber = authStore.currentCompany;

      const recipients = legacyRecipientsStore.recipients.data.map((recipient) =>
         recipient.profileId === currentRecipient.profileId ? currentRecipient : recipient
      );

      return legacyRecipientsStore.saveInternalState(customerNumber, recipients);
   },

   deleteRecipient: async () => {
      if (isNil(authStore.currentCompany)) {
         return Promise.reject();
      }

      const customerNumber = authStore.currentCompany;
      const recipients = legacyRecipientsStore.recipients.data.filter(
         (recipient) => recipient.profileId !== legacyRecipientsStore.currentlyDeletingId
      );

      return legacyRecipientsStore.saveInternalState(customerNumber, recipients);
   },

   saveInternalState: (customerNumber, recipients) => {
      const abortController = setAsWaitingForData(legacyRecipientsStore.requestProgress);

      const payload = recipients.map(mapToLegacyRecipient);
      return apiClient(`${API_HOST}/api/notification_contacts/${customerNumber}`, authStore.getSessionToken())
         .signal(abortController)
         .query({
            companyNumber: theme.m3CompanyNumber,
            division: theme.m3DivisionNumber
         })
         .post(payload)
         .res((res) => {
            setAsDataAvailable(legacyRecipientsStore.requestProgress, res.success);
            void legacyRecipientsStore.fetchRecipients();
            recipientsStore.setModalVisibility(RECIPIENT_MODAL.NONE);
         })
         .catch((err) => {
            setAsErrorOccured(legacyRecipientsStore.requestProgress, ensureError(err).message);
         });
   },

   getNotificationCategoryName: (queryId) =>
      find(legacyRecipientsStore.categories.data, (category) => category.id === queryId)?.name,

   setModalVisibility: (modalType: RECIPIENT_MODAL, recipientId?: string) => {
      batch(() => {
         legacyRecipientsStore.currentlyDeletingId = null;
         legacyRecipientsStore.currentlyEditingId = null;
         legacyRecipientsStore.requestProgress = initializeWithDefaultData(false);

         if (modalType === RECIPIENT_MODAL.DELETE && recipientId) {
            legacyRecipientsStore.currentlyDeletingId = recipientId;
         } else if (modalType === RECIPIENT_MODAL.ADD) {
            recipientsStore.currentlyEditingId = NEW_RECIPIENT;
         } else if (modalType === RECIPIENT_MODAL.EDIT && recipientId) {
            recipientsStore.currentlyEditingId = recipientId;
         }
      });
   },

   clearCompanySpecificData: () => {
      legacyRecipientsStore.recipients = initializeWithDefaultData([]);
      legacyRecipientsStore.categories = initializeWithDefaultData([]);
   }
});

export default legacyRecipientsStore;
