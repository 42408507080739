import { VideoPlayer } from "@tine/designsystem-ui-react";

import type { RecipeMedia } from "../../../common/types/recipeTypes";
import { getYoutubeVideoIdFromURL } from "../../../common/utils";

type StepVideoProps = {
   video: RecipeMedia | null | undefined;
};

const StepVideo = ({ video }: StepVideoProps) => {
   if (video) {
      const { url } = video;
      if (url) {
         const id = getYoutubeVideoIdFromURL(url);
         if (id) {
            return <VideoPlayer videoId={id} />;
         }
      }
   }
   return null;
};

export default StepVideo;
