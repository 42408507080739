import { view } from "@risingstack/react-easy-state";
import { Grid } from "@tine/designsystem-ui-react";
import type { Entry } from "contentful";

import type { ContentfulAdventCalendarSlot } from "../../../common/types/cmsTypes";
import type { TypeCallToActionListSkeleton } from "../../../common/types/contentful";

import AdventCalendarSlot from "./AdventCalendarSlot";

type AdventCalendarProps = {
   content: Entry<TypeCallToActionListSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>;
};

const AdventCalendar = ({ content }: AdventCalendarProps) => {
   if (content.fields.contentList) {
      const contentList = content.fields.contentList;
      const trackingListName = content.fields.title as string;

      if (contentList.length === 0) {
         return null;
      }

      return (
         <Grid cols={{ base: 2, sm: 3, md: 4, xl: 5 }} gap={4}>
            {contentList.map((adventCalendarSlot) => {
               const entry = adventCalendarSlot as ContentfulAdventCalendarSlot;
               if (entry.fields.slotImage) {
                  return (
                     <AdventCalendarSlot
                        key={`advent-calendar-slot-${entry.sys.id}`}
                        content={entry}
                        trackingListName={trackingListName}
                     />
                  );
               }
               return null;
            })}
         </Grid>
      );
   }
};

export default view(AdventCalendar);
