import { jsxs as a, jsx as t } from "react/jsx-runtime";
import { RadioGroup as l } from "./designsystem-ui-react68.js";
function d({ children: n, label: e, onChange: r, defaultValue: o }) {
  return /* @__PURE__ */ a("div", { className: "tw-flex tw-flex-col tw-gap-y-2", children: [
    e ? /* @__PURE__ */ t("p", { className: "tw-text-ink-brand-default tw-text-lg", children: e }) : null,
    /* @__PURE__ */ a(l, { defaultValue: o, onChange: (s) => {
      r({ target: { value: s } });
    }, children: [
      /* @__PURE__ */ t(l.Label, { className: "tw-sr-only", children: e }),
      /* @__PURE__ */ t("div", { className: "tw-flex tw-items-center tw-gap-x-0.5 tw-bg-chip-default tw-w-max tw-rounded-full", children: n })
    ] })
  ] });
}
d.displayName = "ToggleGroup";
export {
  d as ToggleGroup
};
