import { view } from "@risingstack/react-easy-state";
import { IconCheckLarge } from "@tine/designsystem-icons/sharp";
import { Group } from "@tine/designsystem-ui-react";
import some from "lodash-es/some";
import { useLocation } from "wouter";

import authStore from "../stores/auth/authStore";
import loginState from "../stores/auth/loginState";

import Modal from "./Modal";
import { AccountListItemWithButtonActions, AccountListItemWithNavigation } from "./account-list-item/AccountListItem";

const pathsToRedirect = {
   "/checkout/confirmation": "/",
   "/customer/favorites/": "/customer/favorites",
   "/customer/subscriptions/": "/customer/subscriptions",
   "/customer/orders/": "/customer/orders",
   "/customer/claims/": "/customer/claims"
};

const SwitchCompanyModal = () => {
   const [path, navigate] = useLocation();
   const companies = (authStore.companies || []).sort((a, b) => a.name.localeCompare(b.name));

   const selectCompany = (customerNumber: string) => {
      if (customerNumber !== authStore.currentCompany) {
         void authStore.changeCompany(customerNumber);

         // Stops iterating when first path is found
         some(pathsToRedirect, (to, startsWithPath) => {
            if (path.startsWith(startsWithPath)) {
               navigate(to);
               return true;
            }
            return false;
         });
      }
   };

   const handleClose = () => {
      if (authStore.currentCompany) {
         loginState.transitionTo("LOGGED_IN");
      } else {
         loginState.transitionTo("NOT_LOGGED_IN");
      }
   };

   return (
      <Modal
         size="lg"
         title="Velg firma"
         name="Endre firma"
         isOpen={loginState.is("COMPANY_SELECTION")}
         onClose={handleClose}
         className="tw-bg-background-subtle"
      >
         <nav aria-label="Liste over firmaer">
            <ul className="tw-p-0">
               {companies.map((company, index) => {
                  if (company.customerNumber === authStore.currentCompany) {
                     return (
                        <AccountListItemWithButtonActions
                           key={`switch-company-${company.customerNumber}`}
                           title={company?.name}
                           subtitles={<small key="subtitle">{company?.customerNumber}</small>}
                           actions={[
                              <Group key="current-company" align="center" gap={1}>
                                 <span className="tw-hidden tw-text-sm sm:tw-block">Nåværende firma</span>
                                 <IconCheckLarge />
                              </Group>
                           ]}
                           marked={true}
                        />
                     );
                  }
                  return (
                     <AccountListItemWithNavigation
                        key={`switch-company-${company.customerNumber}-${index}`}
                        title={company?.name}
                        subtitles={<small>{company?.customerNumber}</small>}
                        onClick={() => selectCompany(company.customerNumber)}
                     />
                  );
               })}
            </ul>
         </nav>
      </Modal>
   );
};

export default view(SwitchCompanyModal);
