import { view } from "@risingstack/react-easy-state";
import { IconCheckCircleFilled } from "@tine/designsystem-icons/sharp";

import type { ContentfulSignupButton } from "../../common/types/cmsTypes";
import type { SignUpRepeaterData } from "../../common/types/promotionTypes";
import { getModuleVisibility } from "../../common/utils/segmentUtils";

import authStore from "../../stores/auth/authStore";
import contentStore from "../../stores/cms/contentStore";
import segmentStore from "../../stores/cms/segmentStore";
import promotionStore from "../../stores/promotionStore";

import type { DynamicsCaptureConfig } from "../../common/types/dynamicsTypes";
import { dynamicsEndpoint } from "../../common/utils/dynamicsUtils";

import type { FormValuesToSubmit } from "../formvalidation/FormTypes";
import CmsPromotionSignupForm from "./promotion/CMSPromotionSignupForm";

type CmsPromotionSignupProps = {
   content: ContentfulSignupButton;
};

const CmsPromotionSignup = ({ content }: CmsPromotionSignupProps) => {
   const company = authStore.getCurrentCompany();
   const user = authStore.user;

   const isVisible = getModuleVisibility(content, segmentStore.currentCustomerProperties);
   if (!isVisible) {
      return null;
   }

   const loggedIn = contentStore.isPreviewMode ? true : authStore.isLoggedIn();
   if (!loggedIn) {
      return content.fields.notLoggedInText ? <p>{content.fields.notLoggedInText}</p> : null;
   }

   const promotionId = content.fields.promotionId;

   const currentlySignedup = promotionStore.isSignedUpTo(promotionId);
   const dynamicsEventId = content.fields.dynamicsEventId || "";
   const voucherifyEventName = content.fields.voucherifyEventName || "";

   const fields = Array.isArray(content.fields.additionalFields)
      ? (content.fields.additionalFields as SignUpRepeaterData)
      : ([] as SignUpRepeaterData);

   const mapping = Array.isArray(content.fields.dynamicsMapping)
      ? (content.fields.dynamicsMapping as SignUpRepeaterData)
      : ([] as SignUpRepeaterData);

   const dynamicsConfig: DynamicsCaptureConfig = {
      FormApiUrl: dynamicsEndpoint,
      FormId: dynamicsEventId
   };

   const columnStyle = "tw-w-full md:tw-mx-auto md:tw-w-2/3 lg:tw-w-1/2";

   const signup = async (values: FormValuesToSubmit): Promise<boolean> => {
      try {
         await promotionStore.signUpToPromotion(promotionId, voucherifyEventName, dynamicsEventId, values);
         await promotionStore.fetchAvailablePromotions();
         await promotionStore.fetchPromotionDiscountsWithoutDelay();
         return true;
      } catch (err) {
         console.warn("Error signing up to promotion", err);
         return false;
      }
   };
   if (!currentlySignedup && company && user) {
      return (
         <div className={columnStyle}>
            <div className="tw-mb-12 tw-px-2 tw-py-6 tw-text-center">
               <CmsPromotionSignupForm
                  additionalFields={fields}
                  dynamicsMapping={mapping}
                  dynamicsConfig={dynamicsConfig}
                  buttonText={content.fields.buttonText}
                  signup={signup}
                  company={company}
                  user={user}
               />
            </div>
         </div>
      );
   }
   return (
      <div className={columnStyle}>
         <p className="tw-mb-12 tw-px-2 tw-py-6 tw-text-center">
            <IconCheckCircleFilled className="tw-inline-block" fill="green" /> {content.fields.alreadySignedUpFeedback}
         </p>
      </div>
   );
};

export default view(CmsPromotionSignup);
