import { InputStepper } from "@tine/designsystem-ui-react";

type IngredientsPortionsControlProps = {
   portions: number;
   onChange: (portions: number) => void;
};

const IngredientsPortionsControl = ({ portions, onChange }: IngredientsPortionsControlProps) => {
   return (
      <div className="tw-mb-3 tw-inline-block">
         <InputStepper value={portions} min={1} size="compact" onChange={onChange} />
      </div>
   );
};

export default IngredientsPortionsControl;
