export const API_HOST = process.env.NEXT_PUBLIC_API_HOST || process.env.API_HOST;
export const STATICDATA_HOST = process.env.NEXT_PUBLIC_STATICDATA_HOST || process.env.STATICDATA_HOST;
export const ENV_NAME = process.env.NEXT_PUBLIC_ENV_NAME || process.env.ENV_NAME;
export const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION || process.env.APP_VERSION;
export const CONTENTFUL_SPACE = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE || process.env.CONTENTFUL_SPACE;
export const CONTENTFUL_PREVIEWTOKEN = process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEWTOKEN || process.env.CONTENTFUL_PREVIEWTOKEN;
export const CONTENTFUL_READTOKEN = process.env.NEXT_PUBLIC_CONTENTFUL_READTOKEN || process.env.CONTENTFUL_READTOKEN;
export const CONTENTFUL_ENV = process.env.NEXT_PUBLIC_CONTENTFUL_ENV || process.env.CONTENTFUL_ENV;
export const PRODUCT_INDEX = process.env.NEXT_PUBLIC_PRODUCT_INDEX || process.env.PRODUCT_INDEX;
export const TAGMANAGERID = process.env.NEXT_PUBLIC_TAGMANAGERID || process.env.TAGMANAGERID;
export const BUGSNAG_ID = process.env.NEXT_PUBLIC_BUGSNAG_ID || process.env.BUGSNAG_ID;
export const BUGSNAG_STAGE = process.env.NEXT_PUBLIC_BUGSNAG_STAGE || process.env.BUGSNAG_STAGE;
export const THEME_NAME = process.env.NEXT_PUBLIC_THEME_NAME || process.env.THEME_NAME;
export const CONFIG_CAT_KEY = process.env.NEXT_PUBLIC_CONFIG_CAT_KEY || process.env.CONFIG_CAT_KEY;
export const SOLIDUM_POSTTOKEN = process.env.NEXT_PUBLIC_SOLIDUM_POSTTOKEN || process.env.SOLIDUM_POSTTOKEN;
export const SOLIDUM_POSTFORM = process.env.NEXT_PUBLIC_SOLIDUM_POSTFORM || process.env.SOLIDUM_POSTFORM;
export const DYNAMICS_INGESTION_KEY = process.env.NEXT_PUBLIC_DYNAMICS_INGESTION_KEY || process.env.DYNAMICS_INGESTION_KEY;
export const NODE_ENV = process.env.NODE_ENV;
