export enum ORDER_UNIT {
   D = "D",
   STK = "STK",
   L = "L",
   K = "K",
   P = "P",
   CS = "CS",
   KG = "KG",
   DFP = "DFP",
   TNK = "TNK"
}

export enum ORDERTYPE {
   WEB = "WEB",
   LEV = "LEV",
   WAS = "WAS",
   STD = "STD",
   EXP = "EXP",
   EXL = "EXL",
   HPN = "HPN",
   KOE = "KOE",
   RLG = "RLG",
   TUP = "TUP",
   XML = "XML",
   ETT = "ETT"
}

export type OrderState = {
   sku: string;
   totalQuantity: number; //M3 order name
   qty: number; //client name
   price?: number;
   combinedOrderLineNumbers?: number[]; //M3 order name
   combinedLines?: number[]; //client name
   canDecrease: boolean;
   canIncrease: boolean;
   canDelete: boolean;
};

export type OrderChangeAction = {
   action: "add" | "upd" | "del";
   sku: string;
   quantity?: number;
   orderLineUnit?: ORDER_UNIT;
   orderLineNumber?: number[];
   orderLineNumbers?: number[];
   lostSalesUniqueId?: string;
   isLostSales?: true;
   lostSalesWarehouse?: string;
};

export type ChangeOrderPayload = {
   companyNumber: string;
   customerNumber: string;
   orderNumber: string;
   estimatedOrderAmount: number;
   deliveryDate?: string;
   orderLines: OrderChangeAction[];
};

export type EditMode = {
   //companyNumber: string;
   //customerNumber?: string;
   estimatedOrderAmount?: number;
   orderlines?: OrderChangeAction[];
   instanceDate?: string;
   existingInstance?: boolean;
   deadline?: string;
   oldState: OrderState[];
   orderNumber?: string;
   orderType?: ORDERTYPE;
   canAddItems: boolean;
   deliveryDate: Date;
};

export type Assortment = {
   itemId: string;
   fpakPrice?: number;
   dpakPrice?: number;
   dpakPriceBeforeDiscount?: number;
   inAgreementAssortment?: boolean;
   comparisonPrice?: number;
   comparisonPriceUnit?: number;
};

type DeliveryNoteInfo = {
   url: string;
   filename: string;
   pid: string;
};
export type DeliveryNotesInfo = {
   orderNumber: string;
   customerNumber: string;
   deliveryNotes: DeliveryNoteInfo[];
};
