import { jsxs as m } from "react/jsx-runtime";
import { cn as u } from "./designsystem-ui-react46.js";
import { isValidElement as b, cloneElement as f } from "react";
function x({
  children: r,
  size: n = "regular",
  outlined: o = !1,
  bgColor: a,
  lip: w = "none",
  variant: t,
  textColor: l,
  borderColor: s,
  className: i,
  icon: e,
  ...d
}) {
  const g = {
    upperLeft: "tw-rounded-tl-none",
    upperRight: "tw-rounded-tr-none",
    lowerLeft: "tw-rounded-bl-none",
    lowerRight: "tw-rounded-br-none",
    none: ""
  }[w], p = {
    regular: "tw-text-sm tw-px-2 tw-py-1",
    small: "tw-text-xs tw-px-1 tw-py-0.5",
    large: "tw-text-lg tw-px-3 tw-py-2"
  }[n], c = {
    success: "tw-bg-success-100",
    warning: "tw-bg-warning-100",
    error: "tw-bg-error-100",
    information: "tw-bg-information-100",
    base: "tw-bg-base-50"
  }[t || "information"];
  return /* @__PURE__ */ m(
    "div",
    {
      className: u(
        "tw-w-fit tw-items-center tw-flex tw-gap-1 tw-rounded-lg tw-select-none tw-whitespace-pre tw-font-medium tw-text-ink-brand-default",
        g,
        p,
        o ? "tw-border" : "tw-border tw-border-base-transparent",
        c,
        i,
        { ...d }
      ),
      style: { backgroundColor: a, borderColor: s, color: l },
      children: [
        e && b(e) ? f(e, {
          style: {
            fill: t === "base" ? "var(--color-ink-brand-default)" : `var(--color-${t}-600)`,
            width: "var(--spacing-4)",
            height: "var(--spacing-4)"
          }
        }) : null,
        r
      ]
    }
  );
}
x.displayName = "Tag";
export {
  x as Tag
};
