import { view } from "@risingstack/react-easy-state";
import { Grid } from "@tine/designsystem-ui-react";

import type { RecipeType } from "../../../common/types/recipeTypes";
import Ingredients from "./Ingredients";
import Instructions from "./Instructions";
import RecipeProductList from "./RecipeProductList";

type IngredientsAndInstructionsProps = {
   recipe: RecipeType;
};

const onDesktop = (cols: number) => ({
   base: 1,
   lg: cols
});

const IngredientsAndInstructions = ({ recipe }: IngredientsAndInstructionsProps) => (
   <Grid cols={{ base: 1, lg: 12 }} gap={12}>
      <Grid.Col span={onDesktop(4)} className="print:tw-col-span-4">
         <Ingredients recipe={recipe} />
      </Grid.Col>
      <Grid.Col span={onDesktop(8)} className="print:tw-col-span-8">
         <Instructions stepGroups={recipe.stepGroups} tip={recipe.tip} />
         <RecipeProductList recipe={recipe} />
      </Grid.Col>
   </Grid>
);
export default view(IngredientsAndInstructions);
