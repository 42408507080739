import { view } from "@risingstack/react-easy-state";
import { IconCheckLarge, IconClose } from "@tine/designsystem-icons/sharp";
import {
   Accordion,
   Alert,
   Breadcrumbs,
   Button,
   Carousel,
   CarouselItem,
   Checkbox,
   Chip,
   ContentCard,
   Flex,
   FormDetails,
   FormError,
   Grid,
   GridSimple,
   Group,
   Input,
   InputStepper,
   Link,
   Modal,
   Parallax,
   ProductCardSimple,
   RadioGroup,
   RecipeCard,
   Search,
   SectionCard,
   Select,
   Stack,
   Switch,
   Table,
   TableBody,
   TableCell,
   TableHeader,
   TableHeaderCell,
   TableRow,
   Tag,
   TextArea,
   ToggleGroup,
   ToggleGroupItem,
   Tooltip,
   VideoPlayer
} from "@tine/designsystem-ui-react";
import { useState } from "react";

const Components = () => {
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [isSwitchOn, setIsSwitchOn] = useState(false);
   const [isChipActive, setIsChipActive] = useState(false);
   const [showChipIcon, setShowChipIcon] = useState(true);
   const [isChecked, setIsChecked] = useState(false);

   const onClose = (): void => {
      setIsModalOpen(false);
   };

   const onOpen = (): void => {
      setIsModalOpen(true);
   };

   const handleToggleSwitch = (): void => {
      setIsSwitchOn(!isSwitchOn);
   };

   const handleChipClick = (): void => {
      setIsChipActive(!isChipActive);
   };

   const handleChipRemoveClick = (): void => {
      setShowChipIcon(!showChipIcon);
   };

   return (
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-y-28 tw-p-8 xl:tw-p-40">
         <h1 className="tw-text-3xl tw-font-bold">Oversikt over komponenter fra KREM</h1>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Accordion</h2>
            <Accordion label="Question 1">Answer 1</Accordion>
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Alert</h2>
            <Alert variant="success" title="Success">
               Alert
            </Alert>
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Breadcrumbs</h2>
            <Breadcrumbs
               items={[
                  { label: "Home", href: "/" },
                  { label: "Components", href: "/components", current: true }
               ]}
            />
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Button</h2>
            <div className="tw-flex tw-flex-wrap tw-gap-4">
               <Button className="tw-w-fit" variant="primary">
                  Primary
               </Button>
               <Button className="tw-w-fit" variant="secondary">
                  Secondary
               </Button>
               <Button className="tw-w-fit" variant="tertiary">
                  Tertiary
               </Button>
               <Button className="tw-w-fit" variant="destructive">
                  Destructive
               </Button>
            </div>
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Carousel and Recipe Card</h2>
            <Carousel numVisible={{ default: 1.9, sm: 1.9, md: 2.5, lg: 3, xl: 3 }} size="md">
               <CarouselItem key={1}>
                  <RecipeCard
                     compactRating
                     href="#"
                     image="https://www.tine.no/_/recipeimage/w_2560%2Ch_1440%2Cc_fill%2Cx_1024%2Cy_576%2Cg_xy_center/recipeimage/mzk4deb35usrg69xr5r7.jpg"
                     rating={4.8}
                     tag={{ label: "Vegetarian" }}
                     title="Item 1: Norwegian Brie Wrap with Lingonberry Jam"
                  />
               </CarouselItem>
               <CarouselItem key={2}>
                  <RecipeCard
                     compactRating
                     href="#"
                     image="https://www.tine.no/_/recipeimage/w_2560%2Ch_1440%2Cc_fill%2Cx_1024%2Cy_576%2Cg_xy_center/recipeimage/mzk4deb35usrg69xr5r7.jpg"
                     rating={4.8}
                     tag={{ label: "Vegetarian" }}
                     title="Jarlsberg Cheese"
                  />
               </CarouselItem>
               <CarouselItem key={3}>
                  <RecipeCard
                     compactRating
                     href="#"
                     image="https://www.tine.no/_/recipeimage/w_2560%2Ch_1440%2Cc_fill%2Cx_1024%2Cy_576%2Cg_xy_center/recipeimage/mzk4deb35usrg69xr5r7.jpg"
                     rating={4.8}
                     tag={{ label: "Vegetarian" }}
                     title="Item 3: Creamy Havarti Wrap with Roasted Peppers"
                  />
               </CarouselItem>
               <CarouselItem key={4}>
                  <RecipeCard
                     compactRating
                     href="#"
                     image="https://www.tine.no/_/recipeimage/w_2560%2Ch_1440%2Cc_fill%2Cx_1024%2Cy_576%2Cg_xy_center/recipeimage/mzk4deb35usrg69xr5r7.jpg"
                     tag={{ label: "Vegetarian" }}
                     title="Item 4: Snøfrisk Cream Cheese Wrap with Salmon and Dill"
                  />
               </CarouselItem>
               <CarouselItem key={5}>
                  <RecipeCard
                     compactRating
                     href="#"
                     image="https://www.tine.no/_/recipeimage/w_2560%2Ch_1440%2Cc_fill%2Cx_1024%2Cy_576%2Cg_xy_center/recipeimage/mzk4deb35usrg69xr5r7.jpg"
                     rating={4.8}
                     tag={{ label: "Vegetarian" }}
                     title="Item 5: Nøkkelost Cheese Wrap with Pickled Cucumbers"
                  />
               </CarouselItem>
               <CarouselItem key={6}>
                  <RecipeCard
                     compactRating
                     href="#"
                     image="https://www.tine.no/_/recipeimage/w_2560%2Ch_1440%2Cc_fill%2Cx_1024%2Cy_576%2Cg_xy_center/recipeimage/mzk4deb35usrg69xr5r7.jpg"
                     tag={{ label: "Vegetarian" }}
                     title="Item 6: Goat Cheese Wrap with Honey and Walnuts"
                  />
               </CarouselItem>
               <CarouselItem key={7}>
                  <RecipeCard
                     compactRating
                     href="#"
                     image="https://www.tine.no/_/recipeimage/w_2560%2Ch_1440%2Cc_fill%2Cx_1024%2Cy_576%2Cg_xy_center/recipeimage/mzk4deb35usrg69xr5r7.jpg"
                     tag={{ label: "Vegetarian" }}
                     title="Item 6: Goat Cheese Wrap with Honey and Walnuts"
                  />
               </CarouselItem>
            </Carousel>
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Checkbox</h2>
            <Checkbox
               label="Checkbox"
               isChecked={isChecked}
               onChange={() => {
                  setIsChecked(!isChecked);
               }}
            />
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Content Card</h2>
            <ContentCard
               className="tw-max-w-[600px]"
               description="En kort tekst her..."
               hasShadow
               href="#"
               image="https://www.tine.no/b%C3%A6rekraft/_/image/ff0df4f9-7051-4244-8d4f-69be9cc087c1:c08ac1f54bbeef90b6c33c0c5c0c22e1b0d8dda8/block-1120-630/aarsrapport2020_forsidebilde.jpg"
               tags={["Tema"]}
               title="Overskrift til inlegget..."
               variant="white"
            />
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Flex</h2>
            <Flex align="start" direction="row" gap={4} justify="start" wrap="nowrap">
               <div className="tw-flex tw-size-16 tw-items-center tw-justify-center tw-bg-information-100">
                  <p>1</p>
               </div>
               <div className="tw-flex tw-size-16 tw-items-center tw-justify-center tw-bg-information-100">
                  <p>2</p>
               </div>
               <div className="tw-flex tw-size-16 tw-items-center tw-justify-center tw-bg-information-100">
                  <p>3</p>
               </div>
            </Flex>
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Form</h2>
            <form className="tw-flex tw-max-w-96 tw-flex-col tw-gap-y-4">
               <FormDetails label="Form details - Input" tooltipPosition="right" tooltipText="Tooltip text" />
               <Input label="" placeholder="Placeholder" />
               <FormError errorMessage="Form Error" showErrorMessage />
               <Select
                  label="Select"
                  options={[
                     { label: "Option 1", value: "option1" },
                     { label: "Kjede: VESTFOLD OG TELEMARKK INNKJØP (GR00002661)", value: "option2" },
                     { label: "Option 3", value: "option3" }
                  ]}
                  value={{ label: "Option 1", value: "option1" }}
                  onChange={() => {}}
               />
               <RadioGroup
                  label="Radio Group"
                  onChange={() => {}}
                  options={[
                     { label: "Alternativ 1", value: "option1" },
                     { label: "Alternativ 2", value: "option2" },
                     { label: "Alternativ 3", value: "option3" }
                  ]}
               />
               <TextArea
                  errorMessage="Error message"
                  label="Textarea"
                  maxLength={100}
                  onChange={() => {}}
                  placeholder="Placeholder"
                  rows={4}
                  showMaxLength
                  value=""
               />
            </form>
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Grid & Grid Simple</h2>
            <Grid className="" cols={12} gap={4} type="media">
               <Grid.Col className="tw-bg-grassGreen-100 tw-p-4 tw-text-center" span={4}>
                  Child 1
               </Grid.Col>
               <Grid.Col className="tw-bg-grassGreen-500 tw-p-4 tw-text-center" span={4}>
                  Child 2
               </Grid.Col>
               <Grid.Col className="tw-bg-grassGreen-900 tw-p-4 tw-text-center" span={4}>
                  Child 3
               </Grid.Col>
            </Grid>

            <GridSimple className="" cols={3} gap={4} type="media">
               <div className="tw-flex tw-size-16 tw-w-full tw-items-center tw-justify-center tw-bg-information-100">
                  <p>1</p>
               </div>
               <div className="tw-flex tw-size-16 tw-w-full tw-items-center tw-justify-center tw-bg-information-100">
                  <p>2</p>
               </div>
               <div className="tw-flex tw-size-16 tw-w-full tw-items-center tw-justify-center tw-bg-information-100">
                  <p>3</p>
               </div>
            </GridSimple>
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Group</h2>

            <Group align="start" className="" gap={4} justify="start" wrap="nowrap">
               <div className="tw-flex tw-size-16 tw-items-center tw-justify-center tw-bg-information-100">
                  <p>1</p>
               </div>
               <div className="tw-flex tw-size-16 tw-items-center tw-justify-center tw-bg-information-100">
                  <p>2</p>
               </div>
               <div className="tw-flex tw-size-16 tw-items-center tw-justify-center tw-bg-information-100">
                  <p>3</p>
               </div>
            </Group>
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Inputstepper</h2>
            <InputStepper max={10} min={0} value={1} />
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Link</h2>
            <Link href="#">Link</Link>
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Modal</h2>
            <p>Click the button to open the modal</p>
            <Button className="tw-mt-8 tw-w-fit" onClick={onOpen} variant="primary">
               Open Modal
            </Button>
            <Modal isOpen={isModalOpen} onClose={onClose} title="Title" description="Here is a description">
               <div className="tw-flex tw-flex-col tw-space-y-4">
                  <div className="tw-flex tw-items-center">
                     <Select
                        label="Select"
                        options={[{ label: "Option 1", value: "option1" }]}
                        value={{ label: "Option 1", value: "option1" }}
                        onChange={() => {}}
                     />
                     <Tooltip tooltipText="Tooltip" tooltipPosition="right" />
                     <div className="tw-ml-auto tw-flex tw-items-center tw-gap-x-2">
                        <Button variant="primary">Primær</Button>
                        <Button onClick={onClose} variant="secondary">
                           Lukk
                        </Button>
                     </div>
                  </div>
               </div>
            </Modal>
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>ProductCard</h2>
            <ProductCardSimple
               className="tw-w-fit"
               image="https://aps.brandmaster.com/v03/product/12590/3498/small/hovedbilde/tine_handel_artnr_3498__small.png"
               imageAlt="Product image"
               title="Product name over two lines of text"
               label="Label"
               href="/"
               backgroundColor="white"
            />
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Parallax</h2>
            <Parallax
               description="Scroll to see the magic happen"
               imageUrl="https://a.vev.design/r/DcoF0h-2-P0_1080.jpg"
               title="Parallax"
            />
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Search</h2>
            <Search className="tw-w-full" placeholder="Search" label="Search" value="" onChange={() => {}} />
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Section Card</h2>
            <SectionCard
               title="Section card"
               description="Here is a description"
               image="https://a.vev.design/r/DcoF0h-2-P0_1080.jpg"
               buttonVariant="primary"
               ctaLabel="Click"
               href="#"
            />
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Stack</h2>
            <Stack align="start" className="" gap={4} justify="start" wrap="nowrap">
               <div className="tw-flex tw-size-16 tw-items-center tw-justify-center tw-bg-information-100">
                  <p>1</p>
               </div>
               <div className="tw-flex tw-size-16 tw-items-center tw-justify-center tw-bg-information-100">
                  <p>2</p>
               </div>
               <div className="tw-flex tw-size-16 tw-items-center tw-justify-center tw-bg-information-100">
                  <p>3</p>
               </div>
            </Stack>
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Switch</h2>
            <Switch isEnabled={isSwitchOn} label="Switch" onChange={handleToggleSwitch} />
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Table</h2>
            <Table>
               <TableHeader>
                  <TableRow>
                     <TableHeaderCell id="Header 1">Header 1</TableHeaderCell>
                     <TableHeaderCell id="Header 2">Header 2</TableHeaderCell>
                     <TableHeaderCell id="Header 3">Header 3</TableHeaderCell>
                  </TableRow>
               </TableHeader>
               <TableBody>
                  <TableRow>
                     <TableCell headers="Header 1">Cell 1</TableCell>
                     <TableCell headers="Header 2">Cell 2</TableCell>
                     <TableCell headers="Header 3">Cell 3</TableCell>
                  </TableRow>
                  <TableRow>
                     <TableCell headers="Header 1">Cell 4</TableCell>
                     <TableCell headers="Header 2">Cell 5</TableCell>
                     <TableCell headers="Header 3">Cell 6</TableCell>
                  </TableRow>
               </TableBody>
            </Table>
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Tag</h2>
            <Tag lip="none" variant="base">
               Tag
            </Tag>
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Video </h2>
            <VideoPlayer
               poster="https://www.tine.no/_/recipeimage/w_1024%2Ch_576%2Cc_fill%2Cx_1500%2Cy_937%2Cg_xy_center/recipeimage/426615.jpg"
               videoId="sg_5IHHY_n4"
            />
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>Chip</h2>
            <div className="tw-flex tw-w-fit tw-items-center tw-gap-x-4">
               <Chip>Chip</Chip>
               <Chip onClick={handleChipClick} checked={isChipActive} icon={<IconCheckLarge />} iconPosition="left">
                  Chip with icon
               </Chip>
               <Chip onClick={handleChipRemoveClick} icon={showChipIcon ? <IconClose /> : undefined}>
                  Chip with close
               </Chip>
            </div>
         </div>

         <div className="tw-flex tw-w-full tw-flex-col tw-gap-y-4">
            <h2>ToggleGroup</h2>
            <ToggleGroup onChange={(value) => console.log(value)} defaultValue="1" aria-label="Velg alternativ">
               <ToggleGroupItem value="1">Item 1</ToggleGroupItem>
               <ToggleGroupItem value="2">Item 2</ToggleGroupItem>
               <ToggleGroupItem value="3">Item 3</ToggleGroupItem>
            </ToggleGroup>
         </div>
      </div>
   );
};

export default view(Components);
