import { groupBy } from "lodash-es";
import debounce from "lodash-es/debounce";
import first from "lodash-es/first";
import isArray from "lodash-es/isArray";
import isNil from "lodash-es/isNil";
import isUndefined from "lodash-es/isUndefined";

import availabilityStore from "../stores/availabilityStore";
import cartStore from "../stores/cart/cartStore";
import categoryStore from "../stores/categories/categoryStore";
import deliveryDatesStore from "../stores/deliveryDates/deliveryDatesStore";
import deliveryFeeStore from "../stores/deliveryFeeStore";
import productStore from "../stores/product/productStore";
import uiStore from "../stores/uiStore";

import promotionStore from "../stores/promotionStore";
import theme from "../themes/theme";
import type { CartItem, Product } from "./types/productTypes";
import {
   type GAAddtionalData,
   type GAError,
   type GAItem,
   type GAItemListInfo,
   type GAItemProduct,
   type GAListInformation,
   LIST_TYPE
} from "./types/trackingTypes";
import { translateOrderType } from "./utils";

const productToGA4 = (product?: Product): GAItemProduct => {
   if (isUndefined(product)) {
      return {};
   }
   const categories = categoryStore.findCategoryKeysBySku(product.sku);
   const category = categories.length > 0 ? first(categories) : { cat1: null, cat2: null, cat3: null };
   const labels = product.labels.map((label) => label.text);

   const voucherifyPromotionIds: string[] = promotionStore.availablePromotions.data.flatMap((promotion) =>
      promotion.products.includes(product.sku) ? [promotion.id] : []
   );

   const priceBeforeDiscount = product.priceBeforeDiscount ?? 0;
   const price = product.price ?? 0;
   const M3Discount = priceBeforeDiscount - price;
   const M3DiscountPercentage = M3Discount ?? ((M3Discount / priceBeforeDiscount) * 100).toFixed(2);

   const promotionTypes: string[] = [];
   if (voucherifyPromotionIds.length) {
      promotionTypes.push("Voucherify");
   }
   if (M3Discount > 0) {
      promotionTypes.push("M3");
   }

   return {
      item_id: product.sku,
      item_name: product.name,
      item_brand: product.brand,
      item_category: category?.cat1,
      item_category2: category?.cat2,
      item_category3: category?.cat3,
      price: product.price,
      unit_size: product.unit,
      callout: labels,
      promotionTypes: promotionTypes,
      voucherifyIds: voucherifyPromotionIds,
      m3_discount: `${M3DiscountPercentage.toString()}%`
   };
};

const buildListInfo = (listName?: string, position?: number): GAItemListInfo => {
   const listInfo: GAItemListInfo = {};
   // If we added to cart from a list, we want to report which list and which position the product had in that list.
   if (!isNil(listName)) {
      listInfo.item_list_name = listName;
      listInfo.index = position;
   }

   return listInfo;
};

const productSkusToGAItem = (items: { sku: string; qty: number; position?: number }[], listInformation?: GAListInformation) => {
   const GAItems: GAItem[] = items.reduce((arr, item) => {
      const product = productStore.resolveSku(item.sku);
      if (!product) {
         console.warn("Unable to find product when sending add to cart event", item);
         return arr;
      }

      const GAItem = {
         ...productToGA4(product),
         ...buildListInfo(listInformation?.name, item.position),
         quantity: item.qty
      } satisfies GAItem;

      arr.push(GAItem);
      return arr;
   }, [] as GAItem[]);
   return GAItems;
};

const debouncedSendSearch = (searchType: string, searchResults: number, searchTerm: string) => {
   sendEvent("ga4_search", {
      search_type: searchType,
      search_results: searchResults,
      search_term: searchTerm
   });
};

// Debounce the search event so that we dont get separate events for each new term (l, le, lett, lettm, lettme, etc)
export const sendSearch = debounce(debouncedSendSearch, 1000);

export const sendFilter = (filterType: string, filterContent: string) => {
   sendEvent("ga4_filter", {
      filter_type: filterType,
      filter_content: filterContent
   });
};

export const sendLoginStart = () => {
   sendEvent("ga4_login_start", { method: uiStore.loginMode });
};

export const sendLoginStep = () => {
   sendEvent("ga4_login_step", { method: uiStore.loginMode });
};

export const sendLoginSuccess = () => {
   sendEvent("ga4_login", { method: uiStore.loginMode });
};

export const sendLoginFailed = () => {
   sendEvent("ga4_login_failed");
};

// Sent if we load a cart that was originally created during edit order mode, while not in edit mode.
export const sendCartCleared = () => {
   sendEvent("ga4_cart_cleared");
};

export const sendAddToCart = (items: { sku: string; qty: number; position?: number }[], listInformation: GAListInformation) => {
   const GAItems: GAItem[] = productSkusToGAItem(items, listInformation);
   const sumOfProductPrices = GAItems.reduce((acc, item) => acc + (item.price || 0), 0);

   sendEvent("ga4_add_to_cart", {
      ecommerce: {
         item_list_id: listInformation.type,
         item_list_name: listInformation.name,
         item_list_length: listInformation.length,
         currency: "NOK",
         value: sumOfProductPrices,
         items: GAItems
      }
   });
};

export const sendRemoveFromCart = (
   items: { sku: string; qty: number; position?: number }[],
   listInformation: GAListInformation
) => {
   const GAItems: GAItem[] = productSkusToGAItem(items);
   sendEvent("ga4_remove_from_cart", {
      ecommerce: {
         item_list_id: listInformation.type,
         item_list_name: listInformation.name,
         currency: "NOK",
         items: GAItems
      }
   });
};

export const sendProductClick = (product: Product, listInformation: GAListInformation, position?: number) => {
   sendEvent("ga4_select_item", {
      ecommerce: {
         item_list_id: listInformation.type,
         item_list_name: listInformation.name,
         item_list_length: listInformation.length,
         items: [
            {
               ...productToGA4(product),
               ...buildListInfo(listInformation.name, position)
            }
         ]
      }
   });
};

export const sendAddItemsToFavoriteList = (products: Product[], listInformation: GAListInformation, position?: number) => {
   const items = products.map((product) => {
      return {
         ...productToGA4(product),
         ...buildListInfo(listInformation.name, position)
      };
   });
   const value = items.reduce((acc, item) => acc + (item.price || 0), 0);
   sendEvent("ga4_add_to_favorite_list", {
      ecommerce: {
         item_list_id: listInformation.type,
         item_list_name: listInformation.name,
         item_list_length: listInformation.length,
         value,
         items
      }
   });
};

export const sendRemoveItemFromFavoriteList = (sku: string, list?: string, position?: number) => {
   const product = productStore.resolveSku(sku);
   if (!isNil(product)) {
      sendEvent("ga4_remove_from_favorite_list", {
         ecommerce: {
            items: [
               {
                  ...productToGA4(product),
                  ...buildListInfo(list, position)
               }
            ]
         }
      });
   }
};

export const sendAddCartToFavoriteList = (cartName: string) => {
   const cartItems = cartStore.items;
   const skus = cartItems.map(({ sku }) => sku);
   const products = productStore.resolveSkus(skus);
   sendAddItemsToFavoriteList(products, { type: LIST_TYPE.CART, name: cartName, length: cartItems.length });
};

export const sendMiniCartVisible = () => {
   sendViewCart("ga4_view_mini_cart");
};

export const sendCartVisible = () => {
   sendViewCart("ga4_view_cart");
};

export const sendModal = (name: string) => {
   sendEvent("ga4_modal_view", { modal_name: name });
};

export const sendPageNotFound = (url: string) => {
   sendEvent("ga4_page_not_found", {
      link_url: `${theme.baseUrl}${url}`
   });
};

/**
 * Sends the cart contents to Google Analytics, with a specific event name.
 */
const sendViewCart = (eventName: "ga4_view_mini_cart" | "ga4_view_cart") => {
   const cartTotal = cartStore.getCartTotal();
   const itemSkusAndQty = cartStore.items.map((item) => ({
      sku: item.sku,
      qty: item.qty
   }));
   const items = productSkusToGAItem(itemSkusAndQty);

   const cartInfo = {
      order_type: translateOrderType(cartStore.orderType, theme.storeCompanyName),
      order_interval: deliveryDatesStore.getCurrentDelivery()?.interval,
      order_class: cartStore.isEditing() ? "change" : "new"
   };

   sendEvent(eventName, {
      ...(eventName === "ga4_view_cart" && cartInfo),
      ecommerce: {
         currency: "NOK",
         value: cartTotal.total,
         items
      }
   });
};

export const sendViewUnavailableItems = () => {
   const items = availabilityStore.availability || [];

   const itemPayload: GAItem[] = items.map((item) => {
      const product = productStore.resolveSku(item.productNumber);
      return {
         ...productToGA4(product)
      };
   });

   sendEvent("ga4_view_unavailable_items", {
      ecommerce: {
         items: itemPayload
      }
   });
};

export const sendProductDetails = (product: Product, stock_status?: string) => {
   sendEvent("ga4_view_item", {
      ecommerce: {
         currency: "NOK",
         value: product.price,
         items: [
            {
               ...productToGA4(product),
               stock_status
            }
         ]
      }
   });
};

export const sendViewTeaser = (teaserName?: string, teaserType?: string, trackingListName?: string) =>
   sendEvent("ga4_view_promotion", {
      creative_slot: trackingListName,
      creative_name: teaserName,
      creative_type: teaserType
   });

export const sendSelectTeaser = (teaserName?: string, teaserType?: string, trackingListName?: string, url?: string) =>
   sendEvent("ga4_select_promotion", {
      creative_slot: trackingListName,
      creative_name: teaserName,
      creative_type: teaserType,
      link_url: `${theme.baseUrl}${url}`
   });

export const sendInternalLinkClick = (url?: string, trackingLocationName?: string, trackingName?: string) =>
   sendEvent("ga4_internal_link_click", {
      link_slot: trackingLocationName,
      link_url: `${theme.baseUrl}${url}`,
      link_name: trackingName
   });

export const sendButtonClick = (trackingName?: string) =>
   sendEvent("ga4_button_click", {
      button_name: trackingName
   });

export const sendSignup = (trackingFormName?: string) =>
   sendEvent("ga4_signup", {
      signup_name: trackingFormName
   });

export const sendPurchase = (orderNumber: string | string[], cartItems: CartItem[], wasEditing: boolean) => {
   const useOrderNumber = isArray(orderNumber) ? orderNumber[0] : orderNumber;
   const cartCalculations = cartStore.getCartTotal();

   const products = cartItems.map((cartItem) => {
      const productFromCartItem = productStore.resolveSku(cartItem.sku);
      return {
         ...productToGA4(productFromCartItem),
         quantity: cartItem.qty
      };
   });

   sendEvent("ga4_purchase", {
      order_type: translateOrderType(cartStore.orderType, theme.storeCompanyName),
      order_interval: deliveryDatesStore.getCurrentDelivery()?.interval,
      order_class: wasEditing ? "changed order" : "new order",
      ecommerce: {
         currency: "NOK",
         coupon: null,
         transaction_id: useOrderNumber,
         value: cartCalculations.total,
         deliveryFee: deliveryFeeStore.currentDeliveryCharge().currentFee?.fee,
         items: products
      }
   });
};

export const sendOrderTypeChange = () => {
   sendEvent("ga4_change_order_type", {
      order_type: translateOrderType(cartStore.orderType, theme.storeCompanyName)
   });
};

export const sendDeliveryDateChange = () => {
   sendEvent("ga4_change_delivery_date", {
      action: "updated"
   });
};

export const sendOrderIntervalChange = () => {
   sendEvent("ga4_change_order_interval", {
      action: "updated",
      order_interval: deliveryDatesStore.getCurrentDelivery()?.interval
   });
};

// Keep track of queued impressions
const impressions: { product: Product; list?: string; position?: number }[] = [];
let impressionTimeout: number | null = null;

export const queueImpression = (product: Product, list?: string, position?: number) => {
   impressions.push({ product, list, position });

   if (impressions.length > 25) {
      sendQueuedImpressions();
   }

   if (impressionTimeout === null) {
      impressionTimeout = window.setTimeout(sendQueuedImpressions, 30_000);
   }
};

const sendQueuedImpressions = () => {
   if (impressions.length > 0) {
      const itemPayload: GAItem[] = impressions.map(({ product, list, position }) => ({
         ...productToGA4(product),
         ...buildListInfo(list, position)
      }));

      const impressionsGrouped = groupBy(itemPayload, "item_list_name");
      impressions.length = 0;

      for (const items of Object.values(impressionsGrouped)) {
         const item = items[0];
         sendEvent("ga4_view_item_list", {
            ecommerce: {
               item_list_id: item.item_list_id,
               item_list_name: item.item_list_name,
               num_items: items.length,
               items: items
            }
         });
      }
   }

   if (impressionTimeout !== null) {
      clearTimeout(impressionTimeout);
      impressionTimeout = null;
   }
};

export const sendErrorMessage = (error: GAError) => {
   sendEvent("ga4_error_message", {
      error_context: error.context,
      error_text: error.text
   });
};

const sendEvent = (event: string, additionalData?: GAAddtionalData) => {
   const eventPayload = {
      event,
      ...additionalData,
      _clear: true
   };

   /*
   // Attempt to send _clear for all events, to see if this solves issue with events getting
   // connected to previous events.

   if (additionalData?.ecommerce || additionalData?.creative_type) {
      // resets the data for each event
      eventPayload = { ...eventPayload, _clear: true };
   }
   */

   console.log("GA Event executed: ", eventPayload);
   window.dataLayer.push(eventPayload);
};
