import { RecipeCard } from "@tine/designsystem-ui-react";
import { formatCookingTime } from "@tine/designsystem-utils";
import { useInView } from "react-intersection-observer";
import { Link } from "wouter";

import { sendSelectTeaser, sendViewTeaser } from "../../../common/tracking";
import type { RecipeType } from "../../../common/types/recipeTypes";
import { difficultyTexts } from "../../../common/utils/cmsStoreUtils";
import useSupportedLocale from "../../../common/utils/useSupportedLocale";

import contentStore from "../../../stores/cms/contentStore";

import RecipeImage from "./RecipeImage";

type RecipeTeaserProps = {
   recipe: RecipeType;
   id: string;
   title?: string;
   trackingListName?: string;
};

const RecipeTeaser = ({ trackingListName, id, recipe, title }: RecipeTeaserProps) => {
   const trackImpression = (isVisible: boolean) => {
      if (isVisible) {
         sendViewTeaser(titleText, "recipe", trackingListName);
      }
   };

   const { ref } = useInView({
      triggerOnce: true,
      onChange: trackImpression
   });

   const locale = useSupportedLocale();
   const urlData = contentStore.urlData.data.find((urlData) => urlData.id === id);

   if (!recipe) return null;

   const titleText = recipe.displayName || title || "Uten tittel";
   const difficultyText = difficultyTexts[locale][recipe.difficulty];
   const { image, workTime = 0 } = recipe;

   const formatedWorkTime = formatCookingTime(workTime || 0);

   const url = image?.url || "";
   const imageTitle = image?.title || titleText;

   function handleClickRecipeTeaser() {
      sendSelectTeaser(title, "recipe", trackingListName, url);
   }

   return (
      <div ref={ref}>
         <Link to={`/${urlData?.fullUrl}`} onClick={handleClickRecipeTeaser} asChild>
            <RecipeCard
               image={
                  <RecipeImage
                     url={url}
                     width={600}
                     ratio="16x9"
                     className="rounded-top"
                     placement="belowFold"
                     title={imageTitle}
                  />
               }
               aspectRatio="16/9"
               title={titleText}
               time={{ label: formatedWorkTime ?? "" }}
               tag={{ label: difficultyText }}
            />
         </Link>
      </div>
   );
};

export default RecipeTeaser;
