import { view } from "@risingstack/react-easy-state";
import { IconChevronLeft, IconChevronRight, IconMenu } from "@tine/designsystem-icons/sharp";
import { cn } from "@tine/designsystem-utils";
import isNil from "lodash-es/isNil";
import { Link, useLocation } from "wouter";

import type { CategoryInfo } from "../../common/types/categoryTypes";
import { titleCase } from "../../common/utils";
import SlideFromSide from "../../components/common/slidefromside/SlideFromSide";

import authStore from "../../stores/auth/authStore";
import loginState from "../../stores/auth/loginState";
import categoryStore from "../../stores/categories/categoryStore";
import contentStore from "../../stores/cms/contentStore";

import Button from "../../components/Button";
import Loading from "../../components/Loading";
import StoreLogoComponent from "../../components/StoreLogoComponent";

import NotificationsBadge from "../../account/notifications/NotificationsBadge";
import { sendInternalLinkClick } from "../../common/tracking";
import { useMenuItems } from "../../common/utils/menuItems";
import notificationsStore from "../../stores/notifications/notificationsStore";

const hasChildren = (cat: CategoryInfo) => !isNil(cat.children) && cat.children.length > 0;

const drillDownTree = (menuItems: CategoryInfo[], path: string[]): CategoryInfo | undefined => {
   // Make sure this doesn't crash if called without data
   if (path.length === 0) {
      return undefined;
   }

   const [lookForKey, ...rest] = path;
   const foundItem = menuItems.find((mi) => mi.url_key === lookForKey);

   // No more path to process, we found the item.
   if (rest.length === 0) {
      return foundItem;
   }

   if (isNil(foundItem)) {
      return undefined;
   }

   return drillDownTree(foundItem.children, rest);
};

const itemCN = cn("tw-flex tw-cursor-pointer tw-font-bold");

const RootMenu = view(() => {
   const path = categoryStore.mobile.path;

   return (
      <ul className="tw-flex tw-flex-col tw-gap-3">
         {/* TODO: This should probably be a Link, right? */}
         {categoryStore.categories.map((cat) => (
            <li className={itemCN} key={cat.id} onClick={() => path.push(cat.url_key)} onKeyDown={() => path.push(cat.url_key)}>
               {titleCase(cat.name)} {hasChildren(cat) && <IconChevronRight className="tw-mr-4" />}
            </li>
         ))}
         {contentStore.mainMenuItems.map((menuItem) => (
            <li className={itemCN} key={menuItem.id}>
               <Link to={menuItem.url} onClick={() => categoryStore.showMenu(false)}>
                  {titleCase(menuItem.title)}
               </Link>
            </li>
         ))}
      </ul>
   );
});

const SubMenu = view(() => {
   const path = categoryStore.mobile.path;
   const selectedItem = drillDownTree(categoryStore.categories, path);
   const [_, redirectTo] = useLocation();

   const handleClick = (cat: CategoryInfo) => {
      if (!hasChildren(cat)) {
         categoryStore.showMenu(false);
         redirectTo(cat.fullUrl);
      } else {
         path.push(cat.url_key);
      }
   };

   const goBack = () => {
      categoryStore.mobile.path.pop();
   };

   if (isNil(selectedItem)) {
      return <div>Det oppsto den feil under lasting av kategoriene.</div>;
   }

   const visibleItems = selectedItem.children.filter((c: CategoryInfo) => c.visible);

   return (
      <div>
         <h2 className="tw-mb-4 tw-flex tw-cursor-pointer tw-items-center" onClick={goBack}>
            <IconChevronLeft className="tw-mr-4" /> {titleCase(selectedItem.name)}
         </h2>
         <Link
            className="tw-cursor-pointer tw-underline"
            onClick={() => categoryStore.showMenu(false)}
            to={`/${path.join("/")}.html`}
         >
            Se alle produkter i {titleCase(selectedItem.name)}
         </Link>

         <ul className="tw-mt-4 tw-flex tw-flex-col tw-gap-3">
            {visibleItems.map((cat: CategoryInfo) => (
               <li
                  className="tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-font-bold"
                  key={cat.id}
                  onClick={() => handleClick(cat)}
               >
                  {titleCase(cat.name)} {hasChildren(cat) && <IconChevronRight />}
               </li>
            ))}
         </ul>
      </div>
   );
});

const MobileCategories = () => {
   const [_, redirectTo] = useLocation();
   const menuItems = useMenuItems();

   const showSwitchCompany = authStore.companies && authStore.companies.length > 1;
   const notificationsCount = notificationsStore.notifications.data.length;

   if (categoryStore.categories.length === 0) {
      return <Loading />;
   }

   const hideMenu = () => {
      categoryStore.showMenu(false);
   };

   const activateSwitchCompany = (e: React.MouseEvent) => {
      loginState.transitionTo("COMPANY_SELECTION");
      e.preventDefault();
   };

   const mobileMenuHeader = () => <StoreLogoComponent className="tw-ml-2" placement="searchbar" />;

   const menuBodyClassName = "tw-m-2 tw-flex tw-min-w-[80vw] tw-flex-col";
   const borderClassName = "tw-border-b tw-border-ink-border-subtle tw-pb-4 tw-mb-4";

   const logout = () => {
      if (authStore.isPunchoutSession()) {
         loginState.transitionTo("NOT_LOGGED_IN");
         window.location.replace("/");
      } else {
         redirectTo("/");
         loginState.transitionTo("NOT_LOGGED_IN");
      }
      hideMenu();
   };

   return (
      <>
         <Button
            variant="tertiary"
            icon={<IconMenu />}
            onClick={() => categoryStore.showMenu(true)}
            trackingName="Vis mobilmeny"
         />
         <SlideFromSide renderHeader={mobileMenuHeader} show={categoryStore.mobile.visible} onHide={() => hideMenu()}>
            <div className={menuBodyClassName}>
               <div className={borderClassName}>{categoryStore.mobile.path.length === 0 ? <RootMenu /> : <SubMenu />}</div>
               {authStore.isLoggedIn() && (
                  <div className={borderClassName}>
                     <ul className="tw-flex tw-flex-col tw-gap-3">
                        {menuItems.map((menuItem) => (
                           <li key={menuItem.name} onClick={hideMenu}>
                              <Link
                                 to={menuItem.link}
                                 className={cn("tw-flex tw-gap-1 tw-items-center", { "tw-underline": menuItem.isActive })}
                                 onClick={() => sendInternalLinkClick(menuItem.link, "Mobile menu", menuItem.name)}
                              >
                                 <span>{menuItem.name}</span>
                                 <span>
                                    {menuItem.name === "Varslinger" && <NotificationsBadge count={notificationsCount} />}
                                 </span>
                              </Link>
                           </li>
                        ))}

                        {authStore.isLoggedIn() && (
                           <li>
                              <button type="button" onClick={logout} data-testid="logout-button">
                                 Logg ut
                              </button>
                           </li>
                        )}
                     </ul>
                  </div>
               )}
               {showSwitchCompany && (
                  <div className={borderClassName}>
                     <button type="button" onClick={activateSwitchCompany}>
                        Bytt firma
                     </button>
                  </div>
               )}
               {authStore.isLoggedIn() && (
                  <div className="tw-text-sm">
                     Logget inn som
                     <br />
                     {authStore.currentCompany} {authStore.getCurrentCompany()?.name ?? ""}
                  </div>
               )}
            </div>
         </SlideFromSide>
      </>
   );
};

export default view(MobileCategories);
