import { store } from "@risingstack/react-easy-state";
import type { Entry, EntryFieldTypes } from "contentful";

import type { TypeSegmentSkeleton } from "../../common/types/contentful";
import { convertEntriesToSegment, getEntryVisibility, keepIdOnly } from "../../common/utils/segmentUtils";

import type { BaseStoreType } from "../../common/types/BaseStoreType";
import authStore from "../auth/authStore";
import { type PreviewSegment, getPreviewSegmentAttributes } from "./previewUtils";

export type CustomerSegment = {
   customerNumber: string[];
   chains: string[];
   customerGroups: string[];
   customerWarehouses: string[];
   customerAttributes: CustomerAttribute[];
};
export type ContentVisibility = "visible" | "requiresLogin" | "hidden";

export type SegmentedContentType = "v2_article" | "productCategory" | "warning";

export type CustomerAttribute = "loggedIn" | "punchoutCustomer";

export type SegmentedEntryFields = {
   includeSegments?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeSegmentSkeleton>>;
   excludeSegments?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeSegmentSkeleton>>;
};
export type SegmentedEntrySkeleton = {
   fields: SegmentedEntryFields;
   contentTypeId: SegmentedContentType;
};

type SegmentStore = BaseStoreType & {
   currentCustomerProperties: CustomerSegment;
   articleSegments: {
      [id: string]: {
         include?: CustomerSegment;
         exclude?: CustomerSegment;
      };
   };
   currentPreviewSegment: PreviewSegment | undefined;

   isArticleVisible: (articleId: string) => boolean;
   getArticleVisibility: (articleId: string) => ContentVisibility;
   updateCurrentCustomerProperties: () => void;
   setArticleSegments: (articles: Entry<SegmentedEntrySkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>[]) => void;
   updateCurrentPreviewSegment: (previewSegment: PreviewSegment) => void;
   updateCurrentPreviewSegmentProperties: () => void;
};

const getCustomerAttributes = (): CustomerAttribute[] => {
   const attributes: CustomerAttribute[] = [];

   if (authStore.isLoggedIn()) {
      attributes.push("loggedIn");
   }
   if (authStore.isPunchoutSession()) {
      attributes.push("punchoutCustomer");
   }

   return attributes;
};

const segmentStore: SegmentStore = store({
   currentCustomerProperties: {
      customerNumber: [],
      chains: [],
      customerGroups: [],
      customerWarehouses: [],
      customerAttributes: []
   },
   articleSegments: {},
   currentPreviewSegment: undefined,

   isArticleVisible: (articleId: string) => {
      const currentCustomerProperties = segmentStore.currentCustomerProperties;
      const article = segmentStore.articleSegments[articleId];
      if (!article) {
         return false;
      }
      return getEntryVisibility(currentCustomerProperties, article) === "visible";
   },
   getArticleVisibility: (articleId: string): ContentVisibility => {
      const currentCustomerProperties = segmentStore.currentCustomerProperties;
      const article = segmentStore.articleSegments[articleId];
      if (!article) {
         return "hidden";
      }
      return getEntryVisibility(currentCustomerProperties, article);
   },

   updateCurrentCustomerProperties: () => {
      const company = authStore.getCurrentCompany();
      if (!company) {
         return;
      }

      segmentStore.currentCustomerProperties = {
         customerNumber: [company.customerNumber],
         chains: [
            keepIdOnly(company.businessLevels.level1),
            keepIdOnly(company.businessLevels.level2),
            keepIdOnly(company.businessLevels.level3),
            keepIdOnly(company.businessLevels.level4)
         ],
         customerGroups: [keepIdOnly(company.customerGroup)],
         customerWarehouses: [keepIdOnly(company.warehouse || "")],
         customerAttributes: getCustomerAttributes()
      };
      console.log("The current customer properties are: ", segmentStore.currentCustomerProperties);
   },
   updateCurrentPreviewSegmentProperties: () => {
      const previewSegment = segmentStore.currentPreviewSegment;
      segmentStore.currentCustomerProperties = {
         customerNumber: previewSegment?.customerNumber ? [previewSegment.customerNumber] : [],
         chains: previewSegment?.chain ? [previewSegment?.chain] : [],
         customerGroups: previewSegment?.customerGroup ? [previewSegment?.customerGroup] : [],
         customerWarehouses: previewSegment?.customerWarehouse ? [previewSegment?.customerWarehouse] : [],
         customerAttributes: getPreviewSegmentAttributes(
            segmentStore.currentPreviewSegment?.notLoggedIn,
            segmentStore.currentPreviewSegment?.punchoutCustomer
         )
      };
      console.log("The current preview segment properties are: ", segmentStore.currentCustomerProperties);
   },
   setArticleSegments: (articles) => {
      for (const article of articles) {
         const include = article.fields.includeSegments && convertEntriesToSegment(article.fields.includeSegments);
         const exclude = article.fields.excludeSegments && convertEntriesToSegment(article.fields.excludeSegments);
         segmentStore.articleSegments[article.sys.id] = { include, exclude };
      }
   },

   updateCurrentPreviewSegment: (previewSegment) => {
      segmentStore.currentPreviewSegment = previewSegment;
      segmentStore.updateCurrentPreviewSegmentProperties();
   },
   clearCompanySpecificData: () => {
      segmentStore.currentCustomerProperties = {
         customerNumber: [],
         chains: [],
         customerGroups: [],
         customerWarehouses: [],
         customerAttributes: []
      };
   }
});

export default segmentStore;
