import { view } from "@risingstack/react-easy-state";

import { ORDERTYPE } from "../../common/types/productOrderTypes";

import cartStore from "../../stores/cart/cartStore";

import theme from "../../themes/theme";
import Button from "../Button";
import ShortcutBox from "./ShortcutBox";

const ShortcutNewSubscription = () => {
   const handleStart = () => {
      cartStore.changeOrderType(ORDERTYPE.WAS);
   };

   const handleAbort = () => {
      cartStore.changeOrderType(theme.defaultOrderType);
   };

   if (cartStore.orderType === ORDERTYPE.WAS) {
      return (
         <ShortcutBox>
            <h4>Velg abonnementets startdato i kassen</h4>
            <p>
               Legg varer i handlekurven, gå til kassen og velg ukedag abonnementet skal leveres. Send inn ordren for å starte
               abonnementet.
            </p>
            <Button variant="secondary" onClick={handleAbort}>
               Tilbake til engangsordre
            </Button>
         </ShortcutBox>
      );
   }

   return (
      <ShortcutBox>
         <h4>Start nytt abonnement</h4>
         <p>Ønsker du å sette opp en levering som gjentas automatisk?</p>
         <Button onClick={handleStart}>Start abonnement</Button>
      </ShortcutBox>
   );
};

export default view(ShortcutNewSubscription);
