import { Tag } from "@tine/designsystem-ui-react";

import type { RecipeDifficultyType } from "../../../common/types/recipeTypes";
import { difficultyTexts } from "../../../common/utils/cmsStoreUtils";
import useSupportedLocale from "../../../common/utils/useSupportedLocale";

type RecipeDifficultyProps = {
   difficulty: RecipeDifficultyType;
};

const RecipeDifficulty = ({ difficulty }: RecipeDifficultyProps) => {
   const locale = useSupportedLocale();
   const text = difficultyTexts[locale][difficulty];
   return <Tag variant="base">{text}</Tag>;
};

export default RecipeDifficulty;
