import { useLocation } from "wouter";
import authStore from "../../stores/auth/authStore";
import featuresStore from "../../stores/features/featuresStore";
import theme from "../../themes/theme";
import { FEATURE_NAME } from "../types/featureTypes";

type MenuItem = {
   name: string;
   link: string;
   featureFlag?: boolean;
   hiddenOnPunchout?: boolean;
   isActive?: boolean;
};

const allMenuItems = (): MenuItem[] => [
   {
      name: "Brukere og innlogging",
      link: "/customer/account"
   },
   {
      name: "Kampanjer",
      link: "/customer/promotions",
      featureFlag: theme.promotionsPage.enabled
   },
   {
      name: "Leveringsdager",
      link: "/customer/deliveries"
   },
   {
      name: "Ordrer",
      link: "/customer/orders"
   },
   {
      name: "Favorittlister",
      link: "/customer/favorites"
   },
   {
      name: "Abonnement",
      link: "/customer/subscriptions",
      featureFlag: featuresStore.hasCustomerFeature(FEATURE_NAME.subscriptionOrderAvailable),
      hiddenOnPunchout: true
   },
   {
      name: "Fakturaer",
      link: "/customer/invoices",
      featureFlag: featuresStore.hasCustomerFeature(FEATURE_NAME.invoicePaymentAvailable)
   },
   {
      name: "Avtalt sortiment",
      link: "/customer/contractual",
      featureFlag: featuresStore.contractualRecommendations
   },
   {
      name: "Reklamasjoner",
      link: "/customer/claims",
      featureFlag: featuresStore.claimsEnabled
   },
   {
      name: "Varslinger",
      link: "/customer/notifications",
      featureFlag: featuresStore.notificationsEnabled
   },
   {
      name: "Nøkkeltall",
      link: "/customer/keyfigures",
      featureFlag: featuresStore.keyFiguresAvailable
   },
   {
      name: "Bedriftsinformasjon",
      link: "/customer/companyInfo"
   }
];

export const useMenuItems = () => {
   const [currentUrl] = useLocation();

   return allMenuItems()
      .filter((menuItem) => {
         if (menuItem.featureFlag !== undefined && !menuItem.featureFlag) return false;
         if (menuItem.hiddenOnPunchout && authStore.isPunchoutSession()) return false;
         return true;
      })
      .map((menuItem) => ({
         ...menuItem,
         isActive: currentUrl === menuItem.link
      }));
};
