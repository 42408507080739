import { store } from "@risingstack/react-easy-state";
import { add, sub } from "date-fns";
import isEmpty from "lodash-es/isEmpty";
import isNil from "lodash-es/isNil";
import type { ChangeEvent } from "react";

import { apiClient } from "../common/apiClient";
import {
   INVOICE_STATUS,
   type Invoice,
   type InvoiceFilter,
   type InvoicePeriod,
   type InvoiceQueryParams,
   type InvoiceStatus
} from "../common/types/invoiceTypes";
import {
   type AsyncData,
   initializeWithDefaultData,
   setAsDataAvailable,
   setAsErrorOccured,
   setAsWaitingForData
} from "../common/utils/asyncDataUtils";
import { formatISODate } from "../common/utils/dateUtils";

import { API_HOST } from "../common/environment";
import type { BaseStoreType } from "../common/types/BaseStoreType";
import theme from "../themes/theme";
import authStore from "./auth/authStore";

type InvoiceStore = BaseStoreType & {
   invoiceList: AsyncData<Invoice[]>;
   filters: InvoiceFilter;

   fetch(url: string, params: InvoiceQueryParams): Promise<void>;
   getStatuses(): InvoiceStatus[];
   getPredefindedIntervals(): InvoicePeriod[];
   fetchInvoices(queryParams?: InvoiceQueryParams): Promise<void>;
   searchInvoices(): Promise<void>;
   updateInvoiceNumberFilter(invoiceNumber: ChangeEvent<HTMLInputElement>): void;
   updateDueDateFilter(dates: [Date, Date]): void;
   updateMinPriceFilter(minPrice: ChangeEvent<HTMLInputElement>): void;
   updateMaxPriceFilter(maxPrice: ChangeEvent<HTMLInputElement>): void;
   updateStatusFilter(statusKey: string): void;
};

const emptyDefaultFilters: InvoiceFilter = {
   invoiceNumber: "",
   dueDate: { from: sub(new Date(), { months: 6 }), to: add(new Date(), { months: 2 }) },
   priceRange: { min: "", max: "" },
   statusKey: ""
};

const invoiceStore: InvoiceStore = store({
   invoiceList: initializeWithDefaultData([]),
   filters: emptyDefaultFilters,

   fetch: async (url, params: InvoiceQueryParams) => {
      if (!authStore.isLoggedIn() || isNil(authStore.currentCompany)) {
         return Promise.reject();
      }

      setAsWaitingForData(invoiceStore.invoiceList);

      try {
         const invoices: Invoice[] = await apiClient(url, authStore.getSessionToken()).query(params).get().json();
         const filteredInvoices = invoices.filter((invoice) => ("status" in params ? invoice.status === params.status : true));

         setAsDataAvailable(invoiceStore.invoiceList, filteredInvoices);
      } catch (errorObj) {
         setAsErrorOccured(invoiceStore.invoiceList, `${errorObj}`);
      }
   },

   getStatuses: () => [
      { value: INVOICE_STATUS.None, label: "Alle", color: "base" },
      { value: INVOICE_STATUS.Paid, label: "Betalt", color: "success" },
      {
         value: INVOICE_STATUS.PastDue,
         label: "Forfalt",
         color: "error"
      },
      { value: INVOICE_STATUS.ReminderSent, label: "Påminnelse sendt", color: "information" },
      { value: INVOICE_STATUS.Unpaid, label: "Ubetalt", color: "warning" },
      { value: INVOICE_STATUS.Credit, label: "Kreditert", color: "success" }
   ],

   getPredefindedIntervals: () => {
      const now = new Date();
      const oneMonthLater = add(now, { months: 1 });
      return [
         {
            value: [sub(now, { months: 1 }), oneMonthLater],
            text: "Siste måneden"
         },
         {
            value: [sub(now, { months: 3 }), oneMonthLater],
            text: "Siste tre måneder"
         },
         {
            value: [sub(now, { months: 6 }), oneMonthLater],
            text: "Siste halvåret"
         },
         {
            value: [sub(now, { years: 1 }), oneMonthLater],
            text: "Siste året"
         }
      ];
   },

   fetchInvoices: async (queryParams: InvoiceQueryParams) => {
      await invoiceStore.fetch(`${API_HOST}/api/invoices/customer/${authStore.currentCompany}`, queryParams);
   },

   searchInvoices: async () => {
      const invoiceNumber = invoiceStore.filters.invoiceNumber;
      const dueDates = invoiceStore.filters.dueDate;
      const fromInvoiceAmount = invoiceStore.filters.priceRange.min;
      const toInvoiceAmount = invoiceStore.filters.priceRange.max;
      const status = invoiceStore.filters.statusKey;
      const commonParameters = {
         companyNumber: theme.m3CompanyNumber,
         division: theme.m3DivisionNumber
      };
      const queryParams: InvoiceQueryParams =
         !isNil(invoiceNumber) && !isEmpty(invoiceNumber)
            ? {
                 invoiceNumber,
                 ...commonParameters
              }
            : {
                 ...commonParameters,
                 ...(dueDates?.from && { fromDueDate: formatISODate(dueDates.from) }),
                 ...(dueDates?.to && { toDueDate: formatISODate(dueDates.to) }),
                 ...(fromInvoiceAmount && { fromInvoiceAmount: Number.parseInt(fromInvoiceAmount) }),
                 ...(toInvoiceAmount && { toInvoiceAmount: Number.parseInt(toInvoiceAmount) }),
                 ...(status && status !== INVOICE_STATUS.None && { status })
              };

      await invoiceStore.fetchInvoices(queryParams);
   },

   updateInvoiceNumberFilter: (invoiceNumber) => {
      invoiceStore.filters.invoiceNumber = invoiceNumber.target.value;
   },

   updateDueDateFilter: (dates) => {
      if (dates.length >= 1) {
         invoiceStore.filters.dueDate.from = dates[0];
      }
      if (dates.length >= 2) {
         invoiceStore.filters.dueDate.to = dates[1];
      }
   },

   updateMinPriceFilter: (minPrice) => {
      invoiceStore.filters.priceRange.min = minPrice.target.value;
   },

   updateMaxPriceFilter: (maxPrice) => {
      invoiceStore.filters.priceRange.max = maxPrice.target.value;
   },

   updateStatusFilter: (statusKey) => {
      invoiceStore.filters.statusKey = statusKey;
   },

   clearCompanySpecificData() {
      invoiceStore.invoiceList = initializeWithDefaultData([]);
      invoiceStore.filters = emptyDefaultFilters;
   }
});

export default invoiceStore;
