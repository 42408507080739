import { cn } from "@tine/designsystem-utils";
import type { PropsWithChildren } from "react";

type PriceBombProps = {
   size: "small" | "large";
};

const PriceBomb = ({ size, children }: PropsWithChildren<PriceBombProps>) => {
   const priceBombClasses =
      "tw-flex tw-aspect-square tw-rounded-[50%] tw-absolute tw-bg-promotion tw-text-base-0 tw-font-semibold tw-text-center tw-justify-center tw-items-center tw-cursor-default";

   const priceBombSize = {
      small: "tw-w-[36%] tw-max-w-24 tw-bottom-5 tw-left-[10%] tw-p-1 tw-text-xs",
      large: "tw-w-[30%] tw-max-w-40 tw-bottom-[10%] tw-left-[10%] tw-p-5 tw-text-2xl"
   }[size];

   return <div className={cn(priceBombClasses, priceBombSize)}>{children}</div>;
};

export default PriceBomb;
