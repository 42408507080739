import { view } from "@risingstack/react-easy-state";
import { type ButtonProps, Button as TineButton } from "@tine/designsystem-ui-react";
import type { MouseEvent, PropsWithChildren } from "react";
import { navigate } from "wouter/use-browser-location";

import { sendButtonClick } from "../common/tracking";
import { isExternalLink } from "../common/utils";

const Button = ({
   size = "compact",
   iconPosition = "right",
   className = "",
   onClick,
   to,
   trackingName,
   children,
   ...rest
}: PropsWithChildren<ButtonProps> & { trackingName?: string }) => {
   const buttonName = trackingName ?? children?.toString();
   const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      sendButtonClick(buttonName);
      if (to && !isExternalLink(to)) {
         navigate(to);
         e.preventDefault();
      }
      onClick?.(e);
   };
   return (
      <TineButton size={size} iconPosition={iconPosition} className={className} onClick={handleClick} to={to} {...rest}>
         {children}
      </TineButton>
   );
};

export default view(Button);
