import { view } from "@risingstack/react-easy-state";
import { Helmet } from "react-helmet";

import type { ContentfulRecipe } from "../../../common/types/cmsTypes";
import type { RecipeType } from "../../../common/types/recipeTypes";
import { canonicalRecipeUrl } from "../../../common/utils/recipeUtils";

import theme from "../../../themes/theme";
import ContentBreadcrumb from "../ContentBreadcrumb";
import IngredientsAndInstructions from "./IngredientsAndInstructions";
import RecipeHeaderAndImage from "./RecipeHeaderAndImage";

type RecipeProps = {
   id: string;
   recipeEntry: ContentfulRecipe;
};

const Recipe = ({ recipeEntry }: RecipeProps) => {
   const dataFromContentful = recipeEntry.fields.recipeData;
   const recipe: RecipeType | undefined =
      dataFromContentful && !Array.isArray(dataFromContentful) ? (dataFromContentful as RecipeType) : undefined;

   if (!recipe) {
      return null;
   }

   const canonical = canonicalRecipeUrl(recipe);
   return (
      <div>
         <ContentBreadcrumb />
         <Helmet>
            <title>{`${recipe.displayName} - ${theme.siteName}`}</title>
            {!!canonical && <link rel="canonical" href={canonical} />}
         </Helmet>
         <RecipeHeaderAndImage recipe={recipe} />
         <IngredientsAndInstructions recipe={recipe} />
      </div>
   );
};

export default view(Recipe);
