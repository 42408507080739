import { view } from "@risingstack/react-easy-state";
import Skeleton from "react-loading-skeleton";

import { isDataAvailable, isDefaultData, isWaitingForData } from "../common/utils/asyncDataUtils";

import loginState from "../stores/auth/loginState";
import contentStore from "../stores/cms/contentStore";

import ErrorAlertWithOptionalTracking from "./ErrorAlertWithOptionalTracking";
import Loading from "./Loading";
import CmsContentFetchWrapper from "./cms/CmsContentFetchWrapper";

const NOT_LOGGED_IN_SLUG = "not_logged_in";

const NotLoggedIn = () => {
   const isLoggingIn = loginState.isLoggingIn();
   if (isLoggingIn) {
      return <Loading label={"Venter på innlogging..."} />;
   }
   if (isWaitingForData(contentStore.urlData) || isDefaultData(contentStore.urlData)) {
      return <Skeleton height={380} />;
   }
   if (isDataAvailable(contentStore.urlData)) {
      const urlData = contentStore.urlData.data.find((data) => data.slug === NOT_LOGGED_IN_SLUG);
      if (!urlData) {
         return (
            <ErrorAlertWithOptionalTracking
               showCloseButton={false}
               gaErrorTracking={{ context: "not_logged_in_page", text: "slug_not_found_error" }}
               className="tw-h-fit tw-w-full"
            >
               Du må logge inn for å se denne siden.
            </ErrorAlertWithOptionalTracking>
         );
      }

      return <CmsContentFetchWrapper id={urlData.id} />;
   }
   return (
      <ErrorAlertWithOptionalTracking
         showCloseButton={false}
         gaErrorTracking={{ context: "not_logged_in_page", text: "page_load_error" }}
      >
         Det oppsto en uventet feil under lasting av siden.
      </ErrorAlertWithOptionalTracking>
   );
};

export default view(NotLoggedIn);
