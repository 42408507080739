import type { Placement } from "@popperjs/core";
import { cn } from "@tine/designsystem-utils";
import { type CSSProperties, type ComponentType, type HTMLAttributes, useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";

import useOutsideClick from "../../../common/useOutsideClick";

import Arrow from "./arrows/Arrow";

export type PopoverProps = HTMLAttributes<HTMLDivElement> & {
   show: boolean;
   onHide: () => void;
   placement?: Placement;
   referenceElement: HTMLElement | null;
   arrowStyles?: Partial<{ [orientation in PopoverOrientation]: CSSProperties }>;
   container?: ComponentType<HTMLAttributes<HTMLDivElement>>;
};

export type PopoverOrientation = "top" | "bottom" | "left" | "right";

const containerCN = cn([
   "tw-border-ink-border-subtle",
   "tw-border tw-border-solid",
   "tw-rounded-lg",
   "tw-overflow-hidden",
   "tw-bg-surface-default",
   "tw-shadow-lg"
]);

const getOrientation = (placement: string): PopoverOrientation => {
   if (placement.startsWith("top")) {
      return "top";
   }
   if (placement.startsWith("left")) {
      return "left";
   }
   if (placement.startsWith("right")) {
      return "right";
   }
   return "bottom";
};

const arrowAdjustmentCN = {
   top: "tw-mb-[--popover-arrow-height]",
   bottom: "tw-mt-[--popover-arrow-height]",
   left: "tw-mr-[--popover-arrow-height]",
   right: "tw-ml-[--popover-arrow-height]"
};

const Popover = ({
   placement = "bottom-start",
   referenceElement,
   className = "",
   container: Container,
   arrowStyles = {},
   show,
   onHide,
   children
}: PopoverProps) => {
   const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
   const arrowRef = useRef<HTMLDivElement>(null);
   const popperRef = useRef<HTMLDivElement | null>(popperElement);
   const referenceRef = useRef<HTMLElement | null>(referenceElement);

   const { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement,
      modifiers: [{ name: "arrow", options: { element: arrowRef.current } }]
   });
   const [currentOrientation, setCurrentOrientation] = useState<PopoverOrientation>(getOrientation(placement));
   useOutsideClick([popperRef, referenceRef], () => {
      onHide();
   });

   useEffect(() => {
      referenceRef.current = referenceElement;
   }, [referenceElement]);

   useEffect(() => {
      popperRef.current = popperElement;
   }, [popperElement]);

   useEffect(() => {
      const currentPlacement = attributes?.popper?.["data-popper-placement"];
      if (currentPlacement) {
         setCurrentOrientation(getOrientation(currentPlacement));
      }
   }, [attributes]);

   useEffect(() => {
      const keydownHandler = (e: KeyboardEvent) => {
         if (e.key === "Escape") {
            onHide();
         }
      };
      if (show) {
         document.addEventListener("keydown", keydownHandler);
      } else {
         document.removeEventListener("keydown", keydownHandler);
      }
      return () => removeEventListener("keydown", keydownHandler);
   }, [show]);

   if (!show) {
      return null;
   }

   return (
      <div
         ref={setPopperElement}
         style={styles.popper}
         {...attributes.popper}
         className={cn([className, "tw-z-[1000]", arrowAdjustmentCN[currentOrientation]])}
      >
         {!Container && <div className={containerCN}>{children}</div>}
         {!!Container && (
            <Container>
               <div className={cn(containerCN, "tw-w-full")}>{children}</div>
            </Container>
         )}
         <Arrow
            ref={arrowRef}
            orientation={currentOrientation}
            style={{ ...(arrowStyles[currentOrientation] || {}), ...styles.arrow }}
         />
      </div>
   );
};

export default Popover;
