import { store } from "@risingstack/react-easy-state";
import isNil from "lodash-es/isNil";
import sortBy from "lodash-es/sortBy";

import { apiClient } from "../common/apiClient";
import type { BaseStoreType } from "../common/types/BaseStoreType";
import type { User } from "../common/types/accessTypes";
import {
   type AsyncData,
   initializeWithDefaultData,
   setAsDataAvailable,
   setAsErrorOccured,
   setAsWaitingForData
} from "../common/utils/asyncDataUtils";

import { API_HOST } from "../common/environment";
import theme from "../themes/theme";
import authStore from "./auth/authStore";

type AccessStore = BaseStoreType & {
   users: AsyncData<User[]>;
   pleaseWait: boolean;
   skeletonEstimate: number;
   getUsers(): Promise<void>;
   removeUser(email: string): Promise<void>;
   addUser(firstName: string, lastName: string, email: string): Promise<void>;
   changeDefaultUser(newDefaultUserID: string): Promise<void>;
};

const accessStore: AccessStore = store({
   users: initializeWithDefaultData([]),
   pleaseWait: false,
   skeletonEstimate: 1,

   getUsers: async () => {
      const token = authStore.getSessionToken();
      const url = `${API_HOST}/api/auth/${theme.storeId}/company/${authStore.currentCompany}/users`;

      setAsWaitingForData(accessStore.users);
      if (isNil(url) || isNil(token)) {
         setAsErrorOccured(accessStore.users, "Feil under henting av brukere. Prøv å logge ut og inn igjen.");
         return;
      }

      try {
         const users: User[] = await apiClient(url, token).get().json();
         setAsDataAvailable(accessStore.users, sortBy(users, ["email"]));
         accessStore.skeletonEstimate = users.length;
      } catch (err) {
         console.warn("Unable to fetch users", err);
         setAsErrorOccured(accessStore.users, `Kunne ikke hente brukere. Teknisk feil: ${err}`);
      }
   },

   removeUser: async (email) => {
      if (!authStore.isLoggedIn() || isNil(authStore.currentCompany)) {
         console.warn("Cannot remove users without being logged in");
         throw new Error("Cannot remove users without being logged in");
      }

      const url = `${API_HOST}/api/auth/${theme.storeId}/company/${authStore.currentCompany}/users`;
      const token = authStore.getSessionToken();

      try {
         accessStore.pleaseWait = true;
         accessStore.skeletonEstimate--;
         await apiClient(url, token).query({ email }).delete().json();
         return;
      } finally {
         accessStore.pleaseWait = false;
      }
   },

   addUser: async (firstName, lastName, email) => {
      if (!authStore.isLoggedIn() || isNil(authStore.currentCompany)) {
         console.warn("Cannot add users without being logged in");
         throw new Error("Cannot add users without being logged in");
      }

      const url = `${API_HOST}/api/auth/${theme.storeId}/company/${authStore.currentCompany}/users`;
      const token = authStore.getSessionToken();

      try {
         accessStore.pleaseWait = true;
         accessStore.skeletonEstimate++;
         await apiClient(url, token).query({ email, firstName, lastName }).post().json();
         return;
      } finally {
         accessStore.pleaseWait = false;
      }
   },
   changeDefaultUser: async (newDefaultUserID) => {
      if (!authStore.isLoggedIn() || isNil(authStore.currentCompany)) {
         console.warn("Cannot change default user without being logged in");
         throw new Error("Cannot change default user without being logged in");
      }

      const url = `${API_HOST}/api/auth/${theme.storeId}/company/${authStore.currentCompany}/defaultuser/${newDefaultUserID}`;
      const token = authStore.getSessionToken();

      try {
         accessStore.pleaseWait = true;
         await apiClient(url, token).query({ newDefaultUserID }).post().json();
         return;
      } finally {
         accessStore.pleaseWait = false;
      }
   },
   clearCompanySpecificData: () => {
      accessStore.users = initializeWithDefaultData([]);
   }
});

export default accessStore;
