import { jsxs as w, jsx as e } from "react/jsx-runtime";
import { cn as p } from "./designsystem-ui-react46.js";
import { Button as d } from "./designsystem-ui-react6.js";
import g from "./designsystem-ui-react83.js";
import o from "./designsystem-ui-react84.js";
function f({
  children: a,
  className: r,
  id: s,
  sortable: n = !1,
  sort: t,
  onSortClick: i,
  ref: l,
  ...m
}) {
  const c = t === "ascending" || t === "descending" ? {
    ascending: /* @__PURE__ */ e(o, {}),
    descending: /* @__PURE__ */ e(o, { className: "tw-rotate-180" })
  }[t] : /* @__PURE__ */ e(g, {});
  return /* @__PURE__ */ w(
    "th",
    {
      "aria-sort": t,
      className: p("tw-p-4 tw-text-sm max-lg:tw-hidden tw-table-cell", n && "", t && "", r),
      "data-cell": a,
      id: s,
      ref: l,
      scope: "row",
      ...m,
      children: [
        n ? /* @__PURE__ */ e(
          d,
          {
            className: "tw-font-semibold tw-text-sm tw-bg-base-transparent !tw-p-1 [&>svg]:tw-flex-shrink-0",
            icon: c,
            iconPosition: "right",
            onClick: i,
            variant: "tertiary",
            children: a
          }
        ) : null,
        !n && a
      ]
    }
  );
}
f.displayName = "TableHeaderCell";
export {
  f as TableHeaderCell
};
