import { isFuture, parseISO, parseJSON } from "date-fns";

import type { DeliveryDateResponse, PickupData } from "../../common/types/deliveryTypes";

export const SIXTY_MINUTES = 1000 * 60 * 60;
export const TEN_MINUTES = 1000 * 60 * 10;
export const MORE_THAN_SIXTY_MINUTES = Number.MAX_SAFE_INTEGER;

export const parse2022DeliveryDates = (resp: DeliveryDateResponse) => {
   return resp.deliveryDates
      .filter((d) => isFuture(parseJSON(d.deadline)))
      .map((d) => ({
         date: parseISO(d.deliveryDate),
         deadline: parseJSON(d.deadline),
         price: 0
      }));
};
export const parse2022DeliveryDatesForSubscription = (resp: DeliveryDateResponse) => {
   return resp.deliveryDates
      .filter((d) => d.subscriptionDeadline !== null && isFuture(parseJSON(d.subscriptionDeadline)))
      .map((d) => ({
         date: parseISO(d.deliveryDate),
         deadline: parseJSON(d.subscriptionDeadline),
         price: 0
      }));
};
export const parse2022PickupDates = (pickupData: PickupData) => {
   return pickupData.pickupAt.map((pickupAlt) => ({
      date: parseJSON(pickupAlt.pickupAfter),
      deadline: parseJSON(pickupAlt.orderBefore),
      price: 0
   }));
};

const toMinutesAndSeconds = (periodInMilliseconds: number) => {
   const minutes = Math.floor(periodInMilliseconds / (1000 * 60));
   const seconds = Math.floor((periodInMilliseconds % (1000 * 60)) / 1000);
   return {
      minutes,
      seconds
   };
};
// Used for Deadline component, only formats min/sek for less than equal 1 hour.
export const formatTimePeriod = (periodInMilliseconds: number): string => {
   if (periodInMilliseconds > 0) {
      if (periodInMilliseconds === MORE_THAN_SIXTY_MINUTES) {
         return "mer enn 60 min";
      }
      const { minutes, seconds } = toMinutesAndSeconds(periodInMilliseconds);
      return `${minutes} min ${seconds} sek`;
   }
   return "";
};
