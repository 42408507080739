import { store } from "@risingstack/react-easy-state";
import find from "lodash-es/find";
import flattenDeep from "lodash-es/flattenDeep";
import has from "lodash-es/has";
import isNil from "lodash-es/isNil";
import isObject from "lodash-es/isObject";
import memoize from "lodash-es/memoize";
import { apiClient } from "../../common/apiClient";
import { ENV_NAME, STATICDATA_HOST } from "../../common/environment";
import { PageNotFoundError } from "../../common/errors";
import type { BaseStoreType } from "../../common/types/BaseStoreType";
import type { CategoryInfo, CategoryQuery, CategoryResponse } from "../../common/types/categoryTypes";
import uiStore from "../uiStore";
import { searchCategoryRescursive, setHiddenStatus } from "./categoryUtils";

type CategoryStore = BaseStoreType & {
   categories: CategoryInfo[];
   mobile: {
      visible: boolean;
      path: string[];
   };
   showMenu(setTo: boolean): void;
   findCategoryByUrlKey(urlKeys: CategoryQuery): CategoryResponse | null;
   findCategoryKeysBySku(sku: string): CategoryQuery[];
   hideEmptyCategories(): void;
   fillStore(): Promise<void>;
};

const categoryStore: CategoryStore = store({
   categories: [],
   mobile: {
      visible: false,
      path: []
   },

   // Used for the mobile menu that slides in
   showMenu: (setTo) => {
      categoryStore.mobile.visible = setTo;
   },

   findCategoryByUrlKey: (urlKeys) => {
      const levels: CategoryInfo[] = [];
      let category = find(categoryStore.categories, { url_key: urlKeys.cat1 });
      if (isNil(category)) {
         throw new PageNotFoundError();
      }
      levels.push(category);

      if (has(urlKeys, "cat2")) {
         const category2 = find(category.children, { url_key: urlKeys.cat2 });
         if (!isNil(category2)) {
            category = category2;
            levels.push(category2);
         } else {
            throw new PageNotFoundError();
         }
      }

      if (has(urlKeys, "cat3")) {
         const category3 = find(category.children, { url_key: urlKeys.cat3 });
         if (!isNil(category3)) {
            category = category3;
            levels.push(category3);
         } else {
            throw new PageNotFoundError();
         }
      }

      return {
         category,
         levels
      };
   },

   findCategoryKeysBySku: memoize((lookForSku) => {
      return flattenDeep(categoryStore.categories.map((cat) => searchCategoryRescursive(lookForSku, cat, 1))).filter(isObject);
   }),

   hideEmptyCategories: () => {
      for (let i = 0; i < categoryStore.categories.length; i++) {
         setHiddenStatus(categoryStore.categories[i]);
      }
   },

   fillStore: () => {
      const categoryDataUrl = `${STATICDATA_HOST}/categories.${ENV_NAME}_${uiStore.dataVersion}.json`;
      console.log(`Fetching categories from ${categoryDataUrl}`);

      return apiClient(categoryDataUrl)
         .get()
         .json((res) => {
            categoryStore.categories = res;
            console.log(`Categories loaded: ${categoryStore.categories.length} root categories`);
         });
   },
   clearCompanySpecificData: () => {}
});

export default categoryStore;
