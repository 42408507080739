import { IconArrowForward } from "@tine/designsystem-icons/sharp";
import { Grid, Group, Stack } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import type React from "react";
import { Link } from "wouter";

import useMediaQuery from "../../common/useMediaQuery";
import { mobileBreakpoint } from "../../common/utils";

export type ListItemProps = {
   title: string;
   titleCentered?: boolean;
   link?: string;
   onClick?: () => void;
   subtitles?: React.ReactNode;
   icon?: React.ReactNode;
   midColumn?: React.ReactNode;
   endColumn?: React.ReactNode;
   actions?: React.ReactNode;
   actionText?: string;
   marked?: boolean;
};

export const AccountListItemClasses = "tw-mb-2 tw-rounded-lg tw-p-4 tw-shadow-sm ";

const TitleColumn = ({ title, subtitles, icon }: ListItemProps) => {
   const subtitleList = Array.isArray(subtitles) ? subtitles : subtitles ? [subtitles] : [];
   return (
      <Group justify="start" gap={4}>
         {icon && <div className="tw-text-muted-foreground tw-hidden sm:tw-block">{icon}</div>}
         <Stack justify="start" align="start" gap={0}>
            {title && <span className={cn("tw-font-semibold", { "tw-mb-0": !subtitles })}>{title}</span>}
            {subtitles && (
               <Stack gap={2} align="start">
                  {subtitleList.filter((subtitle) => subtitle)}
               </Stack>
            )}
         </Stack>
      </Group>
   );
};

const AccountListItemContent = ({ title, titleCentered = false, subtitles, icon, midColumn, endColumn }: ListItemProps) => {
   const isMobile = useMediaQuery(mobileBreakpoint);
   return (
      <Grid cols={3} gap={1} className={cn("tw-w-full", { "tw-grid-cols-[1fr_fit-content(60px)]": isMobile })}>
         <Grid.Col order={1} span={isMobile ? 1 : midColumn ? 1 : 2} className={cn({ "tw-flex tw-items-center": titleCentered })}>
            <TitleColumn title={title} subtitles={subtitles} icon={icon} />
         </Grid.Col>
         {midColumn && (
            <Grid.Col order={{ base: 3, sm: 2 }} span={{ base: 2, sm: 1 }} className="tw-mt-2 tw-flex tw-items-center sm:tw-mt-0">
               {midColumn}
            </Grid.Col>
         )}
         {endColumn && (
            <Grid.Col order={{ base: 2, sm: 3 }} span={1} className="tw-flex tw-items-start tw-justify-end sm:tw-items-center">
               {endColumn}
            </Grid.Col>
         )}
      </Grid>
   );
};

const ArrowAndOptionalText = ({ actionText }: { actionText?: string }) => {
   return (
      <Group gap={2}>
         {actionText && <span className="tw-hidden md:tw-block">{actionText}</span>}
         <IconArrowForward />
      </Group>
   );
};

export const AccountListItemWithNavigation = ({
   title,
   titleCentered = false,
   subtitles,
   icon,
   midColumn,
   actionText,
   onClick,
   link,
   marked = false
}: ListItemProps) => (
   <li
      key={title}
      className={cn(AccountListItemClasses, "hover:tw-cursor-pointer hover:tw-bg-surface-default-hover", {
         "tw-bg-surface-default": !marked,
         "tw-text-button-tertiary-bg-hover": marked
      })}
      data-testid="list-item-with-navigation"
   >
      {link ? (
         <Link to={link} className="tw-no-underline" onClick={onClick}>
            <AccountListItemContent
               title={title}
               titleCentered={titleCentered}
               subtitles={subtitles}
               icon={icon}
               midColumn={midColumn}
               endColumn={<ArrowAndOptionalText actionText={actionText} />}
            />
         </Link>
      ) : (
         <button type="button" className="tw-size-full tw-text-left" onClick={onClick}>
            <AccountListItemContent
               title={title}
               titleCentered={titleCentered}
               subtitles={subtitles}
               icon={icon}
               midColumn={midColumn}
               endColumn={<ArrowAndOptionalText actionText={actionText} />}
            />
         </button>
      )}
   </li>
);

export const AccountListItemWithButtonActions = ({
   title,
   titleCentered = false,
   subtitles,
   icon,
   midColumn,
   actions,
   marked = false
}: ListItemProps) => (
   <li
      key={title}
      className={cn(AccountListItemClasses, {
         "tw-bg-surface-default": !marked,
         "tw-bg-button-tertiary-bg-hover": marked
      })}
      data-testid="list-item-with-button"
   >
      <AccountListItemContent
         title={title}
         titleCentered={titleCentered}
         subtitles={subtitles}
         icon={icon}
         midColumn={midColumn}
         endColumn={actions}
      />
   </li>
);
