import { view } from "@risingstack/react-easy-state";
import { Tag } from "@tine/designsystem-ui-react";
import groupBy from "lodash-es/groupBy";
import includes from "lodash-es/includes";
import map from "lodash-es/map";
import size from "lodash-es/size";

import type { Label, PageType } from "../common/types/productTypes";

type PositionType = "top-left" | "top-right" | "bottom-left" | "bottom-right";

const mapLabelTextColor = (label: Label) => {
   if (label.color === "red") {
      return "var(--color-tine-red-600)";
   }

   if (label.color === "blue") {
      return "var(--color-tine-blue-500)";
   }

   if (label.color === "green") {
      return "var(--color-forest-green-900)";
   }

   if (label.color === "gray") {
      return "var(--color-base-700)";
   }

   if (label.color === "moss") {
      return "var(--color-moss-green-900)";
   }

   return "var(--color-base-700)";
};

const LabelComponent = ({ label }: { label: Label }) => {
   return (
      <Tag variant="base" textColor={mapLabelTextColor(label)}>
         {label.text}
      </Tag>
   );
};

interface Props {
   labels: Label[];
   sku: string;
   page: PageType;
   className?: string;
}

const positionStyles: { [position in PositionType]: string } = {
   "top-left": "tw-left-0 tw-top-0",
   "top-right": "tw-right-0 tw-top-0",
   "bottom-left": "tw-left-0 tw-bottom-2",
   "bottom-right": "tw-right-0 tw-bottom-2"
};

const Labels = ({ labels, page, className = "", sku }: Props) => {
   //TODO: Create labels based on voucher/campaigns
   const voucherLabels: Label[] = [];
   labels = [...labels, ...voucherLabels];
   const relevantLabels = groupBy(
      labels.filter((l) => includes(l.page, page)),
      "position"
   );

   if (size(relevantLabels) === 0) {
      return null;
   }

   return (
      <>
         {map(relevantLabels, (labels: Label[], position: PositionType) => (
            <div key={position} className={`tw-absolute tw-flex tw-flex-col ${positionStyles[position]} ${className}`}>
               {labels.map((label, index) => (
                  <LabelComponent key={`${label.text}${index + 1}`} label={label} />
               ))}
            </div>
         ))}
      </>
   );
};

export default view(Labels);
