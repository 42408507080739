import { jsx as t, jsxs as r } from "react/jsx-runtime";
import { cn as a } from "./designsystem-ui-react46.js";
import { useState as s, useEffect as f } from "react";
import C from "./designsystem-ui-react79.js";
import { useYouTubePlayer as D } from "./designsystem-ui-react80.js";
import { VideoControls as j } from "./designsystem-ui-react81.js";
import { Button as g } from "./designsystem-ui-react6.js";
import R from "./designsystem-ui-react82.js";
function _({ videoId: i, poster: c, width: l = "100%" }) {
  const {
    playerRef: n,
    isPlaying: u,
    hasStarted: d,
    duration: h,
    currentTime: p,
    isLoading: y,
    loadedFraction: v,
    playButtonText: b,
    handleReady: P,
    handleStateChange: k,
    seekTo: E,
    startSeeking: x,
    seekWhileDragging: S,
    endSeeking: N
  } = D(), [o, F] = s(!1), [m, I] = s({
    isIPhone: !1
  }), [L, z] = s(!1);
  f(() => {
    if (z(!0), window != null && window.navigator) {
      const e = window.navigator.userAgent;
      I({
        isIPhone: e.includes("iPhone")
      });
    }
  }, []), f(() => {
    const e = () => {
      const T = !!(document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement || document.mozFullScreenElement);
      F(T);
    };
    return document.addEventListener("fullscreenchange", e), document.addEventListener("webkitfullscreenchange", e), document.addEventListener("msfullscreenchange", e), document.addEventListener("mozfullscreenchange", e), () => {
      document.removeEventListener("fullscreenchange", e), document.removeEventListener("webkitfullscreenchange", e), document.removeEventListener("msfullscreenchange", e), document.removeEventListener("mozfullscreenchange", e);
    };
  }, []);
  const V = () => {
    n.current && (u ? n.current.pauseVideo() : n.current.playVideo());
  }, B = () => {
    n.current && (m.isIPhone && n.current.loadVideoById({
      videoId: i,
      startSeconds: 0
    }), setTimeout(() => {
      var e;
      (e = n.current) == null || e.playVideo();
    }, 100));
  }, w = !!(c && !d);
  return L ? /* @__PURE__ */ t(
    "div",
    {
      className: a(
        "video-player-container tw-mx-auto tw-w-full tw-max-w-4xl",
        o && "tw-fixed tw-inset-0 tw-max-w-none tw-bg-black tw-m-0 tw-flex tw-items-center tw-justify-center"
      ),
      children: /* @__PURE__ */ r(
        "div",
        {
          className: a(
            "tw-relative tw-overflow-hidden tw-bg-black",
            d ? "tw-rounded-t-lg" : "tw-rounded-lg",
            o && "tw-rounded-none"
          ),
          style: { width: typeof l == "number" ? `${l}px` : l },
          children: [
            /* @__PURE__ */ r("div", { className: "tw-relative", style: { paddingBottom: "56.25%" }, children: [
              w ? /* @__PURE__ */ r("div", { className: "tw-absolute tw-inset-0 tw-z-20 -tw-mb-10 tw-bg-black", children: [
                /* @__PURE__ */ t(
                  "div",
                  {
                    className: "tw-absolute tw-inset-0 tw-bg-cover tw-bg-center",
                    style: { backgroundImage: `url(${c})` }
                  }
                ),
                /* @__PURE__ */ t(
                  g,
                  {
                    className: "tw-absolute tw-bottom-6 tw-left-6 tw-z-20",
                    icon: /* @__PURE__ */ t(R, {}),
                    iconPosition: "left",
                    onClick: B,
                    size: "regular",
                    variant: "primary",
                    children: b
                  }
                )
              ] }) : null,
              /* @__PURE__ */ t("div", { className: a("tw-absolute tw-inset-0 tw-z-10", w ? "tw-invisible" : ""), children: /* @__PURE__ */ t(
                C,
                {
                  allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
                  allowFullScreen: !0,
                  className: "tw-absolute tw-inset-0 tw-w-full tw-h-full",
                  onReady: P,
                  onStateChange: k,
                  opts: {
                    width: "100%",
                    height: "100%",
                    playerVars: {
                      autoplay: 0,
                      controls: 0,
                      modestbranding: 1,
                      rel: 0,
                      fs: 1,
                      playsinline: m.isIPhone ? 0 : 1,
                      // Iphone don't support fullscreen, so we need to use the native player controls
                      enablejsapi: 1,
                      showinfo: 0,
                      iv_load_policy: 3
                    }
                  },
                  videoId: i
                }
              ) }),
              y ? /* @__PURE__ */ t("div", { className: "tw-absolute tw-top-1/2 tw-left-1/2 tw--translate-x-1/2 tw--translate-y-1/2", children: /* @__PURE__ */ t(g, { loading: !0, size: "iconOnly", variant: "primary" }) }) : null
            ] }),
            /* @__PURE__ */ t(
              j,
              {
                currentTime: p,
                duration: h,
                isFullscreen: o,
                isPlaying: u,
                loadedFraction: v,
                onDragEnd: N,
                onDragStart: x,
                onDragging: S,
                onPlayPause: V,
                onSeek: E,
                player: n.current
              }
            )
          ]
        }
      )
    }
  ) : /* @__PURE__ */ t("div", {});
}
_.displayName = "VideoPlayer";
export {
  _ as VideoPlayer
};
