import { cn } from "@tine/designsystem-utils";
import type { CSSProperties } from "react";

import type { DayFlagLabel } from "../../../common/types/calendarTypes";

type CalendarKeysContext = "default" | "delivery-date-picker";

type Size = "compact" | "regular";

const style: CSSProperties & Record<string, string> = {
   "--button-border-radius": "0.25rem"
};
export type CalendarKeysProps = {
   flagLabels: DayFlagLabel[];
   context?: CalendarKeysContext;
   size?: Size;
};

const CalendarKey = ({ flag, label, size }: DayFlagLabel & { size: Size }) => {
   const keyClassName = cn("tw-rounded-button tw-border-2 tw-border-solid tw-leading-none", {
      "tw-h-7 tw-w-7 tw-px-button-compact-padding-x tw-py-button-compact-padding-y": size === "compact",
      "tw-h-8 tw-w-8 tw-px-button-regular-padding-x tw-py-button-regular-padding-y": size === "regular",
      "tw-border-calendar-selected-bg tw-bg-calendar-selected-bg tw-text-calendar-selected-text": flag === "selected",
      "tw-border-button-secondary-border tw-bg-button-secondary-bg tw-text-button-secondary-text": flag === "secondary",
      "tw-border-calendar-cancelled-border tw-bg-calendar-cancelled-bg tw-text-calendar-cancelled-text": flag === "cancelled",
      "tw-bg-calendar-selectable-bg tw-border-0": flag === "selectable"
   });
   return (
      <div
         role="presentation"
         className={cn("tw-flex tw-flex-grow-0 tw-items-center tw-gap-1", { "tw-text-sm": size === "compact" })}
         style={style}
      >
         <div className={keyClassName} />
         {label}
      </div>
   );
};

const CalendarKeys = ({ flagLabels, size = "compact" }: CalendarKeysProps) => {
   return (
      <>
         {flagLabels.map(({ flag, label }) => (
            <CalendarKey key={flag} flag={flag} label={label} size={size} />
         ))}
      </>
   );
};

export default CalendarKeys;
