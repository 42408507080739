import { ENV_NAME } from "../environment";
import type { DynamicsCaptureConfig, DynamicsCaptureMapping, DynamicsFormCaptureType } from "../types/dynamicsTypes";

export const getCaptureMappings = (type: DynamicsFormCaptureType): DynamicsCaptureMapping[] => {
   if (["slot", "main"].includes(type)) {
      return [
         {
            FormFieldName: "company",
            DataverseFieldName: "ava_parentaccountidstringvalue",
            label: "",
            hidden: true
         },
         {
            FormFieldName: "description",
            DataverseFieldName: type === "main" ? "subject" : "description",
            label: "",
            hidden: true
         },
         {
            FormFieldName: "firstname",
            DataverseFieldName: "firstname",
            label: "Fornavn",
            validation: "mandatory"
         },
         {
            FormFieldName: "lastname",
            DataverseFieldName: "lastname",
            label: "Etternavn",
            validation: "mandatory"
         },
         {
            FormFieldName: "email",
            DataverseFieldName: "emailaddress1",
            label: "E-post",
            validation: "email"
         }
      ];
   }

   return [];
};

const isProd = ENV_NAME === "prod";
const dynamicsOrg = isProd ? "1e640fbe-4ea8-4ae5-a093-a84e6f604838" : "d266f74e-f7eb-43f2-8753-984c8a77eac6";
export const dynamicsEndpoint = `https://public-eur.mkt.dynamics.com/api/v1.0/orgs/${dynamicsOrg}/landingpageforms`;
export const scriptUrl = "https://cxppusa1formui01cdnsa01-endpoint.azureedge.net/eur/FormCapture/FormCapture.bundle.js";

export const getCaptureConfig = (type: DynamicsFormCaptureType): DynamicsCaptureConfig => {
   return {
      slot: {
         FormId: isProd ? "4c53cb58-1987-ef11-ac21-6045bddd37a5" : "84cc9f07-8f6e-ef11-a671-6045bd8f5d4f",
         FormApiUrl: dynamicsEndpoint
      },
      main: {
         FormId: isProd ? "70637140-7659-ef11-bfe3-000d3ab0cc72" : "56486e50-9b5f-ef11-bfe3-000d3a273416",
         FormApiUrl: dynamicsEndpoint
      }
   }[type];
};
