import { jsx as t, jsxs as s } from "react/jsx-runtime";
import { cn as l } from "./designsystem-ui-react46.js";
import { Tag as b } from "./designsystem-ui-react39.js";
const w = (e, i, n = !1) => typeof e > "u" ? "" : typeof e == "string" && n ? e : typeof e == "object" ? e[i] ?? "" : "";
function v({
  title: e,
  description: i,
  image: n,
  imageAlt: p,
  variant: u = "white",
  hasShadow: f = !0,
  href: c,
  className: r,
  tags: a,
  forceVertical: o = !1,
  aspectRatio: x = "",
  ...m
}) {
  const h = {
    white: "tw-bg-surface-default tw-text-ink-brand-default",
    transparent: "tw-bg-base-transparent",
    inverted: "tw-bg-button-primary-bg tw-text-button-primary-text"
  }[u];
  return /* @__PURE__ */ t("a", { href: c, children: /* @__PURE__ */ t(
    "article",
    {
      className: l(
        "tw-@container tw-rounded-card-lg tw-overflow-clip tw-w-full tw-group tw-inline-block tw-group",
        f && "tw-drop-shadow-md",
        h,
        w(r, "container", !0)
      ),
      ...m,
      children: /* @__PURE__ */ s("div", { className: l("tw-flex", o ? "tw-flex-col" : "@sm:tw-flex-col"), children: [
        /* @__PURE__ */ t(
          "div",
          {
            className: l(
              "tw-flex-shrink-0",
              o ? "tw-w-full" : ["tw-w-32", "@sm:tw-w-full"],
              "tw-relative",
              o ? "tw-aspect-5/4" : ["tw-aspect-square", "@sm:tw-aspect-video"],
              "tw-overflow-hidden",
              w(r, "image")
            ),
            style: { aspectRatio: x },
            children: typeof n == "string" ? /* @__PURE__ */ t(
              "img",
              {
                alt: p,
                className: "tw-size-full tw-absolute tw-inset-0 tw-object-cover group-hover:tw-scale-105 tw-duration-300",
                src: n
              }
            ) : /* @__PURE__ */ t("div", { className: "tw-w-full tw-h-full", children: n })
          }
        ),
        /* @__PURE__ */ s(
          "div",
          {
            className: l(
              "tw-flex tw-flex-col tw-gap-2 tw-p-3",
              o ? "tw-px-4 tw-pt-4 tw-pb-5" : ["@sm:tw-px-4", "@sm:tw-pt-4", "@sm:tw-pb-5"],
              "@xl:tw-items-start @xl:tw-justify-center",
              w(r, "content")
            ),
            children: [
              a ? /* @__PURE__ */ t("div", { className: l("tw-flex tw-gap-x-2", w(r, "tags")), children: a.map((d) => /* @__PURE__ */ t(b, { textColor: "var(--color-ink-brand-default)", variant: "base", children: d }, d)) }) : null,
              e ? /* @__PURE__ */ t(
                "h2",
                {
                  className: l(
                    "tw-text-xl @sm:tw-text-2xl tw-m-0 tw-line-clamp-2 @xl:tw-line-clamp-3 group-hover:tw-underline tw-underline-offset-4 tw-font-bold",
                    w(r, "title")
                  ),
                  children: e
                }
              ) : null,
              i ? /* @__PURE__ */ t("div", { className: l("tw-hidden tw-text-lg @sm:tw-line-clamp-2", w(r, "description")), children: i }) : null
            ]
          }
        )
      ] })
    }
  ) });
}
v.displayName = "ContentCard";
export {
  v as ContentCard
};
