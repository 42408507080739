import { IconSpinner } from "@tine/designsystem-icons/sharp";
import { Breadcrumbs } from "@tine/designsystem-ui-react";
import { Link, useLocation } from "wouter";

import { isDataAvailable } from "../../common/utils/asyncDataUtils";

import contentStore from "../../stores/cms/contentStore";

const ContentBreadcrumb = () => {
   const [fullUrl] = useLocation();

   if (!isDataAvailable(contentStore.urlData)) {
      return <IconSpinner className="tw-animate-spin" />;
   }

   if (!fullUrl) {
      console.log("Missing parameter 'fullUrl' when rendering breadcrumbs for content");
      return null;
   }

   if (fullUrl === "/") {
      return null;
   }

   const cleanedFullUrl = fullUrl.startsWith("/") ? fullUrl.slice(1) : fullUrl;

   if (cleanedFullUrl.includes("julekalender")) {
      return null;
   }

   const urlData = contentStore.urlData.data.find((data) => data.slug === cleanedFullUrl || data.fullUrl === cleanedFullUrl);
   const splittedSlugList = urlData?.fullUrl.split("/") ?? cleanedFullUrl.split("/");
   const breadcrumbItems = splittedSlugList.map((slug, index, arr) => {
      const urlData = contentStore.urlData.data.find((urlData) => urlData.slug === slug);

      return {
         label: urlData?.title || "",
         href: `/${urlData?.fullUrl}`,
         current: index === arr.length - 1,
         as: Link
      };
   });

   return (
      <div className="tw-mb-4 tw-mt-2 print:tw-hidden">
         <Breadcrumbs items={breadcrumbItems} />
      </div>
   );
};

export default ContentBreadcrumb;
