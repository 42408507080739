import Bugsnag from "@bugsnag/js";
import { Input } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import { type ChangeEventHandler, type FocusEventHandler, type FormEvent, type ReactNode, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import isEmail from "validator/lib/isEmail";

import { sendSignup } from "../../../common/tracking";
import type { DynamicsCaptureConfig, DynamicsCaptureMapping } from "../../../common/types/dynamicsTypes";

import { scriptUrl } from "../../../common/utils/dynamicsUtils";
import Button from "../../Button";

type SignupFormProps = {
   values: { [fieldname: string]: string };
   mappings: DynamicsCaptureMapping[];
   config: DynamicsCaptureConfig;
   buttonText: string;
   disabled?: boolean;
   trackingFormName?: string;
   children?: ReactNode;
   buttonVariant?: "primary" | "secondary";
   onSignup?: (payload: Record<string, string>) => void;
};

const SignupForm = ({
   values,
   mappings,
   buttonText,
   config,
   disabled = false,
   buttonVariant = "primary",
   trackingFormName,
   children,
   onSignup
}: SignupFormProps) => {
   const initialValues = mappings.reduce(
      (acc, mapping) => {
         acc[mapping.FormFieldName] = values[mapping.FormFieldName] || "";
         return acc;
      },
      {} as Record<string, string>
   );

   const [currentValues, setCurrentValues] = useState<Record<string, string>>(initialValues);
   const [errors, setErrors] = useState<Record<string, string>>({});
   const [signingUp, setSigningUp] = useState(false);

   const handleSignup = async (e: FormEvent<HTMLFormElement>) => {
      if (signingUp) {
         return;
      }
      setSigningUp(true);
      e.preventDefault();
      const payload: Record<string, string> = {};
      for (const formElement of e.currentTarget.elements) {
         if (
            formElement instanceof HTMLInputElement ||
            formElement instanceof HTMLSelectElement ||
            formElement instanceof HTMLTextAreaElement
         ) {
            if (formElement.value) {
               payload[formElement.name] = formElement.value;
            }
         }
      }

      const form = e.currentTarget;
      let error: string | Error | undefined;
      if (form) {
         try {
            const serializedForm = window.d365mktformcapture.serializeForm(form, mappings);
            const dynamicsPayload = serializedForm.SerializedForm.build();
            await window.d365mktformcapture.submitForm(config, dynamicsPayload);
         } catch (err) {
            error = typeof err === "string" || err instanceof Error ? err : "D365 form capture submission error";
            Bugsnag.notify(error, (e) => {
               e.context = "Signup form capture";
               e.addMetadata("signup", {
                  payload,
                  mappings
               });
            });
            console.error(err);
         }
      }

      if (error) {
         if (error instanceof Error) {
            payload.d365Error = error.message;
         } else if (typeof error === "string") {
            payload.d365Error = error;
         } else {
            payload.d365Error = "D365 form capture failed";
         }
      }

      if (onSignup) {
         onSignup(payload);
      }

      sendSignup(trackingFormName);
      setSigningUp(false);
   };

   const validateValue = (validationType: "email" | "mandatory" | undefined, value: string): string => {
      if (value.length > 50) {
         return "Maksimalt 50 tegn er tillatt.";
      }

      if (validationType !== "email") {
         const allowedChars = /^[a-zA-Z0-9æøåÆØÅäöüÄÖÜéèêÉÈÊçÇñÑ\s]*$/;
         if (!allowedChars.test(value)) {
            return "Spesialtegn er ikke tillatt.";
         }
      }

      return validationType === "email" && !isEmail(value)
         ? "Skriv inn gyldig e-postadresse."
         : validationType === "mandatory" && !value
           ? "Feltet må fylles."
           : "";
   };

   const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      const { value, name } = e.target;
      const values = { ...currentValues, [name]: value };
      setCurrentValues(values);
   };

   const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
      const { value, name } = e.target;
      const mapping = mappings.find((mapping) => mapping.FormFieldName === name);
      if (mapping?.validation) {
         const error = validateValue(mapping.validation, value);
         setErrors({ ...errors, [name]: error });
      }
   };

   const buttonDisabled = useMemo(() => {
      return (
         disabled ||
         !mappings.every((mapping) => {
            if (mapping.validation) {
               return !validateValue(mapping.validation, currentValues[mapping.FormFieldName] || "");
            }
            return true;
         })
      );
   }, [disabled, mappings, currentValues]);

   return (
      <>
         <Helmet>
            {!window.d365mktformcapture && (
               <script
                  src={scriptUrl}
                  onError={() => {
                     const error = new Error("Error loading script");
                     Bugsnag.notify(error, (e) => {
                        e.context = "Dynamics Form Capture script load";
                        e.addMetadata("signup", { scriptUrl: scriptUrl });
                     });
                     console.error("Kunne ikke laste Dynamics Form Capture script");
                  }}
               />
            )}
         </Helmet>
         <form onSubmit={handleSignup}>
            {mappings.map((mapping) => {
               const props = { value: currentValues[mapping.FormFieldName] || "" };
               return (
                  <Input
                     key={mapping.FormFieldName}
                     {...props}
                     label={mapping.label}
                     name={mapping.FormFieldName}
                     onChange={handleChange}
                     disabled={mapping.hidden}
                     onBlur={handleBlur}
                     errorMessage={errors[mapping.FormFieldName]}
                     showErrorMessage={!!errors[mapping.FormFieldName]}
                     className={cn("tw-mb-2", { "tw-hidden": mapping.hidden })}
                  />
               );
            })}
            {children}
            <Button variant={buttonVariant} className="tw-mt-3" disabled={buttonDisabled} loading={signingUp} type="submit">
               {buttonText}
            </Button>
         </form>
      </>
   );
};

export default SignupForm;
