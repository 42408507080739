import { store } from "@risingstack/react-easy-state";
import find from "lodash-es/find";
import isNil from "lodash-es/isNil";
import size from "lodash-es/size";

import type { Product } from "../common/types/productTypes";
import { getBarcodeDeviceId, setBarcodeDeviceIdInStorage } from "../common/utils/storageUtils";

import type { BaseStoreType } from "../common/types/BaseStoreType";
import productStore from "./product/productStore";

type BarcodeStore = BaseStoreType & {
   modalVisible: boolean;
   cameraStart: boolean;
   cameraLoading: boolean;
   selectedCamera: string | null;
   cameras: Camera[];
   focus: SelectedFocus | null;
   error: Error | null;
   torch: boolean | null;
   barcodeFound: string;
   productsFound: Product[];
   qty: number;

   hideModal(): void;
   resetMatches(): void;
   handleMatch(code: string): boolean;
   parseCameraArray(cameras: MediaDeviceInfo[]): void;
   selectCamera(deviceId: string | null): void;
   getSelectedCamera(): Camera | undefined;
   showModal(): void;
   hideLoader(): void;
   startCamera(): void;
};

const barcodeStore: BarcodeStore = store({
   modalVisible: false,
   cameraStart: false,
   cameraLoading: true,
   selectedCamera: getBarcodeDeviceId(),
   cameras: [],
   focus: null,
   error: null,
   torch: null,
   barcodeFound: "",
   productsFound: [],
   qty: 1,

   hideModal: () => {
      barcodeStore.resetMatches();
      barcodeStore.cameraLoading = true;
      barcodeStore.cameraStart = false;
      barcodeStore.modalVisible = false;
      barcodeStore.error = null;
   },

   resetMatches: () => {
      barcodeStore.productsFound = [];
      barcodeStore.barcodeFound = "";
      barcodeStore.qty = 1;
   },

   handleMatch: (code) => {
      console.log("Updating matched products with code: ", code);
      if (size(barcodeStore.productsFound) === 0) {
         const productsFound = productStore.findProductsByEAN(code);

         barcodeStore.barcodeFound = code;
         barcodeStore.productsFound = productsFound;

         if (size(productsFound) > 0) {
            console.log("Found products, pausing video...");
            return true;
         }
      }
      console.log("Did not find products");
      return false;
   },

   parseCameraArray: (cameras) => {
      barcodeStore.cameras = cameras.map((cam) => ({
         name: cam.label || cam.deviceId /* || cam.id*/,
         deviceId: cam.deviceId /* || cam.id*/
      }));
      console.log("Internal cameras list updated", barcodeStore.cameras);
   },

   selectCamera: (deviceId) => {
      const foundCamera = find(barcodeStore.cameras, (cam) => cam.deviceId === deviceId);
      if (isNil(foundCamera)) {
         console.warn(`Unable to switch to camera, [${deviceId}] not found.`, barcodeStore.cameras);
         return;
      }

      console.log("Switching to camera", foundCamera);
      setBarcodeDeviceIdInStorage(foundCamera.deviceId);
      barcodeStore.selectedCamera = foundCamera.deviceId;
   },

   getSelectedCamera: () => {
      return find(barcodeStore.cameras, (cam) => cam.deviceId === barcodeStore.selectedCamera);
   },

   showModal: () => {
      barcodeStore.modalVisible = true;
      setTimeout(barcodeStore.startCamera, 150);
   },

   hideLoader: () => {
      barcodeStore.cameraLoading = false;
   },

   startCamera: () => {
      console.log("Starting camera for barcode scanner...");
      barcodeStore.cameraStart = true;
   },
   clearCompanySpecificData: () => {
      // add methods to clear company specific data
   }
});

export default barcodeStore;
