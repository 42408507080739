import {
   type Day,
   addDays,
   addMonths,
   addWeeks,
   endOfMonth,
   format,
   formatISO,
   getDaysInMonth,
   getWeeksInMonth,
   isAfter,
   isBefore,
   isFuture,
   isPast,
   isSameDay,
   isToday,
   parse,
   startOfMonth,
   startOfWeek
} from "date-fns";
import { nb } from "date-fns/locale";
import { isNil, min as ldMin, upperFirst } from "lodash-es";
import isEmpty from "lodash-es/isEmpty";

import type {
   ArrowDirection,
   CalendarMatrix,
   CalendarMatrixIndices,
   DateAndFlags,
   DayFlags,
   MonthAndYear,
   MonthNumber
} from "../types/calendarTypes";
import type { OpeningHourSummary, OpeningHours, OrderTypeWithDeliveryDates } from "../types/deliveryTypes";
import { ORDERTYPE } from "../types/productOrderTypes";

/**
 * Removes expired entries from the delivery date object for the given order types
 */
export const removeUnwantedDates = (
   orderTypeWithDeliveryDates: OrderTypeWithDeliveryDates,
   orderTypesToProcess: ORDERTYPE[]
): OrderTypeWithDeliveryDates => {
   const cutoff = startOfMonth(addMonths(new Date(), 4));

   for (const key of orderTypesToProcess) {
      if (key in orderTypeWithDeliveryDates) {
         orderTypeWithDeliveryDates[key] = orderTypeWithDeliveryDates[key]?.filter(
            (e) => isFuture(e.date) && isBefore(e.date, cutoff)
         );
      }
   }

   return orderTypeWithDeliveryDates;
};

/**
 * Takes a weekday index (1-7) and an interval and returns the string representation of the subscription name, ex: "Hver mandag" or "Hver tredje onsdag"
 */
export const generateSubscriptionName = (weekdayIndex: string | number, interval: string | number) => {
   if (isNil(interval)) {
      return "Missing parameter: interval";
   }

   const weekdayIndexNumber = typeof weekdayIndex === "string" ? Number.parseInt(weekdayIndex) : weekdayIndex;

   let name = intervalToText(interval);
   name += ` ${format(addDays(startOfWeek(new Date()), weekdayIndexNumber), "EEEE", { locale: nb, weekStartsOn: 1 })}`;
   return name;
};

/**
 * Takes a Date object, an order type and an interval and returns the string representation of the delivery date
 */
export const formatDeliveryDate = (
   date: Date | undefined,
   orderType: ORDERTYPE,
   interval?: string | number,
   shortened = false
) => {
   if (isNil(date)) {
      return "-";
   }

   if (orderType === ORDERTYPE.WAS && !isNil(interval)) {
      const intervalDescription = intervalToText(interval);
      const dayOfWeek = format(date, "EEEE", { locale: nb });
      return `${intervalDescription} ${dayOfWeek}${shortened ? "" : ` f.o.m. ${formatDate(date)}`}`;
   }

   if (orderType === ORDERTYPE.HPN) {
      return formatDateTime(date, true, true);
   }

   return formatDate(date, true, false, true);
};

/**
 * Takes an interval string and returns the text representation of the prefix that should be used for that interval
 */
export const intervalToText = (interval: string | number | null | undefined) => {
   if (isNil(interval)) {
      return "-";
   }

   const parsedInterval = typeof interval === "string" ? Number.parseInt(interval) : interval;
   if (parsedInterval === 7) {
      return "Hver";
   }
   if (parsedInterval === 14) {
      return "Annenhver";
   }
   if (parsedInterval === 21) {
      return "Hver tredje";
   }
   if (parsedInterval === 28) {
      return "Hver fjerde";
   }

   return `Hver ${interval}.`;
};

/**
 * Parses a date from M3 in format yyyyMMdd (ex: 20231231) and returns the Date object representation
 */
export const parseM3Date = (date: string): Date => {
   return parse(date, "yyyyMMdd", new Date());
};

/**
 * Takes a Date object and returns the string representation for use in M3 with format yyyyMMdd (ex: 20231231)
 */
export const formatM3Date = (date: Date | null | undefined) => {
   if (isNil(date)) {
      console.log("formatM3Date attempted to format empty date");
      return "";
   }
   return format(date, "yyyyMMdd");
};

/**
 * Takes a Date object and returns the ISO representation for use in JSON responses etc with format yyyy-MM-dd (ex: 2023-12-31)
 */
export const formatISODate = (date: Date) => {
   return formatISO(date, { representation: "date" });
};

/**
 * Takes a string as input and returns the Date object representation of the same date. Supports the following formats: yyyyMMdd, yyyy-MM-dd, yyyy-MM-ddTHH:mm
 */
export const getDateFromString = (dateStr: string | null | undefined) => {
   if (isNil(dateStr)) {
      return undefined;
   }

   const isM3Format = dateStr.length === 8;
   const isISOFormat = dateStr.length >= 10 && dateStr.length < 16;
   const isISOWithTimeFormat = dateStr.length >= 16;
   if (isM3Format) {
      return parseM3Date(dateStr);
   }
   if (isISOFormat) {
      return parse(dateStr.substring(0, 10), "yyyy-MM-dd", new Date());
   }
   if (isISOWithTimeFormat) {
      return parse(dateStr.substring(0, 16), "yyyy-MM-dd'T'HH:mm", new Date());
   }
};

/**
 * Ensures the param is a Date-object, parsing datetime strings if needed
 */
const forceDate = (date: Date | string | null | undefined) => {
   if (date instanceof Date) {
      return date;
   }
   return getDateFromString(date);
};

const formatDateWithWeekday = (date: Date, monthYearFormat: string) => format(date, `EEEE ${monthYearFormat}`, { locale: nb });

/**
 * Format a date or string into a humanly readable format, based on the given parameters
 */
export const formatDate = (
   dateOrString: Date | string | null | undefined,
   showDayName = false,
   showFullMonthYear = false,
   capitalize = false
) => {
   const date = forceDate(dateOrString);
   if (!date) {
      return `Unknown date: ${dateOrString}`;
   }

   const monthYearFormat = showFullMonthYear ? "d. MMM yyyy" : "dd.MM.yy";
   if (showDayName) {
      const dateWithDayNameFormat = formatDateWithWeekday(date, monthYearFormat);
      return capitalize ? upperFirst(dateWithDayNameFormat) : dateWithDayNameFormat;
   }

   return format(date, `${monthYearFormat}`, { locale: nb });
};

export const formatDatesTuple = (dates: [Date, Date]) => {
   return `${formatDate(dates[0])} - ${formatDate(dates[1])}`;
};

export const formatDateTime = (
   dateOrString: Date | string | null | undefined,
   showDayName = true,
   capitalize = false,
   separator = "|"
) => {
   const date = forceDate(dateOrString);
   if (isNil(date)) {
      return `Unknown date: ${dateOrString}`;
   }

   if (showDayName) {
      const dateWithDayNameFormat = format(date, `EEEE dd.MM.yy '${separator}' HH.mm`, { locale: nb });
      return capitalize ? upperFirst(dateWithDayNameFormat) : dateWithDayNameFormat;
   }

   return format(date, "dd.MM.yy | HH.mm");
};

const combineDays = (first: string, last: string) => {
   if (last === "") {
      return first;
   }
   return `${first} - ${last}`;
};

export const summarizeOpeningHours = (openingHours: OpeningHours[]): OpeningHourSummary[] => {
   if (isNil(openingHours) || isEmpty(openingHours)) {
      return [];
   }

   const openingHourSummary: OpeningHourSummary[] = [];
   let currentFirstDay = "";
   let currentLastDay = "";
   let currentOpeningWindow = "";

   for (const opening of openingHours) {
      const { weekday, openingHour, closingHour } = opening;
      const openingWindow = `${openingHour} - ${closingHour}`;

      const hasNewOpeningWindow = currentOpeningWindow !== openingWindow;
      const hasValidData = currentOpeningWindow !== "";

      if (hasNewOpeningWindow) {
         if (hasValidData) {
            openingHourSummary.push({ days: combineDays(currentFirstDay, currentLastDay), open: currentOpeningWindow });
         }

         currentFirstDay = weekday;
         currentLastDay = "";
         currentOpeningWindow = openingWindow;
      } else {
         currentLastDay = weekday;
      }
   }

   openingHourSummary.push({ days: combineDays(currentFirstDay, currentLastDay), open: currentOpeningWindow });

   return openingHourSummary;
};

/**
 * Checks if date is in the future (not included today), and returns a boolean value
 */
export const isDateInTheFuture = (date: Date): boolean => {
   return isFuture(date) && !isToday(date);
};

/**
 * Checks if date is in the past (not included today), and returns a boolean value
 */
export const isDateInThePast = (date: Date): boolean => {
   return isPast(date) && !isToday(date);
};

export const isDateBetween = (candiDate: Date, date1: Date, date2: Date) =>
   !isSameDay(candiDate, date1) &&
   !isSameDay(candiDate, date2) &&
   ((isAfter(candiDate, date1) && isBefore(candiDate, date2)) || (isAfter(candiDate, date2) && isBefore(candiDate, date1)));

export const getFirstDate = (dates: (Date | undefined)[]): Date | undefined =>
   dates
      .filter((d) => d)
      .reduce((current: Date | undefined, next) => (current && next && isBefore(current, next) ? current : next), undefined);

export const toMonthYear = (date: Date): MonthAndYear => ({ month: date.getMonth() as MonthNumber, year: date.getFullYear() });

export const datesForWeek = (weekIndex: number, monthYear: MonthAndYear, weekStartsOn: Day) => {
   const { month, year } = monthYear;
   const startOfMonth = new Date(year, month, 1);
   const startOfWeek0 = startOfWeek(startOfMonth, { weekStartsOn });
   const startOfThisWeek = weekIndex ? addWeeks(startOfWeek0, weekIndex) : startOfWeek0;
   return [0, 1, 2, 3, 4, 5, 6].map((daysToAdd) => addDays(startOfThisWeek, daysToAdd));
};
export const createCalendarMatrix = (months: MonthAndYear[], getDayFlags: (date: Date) => DayFlags): CalendarMatrix => {
   const weekStartsOn = 1;
   return months.map((monthYear) => {
      const { year, month } = monthYear;
      const weeks = [];
      const weekCount = getWeeksInMonth(new Date(year, month, 1), { weekStartsOn });
      for (let i = 0; i < weekCount; i++) {
         const dates = datesForWeek(i, monthYear, weekStartsOn);
         weeks.push(
            dates.map((date) => {
               const dayFlags = getDayFlags(date);
               dayFlags.notInMonth = date.getMonth() !== month;
               dayFlags.selectable = dayFlags.selectable && !dayFlags.notInMonth;
               return {
                  date,
                  ...dayFlags
               };
            })
         );
      }
      return weeks;
   });
};

export const getCalendarMatrixIndicesFromDate = (
   date: Date,
   calendarMatrix: CalendarMatrix
): CalendarMatrixIndices | undefined => {
   const month = date.getMonth();
   const monthIndex = calendarMatrix.findIndex((weeks) => {
      return weeks.length > 1 && weeks[1][0].date.getMonth() === month;
   });
   if (monthIndex >= 0) {
      let dayIndex = -1;
      const weeks = calendarMatrix[monthIndex];
      const weekIndex = weeks.findIndex((week) => {
         const index = week.findIndex((day) => {
            const { date: currentDate, notInMonth } = day;
            return !notInMonth && isSameDay(currentDate, date);
         });
         if (index >= 0) {
            dayIndex = index;
            return true;
         }
         return false;
      });
      if (weekIndex >= 0 && dayIndex >= 0) {
         return [monthIndex, weekIndex, dayIndex];
      }
   }
   return undefined;
};

export const getRelativeDate = (date: Date, direction: ArrowDirection, calendarMatrix: CalendarMatrix) => {
   const indices = getCalendarMatrixIndicesFromDate(date, calendarMatrix);
   if (indices) {
      const isBackwards = direction === "ArrowUp" || direction === "ArrowLeft";
      if (direction === "ArrowDown" || direction === "ArrowUp") {
         return verticalRelativeDate(indices, isBackwards, calendarMatrix);
      }
      return horizontalRelativeDate(indices, isBackwards, calendarMatrix);
   }
};

const closestSelectableInWeekInDirection = (week: DateAndFlags[], dayIndex: number, isBackwards: boolean) => {
   if (week[dayIndex].selectable && !week[dayIndex].notInMonth) {
      //
      return week[dayIndex].date;
   }
   if (isBackwards) {
      if (dayIndex > 0) {
         return closestSelectableInWeekInDirection(week, dayIndex - 1, true);
      }
   } else {
      if (dayIndex + 1 < week.length) {
         return closestSelectableInWeekInDirection(week, dayIndex + 1, false);
      }
   }
};

const closestSelectableInWeek = (week: DateAndFlags[], dayIndex: number, isBackwards: boolean) => {
   const dateInDirection = closestSelectableInWeekInDirection(week, dayIndex, isBackwards);
   return dateInDirection || closestSelectableInWeekInDirection(week, dayIndex, !isBackwards);
};

const verticalRelativeDate = (startIndices: CalendarMatrixIndices, isBackwards: boolean, calendarMatrix: CalendarMatrix) => {
   const nextWeek = isBackwards
      ? (monthIndex: number, weekIndex: number) => {
           return weekIndex
              ? { weekIndex: weekIndex - 1, monthIndex }
              : monthIndex
                ? { monthIndex: monthIndex - 1, weekIndex: calendarMatrix[monthIndex - 1].length - 1 }
                : undefined;
        }
      : (monthIndex: number, weekIndex: number) => {
           return weekIndex + 1 < calendarMatrix[monthIndex].length
              ? { weekIndex: weekIndex + 1, monthIndex }
              : monthIndex + 1 < calendarMatrix.length
                ? { monthIndex: monthIndex + 1, weekIndex: 0 }
                : undefined;
        };
   const [monthIndex, weekIndex, dayIndex] = startIndices;
   let weekAndMonth = nextWeek(monthIndex, weekIndex);
   while (weekAndMonth) {
      const date = closestSelectableInWeek(
         calendarMatrix[weekAndMonth.monthIndex][weekAndMonth.weekIndex],
         dayIndex,
         isBackwards
      );
      if (date) {
         return date;
      }
      weekAndMonth = nextWeek(weekAndMonth.monthIndex, weekAndMonth.weekIndex);
   }
};

const horizontalRelativeDate = (
   startIndices: CalendarMatrixIndices,
   isBackwards: boolean,
   calendarMatrix: CalendarMatrix
): Date | undefined => {
   const [monthIndex, weekIndex, dayIndex] = startIndices;
   const currentWeek = calendarMatrix[monthIndex][weekIndex];
   const subweek = isBackwards ? [...currentWeek.slice(0, dayIndex)].reverse() : currentWeek.slice(dayIndex + 1);
   const found = subweek.find((dateAndFlags) => dateAndFlags.selectable);
   if (found) {
      return found.date;
   }
   if (isBackwards && monthIndex > 0) {
      const previousMonth = calendarMatrix[monthIndex - 1];
      const previousMonthWeekIndex = weekIndex < previousMonth.length ? weekIndex : weekIndex - 1;
      const previousMonthWeek = previousMonth[previousMonthWeekIndex];
      const found = [...previousMonthWeek].reverse().find((dateAndFlags) => dateAndFlags.selectable);
      if (found) {
         return found.date;
      }
      for (let weekIndexDelta = 1; weekIndexDelta < 5; weekIndexDelta++) {
         let found =
            previousMonthWeekIndex - weekIndexDelta >= 0
               ? [...previousMonth[previousMonthWeekIndex - weekIndexDelta]]
                    .reverse()
                    .find((dateAndFlags) => dateAndFlags.selectable)
               : undefined;
         if (found) {
            return found.date;
         }
         found =
            previousMonthWeekIndex + weekIndexDelta < previousMonth.length
               ? [...previousMonth[previousMonthWeekIndex + weekIndexDelta]]
                    .reverse()
                    .find((dateAndFlags) => dateAndFlags.selectable)
               : undefined;
         if (found) {
            return found.date;
         }
      }
      if (monthIndex > 1) {
         const previousMonthStartIndices: CalendarMatrixIndices = [monthIndex - 1, previousMonthWeekIndex, 0];
         return horizontalRelativeDate(previousMonthStartIndices, true, calendarMatrix);
      }
   }
   if (!isBackwards && monthIndex + 1 < calendarMatrix.length) {
      const nextMonth = calendarMatrix[monthIndex + 1];
      const nextMonthWeekIndex = weekIndex < nextMonth.length ? weekIndex : weekIndex - 1;
      const nextMonthWeek = nextMonth[nextMonthWeekIndex];
      const found = nextMonthWeek.find((dateAndFlags) => dateAndFlags.selectable);
      if (found) {
         return found.date;
      }
      for (let weekIndexDelta = 1; weekIndexDelta < 5; weekIndexDelta++) {
         let found =
            nextMonthWeekIndex + weekIndexDelta < nextMonth.length
               ? nextMonth[nextMonthWeekIndex + weekIndexDelta].find((dateAndFlags) => dateAndFlags.selectable)
               : undefined;
         if (found) {
            return found.date;
         }
         found =
            nextMonthWeekIndex - weekIndexDelta >= 0
               ? nextMonth[nextMonthWeekIndex - weekIndexDelta].find((dateAndFlags) => dateAndFlags.selectable)
               : undefined;
         if (found) {
            return found.date;
         }
      }
      if (monthIndex + 2 < calendarMatrix.length) {
         const nextMonthStartIndices: CalendarMatrixIndices = [monthIndex + 1, nextMonthWeekIndex, 6];
         return horizontalRelativeDate(nextMonthStartIndices, false, calendarMatrix);
      }
   }
};

export const isArrowDirection = (value: string): value is ArrowDirection => {
   return ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(value);
};

export const isInMonthYear = (date: Date, monthYear: MonthAndYear): boolean => {
   const { month, year } = monthYear;
   const monthStart = new Date(year, month, 1);
   const monthEnd = endOfMonth(monthStart);
   return isSameDay(date, monthStart) || isSameDay(date, monthEnd) || (isAfter(date, monthStart) && isBefore(date, monthEnd));
};

export const isInMonthYears = (date: Date, monthYears: MonthAndYear[]) =>
   monthYears.some((monthYear) => isInMonthYear(date, monthYear));

export const toMonthAndYear = (date: Date) => ({
   month: date.getMonth() as MonthNumber,
   year: date.getFullYear()
});

export const monthAndYearCompare = (my1: MonthAndYear, my2: MonthAndYear) =>
   my1.year * 12 + my1.month - (my2.year * 12 + my2.month);

export const getLowestMonthAndYear = (min?: Date): MonthAndYear => (min ? toMonthAndYear(min) : { month: 0, year: 0 });
export const getHighestMonthAndYear = (max?: Date): MonthAndYear => (max ? toMonthAndYear(max) : { month: 11, year: 9999 });

export const getPreviousMonth = ({ month, year }: MonthAndYear): MonthAndYear =>
   month ? { month: (month - 1) as MonthNumber, year } : { month: 11, year: year - 1 };

export const getNextMonth = ({ month, year }: MonthAndYear): MonthAndYear =>
   month === 11 ? { month: 0, year: year + 1 } : { month: (month + 1) as MonthNumber, year };

const toMonthAD = (monthAndYear: MonthAndYear) => monthAndYear.year * 12 + monthAndYear.month;
const fromMonthAD = (monthAD: number) => ({ year: Math.floor(monthAD / 12), month: (monthAD % 12) as MonthNumber });

export const getMonthAndYears = (month: MonthNumber, year: number, monthCount: number, min?: Date, max?: Date) => {
   const lowestMonthAndYear = getLowestMonthAndYear(min);
   const highestMonthAndYear = getHighestMonthAndYear(max);
   const lowestMonths = toMonthAD(lowestMonthAndYear);
   const highestMonths = toMonthAD(highestMonthAndYear);
   const count = ldMin([monthCount, 1 + highestMonths - lowestMonths]) || 1;
   const months = [toMonthAD({ month, year })];
   let monthsLeft = count - 1;
   let nextMonthAfter = true;

   while (monthsLeft) {
      if (nextMonthAfter) {
         const lastMonths = months[months.length - 1];
         if (lastMonths < highestMonths) {
            months.push(lastMonths + 1);
            monthsLeft--;
         }
         nextMonthAfter = false;
      } else {
         const firstMonths = months[0];
         if (firstMonths > lowestMonths) {
            months.unshift(firstMonths - 1);
            monthsLeft--;
         }
         nextMonthAfter = true;
      }
   }
   return months.map((month) => fromMonthAD(month));
};

export const datesInMonth = (monthAndYear: MonthAndYear): Date[] => {
   const { month, year } = monthAndYear;
   const daysInMonth = getDaysInMonth(new Date(year, month));
   const dates = [];
   for (let day = 1; day <= daysInMonth; day++) {
      dates.push(new Date(year, month, day));
   }
   return dates;
};

export const getSelectedOrFirstSelectableDate = (
   monthsAndYears: MonthAndYear[],
   getDayFlags: (date: Date) => DayFlags
): Date | undefined => {
   if (monthsAndYears.length) {
      const dates = monthsAndYears.flatMap((monthAndYear) => datesInMonth(monthAndYear));
      const date = dates.find((date) => getDayFlags(date).selected);
      if (date) {
         return date;
      }
      return dates.find((date) => getDayFlags(date).selectable);
   }
   return;
};

export const englishDayToDay = (englishDay: string): Day | undefined => {
   const lcDay = (englishDay || "").toLowerCase();
   const days: Record<string, Day> = { sunday: 0, monday: 1, tuesday: 2, wednesday: 3, thursday: 4, friday: 5, saturday: 6 };
   return days[lcDay];
};
