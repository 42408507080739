import { z } from "zod";

export const validateEmail = (value: string, required?: boolean): string => {
   const requiredPhoneSchema = z
      .string()
      .nonempty({ message: "E-postadresse er påkrevd" })
      .email({ message: "E-postadressen er ikke gyldig" });
   const optionalPhoneSchema = z.union([z.string().email({ message: "E-postadressen er ikke gyldig" }), z.literal("")]);

   const schema = required ? requiredPhoneSchema : optionalPhoneSchema;
   const result = schema.safeParse(value);

   return result.error?.errors[0].message ?? "";
};

export const validatePhone = (value: string, required?: boolean): string => {
   const requiredTelefonSchema = z
      .string()
      .nonempty({ message: "Telefonnummer er påkrevd" })
      .min(8, { message: "Telefonnummeret er ikke gyldig" });

   const optionalPhoneSchema = z.union([
      z
         .string()
         .regex(/^[\d+]+$/, { message: "Telefonnummeret er ikke gyldig" })
         .min(8, { message: "Telefonnummeret er ikke gyldig" }),
      z.literal("")
   ]);

   const schema = required ? requiredTelefonSchema : optionalPhoneSchema;
   const result = schema.safeParse(value);

   return result.error?.errors[0].message ?? "";
};

export const validateNotEmpty = (value: string, required?: boolean) => {
   if (!required) {
      return "";
   }
   const schema = z.string().nonempty({ message: "Feltet kan ikke være tomt" });
   const result = schema.safeParse(value);

   return result.error?.errors[0].message ?? "";
};

export const validateFavoriteListName = (value: string, required?: boolean) => {
   const requiredFavoriteListSchema = z
      .string()
      .nonempty({ message: "Det må være mellom 1 og 30 tegn i navnet" })
      .max(30, { message: "Det kan ikke være flere enn 30 tegn i navnet" });

   const optionalFavoriteListSchema = z.union([
      z.string().max(30, { message: "Det kan ikke være flere enn 30 tegn i navnet" }),
      z.literal("")
   ]);

   const schema = required ? requiredFavoriteListSchema : optionalFavoriteListSchema;
   const result = schema.safeParse(value);

   return result.error?.errors[0].message ?? "";
};

export const validateNameLength = (value: string, required?: boolean) => {
   const requiredNameSchema = z
      .string()
      .nonempty({ message: "Vennligst oppi navn på mottaker" })
      .max(50, { message: "Det kan ikke være flere enn 50 tegn i navnet" });

   const optionalNameSchema = z.union([
      z.string().max(50, { message: "Det kan ikke være flere enn 50 tegn i navnet" }),
      z.literal("")
   ]);

   const schema = required ? requiredNameSchema : optionalNameSchema;
   const result = schema.safeParse(value);

   return result.error?.errors[0].message ?? "";
};

export const validateChecked = (value: boolean, required: boolean) => {
   return !value && required ? "Dette feltet må være avkrysset" : "";
};
