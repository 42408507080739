import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const SvgGryr = (props) => (_jsxs("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 200 200", focusable: "false", "aria-hidden": "true" }, props, { children: [_jsxs("defs", { children: [_jsx("clipPath", { id: "gryr_svg__a", clipPathUnits: "userSpaceOnUse", children: _jsx("path", { d: "M0 841.89h1496.693V0H0Z" }) }), _jsx("clipPath", { id: "gryr_svg__b", clipPathUnits: "userSpaceOnUse", children: _jsx("path", { d: "M602.509 622.599h379.328V214.662H602.509Z" }) })] }), _jsxs("g", { clipPath: "url(#gryr_svg__a)", transform: "matrix(.39655 0 0 -.39655 -199.493 266.009)", children: [_jsxs("g", { clipPath: "url(#gryr_svg__b)", style: {
                        opacity: 0.80000299,
                    }, children: [_jsx("path", { d: "M0 0c26.994 27.332 90.541 38.309 66.798 108.205C16.117 89.081-15.009 52.048 0 0", style: {
                                fill: "#37b0c9",
                                fillOpacity: 1,
                                fillRule: "nonzero",
                                stroke: "none",
                            }, transform: "translate(606.294 231.165)" }), _jsx("path", { d: "M0 0c15.586 45.223 1.418 75.998-42.413 95.151C-79.23 36.689-27.708 25.267 0 0", style: {
                                fill: "#37b0c9",
                                fillOpacity: 1,
                                fillRule: "nonzero",
                                stroke: "none",
                            }, transform: "translate(737.851 214.662)" }), _jsx("path", { d: "M0 0c-31.8 40.007 2.129 65.299 23.963 84.901C39.701 50.975 32.402 18.314 0 0", style: {
                                fill: "#37b0c9",
                                fillOpacity: 1,
                                fillRule: "nonzero",
                                stroke: "none",
                            }, transform: "translate(897.613 537.698)" }), _jsx("path", { d: "M0 0c-4.887 30.81-41.948 54.514-77.938 41.026C-73.593-4.615-37.583-.202 0 0", style: {
                                fill: "#37b0c9",
                                fillOpacity: 1,
                                fillRule: "nonzero",
                                stroke: "none",
                            }, transform: "translate(809.2 272.471)" }), _jsx("path", { d: "M0 0c-61.101-1.093-58.273 38.492-68.611 71.119C-16.618 76.896-4.486 30.103 0 0", style: {
                                fill: "#37b0c9",
                                fillOpacity: 1,
                                fillRule: "nonzero",
                                stroke: "none",
                            }, transform: "translate(699.151 492.98)" }), _jsx("path", { d: "M0 0c7.857-24.565 25.742-53.511-9.674-81.754C-33.103-62.094-28.202-15.692 0 0", style: {
                                fill: "#37b0c9",
                                fillOpacity: 1,
                                fillRule: "nonzero",
                                stroke: "none",
                            }, transform: "translate(727.475 574.774)" }), _jsx("path", { d: "M0 0c13.581 32.916 38.422 73.933 93.578 54.152C69.04 22.518 66.152-16.766 0 0", style: {
                                fill: "#37b0c9",
                                fillOpacity: 1,
                                fillRule: "nonzero",
                                stroke: "none",
                            }, transform: "translate(888.259 494.21)" })] }), _jsx("path", { d: "m0 0-.92-.644c-.121-.11-16.899-11.519-40.395-11.519-1.778 0-3.6.065-5.413.194C-66.915-10.53-85.29-1.611-98.467 13.147c-14.166 15.864-20.778 36.537-19.121 59.782 1.577 22.126 10.206 41.112 24.955 54.906 14.674 13.725 33.955 20.98 55.761 20.98a99 99 0 0 0 7.01-.252c26.052-1.857 44.677-13.099 49.655-17.274 5.179-4.397 5.568-7.987 1.473-13.71l-6.481-8.775c-2.701-3.66-5.011-4.083-6.514-4.083-1.996 0-4.376.858-7.276 2.624-8.054 4.901-16.452 9.385-30.566 10.391-1.691.12-3.374.181-5.003.181-21.393 0-45.1-11.889-47.478-45.258-1.045-14.666 3.134-28.245 11.768-38.237 8.256-9.556 19.954-15.328 32.94-16.253a61 61 0 0 1 4.335-.156c21.09 0 31.423 11.253 31.853 11.732l.612.683L.498 45.041l-13.022.928c-3.296.235-5.707 1.207-7.166 2.89-1.459 1.682-2.079 4.206-1.844 7.502l.625 8.775c.44 6.179 3.325 9.058 9.079 9.058.425 0 34.161-2.39 34.161-2.39 6.618-.471 9.481-3.773 9.009-10.392L26.785-2.346c-.44-6.18-3.325-9.058-9.078-9.058-.425 0-8.738.577-8.738.577-5.984.427-9.51 3.374-9.202 7.693z", style: {
                        fill: "#201751",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    }, transform: "translate(633.218 361.071)" }), _jsx("path", { d: "m0 0-14.655.415c-6.633.188-9.635 3.365-9.447 9.998l1.54 54.358-42.916 82.155c-1.787 3.298-2.154 6.112-1.054 7.959 1.063 1.785 3.568 2.767 7.055 2.767q.266 0 .537-.007l17.811-.505c5.909-.168 9.432-2.57 11.783-8.033l13.149-29.951c4.766-10.948 9.28-23.113 9.321-23.235l1.807-5.19.912-.026 2.076 4.942c.048.12 5.19 12.149 10.572 22.817l14.594 29.154c2.578 5.163 6.05 7.365 11.612 7.365q.3 0 .607-.009l17.812-.504c3.682-.104 6.378-1.248 7.397-3.136 1.029-1.908.512-4.714-1.456-7.901l-47.52-79.628-1.54-54.359C9.814 2.996 6.814-.008.555-.008Q.281-.008 0 0", style: {
                        fill: "#201751",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    }, transform: "translate(828.376 381.453)" }), _jsx("path", { d: "M0 0c-1.797-12.256-9.621-19.285-21.466-19.285-1.528 0-3.13.122-4.762.361l-19.612 2.875 6.47 44.134 15.819-2.319c7.015-1.029 11.765-2.492 15.39-4.742C1.027 15.725 1.028 7.007 0 0m34.781-2.592c3.104 21.176-4.664 38.676-21.321 48.017-6.503 3.68-14.231 5.779-29.533 8.023l-43.295 6.347c-.857.126-1.677.19-2.439.19-3.161 0-7.49-.872-8.594-8.402L-90.196-83.433c-.963-6.565 1.647-10.071 8.212-11.034l14.505-2.127a17 17 0 0 1 2.439-.189c3.161 0 7.491.871 8.595 8.402l6.474 44.16 22.555-3.307 17.561-50.017c2.132-5.637 5.351-8.281 11.112-9.126l16.961-2.487a16.6 16.6 0 0 1 2.384-.184c1.873 0 4.368.387 5.616 2.227 1.166 1.72 1.081 4.489-.256 7.846L9.227-54.892C6.538-47.958 4.784-44.78 4.769-44.751l-.446.795.001.004c-.912 1.533-1.52 2.266-1.52 2.266l1.956.702.001.004 1.559.524c16.644 5.583 26.287 23.041 28.461 37.864", style: {
                        fill: "#201751",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    }, transform: "translate(943.13 421.898)" }), _jsx("path", { d: "M0 0c1.96-12.231-3.397-21.282-14.698-24.833a33 33 0 0 0-4.651-1.083l-19.572-3.137-7.057 44.044 15.786 2.53c7.001 1.121 11.971 1.149 16.103.089C-3.734 15.31-1.12 6.993 0 0m33.958 7.954C30.572 29.087 17.915 43.453-.777 47.371c-7.306 1.561-15.309 1.247-30.58-1.2l-43.206-6.924a17 17 0 0 1-2.384-.55c-3.015-.948-6.884-3.077-5.68-10.592l21.591-134.74c1.05-6.552 4.591-9.115 11.142-8.065l14.476 2.32c.855.137 1.657.322 2.384.551 3.016.947 6.885 3.076 5.68 10.591l-7.062 44.07 22.509 3.607 31.748-42.452c3.724-4.739 7.588-6.296 13.337-5.375l16.926 2.712c.833.134 1.617.315 2.329.539 1.787.562 4.052 1.679 4.691 3.809.596 1.99-.315 4.606-2.597 7.408L25.259-49.601c-4.644 5.808-7.27 8.315-7.294 8.337l-.663.626-.001.004c-1.33 1.189-2.129 1.706-2.129 1.706l1.656 1.256-.001.004 1.331.967c14.204 10.317 18.171 29.862 15.8 44.655", style: {
                        fill: "#201751",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    }, transform: "translate(755.248 435.749)" }), _jsx("path", { d: "M0 0c2.361 0 3.522 1.587 3.522 3.794S2.361 7.51.116 7.51h-3.445V0Zm-10.104 11.188c0 1.394.736 2.129 2.129 2.129h9.369c5.38 0 9.019-3.949 9.019-9.523 0-5.575-3.639-9.601-9.019-9.601h-4.723v-6.426c0-1.393-.736-2.129-2.129-2.129h-2.517c-1.393 0-2.129.736-2.129 2.129z", style: {
                        fill: "#201751",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    }, transform: "translate(565.594 607.685)" }), _jsx("path", { d: "M0 0c0 1.394.736 2.129 2.129 2.129h2.517c1.393 0 2.129-.735 2.129-2.129v-19.743h9.174c1.394 0 2.13-.736 2.13-2.129v-1.549c0-1.393-.736-2.129-2.13-2.129H2.129C.736-25.55 0-24.814 0-23.421Z", style: {
                        fill: "#201751",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    }, transform: "translate(581.271 618.873)" }), _jsx("path", { d: "m0 0-1.549 5.381c-.503 1.742-1.122 4.761-1.122 4.761h-.078s-.619-3.019-1.122-4.761L-5.458 0Zm-6.852 14.71c.387 1.2 1.161 1.742 2.4 1.742h3.484c1.239 0 2.013-.542 2.4-1.742L9.445-8.788c.504-1.471-.193-2.439-1.741-2.439H5.148c-1.238 0-2.013.581-2.399 1.781L1.587-5.42h-8.633l-1.161-4.026c-.348-1.2-1.123-1.781-2.361-1.781h-2.556c-1.548 0-2.245.968-1.741 2.439z", style: {
                        fill: "#201751",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    }, transform: "translate(616.962 604.55)" }), _jsx("path", { d: "M0 0c0 1.394.736 2.129 2.129 2.129h3.02c1.2 0 1.974-.426 2.555-1.432l6.813-11.498c1.084-1.819 2.323-4.722 2.323-4.722h.077s-.31 2.98-.31 4.722V0c0 1.394.736 2.129 2.13 2.129h2.516c1.393 0 2.129-.735 2.129-2.129v-23.421c0-1.393-.736-2.129-2.129-2.129h-3.02c-1.161 0-1.935.426-2.516 1.432L8.865-12.659c-1.084 1.82-2.323 4.723-2.323 4.723h-.077s.31-2.981.31-4.723v-10.762c0-1.393-.736-2.129-2.129-2.129H2.129C.736-25.55 0-24.814 0-23.421Z", style: {
                        fill: "#201751",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    }, transform: "translate(630.936 618.873)" }), _jsx("path", { d: "M0 0h-6.271C-7.665 0-8.4.735-8.4 2.129v1.549c0 1.393.735 2.129 2.129 2.129h19.316c1.394 0 2.13-.736 2.13-2.129V2.129C15.175.735 14.439 0 13.045 0H6.774v-19.743c0-1.394-.735-2.129-2.129-2.129H2.129C.735-21.872 0-21.137 0-19.743Z", style: {
                        fill: "#201751",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    }, transform: "translate(667.556 615.195)" }), _jsx("path", { d: "M0 0c0 1.394.736 2.129 2.129 2.129h13.085c1.393 0 2.129-.735 2.129-2.129v-1.549c0-1.393-.736-2.129-2.129-2.129H6.775V-8.71h6.309c1.395 0 2.13-.736 2.13-2.129v-1.549c0-1.393-.735-2.129-2.13-2.129H6.775v-5.226h8.981c1.393 0 2.129-.736 2.129-2.129v-1.549c0-1.393-.736-2.129-2.129-2.129H2.129C.736-25.55 0-24.814 0-23.421Z", style: {
                        fill: "#201751",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    }, transform: "translate(687.375 618.873)" }), _jsx("path", { d: "M0 0c1.974 0 2.942 1.355 2.942 2.903S2.013 5.729.039 5.729h-4.103V0Zm-.581 11.149c1.704 0 2.439 1.239 2.439 2.555 0 1.277-.735 2.361-2.555 2.361h-3.367v-4.916zm-10.258 8.594c0 1.394.735 2.129 2.129 2.129h8.052c5.536 0 9.407-2.477 9.407-7.239 0-2.322-1.123-4.684-3.213-5.884v-.078c3.174-.967 4.336-3.793 4.336-6.232 0-6.039-5.033-8.246-10.53-8.246H-8.71c-1.394 0-2.129.736-2.129 2.129z", style: {
                        fill: "#201751",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    }, transform: "translate(722.795 599.13)" }), _jsx("path", { d: "m0 0-1.549 5.381c-.503 1.742-1.123 4.761-1.123 4.761h-.077s-.62-3.019-1.123-4.761L-5.459 0Zm-6.853 14.71c.388 1.2 1.162 1.742 2.4 1.742h3.484c1.24 0 2.014-.542 2.401-1.742L9.445-8.788c.503-1.471-.193-2.439-1.742-2.439H5.148c-1.239 0-2.013.581-2.4 1.781L1.586-5.42h-8.632l-1.162-4.026c-.348-1.2-1.122-1.781-2.361-1.781h-2.555c-1.548 0-2.245.968-1.742 2.439z", style: {
                        fill: "#201751",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    }, transform: "translate(750.55 604.55)" }), _jsx("path", { d: "m0 0 1.201 1.742c.774 1.123 1.664 1.2 2.942.503.89-.464 2.632-1.703 5.033-1.703 2.012 0 2.941.852 2.941 1.936 0 3.909-12.232 3.29-12.232 11.961 0 5.11 4.258 8.478 9.871 8.478 3.213 0 6.155-1.123 7.471-2.051 1.123-.813 1.239-1.781.658-2.904l-.929-1.819c-.774-1.51-1.819-1.278-2.903-.736-1.278.619-2.555 1.278-4.375 1.278-2.129 0-3.02-.852-3.02-1.936 0-4.026 12.234-3.058 12.234-11.807 0-4.645-3.484-8.632-9.716-8.632-3.872 0-7.318 1.586-8.788 2.748C-.426-2.284-1.006-1.432 0 0", style: {
                        fill: "#201751",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    }, transform: "translate(763.053 598.55)" }), _jsx("path", { d: "M0 0c0 1.394.735 2.129 2.129 2.129h13.084c1.394 0 2.129-.735 2.129-2.129v-1.549c0-1.393-.735-2.129-2.129-2.129H6.774V-8.71h6.31c1.395 0 2.129-.736 2.129-2.129v-1.549c0-1.393-.734-2.129-2.129-2.129h-6.31v-5.226h8.982c1.392 0 2.129-.736 2.129-2.129v-1.549c0-1.393-.737-2.129-2.129-2.129H2.129C.735-25.55 0-24.814 0-23.421Z", style: {
                        fill: "#201751",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    }, transform: "translate(788.408 618.873)" }), _jsx("path", { d: "M0 0c2.168 0 3.523 1.2 3.523 3.407 0 1.393-.348 2.438-1.626 2.942-.62.271-1.432.387-2.555.387h-2.283V0Zm-9.717 10.414c0 1.393.737 2.128 2.13 2.128h7.51c2.826 0 4.181-.193 5.382-.658 3.134-1.199 5.109-4.026 5.109-8.051 0-2.943-1.393-6.156-4.143-7.549v-.078s.387-.503 1.045-1.664l3.95-7.123c.851-1.471.193-2.555-1.471-2.555H6.853c-1.162 0-1.936.464-2.517 1.51L.232-5.807h-3.173v-7.2c0-1.394-.737-2.129-2.129-2.129h-2.517c-1.393 0-2.13.735-2.13 2.129z", style: {
                        fill: "#201751",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    }, transform: "translate(822.705 608.46)" }), _jsx("path", { d: "M0 0h-6.271C-7.666 0-8.4.735-8.4 2.129v1.549c0 1.393.734 2.129 2.129 2.129h19.316c1.394 0 2.129-.736 2.129-2.129V2.129C15.174.735 14.439 0 13.045 0H6.773v-19.743c0-1.394-.734-2.129-2.128-2.129H2.129C.734-21.872 0-21.137 0-19.743Z", style: {
                        fill: "#201751",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    }, transform: "translate(845.12 615.195)" }), _jsx("path", { d: "M0 0c5.178 0 9.152 4.303 9.152 9.736 0 5.397-3.974 9.663-9.152 9.663-5.068 0-8.971-4.266-8.971-9.663C-8.971 4.303-5.068 0 0 0m.004 22.589c7.056 0 12.68-5.728 12.68-12.855C12.684 2.609 7.06-3.19.004-3.19c-6.917 0-12.541 5.799-12.541 12.924 0 7.127 5.624 12.855 12.541 12.855", style: {
                        fill: "#201751",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    }, transform: "translate(982.422 467.414)" }), _jsx("path", { d: "M0 0h1.227C2.28 0 2.908.655 2.908 1.751c0 1.09-.587 1.716-1.611 1.716H0Zm4.581-6.07c-1.009 0-1.599.387-1.974 1.292L1.453-1.947H0v-2.485C0-5.565-.462-6.07-1.498-6.07h-.209c-1.036 0-1.498.505-1.498 1.638v8.278c0 1.102.536 1.638 1.638 1.638h3.772c2.454 0 3.978-1.431 3.978-3.733 0-1.912-1.437-2.724-1.822-2.837l-.203-.059-.234-.048.34-.457v.001c-.001 0 .146-.141.377-.605l1.015-1.959c.33-.628.406-1.175.209-1.501-.178-.294-.55-.356-.831-.356Z", style: {
                        fill: "#201751",
                        fillOpacity: 1,
                        fillRule: "nonzero",
                        stroke: "none",
                    }, transform: "translate(981.374 477.529)" })] })] })));
export default SvgGryr;
