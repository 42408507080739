import type { SortColumn } from "./commonTypes";

export enum INVOICE_STATUS {
   PastDue = "PAST_DUE",
   Unpaid = "UNPAID",
   ReminderSent = "REMINDER_SENT",
   Paid = "PAID",
   Credit = "CREDIT",
   None = "NONE"
}

export type InvoiceStatus = {
   value: INVOICE_STATUS;
   label: string;
   color?: "success" | "warning" | "error" | "information" | "base";
};

export type InvoiceFields = "invoiceNumber" | "dueDate" | "invoiceDate" | "invoiceAmount" | "paidAmount" | "status";

export type InvoiceColumn = SortColumn & {
   field: InvoiceFields;
};

export type InvoicePeriod = {
   value: [Date, Date];
   text: string;
};

export type InvoiceFilter = {
   invoiceNumber: string;
   dueDate: {
      from: Date;
      to: Date;
   };
   priceRange: {
      min: string;
      max: string;
   };
   statusKey: string;
};

export type InvoiceQueryParams = { companyNumber: string; division: string } & (
   | {
        invoiceNumber?: string;
     }
   | {
        fromDueDate?: string;
        toDueDate?: string;
        fromInvoiceAmount?: number;
        toInvoiceAmount?: number;
        status?: string;
     }
);

export type Invoice = {
   invoiceNumber: string;
   customerNumber: string;
   invoiceDate: string;
   dueDate: string;
   invoiceAmount: number;
   invoiceAmountCurrency: string;
   amountToPay: number;
   amountToPayCurrency: string;
   orderNumbers: [];
   debtCollectionCaseNumber: string;
   status: INVOICE_STATUS;
   url: string;
   deliveries: null;
};

export interface CalendarActions {
   openCalendar(): void;
   closeCalendar(): void;
}
