import { parseISO } from "date-fns";
import every from "lodash-es/every";

import type { Claim, ClaimsResponse } from "../../common/types/claimTypes";

/**
 * Turns a ClaimResponse into Claim[] by converting each order line reference in the claim into a list of Claim's
 */
export const splitClaimResponseIntoClaims = (claimResp: ClaimsResponse): Claim => ({
   key: claimResp.notes_unid,
   isApproved: every(claimResp.ordrelinjer, (orderLine) => orderLine.godkjent),
   isUnderProcess: claimResp.status_reklamasjon === "Til godkjenning",
   orderNumber: claimResp.tine_ordrenr,
   dateCreated: parseISO(claimResp.opprettet.substring(0, claimResp.opprettet.indexOf("T"))),
   rawData: claimResp
});
