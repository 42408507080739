import { store } from "@risingstack/react-easy-state";
import { remove } from "lodash-es";
import defaultTo from "lodash-es/defaultTo";
import find from "lodash-es/find";
import isUndefined from "lodash-es/isUndefined";
import type { MouseEventHandler } from "react";
import { v4 as uuid } from "uuid";

import { sendErrorMessage } from "../common/tracking";
import type { BaseStoreType } from "../common/types/BaseStoreType";
import type { ToastType } from "../common/types/toastTypes";
import type { GAError } from "../common/types/trackingTypes";

type ToastStore = BaseStoreType & {
   toasts: ToastType[];
   addSuccess(title: string, message: string): void;
   addError(title: string, message: string, gaErrorTracking?: GAError, persist?: boolean): void;
   hasDuplicate(toast: ToastType): boolean;
   addToast(
      title: string,
      message: string,
      type: "success" | "error",
      persist: boolean,
      hideDelay?: number,
      onClick?: MouseEventHandler
   ): void;
};

const toastStore: ToastStore = store({
   toasts: [],

   addSuccess: (title, message) => {
      toastStore.addToast(title, message, "success", false);
   },

   addError: (title, message, gaErrorTracking, persist = false) => {
      gaErrorTracking && sendErrorMessage(gaErrorTracking);
      toastStore.addToast(title, message, "error", persist);
   },

   hasDuplicate: (toast) => {
      const search = {
         title: toast.title,
         message: toast.message,
         type: toast.type
      };
      const existing = find(toastStore.toasts, search);
      return !isUndefined(existing);
   },

   addToast: (title, message, type, persist, hideDelay, onClick) => {
      const id = uuid();
      const duration = defaultTo(hideDelay, message.length > 50 ? 5000 : 3000);

      const toast: ToastType = {
         id,
         title,
         message,
         type,
         persist,
         duration,
         show: false,
         onClick
      };

      // If there is already a toast with this exact title, message and type, we just abort.
      if (toastStore.hasDuplicate(toast)) {
         return;
      }

      toastStore.toasts.push(toast);

      // Removes toast from list of toasts after the duration time
      if (toast.duration > 0 && !persist) {
         // This toast actually cleans up the array 500 ms later, so we don't accumulate hidden Toast elements
         setTimeout(() => {
            remove(toastStore.toasts, { id });
         }, duration + 500);
      }
   },
   clearCompanySpecificData: () => {
      toastStore.toasts = [];
   },
   clearUserSpecificData: () => {
      // add methods to clear user specific data
   }
});

export default toastStore;
