import { view } from "@risingstack/react-easy-state";
import type { SignUpRepeaterData } from "../../../common/types/promotionTypes";
import Button from "../../Button";
import { validateEmail, validatePhone } from "../../formvalidation/FormUtils/FieldValidators";

import Bugsnag from "@bugsnag/js";
import { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import type { Company, User } from "../../../common/types/companyTypes";
import type { DynamicsCaptureConfig, DynamicsCaptureMapping } from "../../../common/types/dynamicsTypes";
import { scriptUrl } from "../../../common/utils/dynamicsUtils";
import type { FormValuesToSubmit } from "../../formvalidation/FormTypes";
import ValidatedForm from "../../formvalidation/ValidatedForm";
import ValidatedInput from "../../formvalidation/ValidatedInput";

type CmsPromotionSignupFormProps = {
   buttonText: string;
   signup: (values: FormValuesToSubmit) => Promise<boolean>;
   additionalFields: SignUpRepeaterData;
   dynamicsMapping: SignUpRepeaterData;
   dynamicsConfig: DynamicsCaptureConfig;
   company: Company;
   user: User;
};

type ValidatorProp = {
   validator?: (value: string, required?: boolean) => string;
};

const DYNAMICS_B2B_CUSTOMER_RELATIONSHIP_CODE = "809020003";

const getCaptureMappings = (
   mappingsFromContentful: SignUpRepeaterData,
   additionalFields: SignUpRepeaterData
): DynamicsCaptureMapping[] => {
   const visibleFieldNames = additionalFields.map((field) => field.key);
   const mappingData: SignUpRepeaterData = [...mappingsFromContentful, { key: "customertypecode", value: "customertypecode" }];
   return mappingData.map((mapping) => {
      const captureMapping: DynamicsCaptureMapping = {
         DataverseFieldName: mapping.value,
         FormFieldName: mapping.key,
         label: ""
      };
      if (visibleFieldNames.includes(mapping.key)) {
         captureMapping.label = mapping.value;
      }
      return captureMapping;
   });
};

const CmsPromotionSignupForm = ({
   additionalFields,
   dynamicsMapping,
   dynamicsConfig,
   buttonText,
   company,
   user,
   signup
}: CmsPromotionSignupFormProps) => {
   const formRef = useRef<HTMLFormElement>(null);
   const [loading, setLoading] = useState<boolean>(false);
   const currentCompany: Record<string, unknown> = company;
   const currentUser: Record<string, unknown> = user;

   const handleSubmit = async (validatedFormFields: FormValuesToSubmit) => {
      let error: Error | string | undefined;
      const captureMappings = getCaptureMappings(dynamicsMapping, additionalFields);
      const form = formRef.current;

      try {
         setLoading(true);
         const signedUp = await signup(validatedFormFields);
         if (signedUp && form && dynamicsMapping.length) {
            const serializedForm = window.d365mktformcapture.serializeForm(form, captureMappings);
            const dynamicsPayload = serializedForm.SerializedForm.build();
            await window.d365mktformcapture.submitForm(dynamicsConfig, dynamicsPayload);
         }
         return { resetFormFields: true };
      } catch (err) {
         error = typeof err === "string" || err instanceof Error ? err : "D365 form capture submission error";
         Bugsnag.notify(error, (e) => {
            e.context = "Promotion signup form capture";
            e.addMetadata("signup", {
               payload: validatedFormFields,
               mappings: captureMappings
            });
         });
         console.warn(err);
         return { resetFormFields: false };
      } finally {
         setLoading(false);
      }
   };

   const getValidatorProp = (fieldName: string): ValidatorProp =>
      /email/i.test(fieldName) ? { validator: validateEmail } : /phone/i.test(fieldName) ? { validator: validatePhone } : {};

   const visibleFieldNames = additionalFields.map((field) => field.key);

   const hiddenFields = dynamicsMapping.filter((mapping) => !visibleFieldNames.includes(mapping.key));

   const getValueForHiddenField = (fieldName: string): string => {
      if (fieldName.includes(".")) {
         const [type, property, ..._] = fieldName.split(".");
         if (type === "company") {
            if (property in currentCompany && typeof currentCompany[property] === "string") {
               return currentCompany[property];
            }
         }
         if (type === "user") {
            if (property in currentUser && typeof currentUser[property] === "string") {
               return currentUser[property];
            }
         }
      }
      return "";
   };

   return (
      <>
         <Helmet>
            {!window.d365mktformcapture && (
               <script
                  src={scriptUrl}
                  onError={() => {
                     const error = new Error("Error loading script");
                     Bugsnag.notify(error, (e) => {
                        e.context = "Dynamics Form Capture script load";
                        e.addMetadata("signup", { scriptUrl: scriptUrl });
                     });
                     console.warn("Error loading Dynamics Form Capture script");
                  }}
               />
            )}
         </Helmet>
         <ValidatedForm onSubmit={handleSubmit} ref={formRef}>
            {additionalFields.map((field) => (
               <ValidatedInput
                  key={field.key}
                  initialValue=""
                  name={`${field.key}`}
                  label={field.value}
                  required={true}
                  className="tw-mb-4"
                  {...getValidatorProp(field.key)}
               />
            ))}
            {hiddenFields.map((hiddenField) => (
               <input key={hiddenField.key} name={hiddenField.key} hidden value={getValueForHiddenField(hiddenField.key)} />
            ))}
            <input name="customertypecode" hidden value={DYNAMICS_B2B_CUSTOMER_RELATIONSHIP_CODE} />
            <Button type="submit" className="tw-mr-3" variant="primary" size="regular" loading={loading}>
               {buttonText}
            </Button>
         </ValidatedForm>
      </>
   );
};

export default view(CmsPromotionSignupForm);
