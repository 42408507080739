import type { Recipient } from "../../common/types/recipientTypes";
import featuresStore from "../features/featuresStore";
import dynamicsRecipientsStore, { type RecipientsStore } from "./dynamicsRecipientsStore";
import legacyRecipientsStore from "./legacyRecipientsStore";

export const NEW_RECIPIENT = Symbol("NEW_RECIPIENT");

const recipientsStore: RecipientsStore = {
   get recipients() {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.recipients;
      }
      return legacyRecipientsStore.recipients;
   },

   get categories() {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.categories;
      }
      return legacyRecipientsStore.categories;
   },

   get currentlyEditingId() {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.currentlyEditingId;
      }
      return legacyRecipientsStore.currentlyEditingId;
   },

   set currentlyEditingId(value) {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         dynamicsRecipientsStore.currentlyEditingId = value;
      } else {
         legacyRecipientsStore.currentlyEditingId = value;
      }
   },

   get currentlyDeletingId() {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.currentlyDeletingId;
      }
      return legacyRecipientsStore.currentlyDeletingId;
   },

   set currentlyDeletingId(value) {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         dynamicsRecipientsStore.currentlyDeletingId = value;
      } else {
         legacyRecipientsStore.currentlyDeletingId = value;
      }
   },

   get requestProgress() {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.requestProgress;
      }
      return legacyRecipientsStore.requestProgress;
   },

   fetchRecipients: async () => {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.fetchRecipients();
      }
      return legacyRecipientsStore.fetchRecipients();
   },

   saveNewRecipient: async (recipient: Recipient) => {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.saveNewRecipient(recipient);
      }
      return legacyRecipientsStore.saveNewRecipient(recipient);
   },

   updateRecipient: async (recipient: Recipient) => {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.updateRecipient(recipient);
      }
      return legacyRecipientsStore.updateRecipient(recipient);
   },

   deleteRecipient: async () => {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.deleteRecipient();
      }
      return legacyRecipientsStore.deleteRecipient();
   },

   getNotificationCategoryName: (queryId) => {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.getNotificationCategoryName(queryId);
      }
      return legacyRecipientsStore.getNotificationCategoryName(queryId);
   },

   setModalVisibility: (modalType, recipientId) => {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.setModalVisibility(modalType, recipientId);
      }
      return legacyRecipientsStore.setModalVisibility(modalType, recipientId);
   },

   clearCompanySpecificData: () => {
      if (featuresStore.useDynamicsForNotificationProfiles) {
         return dynamicsRecipientsStore.clearCompanySpecificData();
      }
      return legacyRecipientsStore.clearCompanySpecificData();
   }
};

export default recipientsStore;
