import type { ComponentType } from "react";

const calendarHeader = new URL("../../../../img/julekalender_header.png", import.meta.url).toString();
const calendarHeaderMobile = new URL("../../../../img/julekalender_header_mobil.png", import.meta.url).toString();
export const AdventCalendarHeader: ComponentType = () => {
   return (
      <>
         <img className="tw-w-full tw-h-full tw-hidden sm:tw-block" src={calendarHeader} alt="Julekalender-header" />
         <img className="tw-w-full tw-h-full tw-block sm:tw-hidden" src={calendarHeaderMobile} alt="Julekalender-header-mobil" />
      </>
   );
};
