import { view } from "@risingstack/react-easy-state";
import { Grid, type GridProps } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import isNil from "lodash-es/isNil";

import type { EmbeddedEntryContextType } from "../../../common/types/cmsTypes";
import type { Product, ProductTileCallbacks } from "../../../common/types/productTypes";
import type { GAListInformation, LIST_TYPE } from "../../../common/types/trackingTypes";

import productStore from "../../../stores/product/productStore";

import Button from "../../Button";
import ProductTile from "./ProductTile";

type ProductListProps = {
   heading?: string | null;
   subtitle?: string | null;
   products?: Product[];
   skus?: string[];
   count?: number;
   buttonText?: string;
   buttonUrl?: string;
   listName: string;
   listType: LIST_TYPE;
   context: EmbeddedEntryContextType;
   callbacks?: ProductTileCallbacks;
};

const ProductList = ({
   heading,
   subtitle,
   products,
   skus = [],
   count,
   buttonText,
   buttonUrl,
   listName,
   listType,
   context,
   callbacks = {}
}: ProductListProps) => {
   const resolvedProducts: Product[] = !isNil(products) ? products : productStore.resolveSkus(skus);
   if (resolvedProducts.length === 0) {
      console.log("No products resolved: ", skus, resolvedProducts);
      return null;
   }

   const visibleSlice = !isNil(count) ? resolvedProducts.slice(0, count) : resolvedProducts;
   const hasSubtitle = !isNil(subtitle);

   const listInformation: GAListInformation = { type: listType, name: listName, length: visibleSlice.length };

   const standardArticleTileStyle = cn(
      "tw-h-auto",
      "tw-basis-full min-[240px]:tw-basis-[156px] min-[375px]:tw-basis-[171px] min-[424px]:tw-basis-[49%] min-[585px]:tw-basis-[32%] md:tw-basis-[24%] lg:tw-basis-[19.2%] xl:tw-basis-[16%]",
      "min-[240px]:tw-max-w-40 min-[375px]:tw-max-w-44 min-[424px]:tw-max-w-[49%] min-[585px]:tw-max-w-[32%] md:tw-max-w-[24%] lg:tw-max-w-[19.2%] xl:tw-max-w-[16%]"
   );
   if (context === "standard-article") {
      return (
         <div className="tw-w-full">
            <div className="tw-mx-auto tw-w-3/4 tw-max-w-[848px] tw-text-center">
               <h2 className="tw-mb-4">{heading}</h2>
               {hasSubtitle && <p className="tw-mb-4">{subtitle}</p>}

               {buttonUrl && buttonText && (
                  <Button to={buttonUrl} variant="secondary">
                     {buttonText}
                  </Button>
               )}
            </div>
            <div className="tw-flex tw-flex-shrink tw-grow tw-flex-row tw-flex-wrap tw-justify-center tw-gap-2">
               {visibleSlice.map((product, i) => (
                  <ProductTile
                     key={product.sku}
                     product={product}
                     callbacks={callbacks}
                     listInformation={listInformation}
                     className={standardArticleTileStyle}
                     position={i + 1}
                  />
               ))}
            </div>
         </div>
      );
   }

   const gridProps: Omit<GridProps, "children"> = {
      type: "container",
      cols: {
         base: 1,
         "300px": 2,
         "530px": 3,
         "750px": 4,
         "950px": 5,
         "1180px": 6
      },
      gap: {
         base: 1,
         "300px": 2
      }
   };

   const productGrid = (
      <Grid {...gridProps}>
         {visibleSlice.map((product, i) => (
            <ProductTile
               key={product.sku}
               product={product}
               callbacks={callbacks}
               listInformation={listInformation}
               position={i + 1}
            />
         ))}
      </Grid>
   );

   if (context === "category" || context === "search") {
      return productGrid;
   }

   return (
      <div className="tw-w-full">
         {!isNil(heading) && (
            <div className="tw-max-w-[680px]">
               <h2>{heading}</h2>
               {hasSubtitle && <p>{subtitle}</p>}
               {buttonUrl && buttonText && (
                  <Button to={buttonUrl} variant="secondary">
                     {buttonText}
                  </Button>
               )}
            </div>
         )}
         {productGrid}
      </div>
   );
};

export default view(ProductList);
