import { view } from "@risingstack/react-easy-state";
import isNil from "lodash-es/isNil";

import type { RecipeType } from "../../../common/types/recipeTypes";
import { LIST_TYPE } from "../../../common/types/trackingTypes";
import ProductList from "../../common/product/ProductList";

import productStore from "../../../stores/product/productStore";

type RecipeProductListProps = {
   recipe: RecipeType;
};

// This product list should be left aligned to work with the design on the rest of the page
const RecipeProductList = ({ recipe }: RecipeProductListProps) => {
   const { ingredientGroups } = recipe;

   const skus = ingredientGroups
      .flatMap((ingredientGroup) => ingredientGroup.ingredientLines.map((ingredientLine) => ingredientLine.ingredient))
      .filter((ingredient) => ingredient.articleNo)
      .map((ingredient) => ingredient.articleNo || "");

   const uniqueSkus = [...new Set(skus)].filter((sku) => !isNil(productStore.resolveSku(sku)));

   if (uniqueSkus.length > 0) {
      return (
         <ProductList
            skus={uniqueSkus}
            listName="Tine-produkter i oppskrift"
            listType={LIST_TYPE.RECIPE}
            heading={"Du vil trenge"}
            context="recipe"
         />
      );
   }
   return null;
};

export default view(RecipeProductList);
