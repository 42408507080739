import type { RecipeStepType, StepGroupType } from "../../../common/types/recipeTypes";
import { hasContent } from "../../../common/utils";

import WhiteSheet from "../../WhiteSheet";
import Step from "./Step";
import StepTip from "./StepTip";

type InstructionsProps = {
   stepGroups: StepGroupType[];
   tip?: string | null;
};

type HeadingType = {
   heading: string;
};

type ListType = RecipeStepType | HeadingType;

// Function that takes an array of step groups and returns an array of steps and headers so we can easily enumerate them all
const generateStepsAndHeaders = (stepGroups: StepGroupType[]) => {
   const stepsAndHeaders: ListType[] = [];
   for (const stepGroup of stepGroups) {
      if (stepGroup.name) {
         stepsAndHeaders.push({ heading: stepGroup.name });
      }
      for (const step of stepGroup.steps) {
         stepsAndHeaders.push(step);
      }
   }

   return stepsAndHeaders;
};

const Instructions = ({ stepGroups, tip }: InstructionsProps) => {
   const stepsAndHeaders = generateStepsAndHeaders(stepGroups);
   let stepNumber = 0;
   return (
      <>
         <h2 className="tw-mb-4">Slik gjør du</h2>
         <WhiteSheet className="tw-mb-12">
            {stepsAndHeaders.map((item, i) => {
               if ("heading" in item) {
                  return (
                     <h3 key={`heading-${i + 1}`} className="tw-mb-6 tw-mt-8">
                        {item.heading}
                     </h3>
                  );
               }

               if ("instruction" in item) {
                  stepNumber++;
                  return <Step key={`step-${i + 1}`} stepNumber={stepNumber} step={item} />;
               }

               return <p key={JSON.stringify(item)}>Unknown item: {JSON.stringify(item)}</p>;
            })}

            {hasContent(tip) && <StepTip title="Tips" tip={tip} variant="tip" />}
         </WhiteSheet>
      </>
   );
};

export default Instructions;
