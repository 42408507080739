import { createContext } from "react";

import type { FormValue, FormValues } from "./FormTypes";

export const FormContext = createContext({
   updateFormValues: <T,>(value: FormValue<T>) => {},
   registerField: <T,>(value: FormValue<T>) => {},
   unregisterField: (value: string) => {},
   isFormValid: true,
   formValues: {} as FormValues
});
