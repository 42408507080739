import { type FormEvent, type ReactNode, forwardRef, useState } from "react";

import { FormContext } from "./FormContext";
import type { FormValue, FormValues, FormValuesToSubmit } from "./FormTypes";

type ValidatedformProps = {
   children: ReactNode;
   onSubmit: (formValues: FormValuesToSubmit) => Promise<{ resetFormFields?: boolean }>;
} & Omit<React.FormHTMLAttributes<HTMLFormElement>, "onSubmit">;

const ValidatedForm = forwardRef<HTMLFormElement, ValidatedformProps>(({ children, onSubmit, ...props }, ref) => {
   const [formValues, setFormValues] = useState<FormValues>({});
   const [initialValues, setInitialValues] = useState<FormValues>({});
   const [isFormValid, setIsFormValid] = useState(true);

   //this function is passed to the children components and updates the formValues state here
   const updateFormValues = <T,>(value: FormValue<T>) => {
      setFormValues((prevFormValues) => {
         const formvalue = prevFormValues[value.name];
         if (formvalue) {
            const updatedFormValues = { ...prevFormValues, [value.name]: value };
            return updatedFormValues;
         }
         return prevFormValues;
      });
   };

   const registerField = <T,>(value: FormValue<T>) => {
      setFormValues((prevFormValues) => {
         const formvalue = prevFormValues[value.name];
         if (formvalue) {
            throw new Error(`Field with name ${value.name} already exists. Field names must be unique`);
         }
         const updatedFormValues = { ...prevFormValues, [value.name]: value };
         return updatedFormValues;
      });

      setInitialValues((prevInitial) => ({
         ...prevInitial,
         [value.name]: prevInitial[value.name] ?? value
      }));
   };

   const unregisterField = (name: string) => {
      setFormValues((prevFormValues) => {
         const formvalue = prevFormValues[name];
         if (formvalue) {
            const updatedFormValues = { ...prevFormValues };
            delete updatedFormValues[name];
            return updatedFormValues;
         }
         return prevFormValues;
      });
   };

   const validateForm = () => {
      const hasErrors = Object.values(formValues).some((value) => value.error !== "");
      setIsFormValid(!hasErrors);
      return !hasErrors;
   };

   const resetForm = () => {
      setFormValues(initialValues);
   };

   const submitform = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!validateForm()) {
         return;
      }

      const formValuestoSubmit: FormValuesToSubmit = {};

      for (const value of Object.values(formValues)) {
         formValuestoSubmit[value.name] = value.value;
      }

      const result = await onSubmit(formValuestoSubmit);

      if (result.resetFormFields) {
         resetForm();
      }
   };

   return (
      <form onSubmit={submitform} {...props} ref={ref}>
         <FormContext.Provider value={{ updateFormValues, registerField, unregisterField, formValues, isFormValid }}>
            {children}
         </FormContext.Provider>
      </form>
   );
});

export default ValidatedForm;
