import { Tag } from "@tine/designsystem-ui-react";
import { isInteger } from "lodash-es";

type RecipePreparationTimeProps = {
   workTime?: number | null;
   workTimeRange?: number | null;
};

const RecipePreparationTime = ({ workTime, workTimeRange }: RecipePreparationTimeProps) => {
   if (typeof workTime === "number" && Number.isInteger(workTime)) {
      const text =
         typeof workTimeRange === "number" && isInteger(workTimeRange) && workTimeRange > workTime
            ? `${workTime} - ${workTimeRange} min`
            : `${workTime} min`;
      return <Tag variant="base">{text}</Tag>;
   }
   return <></>;
};

export default RecipePreparationTime;
