import { view } from "@risingstack/react-easy-state";

import useMediaQuery from "../common/useMediaQuery";
import { mobileBreakpoint } from "../common/utils";

import theme from "../themes/theme";

type StoreLogoProps = {
   placement: "searchbar" | "searchbarMobile" | "footer";
} & React.HTMLAttributes<HTMLImageElement>;

const StoreLogoComponent = ({ placement, ...rest }: StoreLogoProps) => {
   const isMobile = useMediaQuery(mobileBreakpoint);
   if (!theme.storeLogo[placement]) {
      return;
   }
   const width = theme.storeLogo[placement].mobileWidth
      ? isMobile
         ? theme.storeLogo[placement].mobileWidth
         : theme.storeLogo[placement].width
      : theme.storeLogo[placement].width;

   return <img width={width} src={theme.storeLogo[placement].url} {...rest} alt={`${theme.storeCompanyName} logo`} />;
};

export default view(StoreLogoComponent);
