import { batch } from "@risingstack/react-easy-state";

export enum RequestProgress {
   defaultData = "defaultData",
   loading = "loading",
   errorOccured = "errorOccured",
   dataAvailable = "dataAvailable",
   updating = "updating"
}

export type AsyncData<T> =
   | {
        data: T;
        progress:
           | RequestProgress.defaultData
           | RequestProgress.dataAvailable
           | RequestProgress.loading
           | RequestProgress.updating;
        abortController?: AbortController;
     }
   | {
        data: T;
        progress: RequestProgress.errorOccured;
        abortController?: AbortController;
        errorMessage?: string;
     };

export const isAbortError = (error: unknown): error is DOMException => {
   return error instanceof DOMException && error.name === "AbortError";
};

export const abortMessage = "Request aborted due to new request.";

/** AsyncData flow represented as a Record*/
const asyncDataFlow: Record<RequestProgress, RequestProgress[]> = {
   defaultData: [RequestProgress.loading],
   loading: [RequestProgress.errorOccured, RequestProgress.dataAvailable],
   errorOccured: [RequestProgress.loading],
   dataAvailable: [RequestProgress.loading, RequestProgress.updating],
   updating: [RequestProgress.errorOccured, RequestProgress.dataAvailable]
};

const isValidFlow = (prevProgress: RequestProgress, newProgress: RequestProgress) =>
   asyncDataFlow[prevProgress].includes(newProgress);

/** Sets the new AsyncData if the progress transition matches the AsyncData flow */
const attemptProgressTransition = <T>(exisitingData: AsyncData<T>, newAsyncData: AsyncData<T>) => {
   if (isValidFlow(exisitingData.progress, newAsyncData.progress)) {
      if ("errorMessage" in exisitingData) {
         exisitingData.errorMessage = undefined;
      }
      batch(() => {
         Object.assign(exisitingData, newAsyncData);
      });
      return true;
   }
   console.warn("Invalid transition: ", exisitingData.progress, " to ", newAsyncData.progress);

   return false;
};

export const initializeWithDefaultData = <T>(defaultData: T): AsyncData<T> => ({
   progress: RequestProgress.defaultData,
   data: defaultData
});

export const setAsLoading = <T>(currentAsyncData: AsyncData<T>) => {
   if (isLoading(currentAsyncData) && currentAsyncData.abortController) {
      currentAsyncData.abortController.abort(abortMessage);
      currentAsyncData.abortController = new AbortController();
      return currentAsyncData.abortController;
   }

   const controller = new AbortController();
   attemptProgressTransition(currentAsyncData, {
      data: currentAsyncData.data,
      progress: RequestProgress.loading,
      abortController: controller
   });
   return controller;
};

export const setAsErrorOccured = <T>(currentAsyncData: AsyncData<T>, errorMessage: string) => {
   if (typeof errorMessage === "string" && errorMessage === abortMessage) {
      return;
   }

   attemptProgressTransition(currentAsyncData, {
      data: currentAsyncData.data,
      progress: RequestProgress.errorOccured,
      errorMessage
   });
};

export const setAsDataAvailable = <T>(currentAsyncData: AsyncData<T>, newData?: T) =>
   attemptProgressTransition(currentAsyncData, {
      data: newData ?? currentAsyncData.data,
      progress: RequestProgress.dataAvailable
   });

export const setAsUpdating = <T>(currentAsyncData: AsyncData<T>) => {
   if (isUpdating(currentAsyncData) && currentAsyncData.abortController) {
      currentAsyncData.abortController.abort(abortMessage);
      currentAsyncData.abortController = new AbortController();
      return currentAsyncData.abortController;
   }

   const controller = new AbortController();
   attemptProgressTransition(currentAsyncData, {
      data: currentAsyncData.data,
      progress: RequestProgress.updating,
      abortController: controller
   });
   return controller;
};

/**
 * Transitions progress to either "loading" or "updating" depending on if data is already available or not
 * @returns boolean indicating if the transition was allowed
 */
export const setAsWaitingForData = <T>(currentAsyncData: AsyncData<T>) => {
   const isNextStateLoading = isDefaultData(currentAsyncData) || isLoading(currentAsyncData) || isErrorOccured(currentAsyncData);
   return isNextStateLoading ? setAsLoading(currentAsyncData) : setAsUpdating(currentAsyncData);
};
export const isDefaultData = <T>(asyncData: AsyncData<T>) => asyncData.progress === RequestProgress.defaultData;

export const isDataAvailable = <T>(asyncData: AsyncData<T>) => asyncData.progress === RequestProgress.dataAvailable;

export const isLoading = <T>(asyncData: AsyncData<T>) => asyncData.progress === RequestProgress.loading;

export const isErrorOccured = <T>(asyncData: AsyncData<T>) => asyncData.progress === RequestProgress.errorOccured;

export const isUpdating = <T>(asyncData: AsyncData<T>) => asyncData.progress === RequestProgress.updating;

export const isWaitingForData = <T>(asyncData: AsyncData<T>) => isLoading(asyncData) || isUpdating(asyncData);
