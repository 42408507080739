import { view } from "@risingstack/react-easy-state";
import type { Entry } from "contentful";

import type {
   ContentEntryType,
   ContentfulAdventCalendarSlot,
   ContentfulArticle,
   ContentfulContestForm,
   ContentfulImageLink,
   ContentfulLargeTeaser,
   ContentfulLogos,
   ContentfulRecipe,
   ContentfulSection,
   ContentfulSignupButton,
   ContentfulTextAndImage,
   ContentfulYoutubeVideo,
   EmbeddedEntryContextType
} from "../../common/types/cmsTypes";
import type {
   TypeAdventCalendarSlotSkeleton,
   TypeAutomaticGeneratedProductListSkeleton,
   TypeCallToActionListSkeleton,
   TypeContestFormSkeleton,
   TypeImageLinkSkeleton,
   TypeImageWithFocusSkeleton,
   TypeLargeTeaserSkeleton,
   TypeLogosSkeleton,
   TypeProductListSkeleton,
   TypeRecipeSkeleton,
   TypeSignupButtonSkeleton,
   TypeV2_articleSkeleton,
   TypeV2_sectionSkeleton,
   TypeV2_textAndImageSkeleton,
   TypeYoutubeVideoSkeleton
} from "../../common/types/contentful";

import LogoList from "../LogoList";
import Article from "./Article";
import CmsAutomaticProductList from "./CmsAutomaticProductList";
import CmsManualProductList from "./CmsManualProductList";
import CmsPromotionSignup from "./CmsPromotionSignup";
import CmsYoutube from "./CmsYoutube";
import ContestForm from "./ContestForm";
import EntryImageLink from "./EntryImageLink";
import ImageWithFocus from "./ImageWithFocus";
import LargeTeaser from "./LargeTeaser";
import Section from "./Section";
import TeaserList from "./TeaserList";
import TextAndImage from "./TextAndImage";
import AdventCalendar from "./advent-calendar/AdventCalendar";
import AdventCalendarArticle from "./advent-calendar/AdventCalendarArticle";
import Recipe from "./recipe/Recipe";
import UspList from "./usp/UspList";

type CmsEmbeddedEntryProps = {
   content: Entry<
      | TypeImageWithFocusSkeleton
      | TypeV2_textAndImageSkeleton
      | TypeProductListSkeleton
      | TypeAutomaticGeneratedProductListSkeleton
      | TypeCallToActionListSkeleton
      | TypeV2_articleSkeleton
      | TypeRecipeSkeleton
      | TypeImageLinkSkeleton
      | TypeYoutubeVideoSkeleton
      | TypeV2_sectionSkeleton
      | TypeSignupButtonSkeleton
      | TypeContestFormSkeleton
      | TypeAdventCalendarSlotSkeleton
      | TypeLargeTeaserSkeleton
      | TypeLogosSkeleton,
      undefined,
      "nb_NO"
   >;
   type: ContentEntryType;
   entryId: string;
   context: EmbeddedEntryContextType;
};

const CmsEmbeddedEntry = ({ content, type, entryId, context }: CmsEmbeddedEntryProps) => {
   switch (type) {
      case "imageWithFocus": {
         const imageEntry = content as Entry<TypeImageWithFocusSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>;
         return <ImageWithFocus width={1250} ratio="16x9" entryId={entryId} imageFromParent={imageEntry} placement="belowFold" />;
      }
      case "v2_textAndImage": {
         const textAndImageEntry = content as ContentfulTextAndImage;
         return <TextAndImage content={textAndImageEntry} />;
      }
      case "largeTeaser": {
         const largeTeaserEntry = content as ContentfulLargeTeaser;
         return <LargeTeaser content={largeTeaserEntry} />;
      }
      case "logos": {
         const logosEntry = content as ContentfulLogos;
         return <LogoList content={logosEntry} />;
      }
      case "productList": {
         const productListEntry = content as Entry<TypeProductListSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>;
         return <CmsManualProductList content={productListEntry} context={context} />;
      }
      case "automaticGeneratedProductList": {
         const automaticProductListEntry = content as Entry<TypeAutomaticGeneratedProductListSkeleton, undefined, "nb_NO">;
         return <CmsAutomaticProductList content={automaticProductListEntry} context={context} />;
      }
      case "callToActionList": {
         const callToActionListEntry = content as Entry<TypeCallToActionListSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>;
         const allAdventCalendarSlots = callToActionListEntry.fields.contentList?.every(
            (item) => item?.sys.contentType?.sys.id === "adventCalendarSlot"
         );
         const allUsp = callToActionListEntry.fields.contentList?.every((item) => item?.sys.contentType?.sys.id === "usp");
         if (allAdventCalendarSlots) {
            return <AdventCalendar content={callToActionListEntry} />;
         }
         if (allUsp) {
            return <UspList content={callToActionListEntry} />;
         }
         return <TeaserList content={callToActionListEntry} />;
      }
      case "v2_article": {
         const articleEntry = content as ContentfulArticle;
         return <Article id={entryId} article={articleEntry} />;
      }
      case "adventCalendarSlot": {
         const adventCalendarEntry = content as ContentfulAdventCalendarSlot;
         return <AdventCalendarArticle adventCalendarArticleContent={adventCalendarEntry} />;
      }
      case "contestForm": {
         const contestFormEntry = content as ContentfulContestForm;
         return <ContestForm id={entryId} entry={contestFormEntry} />;
      }

      case "v2_section": {
         const sectionEntry = content as ContentfulSection;
         return <Section id={entryId} section={sectionEntry} />;
      }
      case "recipe": {
         const recipeEntry = content as ContentfulRecipe;
         return <Recipe id={entryId} recipeEntry={recipeEntry} />;
      }
      case "imageLink": {
         const imageLinkEntry = content as ContentfulImageLink;
         const target: ContentfulArticle | ContentfulRecipe = imageLinkEntry.fields.entry as ContentfulArticle | ContentfulRecipe;
         const imageWithFocus = imageLinkEntry.fields.image;
         const title = imageLinkEntry.fields.text;
         const subTitle = imageLinkEntry.fields.caption;
         if (imageWithFocus) {
            const id = target.sys.id;
            const type = target.sys.contentType.sys.id === "v2_article" ? "article" : "recipe";
            return (
               <EntryImageLink id={id} type={type} imageWithFocusId={imageWithFocus.sys.id} title={title} subTitle={subTitle} />
            );
         }
         return null;
      }
      case "youtubeVideo": {
         const youtubeEntry = content as ContentfulYoutubeVideo;
         return <CmsYoutube content={youtubeEntry} />;
      }
      case "signupButton": {
         const campaignSignup = content as ContentfulSignupButton;
         return <CmsPromotionSignup content={campaignSignup} />;
      }
      default:
         return <div>Fant ikke visningen for innhold av typen {type}</div>;
   }
};

export default view(CmsEmbeddedEntry);
