import { cn } from "@tine/designsystem-utils";

type FullWidthBackgroundProps = {
   color: string;
};

/**
 * FullWidthBackground Component
 *
 * This component creates a full-width background that spans the entire viewport
 * width, regardless of the parent container's width. It is designed to be used
 * in a layout where a background color or image is desired to extend beyond the
 * confines of the container.
 *
 * @param {string} color - The Tailwind CSS class for the background color.
 *
 * @returns {JSX.Element} The rendered FullWidthBackground component.
 *
 * @example
 * // Usage in a parent component
 * import FullWidthBackground from '../FullWidthBackground';
 *
 * <div className="tw-relative">
 *    {someCondition && <FullWidthBackground color="tw-bg-some-color" />}
 * </div>
 *
 * @note
 * Ensure that the parent container has `relative` positioning to correctly
 * align the absolute positioning of this component.
 *
 * tw-w-[calc(100vw-15px)] is used to account for the scrollbar width in the viewport.
 */

const FullWidthBackground = ({ color }: FullWidthBackgroundProps): JSX.Element => {
   return (
      <div
         className={cn(
            color,
            "tw-absolute tw-left-1/2 -tw-z-10 tw-h-full tw-w-[calc(100vw-15px)] -tw-translate-x-1/2 tw-transform"
         )}
      />
   );
};

export default FullWidthBackground;
