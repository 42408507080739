import { Select, type SelectProps } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import { useEffect, useRef, useState } from "react";

type ZValue = 0 | 10 | 20 | 30 | 40 | 50 | "auto";
type SelectWrapperProps = SelectProps & { zOnOpen?: ZValue };

const zStyle = {
   0: "!tw-z-0",
   10: "!tw-z-10",
   20: "!tw:-z-20",
   30: "!tw-z-30",
   40: "!tw-z-40",
   50: "!tw-z-50",
   auto: "!tw-z-auto"
};

const SelectWapper = ({ zOnOpen = 0, className = "", ...props }: SelectWrapperProps) => {
   const ref = useRef<HTMLDivElement>(null);
   const [open, setOpen] = useState<boolean>(false);

   const selectClassName = cn(className, { [zStyle[zOnOpen]]: open });

   useEffect(() => {
      const mutationCallback = () => {
         if (ref.current) {
            const ul = ref.current.querySelector("ul");
            if (ul) {
               setOpen(true);
               return;
            }
         }
         setOpen(false);
      };
      let observer: MutationObserver | null = null;
      if (ref.current) {
         observer = new MutationObserver(mutationCallback);
         observer.observe(ref.current, { subtree: true, childList: true });
      }

      return () => {
         if (observer) {
            observer.disconnect();
         }
      };
   }, [ref]);

   return (
      <div ref={ref} className="tw-w-full">
         <Select {...props} className={selectClassName} />
      </div>
   );
};

export default SelectWapper;
