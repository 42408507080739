import { Input } from "@tine/designsystem-ui-react";
import { type ChangeEvent, type InputHTMLAttributes, useEffect, useState } from "react";
import PasswordStrengthBar, { type PasswordStrengthBarProps } from "react-password-strength-bar";

import type { SupportedLocaleType } from "../common/types/languageTypes";

type PasswordFieldProps = InputHTMLAttributes<HTMLInputElement> & {
   value: string;
   locale?: SupportedLocaleType;
   strengthBar?: boolean;
   allowClearText?: boolean;
   validator?: (value: string) => string;
   label?: string;
   isInvalid?: boolean;
   type?: "password" | "text";
};
type StrengthBarPropsByLocale = {
   [L in SupportedLocaleType]: Omit<PasswordStrengthBarProps, "password">;
};

const strengthBarPropsByLocale: StrengthBarPropsByLocale = {
   "nb-NO": {
      scoreWords: ["veldig svakt", "svakt", "ok", "bra", "sterkt"],
      shortScoreWord: ["for kort"]
   },
   "en-US": {}
};

const PasswordField = ({
   strengthBar = false,
   allowClearText = false,
   locale = "nb-NO",
   validator,
   onChange,
   value,
   min = 8,
   label = "Nytt passord",
   type = "password",
   className = "",
   isInvalid,
   ...rest
}: PasswordFieldProps) => {
   const [error, setError] = useState<string>("");

   const strengthBarProps = strengthBarPropsByLocale[locale];

   const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
      const error = validator?.(e.target.value) || "";
      validator && setError(validator(e.target.value));
   };

   useEffect(() => {
      validator && setError(validator(value));
   }, [isInvalid]);

   return (
      <div>
         <Input
            label={label}
            value={value}
            onChange={onPasswordChange}
            type={type}
            errorMessage={error}
            showErrorMessage={isInvalid || !!error}
            {...rest}
         />
         {strengthBar ? (
            <div className="tw-pt-1">
               <PasswordStrengthBar {...strengthBarProps} password={value} minLength={Number(min)} />
            </div>
         ) : null}
      </div>
   );
};

export default PasswordField;
