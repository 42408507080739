import { view } from "@risingstack/react-easy-state";
import { IconNokkelhull, IconNytNorge, IconPant2Kr, IconPant3Kr } from "@tine/designsystem-icons/sharp";
import isNil from "lodash-es/isNil";

import { type Badge, PageType } from "../common/types/productTypes";

type BadgesProps = {
   badges: Badge[];
   page: PageType;
};
const badgeIconMap = {
   nokkelhullet: IconNokkelhull,
   nyt_norge: IconNytNorge,
   pant_2kr: IconPant2Kr,
   pant_3kr: IconPant3Kr
};

const Badges = ({ badges, page }: BadgesProps) => {
   if (isNil(badges) || badges.length === 0) {
      return null;
   }

   const iconClass = page === PageType.ProductDetailsPage ? "tw-w-10 tw-h-10" : "tw-w-8 tw-h-8";
   const outerClass =
      page === PageType.ProductDetailsPage ? "tw-right-[-7px] tw-top-7 tw-w-10" : "-tw-right-[3px] tw-top-7 tw-w-8";
   const visibleBadges = badges.slice(0, 3); // Show max 3 badges

   return (
      <div className={`tw-absolute ${outerClass}`}>
         {visibleBadges.map((b) => {
            const IconComponent = badgeIconMap[b.key];
            return IconComponent ? <IconComponent key={b.key} className={iconClass} /> : null;
         })}
      </div>
   );
};

export default view(Badges);
