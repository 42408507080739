import { view } from "@risingstack/react-easy-state";
import useLoginRequired from "../../common/hooks/useLoginRequired";
import NotLoggedIn from "../../components/NotLoggedIn";
import PowerBIReport from "../../components/PowerBIReport";
import authStore from "../../stores/auth/authStore";

/**
 * Page for showing the "Årets Matglede" campaign report.
 */
const JoyOfFoodCampaignPage = () => {
   useLoginRequired("SEGMENTED_CONTENT");

   if (!authStore.isLoggedIn()) {
      return <NotLoggedIn />;
   }

   return (
      <PowerBIReport
         reportName="aretsmatglede"
         title={`Utvikling for ${authStore.getCurrentCompany()?.name}`}
         mobileHeightRatio={4}
      />
   );
};

export default view(JoyOfFoodCampaignPage);
