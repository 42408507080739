import searchStore from "../../stores/searchStore";

import { DEFAULT_LOCALE, type SupportedLocaleType } from "../types/languageTypes";
import {
   type AlgoliaHit,
   type AlgoliaResultCallbacks,
   type Localized,
   SEARCH_TYPE,
   type SearchDomain,
   type SearchDomainType,
   type SearchResults
} from "../types/searchTypes";

export const getLocalizedValue = <T>(obj: Localized<T>, preferredLocale: SupportedLocaleType): T | undefined =>
   (preferredLocale in obj ? obj[preferredLocale] : undefined) || obj[DEFAULT_LOCALE];

export const createCallbacks = (searchDomain: SearchDomain, queryID: string): AlgoliaResultCallbacks => {
   return {
      addToCart: (hit: AlgoliaHit) => {
         searchStore.reportAlgoliaAddToCart(hit.objectID, searchDomain.indexName, queryID, true, SEARCH_TYPE.Full);
      },
      click: (hit: AlgoliaHit, _listName: string, position: number) => {
         searchStore.reportAlgoliaClick(hit.objectID, searchDomain.indexName, queryID, position, true, SEARCH_TYPE.Full);
      }
   };
};

export const getLocalizedString = getLocalizedValue<string>;

export const getMaxHitsForSearchDomain = (
   searchDomainType: SearchDomainType,
   resultsWithHits: SearchResults[],
   isLg: boolean,
   isXl: boolean
) => {
   const results = resultsWithHits.find((result) => result.searchDomainType === searchDomainType);
   if (results) {
      if (searchDomainType === "product") {
         if (isXl) {
            return resultsWithHits.length > 1 ? 12 : 15;
         }
         if (isLg) {
            return resultsWithHits.length > 1 ? 9 : 12;
         }
         return resultsWithHits.length > 1 ? 5 : 8;
      }
      const productResults = resultsWithHits.find((result) => result.searchDomainType === "product");
      const isNarrow = productResults ? resultsWithHits.length > 2 : resultsWithHits.length > 1;
      if (productResults) {
         if (isNarrow) {
            return 2;
         }
         return isXl ? 6 : 3;
      }
      if (isNarrow) {
         return 4;
      }
      return 8;
   }
   return 0;
};
