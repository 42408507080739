import { view } from "@risingstack/react-easy-state";
import { useCallback, useEffect } from "react";

declare global {
   interface Document extends Record<string, unknown> {
      msHidden: boolean;
      webkitHidden: boolean;
   }
}

function getBrowserVisibilityEventName() {
   if (typeof document.visibilityState !== "undefined" || typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      return "visibilitychange";
   }
   if (typeof document.msHidden !== "undefined") {
      return "msvisibilitychange";
   }
   if (typeof document.webkitHidden !== "undefined") {
      return "webkitvisibilitychange";
   }
}

const getBrowserDocumentVisibilityProp = () =>
   ["visibilityState", "hidden", "msHidden", "webkitHidden"].find((p) => typeof document[p] !== "undefined");

const desiredValueForVisible: Record<string, string | boolean> = {
   visibilityState: "visible",
   hidden: false,
   msHidden: false,
   webkitHidden: false
};

const getIsDocumentVisible = () => {
   const propName = getBrowserDocumentVisibilityProp();
   return propName !== undefined && document[propName] === desiredValueForVisible[propName];
};

type SessionCheckerProps = {
   onChange(isVisible: boolean): void;
};

const SessionChecker = ({ onChange }: SessionCheckerProps) => {
   const handleVisiblityChange = useCallback(() => onChange(getIsDocumentVisible()), [onChange]);

   useEffect(() => {
      const visibilityEventName = getBrowserVisibilityEventName();
      if (!visibilityEventName) {
         console.warn("Unable to determine browser visibility event name for this broweser, disabling visiblity change handler.");
         return;
      }
      document.addEventListener(visibilityEventName, handleVisiblityChange, false);
      return () => {
         document.removeEventListener(visibilityEventName, handleVisiblityChange);
      };
   });

   return null;
};

export default view(SessionChecker);
