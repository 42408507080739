import { type Options, type RenderNode, documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, type Block, type Document, type Inline, type Text } from "@contentful/rich-text-types";
import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "@tine/designsystem-ui-react";
import { cn } from "@tine/designsystem-utils";
import React, { type ReactNode } from "react";

import { extractTextFromReactNode } from "../../common/utils";

export type TextTags = "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "ul" | "ol" | "table" | "li";

const fullWidth = "tw-w-full tw-mx-0";
const mdThreeQuarters = "md:tw-w-3/4 md:tw-mx-auto tw-max-w-[848px]";
const lgHalf = "lg:tw-w-1/2";
const lgFull = "lg:tw-w-full lg:tw-mx-0";
const mdSevenTwelfths = "md:tw-w-7/12 md:tw-mx-auto tw-max-w-[848px]";

export const cmsTextColumnClassNames = {
   absoluteFull: fullWidth,
   threeQuarters: cn(fullWidth, mdThreeQuarters),
   half: cn(fullWidth, mdThreeQuarters, lgHalf),
   full: cn(fullWidth, mdThreeQuarters, lgFull),
   sevenTwelfths: cn(fullWidth, mdSevenTwelfths)
};

export type ColumnWidthType = "full" | "threeQuarters" | "half" | "absoluteFull";

export const RichTextColumn = ({ children, columnWidth }: { children: ReactNode; columnWidth?: ColumnWidthType }) => {
   const width: ColumnWidthType = columnWidth ?? "full";
   return <div className={cmsTextColumnClassNames[width]}>{children}</div>;
};

export const RichTextListItem = ({ node, customRenderNodes }: { node: Block | Inline; customRenderNodes: RenderNode }) => {
   const listItemDocument: Document = {
      nodeType: BLOCKS.DOCUMENT,
      content: [{ nodeType: BLOCKS.EMBEDDED_RESOURCE, content: node.content, data: {} }],
      data: {}
   };
   const customRenderOptions: Options = {
      renderNode: {
         ...customRenderNodes
      }
   };
   return <li className="tw-list-outside">{documentToReactComponents(listItemDocument, customRenderOptions)}</li>;
};

export const RichTextTable = ({ table, customRenderNodes }: { table: Block | Inline; customRenderNodes: RenderNode }) => {
   const toBlockOrInline = (item: Text | Block | Inline): Block | Inline | undefined => {
      if ("content" in item) {
         return item as Block | Inline;
      }
      return undefined;
   };

   const hasHeader = table.content.map((rows) =>
      toBlockOrInline(rows)?.content.find((cell) => cell.nodeType === "table-header-cell")
   );

   const headerRow = hasHeader ? toBlockOrInline(table.content[0])?.content : undefined;
   const tableRows = hasHeader ? table.content.slice(1) : table.content;

   const getHeaderName = (index: number) => {
      const headerCell = headerRow && toBlockOrInline(headerRow[index]);
      const headerContent = headerCell && getCellContent(headerCell);

      if (React.isValidElement(headerContent)) {
         return extractTextFromReactNode(headerContent);
      }

      return "";
   };

   const getCellContent = (cell: Block | Inline) => {
      const tableCellDocument: Document = {
         nodeType: BLOCKS.DOCUMENT,
         content: [
            {
               nodeType: BLOCKS.EMBEDDED_RESOURCE,
               content: cell.content,
               data: {}
            }
         ],
         data: {}
      };
      const customRenderOptions: Options = {
         renderNode: {
            ...customRenderNodes
         }
      };
      return <>{documentToReactComponents(tableCellDocument, customRenderOptions)}</>;
   };

   return (
      <Table fullWidth className="tw-my-8">
         {headerRow && (
            <TableHeader>
               <TableRow>
                  {headerRow.map((_, cellIndex) => (
                     <TableHeaderCell id={cellIndex.toString()} key={`${cellIndex + 1}`}>
                        {getHeaderName(cellIndex)}
                     </TableHeaderCell>
                  ))}
               </TableRow>
            </TableHeader>
         )}
         <TableBody>
            {/* biome-ignore lint/suspicious/noExplicitAny: <explanation> */}
            {tableRows.map((row: any, rowIndex: number) => (
               <TableRow key={`${rowIndex + 1}`}>
                  {row.content.map((cell: Block | Inline, cellIndex: number) => (
                     <TableCell headers={getHeaderName(cellIndex)} key={`${cellIndex + 1}`}>
                        {getCellContent(cell)}
                     </TableCell>
                  ))}
               </TableRow>
            ))}
         </TableBody>
      </Table>
   );
};
