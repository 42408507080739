import { view } from "@risingstack/react-easy-state";
import { Stack } from "@tine/designsystem-ui-react";

import type { IngredientGroupType } from "../../../common/types/recipeTypes";

import IngredientLine from "./IngredientLine";

type IngredientGroupProps = {
   ingredientGroup: IngredientGroupType;
   multiplier?: number;
};

const IngredientGroup = ({ ingredientGroup, multiplier = 1 }: IngredientGroupProps) => {
   const { name, ingredientLines } = ingredientGroup;

   return (
      <div>
         {!!name && <p className="tw-text-sm tw-font-semibold">{name}</p>}
         <Stack align="start" gap={0} className="tw-divide-x-0 tw-divide-y tw-divide-solid tw-divide-ink-border-subtle">
            {ingredientLines.map((ingredientLine, i) => (
               <IngredientLine
                  key={`${ingredientLine.ingredient.singular}-${i}`}
                  ingredientLine={ingredientLine}
                  multiplier={multiplier}
               />
            ))}
         </Stack>
      </div>
   );
};

export default view(IngredientGroup);
