import type { ContentfulRecipe } from "../types/cmsTypes";
import type { SupportedLocaleType } from "../types/languageTypes";
import type { RecipeDifficultyType, RecipeType } from "../types/recipeTypes";

export const getRecipeForRecipeEntry = (entry: ContentfulRecipe): RecipeType | undefined => {
   const jsonRecipe = entry.fields.recipeData;
   if (jsonRecipe && !Array.isArray(jsonRecipe)) {
      if ("name" in jsonRecipe && "description" in jsonRecipe && "displayName" in jsonRecipe && "difficulty" in jsonRecipe) {
         return jsonRecipe as RecipeType;
      }
   }
};
export const difficultyTexts: { [locale in SupportedLocaleType]: { [difficulty in RecipeDifficultyType]: string } } = {
   "nb-NO": {
      easy: "Enkel",
      medium: "Medium",
      difficult: "Utfordrende"
   },
   "en-US": {
      easy: "Easy",
      medium: "Medium",
      difficult: "Challenging"
   }
};
