import { view } from "@risingstack/react-easy-state";
import { IconExpandMore } from "@tine/designsystem-icons/sharp";
import { cn } from "@tine/designsystem-utils";
import { useRef, useState } from "react";
import { Link } from "wouter";

import { sendInternalLinkClick } from "../../common/tracking";
import type { CategoryInfo } from "../../common/types/categoryTypes";
import type { MenuItem } from "../../common/types/cmsTypes";
import { titleCase } from "../../common/utils";
import MainColumn from "../../components/common/MainColumn";
import Popover from "../../components/common/popover/Popover";

import categoryStore from "../../stores/categories/categoryStore";
import contentStore from "../../stores/cms/contentStore";
import segmentStore from "../../stores/cms/segmentStore";
import productStore from "../../stores/product/productStore";

import theme from "../../themes/theme";
import MegaMenu from "./MegaMenu";

type CategoryProps = {
   cat: CategoryInfo;
};

const itemCN = cn(["tw-py-[--topnav-categories-padding-y]", "!tw-text-headerFooterText"]);

const Category = view(({ cat }: CategoryProps) => {
   const [show, setShow] = useState(false);
   const parentRef = useRef<HTMLDivElement>(null);

   return (
      <div ref={parentRef}>
         <div className="tw-font-medium">
            <button type="button" className={cn(itemCN, "tw-ps-0")} onClick={() => setShow(!show)}>
               {titleCase(cat.name)}
               <IconExpandMore className="tw-inline" />
            </button>
         </div>

         <Popover
            className="tw-w-[100vw]"
            placement="bottom"
            onHide={() => setShow(false)}
            show={show}
            referenceElement={parentRef.current}
            container={MainColumn}
         >
            <MegaMenu cat={cat} onHide={() => setShow(false)} />
         </Popover>
      </div>
   );
});

const categoriesClassName = cn(["tw-w-full tw-bg-headerFooterBackground"]);

const categoriesNavigationContainerClassName = cn([
   "tw-flex tw-shrink-0 tw-grow tw-flex-row tw-items-center",
   "tw-w-full tw-py-[--topnav-padding-y]"
]);

const categoriesItemsClassName = cn(["tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-start tw-gap-4"]);

const Categories = () => {
   const { categories } = categoryStore;
   const { productDropdownEnabled } = theme;
   const categoryTree = productDropdownEnabled
      ? categories
      : (categories[0]?.children || categories).filter((cat) => productStore.resolveSkus(cat.skus, true).length);

   const isMenuItemVisible = (menuItem: MenuItem) =>
      (menuItem.contentType === "v2_article" && segmentStore.isArticleVisible(menuItem.id)) ||
      menuItem.contentType !== "v2_article";

   return (
      <div className={categoriesClassName}>
         <MainColumn>
            <nav className={categoriesNavigationContainerClassName}>
               <div className={categoriesItemsClassName}>
                  {productDropdownEnabled && categoryTree.map((cat) => <Category key={cat.id} cat={cat} />)}
                  {!productDropdownEnabled &&
                     categoryTree.map((cat) => (
                        <div key={cat.id} className="tw-py-[--topnav-categories-padding-y] tw-font-medium">
                           <Link
                              className={itemCN}
                              to={cat.fullUrl}
                              onClick={() => sendInternalLinkClick(cat.fullUrl, "Navbar", cat.name)}
                           >
                              {cat.name}
                           </Link>
                        </div>
                     ))}
                  {contentStore.mainMenuItems.map(
                     (menuItem) =>
                        isMenuItemVisible(menuItem) && (
                           <div key={menuItem.id}>
                              <Link
                                 className={itemCN}
                                 to={menuItem.url}
                                 onClick={() => sendInternalLinkClick(menuItem.url, "Navbar", titleCase(menuItem.title))}
                              >
                                 {titleCase(menuItem.title)}
                              </Link>
                           </div>
                        )
                  )}
               </div>
            </nav>
         </MainColumn>
      </div>
   );
};

export default view(Categories);
