import type { SiteConfig } from "../common/types/themeTypes";

import diplomisSiteConfig from "./diplomis";
import isdalenSiteConfig from "./isdalen";
import leverantSiteConfig from "./leverant";
import tineSiteConfig from "./tine";

console.log("Theme selected: ", process.env.THEME_NAME);

const theme: SiteConfig =
   process.env.THEME_NAME === "tine"
      ? tineSiteConfig
      : process.env.THEME_NAME === "diplomis"
        ? diplomisSiteConfig
        : process.env.THEME_NAME === "isdalen"
          ? isdalenSiteConfig
          : leverantSiteConfig;

export default theme;
