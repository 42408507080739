import { autoEffect, batch, store } from "@risingstack/react-easy-state";
import find from "lodash-es/find";
import isNil from "lodash-es/isNil";
import uniq from "lodash-es/uniq";

import { apiClient, ensureWretchError } from "../../common/apiClient";
import {
   type NotificationCategory,
   RECIPIENT_MODAL,
   type Recipient,
   type RecipientsResponse
} from "../../common/types/recipientTypes";
import {
   type AsyncData,
   initializeWithDefaultData,
   setAsDataAvailable,
   setAsErrorOccured,
   setAsWaitingForData
} from "../../common/utils/asyncDataUtils";

import { API_HOST } from "../../common/environment";
import type { BaseStoreType } from "../../common/types/BaseStoreType";
import authStore from "../auth/authStore";
import { NEW_RECIPIENT } from "./recipientsStore";
import { getDeleteRequestData, toApiRecipient } from "./recipientsUtils";

export type RecipientsStore = BaseStoreType & {
   recipients: AsyncData<Recipient[]>;
   categories: AsyncData<NotificationCategory[]>;
   requestProgress: AsyncData<boolean>;
   currentlyEditingId: string | typeof NEW_RECIPIENT | null;
   currentlyDeletingId: string | null;

   fetchRecipients(): Promise<void>;
   saveNewRecipient(recipient: Recipient): Promise<void>;
   updateRecipient(recipient: Recipient): Promise<void>;
   deleteRecipient(): Promise<void>;
   getNotificationCategoryName(queryId: string): string | undefined;
   setModalVisibility(modalType: RECIPIENT_MODAL, recipientId?: string): void;
};

const dynamicsRecipientsStore: RecipientsStore = store({
   recipients: initializeWithDefaultData([]),
   categories: initializeWithDefaultData([]),
   currentlyEditingId: null,
   currentlyDeletingId: null,
   requestProgress: initializeWithDefaultData(false),

   fetchRecipients: async () => {
      const abortController = setAsWaitingForData(dynamicsRecipientsStore.recipients);
      setAsWaitingForData(dynamicsRecipientsStore.categories);

      if (isNil(authStore.currentCompany)) {
         return Promise.reject();
      }

      return apiClient(`${API_HOST}/api/notification_profiles/${authStore.currentCompany}`, authStore.getSessionToken())
         .signal(abortController)
         .get()
         .json()
         .then((res) => {
            const response = res as RecipientsResponse;

            const recipients = response.ProfileList.map(
               (profile) =>
                  ({
                     profileId: profile.Id,
                     firstName: profile.FirstName ?? "",
                     lastName: profile.LastName ?? "",
                     email: profile.Email ?? "",
                     mobile: profile.Mobile ?? "",
                     emailNotifications: profile.EmailNotifications,
                     smsNotifications: profile.SmsNotifications,
                     allNotifications: uniq([...profile.EmailNotifications, ...profile.SmsNotifications])
                  }) satisfies Recipient
            );

            const categories = response.SubscriptionInformation.map(
               (category) =>
                  ({
                     id: category.Id,
                     name: category.NameNo ?? category.Name ?? "Ukjent kategori"
                  }) satisfies NotificationCategory
            );

            setAsDataAvailable(dynamicsRecipientsStore.recipients, recipients);
            setAsDataAvailable(dynamicsRecipientsStore.categories, categories);
         })
         .catch((errorObject) => {
            const wretchError = ensureWretchError(errorObject);
            if (wretchError) {
               console.log("An request failure occurred while fetching recipients: ", wretchError);
            } else {
               console.log("An error occurred while fetching recipients: ", errorObject);
            }

            setAsErrorOccured(dynamicsRecipientsStore.recipients, `${errorObject}`);
            setAsErrorOccured(dynamicsRecipientsStore.categories, `${errorObject}`);
         });
   },

   saveNewRecipient: async (recipient: Recipient) => {
      const transitionedSuccessfully = setAsWaitingForData(dynamicsRecipientsStore.requestProgress);

      if (
         !transitionedSuccessfully ||
         isNil(authStore.currentCompany) ||
         dynamicsRecipientsStore.currentlyEditingId !== NEW_RECIPIENT
      ) {
         return Promise.reject();
      }

      return apiClient(`${API_HOST}/api/notification_profiles/${authStore.currentCompany}`, authStore.getSessionToken())
         .json(toApiRecipient(recipient))
         .post()
         .res((res) => {
            setAsDataAvailable(dynamicsRecipientsStore.requestProgress, res.success);
            void dynamicsRecipientsStore.fetchRecipients();
            dynamicsRecipientsStore.setModalVisibility(RECIPIENT_MODAL.NONE);
         })
         .catch((errorObject) => {
            setAsErrorOccured(dynamicsRecipientsStore.requestProgress, `${errorObject}`);
         });
   },

   updateRecipient: async (recipient: Recipient) => {
      const transitionedSuccessfully = setAsWaitingForData(dynamicsRecipientsStore.requestProgress);

      if (
         !transitionedSuccessfully ||
         isNil(authStore.currentCompany) ||
         typeof dynamicsRecipientsStore.currentlyEditingId !== "string"
      ) {
         return Promise.reject();
      }

      return apiClient(`${API_HOST}/api/notification_profiles/${authStore.currentCompany}`, authStore.getSessionToken())
         .json(toApiRecipient(recipient))
         .put()
         .res((res) => {
            setAsDataAvailable(dynamicsRecipientsStore.requestProgress, res.success);
            void dynamicsRecipientsStore.fetchRecipients();
            dynamicsRecipientsStore.setModalVisibility(RECIPIENT_MODAL.NONE);
         })
         .catch((errorObject) => {
            setAsErrorOccured(dynamicsRecipientsStore.requestProgress, `${errorObject}`);
         });
   },

   deleteRecipient: async () => {
      const transitionedSuccessfully = setAsWaitingForData(dynamicsRecipientsStore.requestProgress);

      if (!transitionedSuccessfully || isNil(authStore.currentCompany) || dynamicsRecipientsStore.currentlyDeletingId === null) {
         return Promise.reject();
      }

      return apiClient(`${API_HOST}/api/notification_profiles/${authStore.currentCompany}`, authStore.getSessionToken())
         .json(getDeleteRequestData(dynamicsRecipientsStore.currentlyDeletingId))
         .delete()
         .res((res) => {
            setAsDataAvailable(dynamicsRecipientsStore.requestProgress, res.success);
            void dynamicsRecipientsStore.fetchRecipients();
            dynamicsRecipientsStore.setModalVisibility(RECIPIENT_MODAL.NONE);
         })
         .catch((errorObject) => {
            setAsErrorOccured(dynamicsRecipientsStore.requestProgress, `${errorObject}`);
         });
   },

   getNotificationCategoryName: (queryId) =>
      find(dynamicsRecipientsStore.categories.data, (category) => category.id === queryId)?.name,

   setModalVisibility: (modalType: RECIPIENT_MODAL, recipientId?: string) => {
      batch(() => {
         dynamicsRecipientsStore.currentlyDeletingId = null;
         dynamicsRecipientsStore.currentlyEditingId = null;
         dynamicsRecipientsStore.requestProgress = initializeWithDefaultData(false);

         if (modalType === RECIPIENT_MODAL.DELETE && recipientId) {
            dynamicsRecipientsStore.currentlyDeletingId = recipientId;
         } else if (modalType === RECIPIENT_MODAL.ADD) {
            dynamicsRecipientsStore.currentlyEditingId = NEW_RECIPIENT;
         } else if (modalType === RECIPIENT_MODAL.EDIT && recipientId) {
            dynamicsRecipientsStore.currentlyEditingId = recipientId;
         }
      });
   },

   clearCompanySpecificData: () => {
      dynamicsRecipientsStore.recipients = initializeWithDefaultData([]);
      dynamicsRecipientsStore.categories = initializeWithDefaultData([]);
   }
});

/** Temporary helper logging out contents every time the store changes */
autoEffect(() => {
   console.log("Currently Editing: ", dynamicsRecipientsStore.currentlyEditingId);
   console.log("Currently Deleting: ", dynamicsRecipientsStore.currentlyDeletingId);
});

export default dynamicsRecipientsStore;
